import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import './Playlist.css'
import '../Explore/Explore.css'
import Container from '@mui/material/Container'
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import userProfile from "../../img/dster-webp/creator-user.webp"
import creatorAvatar from "../../img/dster-webp/creator-avatar.webp"
import { useTheme } from '@mui/material/styles';
import { Stack } from "@mui/material";
import Copy from "../../img/dster-webp/copy.webp"
import addbutton from '../../img/dster-webp/add-button.webp'
import artist1 from '../../img/dster-webp/artist1.webp'
import artist2 from '../../img/dster-webp/artist2.webp'
import artist3 from '../../img/dster-webp/artist3.webp'
import artist4 from '../../img/dster-webp/artist4.webp'
import '../Particpate/Particpate.css'
import Cards from '../../img/dster-webp/card-1.webp';
import Cards1 from '../../img/dster-webp/card-2.webp';
import Cards3 from '../../img/dster-webp/card-3.webp';
import Cards12 from '../../img/dster-webp/card-12.webp';
import icon from '../../img/dster-webp/c-icon.webp';
import cardmenu from '../../img/dster-webp/card-menu.webp';
import Link from '@mui/material/Link';
import join from '../../img/dster-webp/join-icon.svg';
import ListIcon from '@mui/icons-material/List';
import HeaderNew from "../HeaderNew/HeaderNew";



const content3 = [
    { img: `${artist1}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist2}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist4}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist1}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist2}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
    { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
    { img: `${artist4}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" }
]



const content5 = [
    { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "Follow" },
]

const drawerWidth = 280;

const title = 'Welcome';

function Playlist() {
    const theme = useTheme();

    const navigate = useNavigate()

    return (
        <div className="explore dashboard playlist">
            <Box sx={{ display: 'flex' }}>

                <HeaderNew />
                <Box component="main"
                    className="explore-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', marginBottom: '100px' }}>


                    <Grid container spacing={0} className="heading-container">
                        <Container maxWidth="lg">
                            <Grid xs={12} sm={12} md={8} lg={8} xl={12}>
                                <div className="explore-heading">
                                    Dashboard
                                </div>
                            </Grid>
                        </Container>

                    </Grid>

                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 3 }}
                        className="explore-box2"
                    >

                        <Container maxWidth="xl">
                            <Grid container spacing={2} className="whole-flex-spc">
                                <Grid xs={12} sm={12} md={12} lg={9} xl={9} className="dash-spc">
                                    <Grid container spacing={2} className="dash-spc-contain dash-spc-contain-mid-part">
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6} className="dash-spc dash-spc-left">
                                            <Card sx={{ display: 'flex' }} className="explore-card1 explore-card1-new" onClick={((e) => { navigate('/premiumtracks', { state: "Premium Tracks" }) })} >
                                                <Stack flexDirection="row" className="select-tract-box" sx={{ display: 'flex' }}>
                                                    <CardContent className="select-tract-box-left" sx={{ flex: '1 0 auto' }}>
                                                        <div className="content-heading">
                                                            Select Your Track
                                                        </div>
                                                        <div className="btn-flex Choose-from-album">
                                                            <div className="dash-btn lib"><a href="/">Choose from album</a></div>
                                                        </div>
                                                    </CardContent>
                                                    <div className="select-tract-box-right">
                                                        <Card className="card-participate">
                                                            <div className="img-card">
                                                                <img src={Cards12} />
                                                            </div>
                                                            <label>Kiiara Castillo</label>
                                                            <p>@eatsleepdesignrepeat</p>
                                                        </Card>
                                                    </div>
                                                </Stack>
                                            </Card>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={1} xl={1} className="dash-spc dash-spc-mid">
                                            <div className="addbutton"><img src={addbutton} alt="addbutton" /></div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={5} xl={5} className="dash-spc dash-spc-right">

                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="follow-us-grid">
                                                <Card sx={{ display: 'flex' }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                                            <div className="content-heading">
                                                                Select Trending playlist
                                                            </div>
                                                            <div className="content-btn dash-btn lib"><a>Follow</a></div>
                                                            {content5.map((row, obj) => {
                                                                return (
                                                                    <>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="artist">
                                                                            <div className="Card">
                                                                                <CardMedia
                                                                                    sx={{ height: 200 }}
                                                                                    image={row.img}
                                                                                    title="green iguana"
                                                                                    className="artist-img"
                                                                                />
                                                                                <div className="artist-txt">
                                                                                    <div className="love-txt1">
                                                                                        {row.txt1}
                                                                                    </div>
                                                                                    <div className="love-txt2">
                                                                                        {row.txt2}
                                                                                    </div>
                                                                                    <div className="btn artist-follow">
                                                                                        {row.btn}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            })}
                                                        </CardContent>

                                                    </Box>
                                                    {/* <CardMedia
                                                component="img"
                                                sx={{ width: 200, height: 150 }}
                                                src={row.img}
                                                alt="Live from space album cover"
                                            /> */}
                                                </Card>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} className="dash-spc-contain CREATOR-block-outer">
                                        <Grid xs={12} sm={12} md={12} lg={6} xl={6} className="dash-spc CREATOR-block-inner">
                                            <div className="creator_card creator_card-inner">
                                                <div className="token_creator token_creator-inner">
                                                    <h6>CREATOR</h6>
                                                    <div className="user_profile">
                                                        <div><img src={userProfile} alt='userprofile' /></div>
                                                        <div>
                                                            <p>Kiiara Castillo</p>
                                                            <span>@eatsleepdesignrepeat</span>
                                                        </div>
                                                    </div>
                                                    <div className="user_profile counts">
                                                        <div>
                                                            <p>Total Tokens</p>
                                                            <h6>3.69 Dster</h6>
                                                        </div>
                                                        <div>
                                                            <p>Ends in</p>
                                                            <h6>04h 12m</h6>
                                                        </div>
                                                    </div>
                                                    <div className="outer_copyright">
                                                        <div className="copyright">
                                                            <p>Dster Token address</p>
                                                            <img src={Copy} alt='Copy' />
                                                        </div>
                                                        <span>0xaF489a41e001dDeaFeC79829df5D56CC4b3a9Aa5</span>
                                                    </div>


                                                </div>
                                                <div className="dash-btn buy"><a href="/">BUY TOKENS</a></div>
                                                <div className="creatorAvatar"><img src={creatorAvatar} alt="creatorAvatar" /></div>

                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={1} xl={1} className="dash-spc">

                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={5} xl={5} className="dash-spc your-top-mix-classname">
                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className="explore-content-heading">
                                                    Your Top MIX
                                                </div>
                                            </Grid>
                                            <div className='participate-card'>
                                                <Grid container className='Your-Top-MIX-part'>
                                                    <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                                        <Card className="card-participate">
                                                            <div className="img-card">
                                                                <img src={Cards12} />
                                                            </div>
                                                            <div className="card-body card-body-title-card">
                                                                <div className="card-title">
                                                                    <p>Jump</p>
                                                                    <p>mainnet</p>
                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <ListIcon />
                                                                </div>
                                                            </div>
                                                            <div className="card-body card-body--card">
                                                                <div className="card-title">
                                                                    <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                </div>
                                                                <div className="toggle-icon">

                                                                    <img src={icon} />
                                                                </div>
                                                            </div>
                                                            <div className="join-btn">
                                                                <div className="join-icon">
                                                                    <img src={join} />
                                                                </div>
                                                                <Link to="#">

                                                                    Join 100 Dster</Link>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                                        <Card className="card-participate">

                                                            <div className="img-card">
                                                                <img src={Cards} />
                                                            </div>
                                                            <div className="card-body card-body-title-card">
                                                                <div className="card-title">
                                                                    <p>ManTra</p>
                                                                    <p>mainnet</p>
                                                                </div>
                                                                <div className="toggle-icon">

                                                                    <ListIcon />
                                                                </div>

                                                            </div>
                                                            <div className="card-body card-body--card">
                                                                <div className="card-title">
                                                                    <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <img src={icon} />
                                                                </div>
                                                            </div>
                                                            <div className="join-btn">
                                                                <div className="join-icon">
                                                                    <img src={join} />
                                                                </div>
                                                                <Link to="#">Join 100 Dster</Link>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                                        <Card className="card-participate">

                                                            <div className="img-card">
                                                                <img src={Cards1} />
                                                            </div>
                                                            <div className="card-body card-body-title-card">
                                                                <div className="card-title">
                                                                    <p>DOJO</p>
                                                                    <p>mainnet</p>
                                                                </div>

                                                                <div className="toggle-icon">

                                                                    <ListIcon />
                                                                </div>


                                                            </div>
                                                            <div className="card-body card-body--card">
                                                                <div className="card-title">
                                                                    <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <img src={icon} />
                                                                </div>
                                                            </div>
                                                            <div className="join-btn">
                                                                <div className="join-icon">
                                                                    <img src={join} />
                                                                </div>
                                                                <Link to="#">Join 100 Dster</Link>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                                        <Card className="card-participate">

                                                            <div className="img-card">
                                                                <img src={Cards3} />
                                                            </div>
                                                            <div className="card-body card-body-title-card">
                                                                <div className="card-title">
                                                                    <p>DOJO</p>
                                                                    <p>mainnet</p>
                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <img src={cardmenu} />
                                                                </div>


                                                            </div>
                                                            <div className="card-body card-body--card">
                                                                <div className="card-title">
                                                                    <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                </div>
                                                                <div className="toggle-icon">
                                                                    <img src={icon} />
                                                                </div>
                                                            </div>
                                                            <div className="join-btn">
                                                                <div className="join-icon">
                                                                    <img src={join} />
                                                                </div>
                                                                <Link to="#">Join 100 Dster</Link>
                                                            </div>
                                                        </Card>
                                                    </Grid>
                                                    {/* 
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards4} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Slime</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                              <ListIcon />
                                          </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards5} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Mmangwane Mpulele</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                                <ListIcon />
                                            </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards6} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Blues-corn</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                              <ListIcon />
                                          </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                              
                                            <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards7} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Blues-corn</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                                <ListIcon />
                                            </div>

                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>


                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards8} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Jump</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                              <ListIcon />
                                          </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards9} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Jump</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                              <ListIcon />
                                          </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards10} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Jump</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                                <ListIcon />
                                            </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
                                    <Card  className="card-participate">

                                        <div className="img-card">
                                            <img src={Cards11} />
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>Jump</p>
                                                <p>mainnet</p>
                                            </div>
                                            <div className="toggle-icon">
                                              
                                                <ListIcon />
                                            </div>


                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                <p>24-01-2024 I 15:35 to 17:45  </p>

                                            </div>
                                            <div className="toggle-icon">
                                                <img src={icon} />
                                            </div>
                                        </div>
                                        <div className="join-btn">
                                        <div className="join-icon">
                                                <img src={join} />
                                            </div>
                                            <Link to="#">Join 100 Dster</Link>
                                        </div>
                                    </Card>
                                </Grid> */}
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={3} xl={3} className="dash-spc">
                                    <Grid container spacing={2} className="dash-spc-contain">
                                        {/* <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="dash-spc">
                        </Grid> */}

                                        <Container maxWidth="lg" className="artist_top_cards-container">
                                            <Grid container spacing={2} className="artist_top_cards">
                                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="artist_top_cards-grid">
                                                    <div className="explore-content-heading">
                                                        Top Playlist
                                                    </div>
                                                </Grid>
                                                <div className="top-playlist-items-list">
                                                    {content3.map((row, obj) => {
                                                        return (

                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="artist">
                                                                <div className="Card">
                                                                    <CardMedia
                                                                        sx={{ height: 200 }}
                                                                        image={row.img}
                                                                        title="green iguana"
                                                                        className="artist-img"
                                                                    />
                                                                    <div className="artist-txt">
                                                                        <div className="love-txt1">
                                                                            {row.txt1}
                                                                        </div>
                                                                        <div className="love-txt2">
                                                                            {row.txt2}
                                                                        </div>
                                                                        <div className="btn artist-follow">
                                                                            {row.btn}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    })}
                                                </div>
                                            </Grid>
                                        </Container>


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>

                        {/* <div>
                            <Track  audiosource={vijay}/>
                        </div> */}
                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default Playlist;