import React, { useState, useEffect, useRef } from "react";
import "./ExploreNew.css";
import HeaderNew from "../HeaderNew/HeaderNew";
import Axios from "../../Axios";
import ava1 from "../../img/dster-webp/ava1.webp";
import RepeatIcon from "@mui/icons-material/Repeat";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Companylogo1 from "../../img/dster-webp/musicwave.gif";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import OwlCarousel from "react-owl-carousel";
import { useTheme } from "@mui/material/styles";
import Tracks from "../Music-traxk/Tracks";
import { useSelector } from "react-redux";
import play from "../../img/dster-webp/paly.webp";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import fdp3 from "../../img/dster-webp/fdplpas.webp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ToastContainer, toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import ntr1 from "../../img/dster-webp/ntr1.webp";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import i3 from "../../img/dster-webp/i3.webp";
import { useNavigate } from "react-router-dom";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ct1 from "../../img/dster-webp/ct1.webp";
import ct2 from "../../img/dster-webp/ct2.webp";
import ct3 from "../../img/dster-webp/ct3.webp";
import ct4 from "../../img/dster-webp/ct4.webp";
import ct5 from "../../img/dster-webp/ct5.webp";
import ct6 from "../../img/dster-webp/ct6.webp";
import ct7 from "../../img/dster-webp/ct7.webp";
import ct8 from "../../img/dster-webp/ct8.webp";
import Track from "../NewMusicTrack/NewMusicTrack";
import exp6 from "../../img/dster-webp/exp6.webp";
import exp7 from "../../img/dster-webp/exp7.webp";
import exp10 from "../../img/dster-webp/exp10.webp";
import SendTrack from "../ShareLink/SendTrack";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import Popover from "@mui/material/Popover";
import nrplay from "../../img/dster-webp/nr-play.webp";
import Skeleton from "@mui/material/Skeleton";
import nodata from "../../img/dster-webp/newnodata.gif";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

const drawerWidth = 240;

const content = [
  {
    clsName: "explore-card2",
    heading: "Trending Playlists",
    para: "Explore premium music available to purchase.",
    img: `${ntr1}`,
  },
  {
    clsName: "explore-card4",
    heading: "My Favourites",
    para: "Explore premium music available to purchase.",
    img: `${exp6}`,
  },
  {
    clsName: "explore-card7",
    heading: "Top Albums",
    para: "Explore premium music available to purchase.",
    img: `${exp7}`,
  },
  {
    clsName: "explore-card9",
    heading: "Most Loved",
    para: "Explore premium music available to purchase.",
    img: `${exp10}`,
  },
];

const itemData = [
  { img: `${ct1}`, title: "Romance" },
  { img: `${ct2}`, title: "Chill" },
  { img: `${ct3}`, title: "Christmas" },
  { img: `${ct4}`, title: "Hip Hop" },
  { img: `${ct5}`, title: "Podcast" },
  { img: `${ct6}`, title: "Pop" },
  { img: `${ct7}`, title: "Rock" },
  { img: `${ct8}`, title: "Jazz" },
];

function ExploreNew() {
  const navigate = useNavigate();
  const [name, setName] = React.useState();
  const options = {
    margin: 30,
    items: 4,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    dots: false,
    loop: true,
    smartSpeed: 2000,
    autoHeightClass: "owl-height",
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  const theme = useTheme();
  const [limit, setLimit] = React.useState(4);
  const [limit1, setLimit1] = React.useState(5);
  const [users, setUsers] = React.useState([]);
  const [playlist, setPlaylist] = React.useState([]);
  const tokens = localStorage.getItem("iphephile");
  const [music, setMusic] = React.useState([]);
  const [musichistory, setMusichistory] = React.useState([]);
  const [audioDurations, setAudioDurations] = useState([]);
  const [audioDurations1, setAudioDurations1] = useState([]);

  const [audiosource, setAudiosource] = React.useState();
  const [topmusic, setTopmusic] = React.useState([]);
  const [song_id, setsongid] = React.useState();
  const [openModel2, setOpenModel2] = React.useState(false);
  const handleOpen2 = () => setOpenModel2(true);
  const handleModelClose2 = () => setOpenModel2(false);
  const [send, setSend] = useState();
  const [rows, setRows] = useState({});
  const [tracks, setTracks] = useState(0);
  const [isplay, setisplay] = useState(0);
  const songlist = useSelector((state) => state.songs);
  const play_mood = useSelector((state) => state.play_mood);
  const index = useSelector((state) => state.index);
  const [audiosources, setAudiosources] = React.useState(songlist);
  const [values, setValues] = React.useState(true);
  const [audioplays, setAudioplays] = useState([]);
  const [audioplays1, setAudioplays1] = useState([]);

  const sendTracks = (data) => {
    handleClose();
    handleOpen2();
    setSend(data);
  };
  const [openModel, setOpenModel] = React.useState(false);
  const handleOpen = () => setOpenModel(true);
  const handleModelClose = () => setOpenModel(false);
  const [newlist, setNewList] = useState(false);
  const handlenewPlayList = () => setNewList(!newlist);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [showMessage, setShowMessage] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover2" : undefined;
  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "simple-popover3" : undefined;
  const isMounted = useRef(false);
  React.useEffect(() => {
    setTracks(
      index !== undefined ? index : Number(localStorage.getItem("tracks"))
    );

    if (!isMounted.current) {
      getuserList();
      getPlayList();
      getmusicHistory();
      getTopmusic();
      getMusic();
      isMounted.current = true;
    } else {
      console.log("fetched");
    }
  }, []);

  // to get Artist

  const getuserList = async () => {
    try {
      const datas = await Axios.post(
        "/followers/get_all_users",
        { type: "artist" },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setUsers(res?.data?.result);
          } else {
            //toast.error(err?.response?.data?.message)
            setUsers([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
          if (err?.response?.data?.message == "Admin Blocked You") {
            localStorage.clear();
            navigate('/');
            toast.error('Admin Blocked You');
          }
        });
    } catch (error) {
      // console.log(error,"error");
    }
  };

  // to get favourite playlist

  const getPlayList = async () => {
    try {
      const datas = await Axios.post(
        "/followers/get_fav_playlist",
        {
          limit: limit,
          name: name,
        },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          // console.log(res, "res")
          if (res?.data?.success) {
            setPlaylist(res?.data?.result);
          } else {
            setPlaylist([]);
          }
        })
        .catch((err) => {
          // console.log(err, "err");
        });
    } catch (error) {
      // console.log(error,"error");
    }
  };
  // to get Top Premium Songs

  const getMusic = async () => {
    try {
      const find_data = {
        explore: "Premium Tracks",
        // name: name
      };
      const datas = await Axios.post("/users/get_user_favourite", find_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {

          if (res?.data?.success) {
            setMusic(res?.data?.result);
          } else {
            //toast.error(err?.response?.data?.message)
            setMusic([]);
          }
        })
        .catch((err) => {
          // console.log(err, "err");
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to get Songs duration

  const getAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };
  const getPreAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations1((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations1((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };

  useEffect(() => {
    if (music) {
      music.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [values]);

  // to get Top Streaming Songs

  const getTopmusic = async () => {
    try {


      const find_data = {
        explore: "Top Stream",
        name: name,
      };
      const datas = await Axios.post("/users/get_user_favourite", find_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {

          if (res?.data?.success) {
            setTopmusic(res?.data?.result);
          } else {
            //toast.error(err?.response?.data?.message)
            setTopmusic([]);
          }
        })
        .catch((err) => {
          // console.log(err, "err");
          setTopmusic([]);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to get song detail which is currently playing

  const getaudioPlays = async (audioSrc, index) => {
    try {
      if ((audioSrc === localStorage.getItem("songName")) === true) {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 1,
        }));
        setisplay(true);
      } else {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 0,
        }));
      }
    } catch (error) {
      console.error(error);
      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };
  const getaudioPlays1 = async (audioSrc, index) => {
    try {
      if (audioSrc === localStorage.getItem("songName")) {
        setAudioplays1((prev) => ({
          ...prev,
          [index]: 1,
        }));
      } else {
        setAudioplays1((prev) => ({
          ...prev,
          [index]: 0,
        }));
      }
    } catch (error) {
      console.error(error);
      setAudioplays1((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  useEffect(() => {
    if (musichistory.length > 0) {
      musichistory.forEach((row) => {
        getAudioDuration(row?.music_details?.audio);
        getPreAudioDuration(row?.music_details?.pre_audio)
      });
      if (musichistory.length > 0) {
        musichistory.forEach((row, index) => {
          getaudioPlays1(row?.music_details?.song_name, index);
        });
      }
    }
  }, [musichistory]);

  useEffect(() => {
    if (topmusic.length > 0) {
      topmusic.forEach((row) => {
        getAudioDuration(row?.audio);
        getPreAudioDuration(row?.pre_audio)

      });
    }
    if (topmusic.length > 0) {
      topmusic.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [topmusic]);

  useEffect(() => {
    if (music.length > 0) {
      music.forEach((row) => {
        getPreAudioDuration(row?.pre_audio)

      });
    }
  }, [music]);

  useEffect(() => {
    if (musichistory.length > 0) {
      musichistory.forEach((row, index) => {
        getaudioPlays1(row?.music_details?.song_name, index);
      });
    }

    if (topmusic) {
      topmusic.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [values]);

  React.useEffect(() => {
    getmusicHistory();
  }, [name]);

  // to get song to history

  const getmusicHistory = async () => {
    try {
      const datas = await Axios.post(
        "/users/get_music_history",
        { name: name },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {

          if (res?.data?.success) {
            setMusichistory(res?.data?.result);
          } else {
            //toast.error(err?.response?.data?.message)
            setMusichistory([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
          setMusichistory([]);
        });
    } catch (error) {
      // console.log(error,"error");
      setMusichistory([]);
    }
  };

  const audio = localStorage.getItem("audiosource");

  // to get song detail which is currently playing

  const setAudio = (data) => {
    localStorage.setItem("audiosource", data.audio);
    localStorage.setItem("songName", data.song_name);
    localStorage.setItem("Image", data.image);
    localStorage.setItem("current", 0);
    localStorage.setItem("isPause", false);
    setAudiosource(data.audio);
    localStorage.setItem("tracks", 0);
    localStorage.setItem("playsong", "false");
    setisplay(true);
  };
  const setAudios = (data) => {
    if (audioDurations1[data?.pre_audio] !== undefined) {
      localStorage.setItem("audiosource", data.pre_audio);
      localStorage.setItem("songName", data.song_name);
      localStorage.setItem("Image", data.image);
      localStorage.setItem("current", 0);
      localStorage.setItem("tracks", 0);
      localStorage.setItem("isPause", false);
      setAudiosource(data.pre_audio);
      localStorage.setItem("playsong", "false");
      setValues(!values);
      setisplay(true);
    }
  };
  // to add song to library
  const addLibrary = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_library", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {

          if (res?.data?.success) {
            toast.success(res?.data?.message, { autoClose: 3000 });
            getMusic();
            getTopmusic();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to favourite/unfavorite a song
  const addFavorite = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };


      await Axios.post("/users/add_to_favourite", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {

          if (res?.data?.success) {
            //                         toast.success(res?.data?.message,{autoClose:3000})

            getMusic();
            getmusicHistory();
            getTopmusic();

          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to repost/unrepost Songs
  const editSong = async (data) => {
    try {
      const create_data = {
        songId: data.id,
        status: data.status,
      };


      await Axios.post("/users/edit_song_details", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {

          if (res?.data?.success) {
            //                        toast.success(res?.data?.message,{autoClose:3000})

            getMusic();
            getmusicHistory();
            getTopmusic();
            // console.log(res?.data?.message)
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  const [btnactive1, setBtnactive1] = useState(true);
  const [exp, setExp] = useState(false);
  const [hist, setHist] = useState(false);

  const changeLimit = () => {

    if (exp === false) {
      setLimit1(10);
    } else {
      setLimit1(5);
    }
    setExp(!exp);
  };
  // to add song to history
  const createMusic = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };


      await Axios.post("/users/add_history", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {

          if (res?.data?.success) {
            getMusic();
            getmusicHistory();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err");
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to follow the artist
  const followUser = async (data) => {
    try {

      const follow_data = {
        id: data,
      };


      await Axios.post("/followers/follow_artist", follow_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {

          if (res?.data?.success) {
            toast.success(res?.data?.message, { autoClose: 3000 });

            getuserList();

          } else {
            getuserList();
          }
        })
        .catch((err) => {
          // console.log(err,"err")
          getuserList();
        });
    } catch (error) {
      // console.log(error,"error");
      getuserList();
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showMessage]);

  return (
    <div className="Explore-new">
      <Box sx={{ display: "flex" }}>
        <HeaderNew name={name} setName={setName} title={"Explore"} />
        <ToastContainer autoClose={3000} />
        <Box
          component="main"
          className="explore-box"
          sx={{
            flexGrow: 1,
            py: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            marginTop: "135px",
            marginBottom: "100px",
          }}
        >
          <Grid container spacing={0}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              sx={{ padding: "15px" }}
            >
              <div className="enew-g1-main">
                <div className="enews-gi-inner">
                  <div className="enew-chart">Top Premium Songs</div>
                  <div className="carosuel-arrows owl-nav">
                    <Button className="owl-prev">
                      <ArrowBackIosNewIcon />
                    </Button>
                    <Button className="owl-next">
                      <ArrowForwardIosIcon />
                    </Button>
                  </div>
                </div>

                <div className="enew-g1-img">
                  {music?.length > 0 ? (
                    <OwlCarousel
                      className="owl-theme owl-carousel nft"
                      {...options}
                      sx={{ width: "160px", height: "204px" }}
                    >
                      <>
                        {music &&
                          music?.map((row, index) => {
                            return showMessage ? (
                              <Stack spacing={1}>
                                <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  sx={{ bgcolor: "var(--clr-skel)" }}
                                  width={150}
                                  height={150}
                                />
                                <Skeleton
                                  variant="text"
                                  animation="wave"
                                  sx={{
                                    fontSize: "15px",
                                    bgcolor: "var(--clr-skel)",
                                  }}
                                />
                                <Skeleton
                                  variant="text"
                                  animation="wave"
                                  sx={{
                                    fontSize: "12px",
                                    bgcolor: "var(--clr-skel)",
                                  }}
                                />
                              </Stack>
                            ) : (
                              <div
                                className="nft-item"

                              >
                                <div
                                  onClick={() => {
                                    navigate(`/trackpage/${row?._id}`);
                                  }}
                                >
                                  <div>
                                    <img
                                      onClick={() => {
                                        navigate(`/trackpage/${row?._id}`);
                                      }}
                                      src={
                                        row?.image &&
                                          !row?.image?.includes("localhost")
                                          ? row?.image
                                          : play
                                      }
                                      alt="img"
                                    />
                                  </div>
                                  <div className="info">
                                    {row?.song_name}
                                    {row?.nft_type === "per" ? (
                                      <VerifiedIcon className="premiums" />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="price"
                                  onClick={() => {
                                    navigate(
                                      `/artistpage/${row?.createUser?._id}`
                                    );
                                  }}
                                >
                                  {row?.createUser?.name}
                                </div>
                                {row?.nft_type == "per" ?
                                  < Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">

                                    <div className="play-new"
                                      onMouseOver={() => setAudios(row)}
                                    //   onMouseOut={() => setisplay(false)}
                                    >
                                      <img
                                        src={play}

                                      />
                                    </div>
                                  </Tooltip> : <></>}
                              </div>
                            );
                          })}
                      </>
                    </OwlCarousel>
                  ) : (
                    <>
                      <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="description-History"></div>
                        <div className="description-History"></div>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ textAlign: "center" }}
                      >
                        <div className="newno">
                          <img src={nodata} alt="no-data" />
                        </div>
                      </Grid>
                    </>
                  )}
                </div>
              </div>
              <Grid container spacing={0}>
                {content.map((row, obj) => {
                  return (
                    <>
                      {showMessage ? (
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={6}
                          sx={{ padding: "15px" }}
                          onClick={(e) => {
                            navigate("/premiumtracks", {
                              state: `${row.heading}`,
                            });
                          }}
                        >
                          <div className="enew-g1-main ntr-mian">
                            <Stack
                              spacing={1}
                              direction="row"
                              sx={{ justifyContent: "space-between" }}
                            >
                              <Stack spacing={2}>
                                <Skeleton
                                  variant="text"
                                  animation="wave"
                                  sx={{
                                    bgcolor: "var(--clr-skel)",
                                    fontSize: "25px",
                                  }}
                                  width={200}
                                />
                                <Skeleton
                                  variant="text"
                                  animation="wave"
                                  sx={{
                                    bgcolor: "var(--clr-skel)",
                                    fontSize: "25px",
                                  }}
                                  width={150}
                                />
                                <Skeleton
                                  variant="text"
                                  animation="wave"
                                  sx={{
                                    bgcolor: "var(--clr-skel)",
                                    fontSize: "25px",
                                  }}
                                  width={100}
                                />
                              </Stack>

                              <Skeleton
                                variant="rectangular"
                                animation="wave"
                                sx={{ bgcolor: "var(--clr-skel)" }}
                                width={200}
                                height={141}
                              />
                            </Stack>
                          </div>
                        </Grid>
                      ) : (
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={6}
                          sx={{ padding: "15px" }}
                          onClick={(e) => {
                            navigate("/premiumtracks", {
                              state: `${row.heading}`,
                            });
                          }}
                        >
                          <div className="enew-g1-main ntr-mian">
                            <div className="new-trending-play">
                              {row.heading}
                            </div>
                            <div className="enew-g1-slider">{row.para}</div>
                            <div className="ntr1">
                              <img src={row.img} />
                            </div>
                          </div>
                        </Grid>
                      )}
                    </>
                  );
                })}
              </Grid>

              <div className="enew-chart">Artists You Should Plug-In</div>
              <div>
                <Grid container spacing={0} className="backstage-artist-grid">
                  {users && users.length > 0 ? (
                    <>
                      {users?.map((row, obj) => {
                        return (
                          <>
                            {showMessage ? (
                              <Grid
                                xs={12}
                                sm={6}
                                md={12}
                                lg={6}
                                xl={3}
                                sx={{ padding: "10px" }}
                              >
                                <Card
                                  sx={{ maxWidth: 240, margin: "auto" }}
                                  className="card-backstage"
                                >
                                  <Stack spacing={1}>
                                    <Skeleton
                                      variant="circular"
                                      animation="wave"
                                      sx={{ bgcolor: "var(--clr-skel)" }}
                                      width={210}
                                      height={210}
                                    />
                                    <Skeleton
                                      variant="text"
                                      animation="wave"
                                      sx={{
                                        bgcolor: "var(--clr-skel)",
                                        fontSize: "15px",
                                      }}
                                      width={200}
                                    />
                                    <Skeleton
                                      variant="text"
                                      animation="wave"
                                      sx={{
                                        bgcolor: "var(--clr-skel)",
                                        fontSize: "15px",
                                      }}
                                      width={170}
                                    />
                                  </Stack>
                                </Card>
                              </Grid>
                            ) : (
                              <Grid
                                xs={12}
                                sm={6}
                                md={12}
                                lg={6}
                                xl={3}
                                sx={{ padding: "10px" }}
                              >
                                <Card
                                  sx={{ maxWidth: 240, margin: "auto" }}
                                  className="card-backstage"
                                >
                                  <div
                                    className="cirle-img"
                                    title="green iguana"
                                  >
                                    <img
                                      src={
                                        row?.profilePicture &&
                                          !row?.profilePicture?.includes(
                                            "localhost"
                                          )
                                          ? row?.profilePicture
                                          : ava1
                                      }
                                      alt="img"
                                      onClick={() => {
                                        navigate(`/artistpage/${row?._id}`);
                                      }}
                                    />
                                  </div>
                                  <CardContent>
                                    <div className="love-txt1">
                                      {row?.name} <img src={i3} />
                                    </div>
                                    <div className="love-txt2">
                                      {row?.TotalFollowers} Plugins
                                    </div>

                                    <div className="explore-more-btn2">
                                      {row?.isFollow === false ? (
                                        <Button
                                          onClick={() => {
                                            followUser(row?._id);
                                          }}
                                        >
                                          Plug-in
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            followUser(row?._id);
                                          }}
                                        >
                                          Unplug
                                        </Button>
                                      )}
                                      {/* <Button onClick={() => { navigate(`/message/${row?._id}`) }}>Message</Button> */}
                                    </div>
                                  </CardContent>
                                </Card>
                              </Grid>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={4}
              sx={{ padding: "15px" }}
            >
              <div className="enew-g1-main">
                <div className="top-stream-mian">
                  <div className="top-stemers">Top Streaming Songs</div>
                </div>

                {btnactive1 && (
                  <div className={exp ? "exp-tran" : "stream-iner-display"}>
                    {topmusic.length > 0 ? (
                      <>
                        {topmusic &&
                          topmusic?.slice(0, limit1)?.map((row, ind) => {
                            return (
                              <>
                                <div className="ts-c1-whole">
                                  <div className="ts-c1-left">
                                    <div className="ts-coun">{ind + 1}</div>
                                    <div className="ts-c1-imgdiv cursor">
                                      <img
                                        src={
                                          row?.image &&
                                            !row?.image?.includes("localhost")
                                            ? row?.image
                                            : ava1
                                        }
                                        alt="ts1"

                                      />
                                      {row?.nft_type == "per" ?
                                        < Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">

                                          <img
                                            src={nrplay}
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                            onMouseOver={() => setAudios(row)}
                                            // onMouseOut={() => setisplay(false)}
                                            className="hover-play"
                                          />
                                        </Tooltip> : <></>}

                                    </div>
                                    <div className="milestone">
                                      {row?.song_name}{" "}
                                      {row?.nft_type === "per" ? (
                                        <VerifiedIcon className="premiums" />
                                      ) : (
                                        <></>
                                      )}
                                      <div className="jsuti">
                                        {row?.createUser?.name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ts-c1-right">
                                    <div className="ts-t-time">
                                      {audioDurations[row?.audio] !==
                                        undefined ? (
                                        <span>
                                          {audioDurations[row?.audio]}
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </div>
                                    <div>
                                      {row?.nftclaim !== 0 &&
                                        row?.userFound === false &&
                                        row?.isMine === false ? (
                                        <Tooltip
                                          title="Add to Library"
                                          placement="top"
                                        >
                                          <DownloadIcon
                                            onClick={() => {
                                              addLibrary(row?._id);
                                            }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.userFound === true ? (
                                        <Tooltip
                                          title="Remove from Library"
                                          placement="top"
                                        >
                                          <UndoIcon
                                            onClick={() => {
                                              addLibrary(row?._id);
                                            }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div>
                                      <Tooltip
                                        title={
                                          row?.userFav === true
                                            ? "Remove From Favourite"
                                            : "Add to Favourite"
                                        }
                                        placement="top"
                                      >
                                        <FavoriteIcon
                                          onClick={() => {
                                            addFavorite(row?._id);
                                          }}
                                          className={
                                            row?.userFav === true
                                              ? "fav-heart"
                                              : "normal-svg"
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                    <div>
                                      {(audioDurations[row?.audio] !==
                                        undefined &&
                                        row?.nft_type === "nor") ||
                                        row?.isMine === true ? (
                                        <>
                                          {" "}
                                          {audioplays[ind] === 0 ||
                                            isplay === false ? (
                                            <Tooltip
                                              title="Play Song"
                                              placement="top"
                                            >
                                              <img
                                                src={nrplay}
                                                onClick={() => {
                                                  setAudio(row);
                                                  setValues(!values);
                                                  createMusic(row?._id);
                                                  setAudiosource(row?.audio);
                                                }}
                                                style={{ cursor: "pointer" }}
                                                alt="nr"
                                              />
                                            </Tooltip>
                                          ) : (
                                            <img
                                              src={Companylogo1}
                                              id="pauseImage"
                                              onClick={() => setisplay(false)}
                                              alt="Companylogo"
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {row?.nft_type === "per" &&
                                            audioDurations[row?.audio] !==
                                            undefined ? (
                                            <Tooltip
                                              title="Premium Song"
                                              placement="top"
                                            >
                                              {(audioplays[ind] == 0 ||
                                                isplay == false) == true ? (
                                                <img
                                                  src={nrplay}
                                                  onClick={() => {
                                                    setAudios(row);
                                                    createMusic(row?._id);
                                                    setAudiosource(
                                                      row?.pre_audio
                                                    );
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                  alt="nr"
                                                />
                                              ) : (
                                                <img
                                                  src={Companylogo1}
                                                  id="pauseImage"
                                                  onClick={() => {
                                                    setisplay(false);
                                                  }}
                                                  alt={Companylogo1}
                                                />
                                              )}
                                            </Tooltip>
                                          ) : (
                                            <Tooltip
                                              title="Can't Play"
                                              placement="top"
                                            >
                                              <img
                                                src={nrplay}
                                                style={{ cursor: "pointer" }}
                                                alt="nr"
                                              />
                                            </Tooltip>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      <MoreHorizIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          handleClick(e);
                                          setsongid(row?._id);
                                          setRows(row);
                                        }}
                                      />
                                      <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                        className="playlist-popover"
                                      >
                                        <div
                                          className="playlist-more-div"
                                          onClick={() => {
                                            sendTracks(row?._id);
                                          }}
                                        >
                                          Share
                                        </div>
                                        <div
                                          className="playlist-more-div"
                                          onClick={(e) => {
                                            navigate(`/trackpage/${rows._id}`);
                                          }}
                                        >
                                          Visit Track Page
                                        </div>
                                        <div
                                          className="playlist-more-div"
                                          onClick={(e) => {
                                            navigate(
                                              `/artistpage/${rows.createUser?._id}`
                                            );
                                          }}
                                        >
                                          Visit Artist Page
                                        </div>
                                      </Popover>
                                    </div>
                                  </div>
                                </div >

                                <div className="hr"></div>
                              </>
                            );
                          })}{" "}
                      </>
                    ) : (
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ textAlign: "center" }}
                      >
                        <div className="newno">
                          <img src={nodata} alt="no-data" className="no-data" />
                        </div>
                      </Grid>
                    )}
                  </div>
                )}

                <div className="stream-exp-btn">
                  {topmusic?.length > 0 ? (
                    <Button
                      onClick={() => {
                        changeLimit();
                      }}
                    >
                      {exp ? "Show Less" : "Show All"}{" "}
                      {exp ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ padding: "15px 0px" }}
              >
                <div className="enew-g1-main">
                  <div className="top-stream-mian">
                    <div className="top-stemers">
                      Songs to Fit Your Mood
                      <div className="description-explore">
                        Songs made by Dreamster users, sorted by mood and feel
                      </div>
                    </div>
                  </div>
                  <div>
                    <ImageList
                      sx={{ width: "auto", height: "auto" }}
                      cols={2}
                      rowHeight={110}
                      className="img-list"
                    >
                      {itemData.map((item) => (
                        <>
                          <ImageListItem
                            key={item?.img}
                            onClick={(e) => {
                              navigate("/premiumtracks", {
                                state: `${item?.title}`,
                              });
                            }}
                          >
                            <img
                              // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                              src={`${item?.img}?w=164&h=164&fit=crop&auto=format`}
                              alt={item?.title}
                              loading="lazy"
                              style={{ height: "200px", cursor: "pointer" }}
                            // loading="lazy"
                            />
                            <ImageListItemBar
                              title={item.title}
                              // subtitle={item.author}
                              actionIcon={
                                <IconButton
                                  sx={{ color: "#fff" }}
                                  aria-label={`info about ${item.title}`}
                                >
                                  <InfoIcon sx={{ fill: "#fff" }} />
                                </IconButton>
                              }
                            />
                          </ImageListItem>
                          {/* <div className='songs-fitname'>Romantic</div> */}
                        </>
                      ))}
                    </ImageList>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              sx={{ padding: "15px" }}
            >
              <div className="enew-g1-main">
                <div className="top-stemers">Listening History</div>
                <Grid container spacing={0} sx={{ justifyContent: "center" }}>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="top-stream-mian"></div>
                    {musichistory?.length > 0 ? (
                      <>
                        {musichistory.map((row, ind) => {
                          return (
                            <>
                              {showMessage ? (
                                <div className="e-news-list">
                                  <Stack
                                    spacing={1}
                                    direction="row"
                                    className="sk-stack main"
                                  >
                                    <Stack
                                      spacing={1}
                                      direction="row"
                                      sx={{ alignItems: "center" }}
                                      className="flex-media"
                                    >
                                      <Skeleton
                                        variant="circular"
                                        animation="wave"
                                        sx={{ bgcolor: "var(--clr-skel)" }}
                                        width={40}
                                        height={40}
                                      />
                                      <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={100}
                                        height={100}
                                        sx={{ bgcolor: "var(--clr-skel)" }}
                                      />
                                      <Stack
                                        spacing={1}
                                        className="sk-stack
                                                    -inner"
                                      >
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          sx={{
                                            fontSize: "15px",
                                            bgcolor: "var(--clr-skel)",
                                          }}
                                          width={150}
                                        />
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          sx={{
                                            fontSize: "12px",
                                            bgcolor: "var(--clr-skel)",
                                          }}
                                          width={120}
                                        />
                                        <Skeleton
                                          variant="text"
                                          animation="wave"
                                          sx={{
                                            fontSize: "12px",
                                            bgcolor: "var(--clr-skel)",
                                          }}
                                          width={70}
                                        />
                                      </Stack>
                                    </Stack>
                                    <Stack spacing={3} direction="row">
                                      <Skeleton
                                        variant="rounded"
                                        animation="wave"
                                        sx={{
                                          borderRadius: "15px",
                                          bgcolor: "var(--clr-skel)",
                                        }}
                                        width={25}
                                        height={25}
                                      />
                                      <Skeleton
                                        variant="rounded"
                                        animation="wave"
                                        sx={{
                                          borderRadius: "15px",
                                          bgcolor: "var(--clr-skel)",
                                        }}
                                        width={25}
                                        height={25}
                                      />
                                      <Skeleton
                                        variant="rounded"
                                        animation="wave"
                                        sx={{
                                          borderRadius: "15px",
                                          bgcolor: "var(--clr-skel)",
                                        }}
                                        width={25}
                                        height={25}
                                      />
                                      <Skeleton
                                        variant="rounded"
                                        animation="wave"
                                        sx={{
                                          borderRadius: "15px",
                                          bgcolor: "var(--clr-skel)",
                                        }}
                                        width={25}
                                        height={25}
                                      />
                                    </Stack>
                                  </Stack>
                                </div>
                              ) : (
                                <div className="e-news-list">
                                  <div className="history-new-main">
                                    <div className="history-new-left">
                                      <div className="hover-play-div">
                                        <img
                                          src={
                                            row?.music_details?.image &&
                                              !row?.music_details?.image?.includes(
                                                "localhost"
                                              )
                                              ? row?.music_details?.image
                                              : ava1
                                          }
                                          className="images"
                                          onClick={(e) => {
                                            navigate(
                                              `/trackpage/${row?.music_details?._id}`
                                            );
                                          }}
                                          alt="ct1"

                                        />
                                        {row?.music_details?.nft_type == "per" ?
                                          < Tooltip title={row?.music_details?.nft_type == "per" && audioDurations1[row?.music_details?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">

                                            <img

                                              src={nrplay}
                                              alt={fdp3}
                                              style={{
                                                width: "32px",
                                                height: "32px",
                                              }}
                                              onMouseOver={() =>
                                                setAudios(row?.music_details)
                                              }
                                              // onMouseOut={() => setisplay(false)}
                                              className="hover-play3"
                                            />
                                          </Tooltip>
                                          : <></>}
                                      </div>

                                      <div className="hist-mai">
                                        {row?.music_details?.song_name}
                                        {row?.music_details?.nft_type ===
                                          "per" ? (
                                          <>
                                            <VerifiedIcon className="premium" />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        <div className="repost-loop">
                                          <p>
                                            {" "}
                                            <RepeatIcon className="repeat" />
                                            {row?.music_details?.reposts?.length
                                              ? row?.music_details?.reposts
                                                ?.length
                                              : 0}{" "}
                                            Repost
                                          </p>
                                        </div>
                                        <div className="repost-loop">
                                          <p>
                                            {" "}
                                            <FavoriteIcon className="unfav" />
                                            {row?.music_details?.fav_count
                                              ? row?.music_details?.fav_count
                                              : 0}{" "}
                                            Favorite
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="history-new-right">
                                      <div className="playtime new-fd-plps">
                                        {audioDurations[
                                          row?.music_details?.audio
                                        ] !== undefined &&
                                          row?.music_details?.nft_type ===
                                          "nor" ? (
                                          <>
                                            {audioplays1[ind] === 0 ||
                                              isplay === false ? (
                                              <Tooltip
                                                title="Play Song"
                                                placement="top"
                                              >
                                                <img
                                                  onClick={() => {
                                                    setAudio(
                                                      row?.music_details
                                                    );
                                                    setValues(!values);
                                                    setAudiosource(
                                                      row?.music_details?.audio
                                                    );
                                                  }}
                                                  src={nrplay}
                                                  alt={fdp3}
                                                />
                                              </Tooltip>
                                            ) : (
                                              <Tooltip placement="top">
                                                <img
                                                  src={Companylogo1}
                                                  id="pauseImage"
                                                  onClick={() =>
                                                    setisplay(false)
                                                  }
                                                  alt="Companylogo"
                                                />
                                              </Tooltip>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {row?.music_details?.nft_type ===
                                              "per" &&
                                              audioDurations[
                                              row?.music_details?.audio
                                              ] !== undefined ? (
                                              <Tooltip
                                                title="Premium Song"
                                                placement="top"
                                              >
                                                {(audioplays1[ind] == 0 ||
                                                  isplay == false) == true ? (
                                                  <img
                                                    src={nrplay}
                                                    onClick={() => {
                                                      setAudios(
                                                        row.music_details
                                                      );
                                                      createMusic(
                                                        row?.music_details?._id
                                                      );
                                                      setAudiosource(
                                                        row?.music_details
                                                          ?.pre_audio
                                                      );
                                                    }}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    alt="nr"
                                                  />
                                                ) : (
                                                  <img
                                                    src={Companylogo1}
                                                    id="pauseImage"
                                                    onClick={() => {
                                                      setisplay(false);
                                                    }}
                                                    alt={Companylogo1}
                                                  />
                                                )}
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="Can't Play"
                                                placement="top"
                                              >
                                                <img
                                                  src={nrplay}
                                                  style={{ cursor: "pointer" }}
                                                  alt="nr"
                                                />
                                              </Tooltip>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <div className="playtime">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="10"
                                          height="12"
                                          viewBox="0 0 10 12"
                                          fill="none"
                                        >
                                          <path
                                            d="M9.65909 7.07169C9.61648 7.13001 9.41761 7.37789 9.26136 7.53828L9.17614 7.62576C7.98295 8.95261 5.0142 10.9502 3.50852 11.5917C3.50852 11.6063 2.61364 11.9854 2.1875 12H2.13068C1.47727 12 0.866477 11.6209 0.553977 11.0085C0.383523 10.6731 0.227273 9.69623 0.213068 9.68165C0.0852273 8.8068 0 7.46683 0 5.99271C0 4.44714 0.0852273 3.04739 0.241477 2.18712C0.241477 2.17254 0.397727 1.38518 0.497159 1.12272C0.653409 0.743621 0.9375 0.422843 1.29261 0.218712C1.5767 0.072904 1.875 0 2.1875 0C2.5142 0.0145808 3.125 0.234751 3.36648 0.335358C4.95739 0.976914 7.99716 3.07655 9.16193 4.35966C9.3608 4.56379 9.57386 4.81167 9.63068 4.86999C9.87216 5.19077 10 5.58445 10 6.00875C10 6.38639 9.88636 6.76549 9.65909 7.07169Z"
                                            fill="#7C8DB5"
                                            fill-opacity="0.72"
                                          />
                                        </svg>{" "}
                                        {row?.music_details?.players?.length}
                                      </div>
                                      <Tooltip
                                        title={
                                          row?.music_details?.isRepost === true
                                            ? "Remove"
                                            : "Repost"
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className="playtime more-histore"
                                          onClick={() => {
                                            editSong({
                                              id: row?.music_details?._id,
                                              status: "repost",
                                            });
                                          }}
                                        >
                                          {/* <MoreHorizIcon /> */}
                                          <RepeatIcon
                                            className={
                                              row?.music_details?.isRepost ===
                                                true
                                                ? "repeat"
                                                : "repeats"
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                      <Tooltip title="Share" placement="top">
                                        <div
                                          className="playtime more-histore"
                                          onClick={() => {
                                            sendTracks(row?.music_details?._id);
                                          }}
                                        >
                                          {/* <MoreHorizIcon /> */}
                                          <div>
                                            <ShortcutIcon />
                                          </div>
                                        </div>
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          row?.music_details?.userFav === true
                                            ? "Remove from Favourite"
                                            : "Add To Favourite"
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className={
                                            row?.music_details?.userFav === true
                                              ? "playtime hist-fav"
                                              : "playtime"
                                          }
                                          onClick={() => {
                                            addFavorite(
                                              row?.music_details?._id
                                            );
                                            setHist(!hist);
                                          }}
                                        >
                                          <svg
                                            key={ind}
                                            width="25"
                                            height="25"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2378_5148)">
                                              <path
                                                opacity="0.4"
                                                d="M9.81207 18.1979C7.90954 17.0227 6.14095 15.6371 4.53867 14.0663C3.40752 12.9448 2.54365 11.5754 2.01307 10.0627C1.06504 7.11269 2.16861 3.74123 5.24969 2.74033C6.87595 2.22961 8.64464 2.54313 10.0046 3.5832C11.3651 2.54429 13.1333 2.23087 14.7596 2.74033C17.8407 3.74123 18.9516 7.11269 18.0036 10.0627C17.4773 11.574 16.6186 12.9433 15.4928 14.0663C13.8891 15.6354 12.1207 17.0209 10.2194 18.1979L10.012 18.3333L9.81207 18.1979Z"
                                                fill="#B9C0DE"
                                              />
                                              <path
                                                d="M10.0084 18.3332L9.81267 18.1978C7.90779 17.0228 6.13674 15.6371 4.53186 14.0662C3.39535 12.9463 2.52634 11.5767 1.99145 10.0626C1.05083 7.11261 2.15439 3.74115 5.23548 2.74024C6.86173 2.22952 8.65375 2.54328 10.0084 3.59206V18.3332Z"
                                                fill="#7C8DB5"
                                                fill-opacity="0.72"
                                              />
                                              <path
                                                d="M15.1907 8.33269C15.0233 8.32191 14.8674 8.23825 14.7596 8.1013C14.6517 7.96436 14.6014 7.78617 14.6203 7.60847C14.6384 7.02317 14.3054 6.49042 13.7918 6.28314C13.4661 6.19424 13.2689 5.84156 13.3504 5.49374C13.4277 5.15152 13.7482 4.93873 14.0702 5.01575C14.1109 5.0225 14.15 5.03723 14.1857 5.05921C15.2154 5.45548 15.8821 6.52201 15.8291 7.68813C15.8274 7.86488 15.7585 8.03332 15.6381 8.15484C15.5178 8.27637 15.3563 8.34055 15.1907 8.33269Z"
                                                fill="white"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2378_5148">
                                                <rect
                                                  width="20"
                                                  height="20"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ textAlign: "center" }}
                      >
                        <div className="newno">
                          <img src={nodata} alt="no-data" />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <SendTrack
              handleModelClose2={handleModelClose2}
              handleOpen2={handleOpen2}
              openModel2={openModel2}
              id={`/trackpage/${send}`}
            />
          </Grid>

          {localStorage.getItem("playsong") === "true" ? (
            <>
              {audiosources.length > 0 ? (
                <Tracks audiosources={audiosources} tracks={tracks} />
              ) : (
                <></>
              )}{" "}
            </>
          ) : (
            <></>
          )}
          {(localStorage.getItem("playsong") === "false" && audiosource) ===
            true ? (
            <Track
              audiosource={audiosource}
              isplay={isplay}
              setisplay={setisplay}
            />
          ) : (
            <>
              {(localStorage.getItem("playsong") === "false" &&
                audio !== null) === true ? (
                <Track
                  audiosource={audio}
                  isplay={isplay}
                  setisplay={setisplay}
                />
              ) : (
                <></>
              )}{" "}
            </>
          )}
        </Box>
      </Box>
    </div >
  );
}

export default ExploreNew;
