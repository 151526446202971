import React, { useState } from 'react'

import Box from '@mui/material/Box';
import { toast } from 'react-toastify';

import Grid from '@mui/material/Grid';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from '@mui/icons-material/Twitter';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import axios from 'axios';
import FacebookIcon from '@mui/icons-material/Facebook';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { useLocation } from 'react-router-dom';

import constant from "../../Constant"
import { FacebookProvider, CustomChat } from 'react-facebook';

import { FacebookShareButton } from "react-share";



const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'var(--clr-bcg)',
  height: '620px',
  // overflow: 'scroll',
  // border: '2px solid #000',
  // boxShadow: 'none',
  p: 4,
  '@media all and (max-width: 1199.98px)': {
    width: '55%'
  },
  '@media all and (max-width: 768.98px)': {
    width: '85%'
  },
  '@media all and (max-width: 575.98px)': {
    width: '95%'
  }
};


const SendTrack = ({ handleModelClose2, openModel2, id }) => {

  var location = useLocation()

  const message = `${constant.MainUrl}${id}`
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php`;
  const [imageUrl, setImageUrl] = useState("");
  const [postCaption, setPostCaption] = useState("");
  const [isSharingPost, setIsSharingPost] = useState(false);
  const [facebookUserAccessToken, setFacebookUserAccessToken] = useState("15fd40dba83f29ab49d074d899d67500");



  // useEffect(() => {
  //   window.FB.getLoginStatus((response) => {
  //     console.log(response.authResponse?.accessToken,"getLoginStatus")
  //      setFacebookUserAccessToken(response.authResponse?.accessToken);
  //   });
  // }, []);

  // const logInToFB = () => {
  //   window.FB.login(
  //     (response) => {
  //       setFacebookUserAccessToken(response.authResponse?.accessToken);
  //     },
  //     {
  //       // Scopes that allow us to publish content to Instagram
  //       scope: "instagram_basic,pages_show_list",
  //     }
  //   );
  // };

  // const logOutOfFB = () => {
  //   window.FB.logout(() => {
  //     setFacebookUserAccessToken(undefined);
  //   });
  // };



  // const getFacebookPages = () => {
  //   return new Promise((resolve) => {
  //     window.FB.api(
  //       "me/accounts",
  //       { access_token: "15fd40dba83f29ab49d074d899d67500" },
  //       (response) => {
  //         resolve(response.data);
  //       }
  //     );
  //   });
  // };

  // const getInstagramAccountId = (facebookPageId) => {
  //   return new Promise((resolve) => {
  //     window.FB.api(
  //       facebookPageId,
  //       {
  //         access_token: "15fd40dba83f29ab49d074d899d67500",
  //         fields: "instagram_business_account",
  //       },
  //       (response) => {
  //         resolve(response.instagram_business_account.id);
  //       }
  //     );
  //   });
  // };

  // const createMediaObjectContainer = (instagramAccountId) => {
  //   return new Promise((resolve) => {
  //     window.FB.api(
  //       `${instagramAccountId}/media`,
  //       "POST",
  //       {
  //         access_token: "15fd40dba83f29ab49d074d899d67500",
  //         image_url: imageUrl,
  //         caption: postCaption,
  //       },
  //       (response) => {
  //         resolve(response.id);
  //       }
  //     );
  //   });
  // };

  // const publishMediaObjectContainer = (
  //   instagramAccountId,
  //   mediaObjectContainerId
  // ) => {
  //   return new Promise((resolve) => {
  //     window.FB.api(
  //       `${instagramAccountId}/media_publish`,
  //       "POST",
  //       {
  //         access_token: "15fd40dba83f29ab49d074d899d67500",
  //         creation_id: mediaObjectContainerId,
  //       },
  //       (response) => {
  //         resolve(response.id);
  //       }
  //     );
  //   });
  // };


  const whatsappSend = () => {


    //  let number = mobileNumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");
    const message = `${constant.MainUrl}${id}`
    console.log(message, "message")

    let url = `https://web.whatsapp.com/send?`;


    url += `text=${message}&app_absent=0`;


    window.open(url);

  };



  const facebookSend = () => {


    //  let number = mobileNumber.replace(/[^\w\s]/gi, "").replace(/ /g, "");
    const message = `${constant.MainUrl}${id}`
    console.log(message, "message")


    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php`;


    // window.location.href = facebookShareUrl;

    window.open(facebookShareUrl);

  };

  const twittersend = () => {
    const tweetText = encodeURIComponent('Check this link and Listen Awesome Song!');
    const tweetUrl = encodeURIComponent(`${constant.MainUrl}${id}`);
    const message = `${constant.MainUrl}${id}`
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;

    window.open(twitterShareUrl, '_blank');
  };

  const copyLink = () => {
    const message = `${constant.MainUrl}${id}`
    navigator.clipboard.writeText(message)
    toast.success("Copied Successfully")
    handleModelClose2()
  }

  const handleTelegramShare = async () => {
    const message = encodeURIComponent(`${constant.MainUrl}${id}`);
    const telegramShareUrl = `https://t.me/+bUvdGrsyBehiZjhl?text=${message}`;



    window.open(telegramShareUrl, '_blank');
    try {
      const telegramBotToken = "6874801984:AAFXqsDxSABHr62KAr6DkF4wjuGMHm9tx-k"


      const res = await axios.get(
        `https://api.telegram.org/bot${telegramBotToken}/getUpdates`,
      );

      console.log(res.data, "res")

      const response = await axios.post(
        `https://api.telegram.org/bot${telegramBotToken}/sendMessage`,
        {
          chat_id: -1906113984,
          text: message,
        }
      );

      console.log(response.data, "response");
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  // const shareInstagramPost = async () => {
  //   setIsSharingPost(true);
  //   const facebookPages = await getFacebookPages();
  //   const instagramAccountId = await getInstagramAccountId(facebookPages[0].id);
  //   const mediaObjectContainerId = await createMediaObjectContainer(
  //     instagramAccountId
  //   );

  //   await publishMediaObjectContainer(
  //     instagramAccountId,
  //     mediaObjectContainerId
  //   );

  //   setIsSharingPost(false);

  //   // Reset the form state
  //   setImageUrl("");
  //   setPostCaption("");
  // };
  return (

    <>
      {/* <div onClick={() => { handleOpen2() }}>
                <ShortcutIcon />
            </div> */}
      <Modal

        open={openModel2}
        onClose={handleModelClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style2}>
          <div>

          </div>
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', color: 'var(--clr-font)' }} >

            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ borderBottom: '1px solid var(--clr-search-border)', display: 'flex' }}>
              <Grid xs={1} sm={1} md={1} lg={1} xl={1} >
                <div>
                  <CloseIcon onClick={handleModelClose2} />
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                <div className='edit-playlist-title'>
                  {/* <QueueMusicIcon /> */}
                  Share Link
                </div>

              </Grid>
            </Grid>



            <>

              <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='grid-height' >

                <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                  <div className='new-playlist created'
                    onClick={() => { whatsappSend() }}
                  >
                    <div className='d-flex'
                    //  onClick={() => { whatsappSend() }}
                    >
                      {/* whatsappSend */}
                      <div style={{ "marginRight": "20px" }}><WhatsAppIcon /> </div>
                      <div>WhatsApp</div>

                    </div>


                  </div>
                  {/* <FacebookProvider appId="2182249128816886">
       <ShareButton href="http://www.facebook.com">
         Share
       </ShareButton>
     </FacebookProvider> */}

                  <div className='new-playlist created'>





                    <FacebookShareButton
                      url={message}
                      quote="Please Share"
                      hashtag={message}
                    >
                      <div><FacebookIcon style={{ "marginRight": "20px" }} />Facebook </div>

                    </FacebookShareButton>


                  </div>


                  {/* <button
                    onClick={shareInstagramPost}
                    className="btn action-btn"
                    disabled={isSharingPost || !imageUrl}
                  >
                    {isSharingPost ? "Sharing..." : "Share"}
                  </button> */}

                  {/* 
                  <div className='new-playlist created'>
                    <div className='d-flex'>
                      <div style={{ "marginRight": "20px" }}><TwitterIcon /> </div>
                      <div>
                        <FacebookMessengerShareButton url={message} appId="2094446014275656">
                          Share via Facebook Messenger
                        </FacebookMessengerShareButton>
                      </div>
                    </div>


                  </div> */}




                  <div className='new-playlist created'
                    onClick={() => { twittersend() }}
                  >
                    <div className='d-flex'
                    //  onClick={() => { twittersend() }}
                    >
                      <div style={{ "marginRight": "20px" }}><TwitterIcon /> </div>
                      <div>Twitter</div>

                    </div>


                  </div>
                  <FacebookProvider appId="2094446014275656" chatSupport>
                    <CustomChat pageId="265382853327457" minimized={false}></CustomChat>
                  </FacebookProvider>

                  {/* <MessengerCustomerChat
    pageId="265382853327457"
    appId="2094446014275656"
    // htmlRef="<REF_STRING>"
  /> */}

                  {/* <div className='new-playlist created'>
                    <div className='d-flex'onClick={() => { handleTelegramShare() }}>
                      <div style={{ "marginRight": "20px" }}> </div>
                      <div>Telegram</div>

                    </div>

                  
                  </div> */}





                  <div className='new-playlist created' onClick={() => { copyLink() }}>
                    <div className='d-flex' >
                      <div style={{ "marginRight": "20px" }}><FileCopyIcon /> </div>
                      <div>Copy Link</div>

                    </div>


                  </div>



                </Grid>

              </Grid>
            </>



          </Grid>

        </Box>
      </Modal>
    </>

  );
};



export default SendTrack;