import React, { useState } from 'react'
import './BckPassNew'
import Header from '../Header/Header'
import { Button, Grid } from '@mui/material'

import bk1 from '../../img/dster-webp/bk1.webp'
import bk2 from '../../img/dster-webp/bk2.webp'
import bk3 from '../../img/dster-webp/bk3.webp'
import bk4 from '../../img/dster-webp/bk4.webp'

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import { useTheme, useMediaQuery } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 700,
    overflowY: 'auto',
    '@media(max-width:991.98px)': {
        width: '80%'
    },
    bgcolor: 'var(--clr-explore-bg)',
    // border: '2px solid #000',
    borderRadius: '8px',
    // boxShadow: 24,
    p: 2,
};



function BckInner1() {

    const [cardImg, setCardImg] = useState([
        { img: `${bk1}`, sub: '$22 /month' },
        { img: `${bk2}`, sub: 'free' },
        { img: `${bk2}`, sub: 'free' },
        { img: `${bk3}`, sub: '$22 /month' },
        { img: `${bk4}`, sub: '$22 /month' },


    ])

    const [jnImg, setJnImg] = useState(null)

    const [inputChange, setInputChange] = useState('')

    const handleInputChange = (e) => {
        setInputChange(e.target.value.length)
        console.log(setInputChange, '/200');
    }

    const [free, setFree] = useState(false)
    console.log(free, 'free');


    const [open, setOpen] = React.useState(false);

    const handleOpen = (val) => {
        setOpen(true);
        setJnImg(val.img)
        if (val.sub.toLowerCase() === 'free') {
            setFree(true)

        } else {
            setFree(false)
        }
    }


    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const handleClose = () => setOpen(false);
    const [pending, setPending] = useState(false)

    const location = useLocation()

    const result = location.state

    const innerBlog = (
        cardImg.map((obj, i) => {
            return (
                <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={3}
                    xl={3}>
                    <div className="layerdiv" style={{ width: 'fit-content' }}>
                        <Card sx={{ maxWidth: 300 }} className='bck-n-card cursor' key={i} >
                            <CardMedia
                                sx={{ height: 140 }}
                                image={obj.img}
                                title="green iguana"
                            />
                            <CardContent>
                                <div className='display-1 mini'>
                                    <img src={obj.img} />
                                    <div className='mini-text'>
                                        Dreamster
                                    </div>
                                </div>
                                <Typography variant="body2" className='mini-text2'>
                                    {'Lizards are a widespread group of squamate reptiles, with over 6,000species, ranging across all continents except Antarctica'.slice(0, 100) + ' more...'}
                                </Typography>
                                <Stack
                                    direction="row"
                                    // divider={<Divider orientation="vertical" flexItem />}
                                    spacing={1}
                                >
                                    <div className='mini-text3'>
                                        Private •
                                    </div>
                                    <div className='mini-text3'>
                                        6.5k Members •
                                    </div>
                                    <div className='mini-text3'>
                                        {obj.sub}
                                    </div>
                                </Stack>
                            </CardContent>

                        </Card>
                    </div>

                </Grid>

            )
        })
    )



    return (
        <div className='bckinner1'>
            <Header />
            <Grid container sx={{ justifyContent: 'center', marginTop: "80px", }}>
                <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={11}>
                    <Grid container sx={{ justifyContent: 'center' }} >
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={7}
                            xl={7}>
                            <div className='bck-inner-mian' >
                                <iframe width="100%" height="500" src="https://www.youtube.com/embed/rgv4twV9ACA?si=dU2M2MAzddN0ltLd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                <div className='clickyell'>
                                    Click the yellow 'Join' button and then PLEASE answer our 3 vetting questions - you MUST answer them!
                                </div>
                                <div className='hostb'>
                                    Hosted by Antonio Centeno and the Real Men Real Style team. Our goal is to help you become the man you know yourself to be by mastering the science of style.
                                </div>
                                <div className='thicomm'>
                                    THIS COMMUNITY IS FOR YOU IF YOU'RE LOOKING TO:
                                </div>
                                <ul className='thicomm'>
                                    <li>
                                        Elevate your image
                                    </li>
                                    <li>Build connections with great guys</li>
                                    <li>Celebrate daily wins</li>
                                    <li>Learn from others</li>
                                    <li>Teach others what you know</li>
                                    <li>Become your best self</li>
                                </ul>
                                <div className='thicomm'>
                                    INSIDE THIS COMMUNITY YOU'LL FIND:
                                </div>
                                <ul className='thicomm'>
                                    <li>
                                        Like-minded positive people wanting to interact
                                    </li>
                                    <li> Access to men's style courses and training</li>
                                    <li>Exclusive LIVE sessions</li>
                                    <li> Unfiltered reviews and recommendations</li>
                                    <li>Giveaways</li>

                                </ul>
                                <div className='thicomm'>
                                    COMMUNITY GUIDELINES:
                                </div>
                                <div className='thicomm'>
                                    Be Respectful: Treat others kindly, no profanities, and offer constructive criticism.
                                    No Asking for Money: No donations or selling.
                                    Stay on Topic: Talk about things that relate to men's style.
                                    Help Each Other Grow: Share tips, support each other, engage and be active.
                                </div>
                                <div className='clickyell'>
                                    *This community is for gentlemen who love style and are aged 18+ and up.
                                </div>

                            </div>

                        </Grid>
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={3}
                            xl={3}>
                            <div>
                                <div className='bck-inner-mian'>
                                    <div className='bck-inner-mian-img'>
                                        <img src={result ? result.img : bk1} alt='join-button' />
                                    </div>
                                    <div className='rmr'>
                                        {'Real Men Real StyleCommunity'.slice(0, 18) + '...'}
                                    </div>
                                    <div className='skllink'>
                                        skool.com/rmrs
                                    </div>
                                    <div className='bckprf'>
                                        Discover the foundations of
                                        timeless style, classic grooming,
                                        and how to be a better man.
                                        Members get access to
                                        professional training & expert
                                        advice
                                    </div>
                                    <div className='otrlink'>
                                        NEW "Watch Lover" Commun…
                                    </div>
                                    <div className='otrlink'>
                                        Brotherhood Of Scent
                                    </div>
                                    <div className='otrlink'>
                                        Our Premium Style Course
                                    </div>
                                    <Stack
                                        direction="row"
                                        divider={<Divider orientation="vertical" flexItem className='divborder' />}
                                        spacing={2}
                                        className='stck-n-card'
                                    >
                                        <div className='rmr text-center'>
                                            6.5k
                                            <div className='adm'>
                                                Members
                                            </div>
                                        </div>
                                        <div className='rmr text-center'>
                                            40
                                            <div className='adm'>
                                                Online
                                            </div>
                                        </div>
                                        <div className='rmr text-center'>
                                            5k
                                            <div className='adm'>
                                                Admins
                                            </div>
                                        </div>
                                    </Stack>
                                    <div className='joinmember text-center'>
                                        <Button onClick={() => handleOpen(result)}>
                                            {result ? `Join ${result.sub}` : "Membership Pending"}
                                        </Button>
                                    </div>
                                    <div className='cancel-mem cursor'>
                                        Cancel membership request
                                    </div>
                                </div>
                            </div>

                        </Grid>
                        {innerBlog}
                    </Grid>

                </Grid>
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="text-right">
                            <CancelOutlinedIcon onClick={handleClose} />
                        </div>
                        {free ? <>
                            <>
                                {pending ? <>
                                    <div className='answer-banner'>
                                        <img src={jnImg} alt='adf' />
                                        <div className='realmen-h'>
                                            Membership pending
                                        </div>
                                        <div className='realmen-d'>
                                            Real Men Real Style Community admins are reviewing your request. You'll get an email when you're approved.
                                        </div>
                                    </div>

                                </> :
                                    <>

                                        <div className='answer-banner'>
                                            <img src={jnImg} alt='adf' />
                                            <div className='realmen-h'>
                                                Real Men Real Style Community
                                            </div>
                                            <div className='realmen-d'>
                                                Answer questions to join group
                                            </div>
                                        </div>
                                        <div className='ask1'>
                                            Youve been invited to a fancy dinner hosted by a friend. What outfit will you wear that would make you feel like a million bucks? In detail please!
                                        </div>
                                        <div className='numver20 text-right'>
                                            {`${inputChange ? inputChange : 0}/200`}
                                        </div>
                                        <div className='ask-inp'>
                                            <TextField id="outlined-basic" variant="outlined" onChange={handleInputChange} />
                                        </div>
                                        <div className='ask1'>
                                            Why do you even care about style? Help me understand why being part of this group is important to you. (You must answer!)
                                        </div>
                                        <div className='numver20 text-right'>
                                            {`${inputChange ? inputChange : 0}/200`}
                                        </div>
                                        <div className='ask-inp'>
                                            <TextField id="outlined-basic" variant="outlined" />
                                        </div>

                                        <div className='ask1'>
                                            What's your best email address? I promise no spam— just weekly community updates and important reminders.
                                        </div>
                                        <div className='numver20 text-right'>
                                            {`${inputChange ? inputChange : 0}/200`}
                                        </div>
                                        <div className='ask-inp'>
                                            <TextField id="outlined-basic" variant="outlined" />
                                        </div>

                                    </>}
                            </>

                        </> : <>
                            {pending ? <>
                                <div className='answer-banner'>
                                    <img src={jnImg} alt='adf' />
                                    <div className='realmen-h'>
                                        Membership pending
                                    </div>
                                    <div className='realmen-d'>
                                        Real Men Real Style Community admins are reviewing your request. You'll get an email when you're approved.
                                    </div>
                                </div>

                            </> :
                                <>

                                    <div className='answer-banner'>
                                        <img src={jnImg} alt='adf' />
                                        <div className='realmen-h'>
                                            Real Men Real Style Community
                                        </div>
                                        <div className='realmen-d'>
                                            $22 /month membership. Cancel anytime.
                                        </div>
                                    </div>
                                    <div className='ask1'>
                                        Email for membership
                                    </div>

                                    <div className='ask-inp'>
                                        <TextField id="outlined-basic" variant="outlined" placeholder='example@gmail.com' onChange={handleInputChange} />
                                    </div>

                                    <div className='ask1'>
                                        Payment information
                                    </div>

                                    <div className='ask-inp display-1'>
                                        <TextField id="outlined-basic" placeholder='Card number' variant="outlined" />
                                        <TextField id="outlined-basic" placeholder='MM/YY' className='mmcv' variant="outlined" />    <TextField id="outlined-basic" placeholder='CVV' className='mmcv' variant="outlined" />
                                    </div>
                                    <div className='answer-banner'>

                                        <div className='ask1 text-center'>
                                            By joining, you accept ANIME SHREDS and Skool's terms. You allow them to charge your card $22 now and $22 every month after that You can cancel anytime-
                                        </div>

                                    </div>


                                </>}
                        </>}

                        <div className='joinask'>
                            <Button onClick={() => setPending(!pending)}>
                                JOIN GROUP
                            </Button>
                        </div>

                    </Box>
                </Modal>
            </Grid>
        </div>
    )
}

export default BckInner1
