import React, { useState } from 'react'
import '../ExploreNew/ExploreNew.css'
import './MusicPlayerNew.css'
import HeaderNew from '../HeaderNew/HeaderNew'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import OwlCarousel from "react-owl-carousel";
import play from "../../img/dster-webp/paly.webp"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ts1 from '../../img/dster-webp/ts1.webp'
import ts2 from '../../img/dster-webp/ts2.webp'
import ts3 from '../../img/dster-webp/ts3.webp'
import ts4 from '../../img/dster-webp/ts4.webp'
import ts5 from '../../img/dster-webp/ts5.webp'
import ts6 from '../../img/dster-webp/ts6.webp'
import c5 from '../../img/dster-webp/bs1.webp'
import c6 from '../../img/dster-webp/bs2.webp'
import c7 from '../../img/dster-webp/bs3.webp'
import c8 from '../../img/dster-webp/bs4.webp'
import { useNavigate } from 'react-router-dom'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ct1 from "../../img/dster-webp/ct1.webp"
import ct2 from "../../img/dster-webp/ct2.webp"
import ct3 from "../../img/dster-webp/ct3.webp"
import ct4 from "../../img/dster-webp/ct4.webp"
import ct5 from "../../img/dster-webp/ct5.webp"
import ct6 from "../../img/dster-webp/ct6.webp"
import ct7 from "../../img/dster-webp/ct7.webp"
import ct8 from "../../img/dster-webp/ct8.webp"
import ls1 from "../../img/dster-webp/ls1.webp"
import nr1 from "../../img/dster-webp/nr1.webp"
import nr2 from "../../img/dster-webp/nr2.webp"
import nr3 from "../../img/dster-webp/nr3.webp"
import nr4 from "../../img/dster-webp/nr4.webp"
import nr5 from "../../img/dster-webp/nr5.webp"
import nrplay from "../../img/dster-webp/nrplay.webp"
import { v4 as uuidv4 } from "uuid";
import Cardmx from "./Cards";
import Carousel from "./Carosuals";
import mx1 from "../../img/dster-webp/mx1.webp"
import mx2 from "../../img/dster-webp/mx2.webp"
import mx3 from "../../img/dster-webp/mx3.webp"
import mx4 from "../../img/dster-webp/mx4.webp"
import Stack from '@mui/material/Stack';



const drawerWidth = 240;

const content4 = [
    { img: `${c5}`, txt1: 'APOKI', txt2: '2.1K Followers' },
    { img: `${c6}`, txt1: 'APOKI', txt2: '2.1K Followers' },
    { img: `${c7}`, txt1: 'Kato On The Track', txt2: '2.1K Followers' },
    { img: `${c8}`, txt1: 'Kato On The Track', txt2: '2.1K Followers' },
    { img: `${c5}`, txt1: '𝔟𝔟𝔶 𝔦𝔳𝔶', txt2: '2.1K Followers' },
    { img: `${c6}`, txt1: '𝔟𝔟𝔶 𝔦𝔳𝔶', txt2: '2.1K Followers' },
    { img: `${c7}`, txt1: 'Matt OX', txt2: '2.1K Followers' },
    { img: `${c8}`, txt1: 'Matt OX', txt2: '2.1K Followers' },
    // { img: `${c8}`, txt1: 'Matt OX', txt2: '2.1K Followers' },
]
const itemData = [
    { img: `${ct1}`, title: 'Pop' },
    { img: `${ct2}`, title: 'Chill' },
    { img: `${ct3}`, title: 'Christmas' },
    { img: `${ct4}`, title: 'Podcast' },
    { img: `${ct5}`, title: 'Romance' },
    { img: `${ct6}`, title: 'Hip Hop' },
    { img: `${ct7}`, title: 'Breakfast' },
    { img: `${ct8}`, title: 'Jazz' },


];

function MusicPlayerNew() {
    const navigate = useNavigate()
    const [name, setName] = React.useState();
    const [see, setSee] = useState(true)
    const [btnactive1, setBtnactive1] = useState(true)
    const [btnactive2, setBtnactive2] = useState(false)
    const [fav, setFav] = useState(false)
    const [exp, setExp] = useState(false)
    const [hist, setHist] = useState(false)


    let cardmx = [
        {
            key: uuidv4(),
            content: (
                <Cardmx imagen={mx1} />
            ),
        },
        {
            key: uuidv4(),
            content: (
                <Cardmx imagen={mx2} />
            ),
        },
        {
            key: uuidv4(),
            content: (
                <Cardmx imagen={mx3} />
            ),
        },
        {
            key: uuidv4(),
            content: (
                <Cardmx imagen={mx4} />
            ),
        },
        {
            key: uuidv4(),
            content: (
                <Cardmx imagen={mx2} />
            ),
        },
    ];
    const options = {
        margin: 30,
        items: 4,
        responsiveClass: true,
        // nav: true,
        autoplay: true,
        autoplayTimeout: 2000,
        dots: false,
        loop: true,
        smartSpeed: 2000,
        autoHeightClass: "owl-height",
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
    };


    console.log(options.autoplay, 'ansewewe')



    const [top, setTop] = useState([
        { name: 'Red (Taylor’s Version)', img: `${nr1}` },
        { name: 'Need To Know', img: `${nr2}` },
        { name: 'After Hours', img: `${nr3}` },
        { name: 'HIT MACHINE', img: `${nr4}` },
        { name: 'Red (Taylor’s Version)', img: `${nr5}` },
    ])

    const [strem, setStrem] = useState([
        { name: 'Mistletoe', artist: 'Justin Bieber', img: `${ts1}` },
        { name: 'Easy On Me', artist: 'Adele', img: `${ts2}` },
        { name: 'Moonlight', artist: 'Public Library Commu...', img: `${ts3}` },
        { name: 'SICKO MODE', artist: 'Travis Scott ft. Drake', img: `${ts4}` },
        { name: 'Get Lost', artist: 'Vincent Fable', img: `${ts5}` },
        { name: 'I Feel Good', artist: 'Pink Sweat$', img: `${ts6}` },
        { name: 'I Feel Good', artist: 'Pink Sweat$', img: `${ts6}` },
        { name: 'I Feel Good', artist: 'Pink Sweat$', img: `${ts6}` },
        { name: 'I Feel Good', artist: 'Pink Sweat$', img: `${ts6}` },
        { name: 'I Feel Good', artist: 'Pink Sweat$', img: `${ts6}` },
        { name: 'I Feel Good', artist: 'Pink Sweat$', img: `${ts6}` },
    ])

    const [lisHistort, setLisHistort] = useState([
        { name: 'She Will Be Loved', artist: 'Maroon', img: `${ls1}` },
        { name: 'She Will Be Loved', artist: 'Maroon', img: `${ls1}` },
        { name: 'She Will Be Loved', artist: 'Maroon', img: `${ls1}` },
        { name: 'She Will Be Loved', artist: 'Maroon', img: `${ls1}` },
        { name: 'She Will Be Loved', artist: 'Maroon', img: `${ls1}` },
    ])


    const mappedRows = top.map((row, ind) => {
        return (

            <div key={ind} className='nft-item-nr'>
                <div className='nr'>
                    <img src={row.img} alt='img' />
                </div>
                <div className='nr-artist'>
                    {row.name}
                </div>
                <div className='song-new'>
                    Song
                </div>
                <div className='play-new2'>
                    <img src={play} alt="play" />
                </div>
            </div>
        );
    });


    return (
        <div className='Explore-new'>
            <Box sx={{ display: 'flex' }}>

                <HeaderNew name={name} setName={setName} />

                <Box component="main"
                    className="explore-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '135px', marginBottom: '100px' }}>
                    <Grid container spacing={0}>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '15px' }}>
                            <Grid container spacing={0}>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='carosuel-grid' sx={{ padding: '15px', borderRadius: '15px', margin: '15px 0px' }}>
                                    <Carousel
                                        cards={cardmx}
                                        height="500px"
                                        width="50%"
                                        margin="0 auto"
                                        offset={5}
                                    // showArrows={false}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className='enew-g1-main'>
                                        <div className='enew-g1-release'>
                                            <div className='new-release-mxp'>
                                                New Releases <img src={nrplay} alt='nr' />
                                            </div>
                                            <div className='new-release-btns' onClick={(() => { setSee(!see) })}>
                                                See more
                                            </div>
                                        </div>
                                        <div className='nr-owlcarosual'>
                                            <div className='lazy-load'>
                                                {see ?

                                                    <OwlCarousel className="owl-theme owl-carousel nft" {...options} sx={{ width: 'auto', height: '204px' }}>
                                                        {mappedRows}
                                                    </OwlCarousel>

                                                    :


                                                    <Grid container spacing={0}>
                                                        {mappedRows.map((row) => {
                                                            return (
                                                                <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
                                                                    {row}
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>


                                                }
                                            </div>

                                        </div>

                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid xs={12} sm={12} md={12} lg={12} xl={8} sx={{ padding: '15px' }}>
                            <div className='enew-g1-main'>
                                <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                                        {/* <div className='top-stream-mian'>
                                            <div className='top-stemers'>
                                                Listening History
                                            </div>
                                            <div className='seeall-new'>
                                                see all
                                            </div>

                                        </div> */}
                                        {lisHistort.map((row, ind) => {
                                            return (
                                                <div className='e-news-list feed'>
                                                    <div className='history-new-main' >
                                                        <div className='history-new-left'>
                                                            <img src={row.img} alt='ct1' />
                                                            <div className='hist-mai'>
                                                                {row.name}
                                                                <div className='maroon'>
                                                                    {row.artist}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='history-new-right'>
                                                            <div className='playtime'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
                                                                    <path d="M9.65909 7.07169C9.61648 7.13001 9.41761 7.37789 9.26136 7.53828L9.17614 7.62576C7.98295 8.95261 5.0142 10.9502 3.50852 11.5917C3.50852 11.6063 2.61364 11.9854 2.1875 12H2.13068C1.47727 12 0.866477 11.6209 0.553977 11.0085C0.383523 10.6731 0.227273 9.69623 0.213068 9.68165C0.0852273 8.8068 0 7.46683 0 5.99271C0 4.44714 0.0852273 3.04739 0.241477 2.18712C0.241477 2.17254 0.397727 1.38518 0.497159 1.12272C0.653409 0.743621 0.9375 0.422843 1.29261 0.218712C1.5767 0.072904 1.875 0 2.1875 0C2.5142 0.0145808 3.125 0.234751 3.36648 0.335358C4.95739 0.976914 7.99716 3.07655 9.16193 4.35966C9.3608 4.56379 9.57386 4.81167 9.63068 4.86999C9.87216 5.19077 10 5.58445 10 6.00875C10 6.38639 9.88636 6.76549 9.65909 7.07169Z" fill="#7C8DB5" fill-opacity="0.72" />
                                                                </svg>   120 k
                                                            </div>
                                                            <div className='playtime more-histore' >
                                                                <MoreHorizIcon />
                                                            </div>
                                                            <div className={hist ? 'playtime hist-fav' : 'playtime'} onClick={(() => { setHist(!hist) })}>
                                                                <svg key={ind} width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_2378_5148)">
                                                                        <path opacity="0.4" d="M9.81207 18.1979C7.90954 17.0227 6.14095 15.6371 4.53867 14.0663C3.40752 12.9448 2.54365 11.5754 2.01307 10.0627C1.06504 7.11269 2.16861 3.74123 5.24969 2.74033C6.87595 2.22961 8.64464 2.54313 10.0046 3.5832C11.3651 2.54429 13.1333 2.23087 14.7596 2.74033C17.8407 3.74123 18.9516 7.11269 18.0036 10.0627C17.4773 11.574 16.6186 12.9433 15.4928 14.0663C13.8891 15.6354 12.1207 17.0209 10.2194 18.1979L10.012 18.3333L9.81207 18.1979Z" fill="#B9C0DE" />
                                                                        <path d="M10.0084 18.3332L9.81267 18.1978C7.90779 17.0228 6.13674 15.6371 4.53186 14.0662C3.39535 12.9463 2.52634 11.5767 1.99145 10.0626C1.05083 7.11261 2.15439 3.74115 5.23548 2.74024C6.86173 2.22952 8.65375 2.54328 10.0084 3.59206V18.3332Z" fill="#7C8DB5" fill-opacity="0.72" />
                                                                        <path d="M15.1907 8.33269C15.0233 8.32191 14.8674 8.23825 14.7596 8.1013C14.6517 7.96436 14.6014 7.78617 14.6203 7.60847C14.6384 7.02317 14.3054 6.49042 13.7918 6.28314C13.4661 6.19424 13.2689 5.84156 13.3504 5.49374C13.4277 5.15152 13.7482 4.93873 14.0702 5.01575C14.1109 5.0225 14.15 5.03723 14.1857 5.05921C15.2154 5.45548 15.8821 6.52201 15.8291 7.68813C15.8274 7.86488 15.7585 8.03332 15.6381 8.15484C15.5178 8.27637 15.3563 8.34055 15.1907 8.33269Z" fill="white" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_2378_5148">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                                                                    <path d="M8.5 0.0717773L0.5 6.10097L8.5 12.1302V0.0717773Z" fill="#B5B5B6" />
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                                                    <path d="M15.5 0.0717773L7.5 6.10097L15.5 12.1302V0.0717773Z" fill="#B5B5B6" />
                                                                    <path d="M8.5 0.0717773L0.5 6.10097L8.5 12.1302V0.0717773Z" fill="#B5B5B6" />
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="116" height="117" viewBox="0 0 116 117" fill="none" style={{ marginTop: '30px' }}>
                                                                    <g filter="url(#filter0_d_2581_1863)">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 43.5807C30 28.126 42.5676 15.5645 58 15.5645C73.4324 15.5645 86 28.126 86 43.5807C86 59.0029 73.4324 71.5645 58 71.5645C42.5676 71.5645 30 59.0029 30 43.5807ZM68.2703 46.4144C68.5676 46.1173 68.946 45.658 69.0271 45.55C69.4595 44.9827 69.6757 44.2803 69.6757 43.5807C69.6757 42.7946 69.4325 42.0652 68.973 41.4709C68.9353 41.4332 68.8616 41.353 68.7655 41.2484C68.5857 41.0527 68.3277 40.7718 68.0811 40.5254C65.8649 38.1481 60.0811 34.2581 57.0541 33.0695C56.5946 32.8831 55.4325 32.4752 54.8108 32.4482C54.2162 32.4482 53.6487 32.5833 53.1081 32.8534C52.4325 33.2316 51.8919 33.8259 51.5946 34.5283C51.4054 35.0145 51.1081 36.4733 51.1081 36.5003C50.8108 38.0941 50.6487 40.6875 50.6487 43.5509C50.6487 46.2821 50.8108 48.7646 51.0541 50.3855C51.0608 50.3922 51.084 50.5081 51.1201 50.6882C51.2299 51.2357 51.4587 52.3762 51.7027 52.8438C52.2973 53.9783 53.4595 54.6807 54.7027 54.6807H54.8108C55.6217 54.6537 57.3244 53.9513 57.3244 53.9243C60.1892 52.7357 65.8379 49.0348 68.1081 46.5765L68.2703 46.4144Z" fill="url(#paint0_linear_2581_1863)" />
                                                                    </g>
                                                                    <defs>
                                                                        <filter id="filter0_d_2581_1863" x="0" y="0.564453" width="116" height="116" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                            <feOffset dy="15" />
                                                                            <feGaussianBlur stdDeviation="15" />
                                                                            <feComposite in2="hardAlpha" operator="out" />
                                                                            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.521569 0 0 0 0 0.239216 0 0 0 0.16 0" />
                                                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2581_1863" />
                                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2581_1863" result="shape" />
                                                                        </filter>
                                                                        <linearGradient id="paint0_linear_2581_1863" x1="85" y1="65.877" x2="28.0905" y2="61.4978" gradientUnits="userSpaceOnUse">
                                                                            <stop stop-color="#175AFF" />
                                                                            <stop offset="1" stop-color="#FC5AFF" />
                                                                        </linearGradient>
                                                                    </defs>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                                                                    <path d="M0.5 0.0717773L8.5 6.10097L0.5 12.1302V0.0717773Z" fill="#B5B5B6" />
                                                                    <path d="M7.5 0.0717773L15.5 6.10097L7.5 12.1302V0.0717773Z" fill="#B5B5B6" />
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                                                                    <path d="M0.5 0.0717773L8.5 6.10097L0.5 12.1302V0.0717773Z" fill="#B5B5B6" />
                                                                </svg>
                                                            </Stack>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })}


                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid xs={12} sm={12} md={12} lg={12} xl={4} sx={{ padding: '15px' }}>

                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '15px 0px' }}>
                                <div className='enew-g1-main'>
                                    <div className='top-stream-mian'>
                                        <div className='top-stemers'>
                                            Top Streams Real-time
                                        </div>
                                        <div className='seeall-new'>
                                            see all
                                        </div>

                                    </div>
                                    <div>
                                        <ImageList sx={{ width: 'auto', height: 'auto' }} cols={2} rowHeight={110} className='img-list'>
                                            {itemData.map((item) => (
                                                <ImageListItem key={item.img}>
                                                    <img
                                                        // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </div>
                                </div>


                            </Grid>
                        </Grid>

                    </Grid>


                </Box>

            </Box >
        </div >
    )
}

export default MusicPlayerNew
