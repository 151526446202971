
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import '../src/Media.css'
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import Header2 from './Components/Header/Header2';
import Explore from './Components/Explore/Explore';
import HotNew from './Components/HotNew/HotNew';
import Player2 from './Components/Music-player/Player2';
import Fanzone from './Components/Fanzone/Fanzone';
import History from './Components/History/History';
import UploadMusic from './Components/Upload-Music/UploadMusic';
import Library from './Components/Library/Library';
import Creater from './Components/Creater/Creater';
import Upload from './Components/Creater/Upload';
import Feed from './Components/Feed/Feed'
import Track from './Components/Music-traxk/Track';
import Tracks from './Components/Music-traxk/Tracks';
import TokenPage from './Components/Header/Tokenpage'
import EmailTokenpage from './Components/Header/EmailTokenpage';
import Resetpassword from './Components/Header/ResetPassword';
import Profile from './Components/Profile/Profile';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import CheckBalance from './Components/PrivateRoute/CheckBalance'
import MintNft from './Components/Creater/MintNft';
import Faq from './Components/Faq/Faq';
import Contactus from './Components/Contactus/Contactus';
import PremiumTrack from './Components/PremiumTracks/PremiumTracks';
import MintNftNew from './Components/Creater/MintNftNew';
import Trackpage from './Components/TrackPage/TrackPage';
import Albumpage from './Components/AlbumPage/AlbumPage';
import CreatePlayList from './Components/CreatePlayList/CreatePlayList';
import ShowPlayList from './Components/CreatePlayList/ShowPlayList';
import BackStagePass from './Components/BackStagePass/BackStagePass';
import BackStageFanZone from './Components/BackStagePass/BackStageFanZone';
import SnackBar from './Components/SnackBar/SnackBar';
import Hoisting from './Components/Hoisting/Hoisting';
import Dashboard from './Components/Dashboard/Dashboard';
import Participate from './Components/Particpate/Participate'
import Playlist from './Components/Playlist/Playlist';
import CalendarNew from './Components/CalendarNew/CalendarNew';
import Activity from './Components/Activity/Activity';
import ActivityDetailPage from './Components/ActivityDetail/ActivityDetailPage';
import Message from './Components/Message/Message';
import Stacking from './Components/Stacking/Stacking';
import ArtistPage from './Components/ArtistPage/ArtistPage';
import Myprofile from './Components/Profile/MyProfile';
import CreateVirtualMeet from './Components/CreateVirtualMeet/CreateVirtualMeet';
import CreateExclusive from './Components/CreateVirtualMeet/CreateExclusive';
import Colabration from './Components/Colabration/Colabration';
import ExploreArtist2 from './Components/ExploreArtist/ExploreArtist2';
import Mymessage from './Components/Message/Mymessage';
import PollsSurvey from './Components/Polls&Survey/PollsSurvey';
import VirtualMeet from './Components/VirtualMeet/VirtualMeet';
import ExploreArtist from './Components/ExploreArtist/ExploreArtist';
import Notify from './Components/Notification/Notify';
import HeaderNew from './Components/HeaderNew/HeaderNew';
import ExploreNew from './Components/ExploreNew/ExploreNew';
import MusicPlayerNew from './Components/MusicPlayerNew/MusicPlayerNew';
import FeedNew from './Components/FeedNew/FeedNew';
import PollsHistory from './Components/Polls&Survey/PollsHistory';
import Participate2 from './Components/Particpate/Participate2';
import ZoomConnect from './Components/ZoomPave/ZoomConnect'
import MessageSet from './Components/MessageSet/MessageSet';
import Join from './Components/Join/Join';
import SupportPage from './Components/BackStagePass/SupportPage';
import SidebarNew from './Components/SidebarNew/SidebarNew';
import PlayerNew from './Components/PlayerNew/PlayerNew';
import CreatorsNew from './Components/CreatoreNew/CreatorsNew';
import LibraryNew from './Components/LibraryNew/LibraryNew';
import BckPassNew from './Components/BackstagepassNew/BckPassNew';
import BckInner1 from './Components/BackstagepassNew/BckInner1';
import BckInner2 from './Components/BackstagepassNew/BckInner2';
import LiveChat from './Components/LiveChat/LiveChat';
import BckPassCreate from './Components/BackstagepassNew/BckPassCreate';
import PlayListNew from './Components/PlayListNew/PlayListNew';
import AlbumNew from './Components/AlbumPageNew/AlbumNew';
import Genre from './Components/Genre/Genre';
import ExploreNew2 from './Components/ExploreNew2/ExploreNew2';
import MintNew from './Components/MintNew/MintNew';
import LoginNew from './Components/LoginNew/LoginNew';
import RegisterNew from './Components/LoginNew/RegisterNew';
import RegisterType from './Components/LoginNew/RegisterType';
import BottomBar from './Components/BottomBar/BottomBar';
import RestPaswd from './Components/LoginNew/RestPaswd';
import Comingsoon from './Components/comingsoon/Comingsoon';
// import { ZoomMtg } from '@zoom/meetingsdk';
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();


function App() {
  const [active, setActive] = useState(true);

  useEffect(() => {
    let timer;

    const handleMouseMove = () => {
      setActive(true);
      clearTimeout(timer);
      timer = setTimeout(() => setActive(false), 2000); // Set cursor to hide after 2 seconds of inactivity
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={active ? '' : 'hidden-cursor'}>
      <div className="App">

        <Router>
          <Routes>
            <Route path="reset/:token" element={<Resetpassword />} />
            <Route exact path="/header" element={<Header />} />
            <Route exact path="/header2" element={<Header2 />} />
            <Route exact path="/home" element={<Home />} />
            <Route path="tokenpage/:token" element={<TokenPage />} />
            <Route path="tokenpage/:token/:email" element={<EmailTokenpage />} />
            {/* <Route exact path="/login" element={<Login />} /> */}
            {/* <Route exact path="/register" element={<Register />} /> */}
            <Route exact path="/explorepage" element={<PrivateRoute> <Explore /> </PrivateRoute>} />
            <Route exact path="/player2" element={<PrivateRoute> <Player2 /> </PrivateRoute>} />
            <Route exact path="/fanzone" element={<PrivateRoute> <Fanzone /> </PrivateRoute>} />
            <Route exact path="/track" element={<PrivateRoute> <Track /> </PrivateRoute>} />
            <Route exact path="/backstagepass" element={<PrivateRoute> <BackStagePass /> </PrivateRoute>} />
            <Route exact path="/join/:room_id" element={<PrivateRoute> <Join /> </PrivateRoute>} />
            <Route exact path="/hotnew" element={<PrivateRoute> <HotNew /> </PrivateRoute>} />
            <Route exact path="/history" element={<PrivateRoute> <History /> </PrivateRoute>} />
            <Route exact path="/calendarnew" element={<PrivateRoute> <CalendarNew /> </PrivateRoute>} />
            <Route exact path="/uploadmusic" element={<PrivateRoute> <UploadMusic /> </PrivateRoute>} />
            <Route exact path="/library" element={<PrivateRoute> <Library /> </PrivateRoute>} />
            <Route exact path="/create" element={<Creater />} />
            <Route exact path="/upload" element={<PrivateRoute> <Upload /> </PrivateRoute>} />
            <Route exact path="/mintnft2" element={<PrivateRoute> <MintNft /> </PrivateRoute>} />
            <Route exact path="/mintnft" element={<PrivateRoute> <MintNftNew /> </PrivateRoute>} />
            <Route exact path="/feedpage" element={<PrivateRoute> <Feed /> </PrivateRoute>} />
            <Route exact path="/profile" element={<PrivateRoute> <Profile /> </PrivateRoute>} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/contactus" element={<Contactus />} />
            <Route exact path="/premiumtracks" element={<PrivateRoute> <PremiumTrack /> </PrivateRoute>} />
            <Route exact path="/trackpage/:_id" element={<Trackpage />} />
            <Route exact path="/albumpage/:_id" element={<PrivateRoute> <Albumpage /> </PrivateRoute>} />
            <Route exact path="/createplaylist" element={<PrivateRoute> <CreatePlayList /> </PrivateRoute>} />
            <Route exact path="/showplaylist/:_id" element={<PrivateRoute> <ShowPlayList /> </PrivateRoute>} />
            <Route exact path="/backstagefanzone" element={<PrivateRoute> <BackStageFanZone /> </PrivateRoute>} />
            <Route exact path="/hoisting" element={<PrivateRoute> <Hoisting /> </PrivateRoute>} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/participate" element={<PrivateRoute> <Participate /> </PrivateRoute>} />
            <Route exact path="/polls_survey" element={<PrivateRoute> <Participate2 /> </PrivateRoute>} />
            <Route exact path="/activity" element={<PrivateRoute> <Activity /> </PrivateRoute>} />
            <Route exact path="/activitydetailpage" element={<PrivateRoute> <ActivityDetailPage /> </PrivateRoute>} />
            <Route exact path="/message" element={<PrivateRoute> <Message /> </PrivateRoute>} />
            <Route exact path="/message/:_id" element={<PrivateRoute> <Message /> </PrivateRoute>} />
            <Route exact path="/mymessage" element={<PrivateRoute> <Mymessage /> </PrivateRoute>} />
            <Route exact path="/staking" element={<PrivateRoute> <Stacking /> </PrivateRoute>} />
            <Route exact path="/myprofile" element={<PrivateRoute> <Myprofile /> </PrivateRoute>} />
            <Route exact path="/createvirtualmeet" element={<PrivateRoute> <CreateVirtualMeet /> </PrivateRoute>} />
            <Route exact path="/createexclusive" element={<PrivateRoute> <CreateExclusive /> </PrivateRoute>} />
            <Route exact path="/colabration" element={<PrivateRoute> <Colabration /> </PrivateRoute>} />
            <Route exact path="/artistpage/:_id" element={<PrivateRoute> <ArtistPage /> </PrivateRoute>} />
            <Route exact path="/artist/:title/:_id" element={<PrivateRoute> <ExploreArtist2 /> </PrivateRoute>} />
            <Route exact path="/pollssurvey" element={<PrivateRoute> <PollsSurvey /> </PrivateRoute>} />
            <Route exact path="/pollshistory" element={<PrivateRoute> <PollsHistory /> </PrivateRoute>} />
            <Route exact path="/virtualMeet" element={<PrivateRoute> <VirtualMeet /> </PrivateRoute>} />
            <Route exact path="/exploreartist" element={<PrivateRoute> <ExploreArtist /> </PrivateRoute>} />
            {/* <Route exact path="/testing" element={<PrivateRoute> <Testing /> </PrivateRoute>} /> */}
            <Route exact path="/notify" element={<PrivateRoute> <Notify /> </PrivateRoute>} />



            {/* <Route exact path="/playlist" element={<PrivateRoute> <Playlist /> </PrivateRoute>} /> */}
            <Route exact path="/zoomconnect" element={<PrivateRoute> <ZoomConnect /> </PrivateRoute>} />


// Redesign


            <Route exact path="/headernew" element={<HeaderNew />} />
            <Route exact path="/explore" element={<PrivateRoute> <ExploreNew /> </PrivateRoute>} />
            <Route exact path="/musicPlayerNew" element={<PrivateRoute> <MusicPlayerNew /> </PrivateRoute>} />
            <Route exact path='/feed' element={<FeedNew />} />

            <Route exact path='/messageSet' element={<PrivateRoute> <MessageSet /> </PrivateRoute>} />

            <Route exact path='/supportpage' element={<SupportPage />} />


// Redesign 2


            {/* <Route exact path="/sidebarnew" element={<SidebarNew />} /> */}

            <Route exact path="/register" element={<RegisterNew />} />
            <Route exact path="/registernew" element={<RegisterType />} />
            <Route exact path="/login" element={<LoginNew />} />
            <Route exact path="/resetpaswd/:token" element={<RestPaswd />} />

            <Route exact path="/" element={<PlayerNew />} />
            <Route exact path="/album/:id" element={<AlbumNew />} />
            <Route exact path="/creators" element={<PrivateRoute allowedRoles={['artist']}><CreatorsNew /></PrivateRoute>} />
            <Route exact path="/playlist" element={<PrivateRoute allowedRoles={['artist', 'user']}><PlayListNew /></PrivateRoute>} />
            {/* <Route exact path="/livechat" element={<PrivateRoute allowedRoles={['artist', 'user']}><LiveChat /></PrivateRoute>} /> */}
            <Route exact path="/livechat" element={<PrivateRoute allowedRoles={['artist', 'user']}><Comingsoon /></PrivateRoute>} />

            {/* <Route exact path="/bckpassnew" element={<PrivateRoute allowedRoles={['artist']}><BckPassNew /></PrivateRoute>} />
            <Route exact path="/bckinner1" element={<PrivateRoute allowedRoles={['artist']}><BckInner1 /></PrivateRoute>} />
            <Route exact path="/bckinner2" element={<PrivateRoute allowedRoles={['artist']}><BckInner2 /></PrivateRoute>} />
            <Route exact path="/bckpasscreate" element={<PrivateRoute allowedRoles={['artist']}><BckPassCreate /></PrivateRoute>} /> */}

            {/* <Route exact path="/bckpassnew" element={<BckPassNew />} /> */}
            <Route exact path="/bckpassnew" element={<Comingsoon />} />
            <Route exact path="/bckinner1" element={<BckInner1 />} />
            <Route exact path="/bckinner2" element={<BckInner2 />} />
            <Route exact path="/bckpasscreate" element={<BckPassCreate />} />

            <Route exact path="/librarynew" element={<PrivateRoute ><LibraryNew /></PrivateRoute>} />
            <Route exact path="/genre" element={<Genre />} />
            <Route exact path="/explorenew2" element={<ExploreNew2 />} />
            {/* <Route exact path="/mintnew" element={<MintNew />} /> */}
            <Route exact path="/mintnew" element={<Comingsoon />} />



            // bottom bar

            <Route exact path="/bottombar" element={<BottomBar />} />


          </Routes>
        </Router>




      </div>
    </div>

  );
}

export default App;
