import React from "react";
import HeaderNew from "../HeaderNew/HeaderNew";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import '../Upload-Music/UploadMusic.css'
import Container from '@mui/material/Container'
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import './Hoisting.css'
import TextField from '@mui/material/TextField';
import { useState, useEffect, useRef } from "react";
import Axios from "../../Axios";
import { useNavigate } from "react-router-dom";



const drawerWidth = 280;



function Hoisting() {
    const theme = useTheme();
    const [validate, setValidate] = useState('')
    const [err, setErr] = useState(null)

    // console.log(validate);

    const music = useRef(null)
    const token = useRef(null)
    const tokenName = useRef(null)
    const description = useRef(null)
    const eventDuration = useRef(null)
    const [date, setDate] = useState("")
    const [date_timestamp, setDate_Timestamp] = useState("")

    const [errMusic, setErrMusic] = useState()
    const [errToken, setErrToken] = useState()
    const [errtokenName, setErrtokenName] = useState()
    const [errdescription, setErrdescription] = useState()
    const [erreventDate, setErreventDate] = useState()
    const [erreventDuration, setErreventDuration] = useState()
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0)

    const [audio, setAudio] = useState(null)

    const [meetingDet, setMeetingDet] = useState()



    const audioRef = useRef(null);

    const handlePlay = () => {
        audioRef.current.play();
        setIsPlaying(true);
    };

    const handleFarward = () => {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
    }

    const handlePause = () => {
        audioRef.current.pause();
        setIsPlaying(false);
    };

    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause();
        } else {
            handlePlay();
        }
    };


    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
        setDuration(audioRef.current.duration);
    };

    const handleSeek = (e) => {
        audioRef.current.currentTime = e.target.value;
        setCurrentTime(e.target.value);
    };

    function formatDuration(durationSeconds) {
        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
        }
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
            }
        };
    }, [audio]);

    const navigate = useNavigate()

    useEffect(() => {
        if (validate.length >= 3) {
            setErr(false)
        }
        //  else if (validate.trim() === '') {
        //     setErr(false)
        // }
        else {
            setErr(true)
        }

    }, [validate])

    const [tokenUrl, setTokenUtl] = useState()

    const CreateMeeting = async (topic, description, start_time, duration) => {
        try {
            setMeetingDet("")
            const { data } = await Axios.post('/zoom/createZoomId', {
                topic: topic,
                description: description,
                start_time: start_time,
                duration: duration
            }, {
                headers: {
                    Authorization: window.localStorage.getItem("iphephile")
                }
            })
            if (data?.success == true) {
                // setMeetingDet(data?.result)
                return data?.result
            } else {
                // toast.error(data?.message)
                return false
            }
        } catch (error) {
            return false
        }
    }

    const CreateRoom = async () => {
        try {

            // if (audio == null) {
            //     toast.error("Please Select Song")
            // }
            if ((token.current.value == '' || token.current.value == undefined)) {
                setErrToken("Please Enter Token")
            }
            else if (token.current.value <= 0) {
                setErrToken("Please Enter Token value More than 0")
            }
            else if ((tokenName.current.value == '' || tokenName.current.value == undefined)) {
                setErrtokenName("Please Enter Track Name")
            } else if ((description.current.value == '' || description.current.value == undefined)) {
                setErrdescription("Please Enter Description")
            }
            // else if ((eventDate.current.value == '' || eventDate.current.value == undefined)) {
            //     setErreventDate("Please Enter Event Date")
            // }
            else if (date == '') {
                setErreventDate("Please Enter Event Date")
            }
            else if ((eventDuration.current.value == '' || eventDuration.current.value == undefined)) {
                setErreventDuration("Please Enter Event Duration1")
            } else {
                var payload = {
                    type: "group",
                    // track_url: tokenUrl,
                    token: token.current.value,
                    track_Name: tokenName.current.value,
                    description: description.current.value,
                    EventDate: date,
                    EventDate_stamp: date_timestamp,
                    EventDuration: eventDuration.current.value,
                    EventDuration_stamp: Number(eventDuration.current.value) * 3600,
                    // zoom_data: meetId
                }
                // Convert timestamp to milliseconds by multiplying with 1000
                var dates = new Date(date_timestamp * 1000);

                // Format the date as "YYYY-MM-DDTHH:MM:SS"
                var formattedDate = dates.toISOString().slice(0, 19);

                var meetId = await CreateMeeting(tokenName.current.value, description.current.value, formattedDate, Number(eventDuration.current.value) * 60)
                if (meetId == false) {
                    toast.error("error creating meeting")
                } else {
                    payload.zoom_data = meetId
                    const { data } = await Axios.post('/followers/create_room', payload, {
                        headers: { Authorization: window.localStorage.getItem('iphephile') }
                    })
                    if (data?.success == true) {
                        toast.success(data?.message)
                        setTimeout(() => {
                            navigate('/backstagepass')
                        }, 2000);
                    } else {
                        toast.error(data?.message)
                    }
                }


            }

        } catch (error) {
            console.log("🚀 ~ CreateRoom ~ error:", error)
        }
    }

    const handleImageChange = async (event) => {
        const files = event.target.files[0];

        try {
            let formData = await new FormData();
            formData.append("image", files);
            const fileName = files.name
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "mp3") {
                const { data } = await Axios.post("/updateDp", formData);
                if (data?.success == true) {
                    setTokenUtl(data?.result)
                }
            } else {
                // setImage(null)
                toast.error("Only Music files are allowed!");
            }
        } catch (error) {
            // setisImage(false)

            console.log(error, "err");
        }
    };


    const handleAudio = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setAudio(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };
    return (

        <div className="Hoisting">
            {/* <SnackBar /> */}
            <ToastContainer position="top-right" />
            <Box sx={{ display: 'flex' }}>

                <HeaderNew />
                <Box component="main"
                    className="Hoisting-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    <Grid container spacing={0} className="heading-container">
                        {/* <Container maxWidth="lg">
                            <Grid xs={12} sm={12} md={12} lg={12} xl={8}>
                                <div className="UploadMusic-heading">
                                    Create Challenges
                                </div>
                            </Grid>



                        </Container> */}
                    </Grid>

                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 0 }}
                        className="UploadMusic-box3"
                    >
                        <Container maxWidth="xl" >
                            <Grid container spacing={2} >
                                {/* <Grid xs={12} sm={12} md={12} lg={12} xl={8} className="upload-grid" sx={{ p: 1 }}>

                                    {audio ? (<>
                                        <div className="description-UploadMusic track-timemain">
                                            <div className='track-time'>

                                                <Box sx={{ width: '80%', margin: 'auto' }}>

                                                    <Slider
                                                        aria-label="time-indicator"
                                                        size="small"
                                                        value={currentTime}
                                                        min={0}
                                                        max={duration}
                                                        onChange={handleSeek}

                                                    />
                                                    <audio ref={audioRef} src={audio} />
                                                    <div className='audio-timmer'>
                                                        <div>{formatDuration(currentTime)}</div>
                                                        <div>{formatDuration(duration - currentTime)}</div>
                                                    </div>

                                                </Box>

                                            </div>
                                            <div>
                                                <Stack direction="row" spacing={2} className='music-stack'>
                                                    <div>
                                                        <img src={s1} />
                                                    </div>
                                                    <div>
                                                        <img src={s2} onClick={handleFarward} />
                                                    </div>
                                                    <div >
                                                        <Button onClick={handlePlayPause} >
                                                            {isPlaying ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <img src={s4} />
                                                    </div>
                                                    <div>
                                                        <img src={s5} />
                                                    </div>
                                                </Stack>
                                            </div>
                                            <Button component="label" variant="contained" className="upload-btn2" >
                                                <CloudUploadIcon />
                                                Upload More
                                                <VisuallyHiddenInput type="file" accept="audio/*" onChange={handleAudio} />
                                            </Button>
                                        </div>
                                    </>) : (<>
                                        <div className="description-UploadMusic">
                                            <Button component="label" variant="contained" className="upload-btn" >
                                                <CloudUploadIcon />
                                                <div>
                                                    Drag-and-drop a track here,<span className="go-violet size">or browse to upload</span>
                                                </div>
                                                <VisuallyHiddenInput type="file" accept="audio/*" onChange={(e) => { handleAudio(e); handleImageChange(e) }} />
                                            </Button>
                                        </div>
                                    </>)}




                                </Grid> */}
                                <Grid xs={12} sm={12} md={12} lg={12} xl={4} className="upload-grid2" >
                                    <div className="hoidting-form">
                                        {/* <div>
                                            <h5>Music Adderss</h5>

                                            <div className="form-input-main-div-hoisting">
                                                <TextField
                                                    error
                                                    id="outlined-error-helper-text crypto-address"
                                                    inputRef={music}
                                                    // defaultValue="Hello World"
                                                    // helperText="Incorrect entry."
                                                    onChange={(() => { setErrMusic('') })}
                                                />
                                                <div className="generate" >
                                                    <Button>
                                                        Generate
                                                    </Button>
                                                </div>
                                            </div>
                                            {errMusic != "" ? <div>{errMusic}</div> : <></>}
                                        </div> */}
                                        <div>
                                            <h5>Set Token To Participate</h5>

                                            <TextField
                                                error
                                                id="outlined-error-helper-text"
                                                inputRef={token}
                                                type="number"
                                                // defaultValue="Hello World"
                                                // helperText="Incorrect entry."
                                                onChange={(() => { setErrToken() })}
                                            />

                                        </div>
                                        {errToken != "" ? <div style={{ color: "red", textAlign: "center" }}  >{errToken}</div> : <></>}
                                        <div>
                                            <h5>TrackName <span className="red">(Excluding special characters)</span></h5>

                                            <TextField
                                                error
                                                id="outlined-error-helper-text"
                                                inputRef={tokenName}
                                                // defaultValue="Hello World"
                                                // helperText="Incorrect entry."
                                                onChange={(() => { setErrtokenName('') })}
                                            />
                                            {errtokenName != "" ? <div style={{ color: "red", textAlign: "center" }} >{errtokenName}</div> : <></>}
                                        </div>
                                        <div>
                                            <h5>Description</h5>

                                            <TextField
                                                error
                                                id="outlined-error-helper-text"
                                                inputRef={description}
                                                // defaultValue="Hello World"
                                                // helperText="Incorrect entry."
                                                onChange={(() => { setErrdescription('') })}
                                            />
                                            {errdescription != "" ? <div style={{ color: "red", textAlign: "center" }} >{errdescription}</div> : <></>}
                                        </div>
                                        <div>
                                            <h5>Event Date And Time <span className="red">(Enter numbers only)</span></h5>
                                            {/* <TextField
                                                error
                                                id="outlined-error-helper-text"
                                                inputRef={eventDate}
                                                // defaultValue="Hello World"
                                                // helperText="Incorrect entry."
                                                onChange={(() => { setErreventDate('') })}
                                            /> */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={[
                                                        'DateTimePicker',
                                                        'MobileDateTimePicker',
                                                        'DesktopDateTimePicker',
                                                        'StaticDateTimePicker',
                                                    ]}
                                                >
                                                    <DemoItem label="">
                                                        <DateTimePicker disablePast={true} onChange={(e) => {
                                                            const toTimestamp = (strDate) => {
                                                                const dt = Date.parse(strDate);
                                                                return dt / 1000;
                                                            }
                                                            var oriDate = `${Number(e?.$M) + 1}/${e?.$D}/${e?.$y} ${e?.$H}:${e?.$m}`
                                                            var timestamp = toTimestamp(oriDate)
                                                            setDate(oriDate)
                                                            setDate_Timestamp(timestamp)
                                                            setErreventDate("")
                                                        }} />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            {erreventDate != "" ? <div style={{ color: "red", textAlign: "center" }} >{erreventDate}</div> : <></>}
                                        </div>

                                        <div>
                                            <h5>Event Duration (in Hours)</h5>
                                            <TextField
                                                error
                                                id="outlined-error-helper-text"
                                                inputRef={eventDuration}
                                                // defaultValue="Hello World"
                                                // helperText="Incorrect entry."
                                                onChange={((e) => {
                                                    setErreventDuration('')
                                                })}
                                            />
                                            {erreventDuration != "" ? <div style={{ color: "red", textAlign: "center" }} >{erreventDuration}</div> : <></>}
                                        </div>
                                        <div className="create-room">
                                            <Button onClick={() => {
                                                CreateRoom()
                                            }} >
                                                Create Room
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>


                        {/* 
                        <div>
                            <Track />
                        </div> */}
                    </Box>
                </Box>

            </Box>
        </div >
    );
}

export default Hoisting;