import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import '../Upload-Music/UploadMusic.css'
import Container from '@mui/material/Container'
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import '../Hoisting/Hoisting.css'
import { useState, useEffect, useRef } from "react";
import '../CreateVirtualMeet/CreateVirtualMeet.css'
import './Colabration.css'
import { trim } from "jquery";
import artist1 from '../../img/dster-webp/artist1.webp'
import artist2 from '../../img/dster-webp/artist2.webp'
import artist3 from '../../img/dster-webp/artist3.webp'
import artist4 from '../../img/dster-webp/artist4.webp'
import SendIcon from '@mui/icons-material/Send';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import HeaderNew from "../HeaderNew/HeaderNew";




const drawerWidth = 280;



function Colabration() {
    const theme = useTheme();
    const [validate, setValidate] = useState('')


    const [card2, seCatd2] = useState(
        [
            { img: `${artist1}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
            { img: `${artist2}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
            { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
            { img: `${artist4}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
            { img: `${artist1}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
            { img: `${artist2}`, txt1: '0x453712f21ddda4b7c00b4dcd3383b13f8…', txt2: '', btn: "follow" },
            { img: `${artist3}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" },
            { img: `${artist4}`, txt1: 'African KasiKulca', txt2: 'South Africa', btn: "follow" }
        ]

    )
    const [err, setErr] = useState(null)

    const [card1, setCard1] = useState([
        { name: 'African KasiKulca' },
        { name: 'African KasiKulca' },
        { name: 'African KasiKulca' },
        { name: 'African KasiKulca' },
        { name: 'African KasiKulca' },
        { name: 'African KasiKulca' },
    ])
    // console.log(validate);

    const inputRef = useRef(null)


    const [message, setMessage] = useState([]);

    const [updated, setUpdated] = useState('');



    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };


    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };



    useEffect(() => {

        if (validate.length >= 3) {
            console.log('yes');
            setErr(false)
        }
        //  else if (validate.trim() === '') {
        //     setErr(false)
        // }
        else {
            setErr(true)
        }

    }, [validate])

    const [title, setTitle] = useState('colabration')
    return (

        <div className="Hoisting explore dashboard">
            {/* <SnackBar /> */}
            <Box sx={{ display: 'flex' }} className='display-block'>

                <HeaderNew title={title} />
                <Box component="main"
                    className="Hoisting-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>


                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 0 }}
                        className="UploadMusic-box3 create-exclusive"
                    >
                        <Container maxWidth="xl" >
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={6} lg={6} xl={4} sx={{ p: 1 }}>
                                    {card1.map((item) => {
                                        return (
                                            <div className="celebrate-c1-main">
                                                <div className="celebrate-c1-img-text-div">
                                                    <div className="celebrate-c1">
                                                        <img src={artist3} alt="artist" />
                                                    </div>
                                                    <div className="celebrate-c1-text">
                                                        <div >
                                                            {item.name}
                                                        </div>
                                                        <div>
                                                            South Africa <span className="d-100">100</span><span>Dster</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="celebrate-c1-btn-div">
                                                    <Button className="btn1">
                                                        aCCEPT
                                                    </Button>
                                                    <Button className="btn2">
                                                        iGNORE
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    })}


                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6} xl={4}  >
                                    <div className="top-playlist">
                                        Top playlist
                                    </div>

                                    {card2.map((row, obj) => {
                                        return (
                                            <>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id='colab' className="artist colabrate">
                                                    <div className="Card">
                                                        <CardMedia
                                                            sx={{ height: 200 }}
                                                            image={row.img}
                                                            title="green iguana"
                                                            className="artist-img"
                                                        />
                                                        <div className="artist-txt">
                                                            <div className="love-txt1">
                                                                {row.txt1}
                                                            </div>
                                                            <div className="love-txt2">
                                                                {row.txt2}
                                                            </div>
                                                            <div className="btn artist-follow">
                                                                {row.btn}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                                <Grid xs={12} sm={12} md={10} lg={10} xl={4}  >
                                    <div className="colab-msg-box">
                                        <div className="colab-avatar-cont">
                                            <Stack direction="row" spacing={-0.5}>
                                                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist1} />
                                                <Avatar sx={{ width: 30, height: 30 }} alt="Travis Howard" src={artist2} />
                                                <Avatar sx={{ width: 30, height: 30 }} alt="Cindy Baker" src={artist3} />
                                            </Stack>
                                        </div>
                                        <div className="colab-chat-area">


                                            <div className="chat-area-inner-text">
                                                <div>
                                                    <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist3} />
                                                </div>
                                                <div className="collab-send-text-main">
                                                    <div className="username">Sankar(dev)</div>
                                                    <div className="txt1">How can I Help!</div>
                                                    <div className="time">11:45am</div>
                                                </div>
                                            </div>
                                            {message.map((todo, ind) => {
                                                return (

                                                    <div className="chat-area-inner-text user" key={ind}>
                                                        <div>
                                                            <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist3} />
                                                        </div>
                                                        <div className="collab-send-text-main2 user">
                                                            <div className="username">Sankar(dev)</div>
                                                            <div className="txt1">{todo}</div>
                                                            <div className="time">11:45am</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>


                                        <div className="colab-text-input">

                                            <div>
                                                <input type="text" placeholder="Start typing..."
                                                    value={updated}
                                                    onChange={handleChangeSend}
                                                    onKeyDown={handleEnterKeyPress}

                                                />
                                            </div>
                                            <div className="colab-send">
                                                <SendIcon />
                                            </div>
                                        </div>
                                    </div>



                                </Grid>

                            </Grid>
                        </Container>


                        {/* 
                        <div>
                            <Track />
                        </div> */}
                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default Colabration;