
import React, { useState, useEffect, useRef } from "react";
import Grid from '@mui/material/Grid'
import StackABI from '../../Web3/stakeAbi.json'
import stakeAddress from '../../Web3/stakeAddress'
import approveABI from '../../Web3/approveAbi.json'
import approveAddress from '../../Web3/approveAddress'
import Web3 from 'web3';
import Tooltip from '@mui/material/Tooltip';
import Axios from "../../Axios";
import { BrowserRouter as Link } from "react-router-dom";

import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import './Stacking.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderNew from "../HeaderNew/HeaderNew";
import newno from "../../img/dster-webp/newnodata.gif"

const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const rows = [

    { time: 'Gold', Interest: 20000, Type: 0 },
    { time: 'Silver', Interest: 1000, Type: 1 },
    { time: 'Platinum', Interest: 1000, Type: 2 },
]

const lockpay = [
    { time: '3 Months', Interest: '57518258' },
    { time: '6 Months', Interest: '139798660' },
    { time: '8 Months', Interest: '277710308' },
    { time: '12 Months', Interest: '500000000' },
]

function Stacking() {
    const navigate = useNavigate()
    const requestAcc = useRef(true)
    const [stakes, setStakes] = useState([])
    const [plans, setPlans] = useState([])
    const [metaAccount, setMetaAccount] = useState()
    const [balance, setBalance] = useState()
    const [amount, setAmount] = useState()
    const [amounterr, setAmountErr] = useState(null)
    const [planerr, setplanErr] = useState(null)
    const [stake, setStake] = useState(false)
    const [plan, setPlan] = useState(null)
    const [rewards, setRewards] = useState(null)
    const [rewarddetails, setRewarddetails] = useState([])
    const [shows, setShows] = useState([])
    const tokens = localStorage.getItem("iphephile");
    const [stakeindex, setStakeindex] = useState(null)
    const account = localStorage.getItem("accounts");

    const tokenExist = localStorage.getItem("iphephile");
    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [activeClass, setActiveClass] = useState("");

    const activeMenuClass = async (e) => {
        setActiveClass(e.target.id);
    }
    useEffect(() => {
        if (requestAcc.current) {

            if (!metaAccount && tokenExist) {
                requestAccount()
            }
        }


        requestAcc.current = false
        // console.log(requestAcc.current, ' requestAcc.current ');
        getToken()
        getStake()

    }, [])


    const getRewards = async (plan, index) => {
        try {
            console.log()
            setRewards(null)
            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);
                // console.log(web3Instance)

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                const contractInstance = new web3Instance.eth.Contract(
                    StackABI,
                    stakeAddress
                );
                // console.log(contractInstance)

                const RewardDetails = await contractInstance.methods.getRewards(accounts[0], plan).call()

                console.log(RewardDetails, "RewardDetails")
                const bal = await web3Instance.utils.fromWei(String(RewardDetails), 'ether')
                setRewards(true)
                setRewarddetails((prev) => ({
                    ...prev,
                    [index]: bal,
                }));
                setShows((prev) => ({
                    ...prev,
                    [index]: false,
                }));

            }
        } catch (err) {
            console.log(err)
            setRewarddetails((prev) => ({
                ...prev,
                [index]: 0,
            }));
            setShows((prev) => ({
                ...prev,
                [index]: false,
            }));
        }
    };

    useEffect(() => {
        if (stakes?.length > 0) {
            stakes.forEach((row, index) => {
                getRewards(row?.plan, index);
            });

        }
    }, [stakes]);

    useEffect(() => {


        // console.log(account,tokenExist,"acc")
        if (account) {
            setMetaAccount(account)

        }

    }, [account])

    const selectPlan = async (e) => {
        setAmountErr(null)

        const data = Number(e.target.id)
        console.log(e.target.id, data)
        const result = plans.filter((pl) => pl.plan === (data - 5))
        console.log(result, "result")
        if (result.length !== 0) {
            setplanErr("You Already Choose this Filter")
        }
        else {
            setplanErr(null)
            setPlan(data - 5)
            setAmount(rows[data - 5].Interest)
        }

        console.log(rows[data - 5])
    }

    const requestAccount = async () => {

        if (window.ethereum) {

            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                setMetaAccount(accounts[0])
                // console.log(accounts[0])
                localStorage.setItem('accounts', accounts[0])

            } catch (error) {
                console.log(error, 'Error connecting...');
            }

        } else {
            alert('Please Add Metamask Extension in Your Browser');
        }
    }
    const withdrawAccount = async (accData) => {
        try {
            setRewards(null)

            console.log(accData, "rows")

            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);
                // console.log(web3Instance)

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                const contractInstance = new web3Instance.eth.Contract(
                    StackABI,
                    stakeAddress
                );
                // console.log(contractInstance)
                //   const Account = await web3Instance.eth.getAccounts()

                var signMessage = web3Instance.utils.soliditySha3(
                    { type: 'address', value: stakeAddress },
                    { type: 'uint256', value: accData.plan },
                    { type: 'address', value: accounts[0] },
                    { type: 'uint256', value: Number(await web3Instance.eth.getTransactionCount(accounts[0])) });


                const signs = await web3Instance.eth.personal.sign(signMessage, accounts[0], "")

                const sign = {
                    r: getSignatureParameters(signs).r,
                    v: getSignatureParameters(signs).v,
                    s: getSignatureParameters(signs).s,
                    nonce: Number(await web3Instance.eth.getTransactionCount(accounts[0]))
                }
                // console.log(sign, "signs2")
                const Arraysign = [String(sign.v), String(sign.r), String(sign.s), String(sign.nonce)]
                console.log(Arraysign, 2, "signs3");
                const Stacking = await contractInstance.methods.emergencyWithdraw(
                    accData.plan,
                    Arraysign
                ).send({ from: accounts[0] }).then(async (res) => {
                    console.log(res, "response")

                    const datas = {

                        plan: accData.plan,
                        wallet_address: accounts[0],

                    }
                    await Axios.post("/wallet/delete_stake", datas, {
                        headers: {
                            Authorization: tokens,
                        },
                    })
                        .then((res) => {
                            console.log(res, "res")
                            if (res?.data?.success) {
                                getToken()
                                getStake()
                                toast.success(res?.data?.message)
                            }
                            else {
                                console.log(res?.data?.message)

                            }
                        }).catch((err) => {
                            console.log(err, "err")


                        })

                }).catch((err) => {
                    setStake(false)
                    setAmount()
                    console.log(err, "Error")
                })




                //  return bal



            }
        } catch (err) {
            console.log(err)
        }
    };
    const withdrawstake = async (accData) => {
        try {
            setRewards(null)

            console.log(accData, "rows")

            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);
                // console.log(web3Instance)

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                const contractInstance = new web3Instance.eth.Contract(
                    StackABI,
                    stakeAddress
                );
                console.log(new Date(accData) > new Date(), "date")
                if (new Date(accData) > new Date() === false) {
                    toast.error(`you Can Withdraw this Stake on ${new Date(accData?.expireDate).toLocaleString()}`)
                }
                else {
                    var signMessage = web3Instance.utils.soliditySha3(
                        { type: 'address', value: stakeAddress },
                        { type: 'uint256', value: accData.plan },
                        { type: 'address', value: accounts[0] },
                        { type: 'uint256', value: Number(await web3Instance.eth.getTransactionCount(accounts[0])) });


                    const signs = await web3Instance.eth.personal.sign(signMessage, accounts[0], "")

                    const sign = {
                        r: getSignatureParameters(signs).r,
                        v: getSignatureParameters(signs).v,
                        s: getSignatureParameters(signs).s,
                        nonce: Number(await web3Instance.eth.getTransactionCount(accounts[0]))
                    }

                    const Arraysign = [String(sign.v), String(sign.r), String(sign.s), String(sign.nonce)]
                    console.log(Arraysign, 2, "signs3");
                    const Stacking = await contractInstance.methods.withdraw(
                        accData.plan,
                        Arraysign
                    ).send({ from: accounts[0] }).then(async (res) => {
                        console.log(res, "response")

                        const datas = {

                            plan: accData.plan,
                            wallet_address: accounts[0],

                        }
                        await Axios.post("/wallet/delete_stake", datas, {
                            headers: {
                                Authorization: tokens,
                            },
                        })
                            .then((res) => {
                                console.log(res, "res")
                                if (res?.data?.success) {
                                    getToken()
                                    getStake()
                                    toast.success(res?.data?.message)
                                }
                                else {
                                    console.log(res?.data?.message)

                                }
                            }).catch((err) => {
                                console.log(err, "err")


                            })

                    }).catch((err) => {
                        setStake(false)
                        setAmount()
                        console.log(err, "Error")
                    })
                }



            }
        } catch (err) {
            console.log(err)
        }
    };
    const getStake = async (accData) => {
        try {

            await Axios.post("/wallet/get_stake", '', {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        setStakes(res?.data?.result)
                        setPlans(res?.data?.findPlan)
                    }
                    else {
                        console.log(res?.data?.message)
                        setStakes([])
                        setPlans([])
                    }
                }).catch((err) => {
                    console.log(err, "err")


                })

        } catch (err) {
            console.log(err)
        }
    };
    const approveStake = async () => {
        try {

            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);

                const accounts = await web3Instance.eth.getAccounts();

                const contractInstance = new web3Instance.eth.Contract(
                    approveABI,
                    approveAddress
                );
                const result = plans.filter((pl) => pl.plan === plan)
                console.log(result, "result")
                // console.log(contractInstance)
                if (plan === null) {
                    setplanErr("Select Any One Plan")
                }
                else if (result.length !== 0) {
                    setplanErr("You Already Choose this Filter")
                }
                else if (amount < balance) {
                    console.log(amount, balance, 'testertes')
                    setAmountErr("You can't Choose this Plan ,Insufficient Balance ")

                }
                else if (!amount) {
                    setAmountErr("Enter the Amount")

                }
                else if (plan === 0 && amount < 20000) {
                    setAmountErr("Enter the Amount greaterthan or Equal to 20000")
                }
                else if (plan === 1 && amount < 1000) {
                    setAmountErr("Enter the Amount greater than or Equal to 1000")
                }
                else if (plan === 2 && amount < 1000) {
                    setAmountErr("Enter the Amount greater than or Equal to 1000")
                }

                else {
                    setStake(true)
                    console.log(amount)
                    console.log(web3Instance.utils.toWei(String(1), 'ether'), "amount")
                    const approveStaking = await contractInstance.methods.approve(
                        stakeAddress,
                        web3Instance.utils.toWei(String(1), 'ether'),
                    ).send({ from: accounts[0] }).then(async (res) => {
                        // console.log("Staking",res)A
                        const stackInstance = new web3Instance.eth.Contract(
                            StackABI,
                            stakeAddress
                        );
                        // console.log(contractInstance)
                        //   const Account = await web3Instance.eth.getAccounts()
                        var signMessage = web3Instance.utils.soliditySha3(
                            { type: 'address', value: stakeAddress },
                            { type: 'uint256', value: plan },
                            { type: 'address', value: accounts[0] },
                            { type: 'uint256', value: Number(await web3Instance.eth.getTransactionCount(accounts[0])) });


                        const signs = await web3Instance.eth.personal.sign(signMessage, accounts[0], "")
                        console.log(signs, "signs1")

                        const sign = {
                            r: getSignatureParameters(signs).r,
                            v: getSignatureParameters(signs).v,
                            s: getSignatureParameters(signs).s,
                            nonce: Number(await web3Instance.eth.getTransactionCount(accounts[0]))
                        }
                        console.log(stackInstance, "signs2")
                        const Arraysign = [String(sign.v), String(sign.r), String(sign.s), String(sign.nonce)]
                        console.log(Arraysign, web3Instance.utils.toWei(String(1), 'ether'),
                            plan,
                            "signs3");



                        const Stacking = await stackInstance.methods.stake(
                            web3Instance.utils.toWei(String(1), 'ether'),
                            plan,
                            Arraysign
                        ).send({ from: accounts[0] }).then(async (response) => {
                            console.log(response)

                            const datas = {

                                plan: plan,
                                wallet_address: accounts[0],
                                transactionhash: response.transactionHash,
                                amount: amount,
                                others: response,
                            }
                            await Axios.post("/wallet/create_stake", datas, {
                                headers: {
                                    Authorization: tokens,
                                },
                            })
                                .then((res) => {
                                    console.log(res, "res")
                                    if (res?.data?.success) {
                                        setStake(false)
                                        setAmount()
                                        getToken()
                                        getStake()
                                        toast.success(res?.data?.message)
                                    }
                                    else {
                                        console.log(res?.data?.message)
                                        setStake(false)
                                        setAmount()
                                    }
                                }).catch((err) => {
                                    console.log(err, "err")


                                })



                        }).catch((err) => {
                            setStake(false)
                            setAmount()
                            console.log(err, "stake Error")
                        })

                    }).catch((err) => {
                        toast.error("You Didnot Approve the Stake")
                        console.log(err, "Stake Error")
                        setStake(false)

                    })
                }
            }
        } catch (err) {
            console.log(err)
        }
    };

    const getReward = async (index) => {
        try {
            console.log(index, "shows")
            setStakeindex(index)
            // setRewards(null)
            // if (window.ethereum) {
            //     const web3Instance = new Web3(window.ethereum);
            //     // console.log(web3Instance)

            //     await window.ethereum.enable();
            //     // setWeb3(web3Instance);

            //     await window.ethereum.request({
            //         method: "eth_requestAccounts",
            //     });
            //     const accounts = await web3Instance.eth.getAccounts();

            //     const contractInstance = new web3Instance.eth.Contract(
            //         StackABI,
            //         stakeAddress
            //     );
            //     // console.log(contractInstance)

            //     const RewardDetails = await contractInstance.methods.getRewards(accounts[0], accData.plan).call()

            //     console.log(RewardDetails, "RewardDetails")
            //     const bal = await web3Instance.utils.fromWei(String(RewardDetails), 'ether')
            //     setRewards(bal)

            // }
        } catch (err) {
            console.log(err)
        }
    };
    const getToken = async () => {
        try {
            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);
                // console.log(web3Instance)

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                const contractInstance = new web3Instance.eth.Contract(
                    StackABI,
                    stakeAddress
                );
                // console.log(contractInstance)

                const Token = await contractInstance.methods.token().call()

                const tokenInstance = new web3Instance.eth.Contract(
                    approveABI,
                    approveAddress //token address and abi to check token balance
                );
                const balances = await tokenInstance.methods.balanceOf(accounts[0]).call()
                const bal = await web3Instance.utils.fromWei(String(balances), 'ether')
                setBalance(Number(bal))

                console.log(Token, Number(bal), "Token")

            }
        } catch (err) {
            console.log(err)
        }
    };

    const getSignatureParameters = (signature) => {

        const web3 = new Web3(window.ethereum)
        const r = signature.slice(0, 66);
        const s = `0x${signature.slice(66, 130)}`;
        let v = `0x${signature.slice(130, 132)}`;
        v = web3.utils.toDecimal(v);

        // if ( ![ 27, 28 ].includes( v ) ) v += 27;

        return {
            r,
            s,
            v
        };
    };


    return (<>
        <div className='creator staking'>
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }}>
                <HeaderNew title="Staking" />

                <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)`, overflowY: 'scroll', marginTop: '100px' } }}>
                    <Grid spacing={2} className='whole-cnt2'>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                            <Grid container spacing={0} className='stack-cont'>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className='stack-title'>
                                        DREAMSTER STAKING
                                        <div className='stack-descript'>
                                            Just stake some tokens to earn
                                            High APR, low risk.
                                        </div>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{ margin: '25px 0px' }}>
                                    <div className='start-staking'>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table" className='stacking-table'>
                                                <TableHead>
                                                    <TableRow>

                                                        <TableCell align="right">Plan</TableCell>
                                                        <TableCell align="right">Minimum Amount</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell align="right">{row.time}</TableCell>
                                                            <TableCell align="right">{row.Interest} 30 Days</TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>

                                    <div className='start-staking'>

                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table" className='stacking-table'>
                                                <TableHead>
                                                    <TableRow>

                                                        <TableCell align="right">Time</TableCell>
                                                        <TableCell align="right">Total locked Dreamster</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {lockpay.map((row) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell align="right">{row.time}</TableCell>
                                                            <TableCell align="right">{(row.Interest === '500000000') ? (<>
                                                                {row.Interest} <p className='go-red'>Limit Reached !</p>
                                                            </>) : (<>
                                                                {row.Interest}
                                                            </>)}</TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </div>
                                </Grid>
                                {/* <Grid xs={12} sm={12} md={6} lg={4} xl={4} sx={{ margin: '25px 0px' }}>
                                    <div className='start-staking'>
                                        <div>
                                            Start Staking
                                        </div>
                                        {metaAccount}

                                        {stake === false ? <Button className='connect-wallet' onClick={() => { approveStake() }}>
                                            Start Stake
                                        </Button> : <Button className='connect-wallet' disabled>
                                            Staking ...
                                        </Button>}


                                    </div>

                                    <div className='start-staking'>
                                        <div>
                                            Your staked Dreamster
                                        </div>
                                        <div className='pls-connet'>
                                            Please connect your wallet
                                        </div>
                                        {!metaAccount ? <Button className='connect-wallet' onClick={() => { requestAccount() }}>
                                            Please connect your wallet
                                        </Button> : <Button className='connect-wallet'>
                                            Connected
                                        </Button>}

                                    </div>


                                </Grid> */}
                                <Grid xs={12} sm={12} md={10} lg={6} xl={6} sx={{ margin: '25px 0px' }}>
                                    <div className='balance'>
                                        Balance: <b> {balance} TT</b>
                                    </div>

                                    <div className='enter-amount'>
                                        Enter Amount
                                    </div>
                                    <div className='percent'>
                                        <Link className={activeClass === "5" ? "active-percent" : "non-percentage"} id={"5"} onClick={(e) => { activeMenuClass(e); selectPlan(e) }} to="#" >   Gold</Link>
                                        <Link className={activeClass === "6" ? "active-percent" : "non-percentage"} id={"6"} onClick={(e) => { activeMenuClass(e); selectPlan(e) }} to="#" >   Silver</Link>
                                        <Link className={activeClass === "7" ? "active-percent" : "non-percentage"} id={"7"} onClick={(e) => { activeMenuClass(e); selectPlan(e) }} to="#" >   Platinum</Link>
                                    </div>
                                    {planerr !== null ? (
                                        <div className="errordiv" style={{ marginTop: "10px" }}>{planerr}</div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='stak-input-div'>
                                        <input type='Number'
                                            value={amount}
                                            onChange={(e) => { setAmount(e.target.value); setAmountErr(null) }}
                                        />
                                        {amounterr !== null ? (
                                            <div className="errordiv" style={{ marginTop: "10px" }}>{amounterr}</div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className='percent'>
                                        <Link className={activeClass === "1" ? "active-percent" : "non-percentage"} id={"1"} onClick={(value) => activeMenuClass(value)} to="#" >   25%</Link>
                                        <Link className={activeClass === "2" ? "active-percent" : "non-percentage"} id={"2"} onClick={(value) => activeMenuClass(value)} to="#" >   50%</Link>
                                        <Link className={activeClass === "3" ? "active-percent" : "non-percentage"} id={"3"} onClick={(value) => activeMenuClass(value)} to="#" >   75%</Link>
                                        <Link className={activeClass === "4" ? "active-percent" : "non-percentage"} id={"4"} onClick={(value) => activeMenuClass(value)} to="#" >   100%</Link>

                                    </div>
                                    <div className='enter-amount'>
                                        LOCK OVERVIEW
                                    </div>
                                    <div className='start-staking lockpay'>
                                        <div>
                                            Dreamster to lock
                                            <div>
                                                1.0
                                            </div>
                                        </div>

                                        <div>
                                            Duration
                                            <div>
                                                365.0000 Days
                                            </div>
                                        </div>
                                        <div>
                                            Dreamster value to lock
                                            <div>
                                                $0.00
                                            </div>
                                        </div>
                                        <div>
                                            Unlocked On
                                            <div>
                                                Wed Feb 12 2025
                                            </div>
                                        </div>
                                        <div>
                                            Received Dreamster

                                            <div>
                                                37.8
                                            </div>
                                        </div>
                                        <div>
                                            Received USD
                                            <div>
                                                $0.0
                                            </div>
                                        </div>
                                    </div>

                                    {/* {metaAccount} */}

                                    <div className="stk-btn-maindiv">
                                        {stake === false ? <Button className='connect-wallet' onClick={() => { approveStake() }}>
                                            Start Stake
                                        </Button> : <Button className='connect-wallet' disabled>
                                            Staking ...
                                        </Button>}
                                    </div>



                                </Grid>
                                <Grid xs={12} sm={12} md={10} lg={12} xl={12} sx={{ margin: '25px 0px' }}>
                                    {/* <div className='enter-amount'>
                                        ADD DURATION
                                    </div> */}
                                    <Box sx={{ width: '100%' }}>
                                        <Box >


                                            <div className='start-staking'>
                                                <div className="stk-history">
                                                    Staking History
                                                </div>
                                                {/* <div className='pls-connet' onClick={()=>{withdrawAccount()}}>
                                                    Please connect your wallet
                                                </div> */}
                                                <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                                                    {stakes?.length > 0 ? <Table aria-label="simple table" className='stacking-table' sx={{ minWidth: 700 }} >
                                                        <TableHead>
                                                            <TableRow>

                                                                <TableCell align="right">Plan</TableCell>
                                                                <TableCell align="right">Start Date</TableCell>
                                                                <TableCell align="right">End Date</TableCell>
                                                                <TableCell align="right"> Amount</TableCell>
                                                                <TableCell align="right">Action</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {stakes && stakes.map((row, index) => (
                                                                <TableRow
                                                                    key={row._id}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell align="right">{row?.plan}</TableCell>
                                                                    <TableCell align="right">{new Date(row?.createdAt).toLocaleString()} </TableCell>
                                                                    <TableCell align="right">{new Date(row?.expireDate).toLocaleString()} </TableCell>
                                                                    <TableCell align="right">{row?.amount}</TableCell>
                                                                    <TableCell align="right"> {console.log(rewarddetails, "rewards")}
                                                                        <Button className='connect-wallet tb' onClick={() => withdrawstake(row)}>Unstake</Button>
                                                                        {stakeindex === index ? <Button className='connect-wallet tb'>{rewarddetails[index] ? Number(rewarddetails[index]).toFixed(3) : '-'}</Button> : <Button className='connect-wallet tb' onClick={() => getReward(index)}>Reward Details</Button>}
                                                                        <Tooltip title="Withdraw your token without Reward" placement="top"><Button className='connect-wallet tb' onClick={() => withdrawAccount(row)}>Emergency Withdraw</Button></Tooltip>
                                                                    </TableCell>

                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table> : <>
                                                        <div className="newno">
                                                            <img src={newno} />
                                                        </div>

                                                    </>}
                                                </TableContainer>
                                            </div>
                                        </Box>
                                    </Box>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    </>);
}

export default Stacking;