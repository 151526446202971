import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import moon from "../../img/dster-webp/moon.gif"
import sun from "../../img/dster-webp/sun.gif"
import './DarkMode.css'

// const getThemeFromStorage = () => {
//     let theme
//     console.log(theme);
//     if (localStorage.getItem('theme')) {
//         theme = localStorage.getItem('theme')
//     }
//     return theme

// }



function DarkMode() {
    var theme = localStorage.getItem('theme')

    const [isLightMode, setIsLightMode] = React.useState(theme)

    const handledarkmode = () => {
        if (theme === 'true') {
            setIsLightMode('false')
        } else {
            setIsLightMode('true')
        }
    }

    useEffect(() => {
        if (isLightMode === 'true') {

            var body = document.querySelector("body");
            body.className = 'light-theme'


        } else {
            var body = document.querySelector("body");
            body.className = 'dark-theme'

        }
        localStorage.setItem('theme', isLightMode)


    }, [isLightMode])

    return (<>
        <IconButton sx={{ ml: 1 }} onClick={handledarkmode} color="inherit">
            {isLightMode === 'true' ? <img src={sun} style={{ width: '40px', height: '40px' }} /> : <img src={moon} style={{ width: '40px', height: '40px' }} />}
        </IconButton>
    </>);
}


export default DarkMode;