import Grid from '@mui/material/Grid';
import React, { useEffect, useRef, useState } from 'react';
import styles from "../NewMusicTrack/NewMusicTrack.module.css"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import './Track.css'
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { indexData, sendplayData } from '../../Redux/action'
import mtn7 from "../../img/dster-webp/mtn7.webp"
import bs2 from "../../img/dster-webp/bs2.webp"
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Popover from '@mui/material/Popover';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import wave from "../../img/dster-webp/musicwave.gif"
import vols from "../../img/dster-webp/volume-up.webp"
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import LoopIcon from '@mui/icons-material/Loop';

function Tracks(props) {
  // console.log("🚀 ~ props:", props)
  const track_no = useSelector(state => state.index);
  const [track, setTrack] = useState(props?.tracks)
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0)
  const [playaudio, setplayaudio] = useState(props.audiosources[0])
  const [isLoop, setIsloop] = useState(false)
  const audioRef = useRef(null);
  const volumes = localStorage.getItem("volume")
  const [volume, setVolume] = useState(volumes !== null ? volumes : 100);
  const [sound, setSound] = useState(false)
  const dispatch = useDispatch();
  const play_mood = useSelector(state => state.play_mood);

  useEffect(() => {
    // setTrack(props?.tracks)
    if (props.tracks !== undefined && props.tracks != track) {
      localStorage.setItem("tracks", props.tracks)
      setTrack(props?.tracks)
    }
    //  else{
    //   localStorage.setItem("tracks",track)  

    //  }
  }, [props?.tracks]);

  // console.log(currentTime, 'currentTime');
  // console.log(duration, 'duration');
  // console.log(track, 'tracks');

  useEffect(() => {
    // console.log("ones 1", props.audiosources, track)
    const isPauses = localStorage.getItem("isPauses")
    const currents = localStorage.getItem("currents")
    if (props.audiosources.length > 0 && props.audiosources[track]?.audio != undefined) {
      //handlePlay()

      if (isPauses && isPauses == "false") {
        if (currents !== undefined && currents !== null && currents !== 0) {
          audioRef.current.currentTime = Number(currents)
          handlePlay()
        }
        else {
          audioRef.current.currentTime = currentTime
          handlePlay()
        }
      }
      else {
        audioRef.current.pause()
        if (currents !== undefined && currents !== null && currents !== 0) {
          audioRef.current.currentTime = Number(currents)

        }
        else {
          audioRef.current.currentTime = currentTime
        }
      }
    }
    else {
      // console.log("handle 2")
      handleStop()
    }

  }, [props.audiosources])





  useEffect(() => {
    setTimeout(() => {
      if (sound === true) {
        setSound(false);

      }
    }, 5000);
  }, [sound]);

  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const [vol, setVol] = React.useState(30);

  const handleChange = (event, newValue) => {
    setVol(newValue);
  };


  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };



  useEffect(() => {
    if (audioRef.current != null && volume) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume, audioRef]);


  // // Function to seek to a specific time in audio.


  const handlebackward = () => {
    try {
      audioRef.current.currentTime += 10;
      audioRef.current.play();
    } catch (error) {
      console.log("🚀 ~ handlebackward ~ error:", error)

    }

  }
  const audio = localStorage.getItem("audiosource")

  const handlePlay = () => {
    try {
      // console.log("plays")
      if (audioRef?.current?.src != null && audioRef?.current?.src != undefined && track_no == track) {
        // console.log(audioRef?.current?.src, 'source');

        const audio = audioRef?.current?.play();
        setIsPlaying(true)
        window.localStorage.setItem("isPauses", false)
        localStorage.setItem("isPause", true)
      }
    } catch (error) {
      console.log(error)
    }



  };

  const handleFarward = () => {
    try {
      audioRef.current.currentTime = 0;
      audioRef?.current?.play();
    } catch (error) {
      console.log(error)

    }

  }

  useEffect(() => {
    const isPauses = localStorage.getItem("isPauses")
    const currents = localStorage.getItem("currents")


    if (isPauses && isPauses === "false") {
      if (props.audiosources[track]?.audio !== undefined) {
        handlePlay()
        localStorage.setItem("tracks", track)

      }

      else if (track === props.audiosources.length) {
        //setTrack(0)

        dispatch(indexData(0));
        localStorage.setItem("tracks", 0)
      }
      else {
        handlePause()
        localStorage.setItem("tracks", track)
      }
    }
    else {
      if (audioRef.current != null) {
        localStorage.setItem("tracks", 0)
        if (currents !== undefined && currents !== null && currents !== 0) {
          audioRef.current.currentTime = Number(currents)

        }
        else {
          audioRef.current.currentTime = currentTime
        }
      }

      // console.log(props.audiosources, typeof (isPauses), "playasdd")

    }

  }, [track])

  const handleStop = () => {
    // console.log("play_ stop")
    try {
      localStorage.setItem("audiosources", props.audiosources)
      localStorage.setItem("currents", 0)
      localStorage.setItem("tracks", track)
      window.localStorage.setItem("isPauses", true)
      audioRef?.current?.pause();
      setIsPlaying(false);
    } catch (error) {
      console.log(error)
    }

  };

  const handlePause = () => {
    // console.log("play_ pause")
    try {
      if (audioRef?.current != null) {
        localStorage.setItem("currents", 0)
        localStorage.setItem("tracks", track)
        window.localStorage.setItem("isPauses", true)
        audioRef.current.pause();
        setIsPlaying(false);
      }
    } catch (error) {
      console.log(error)
    }

  };

  const handlePlayPause = () => {


    // console.log(isPlaying, "isplaying1", typeof (localStorage.getItem("isPauses")))
    if (isPlaying == true) {
      dispatch(sendplayData("false"))
      handlePause();

    } else {
      dispatch(sendplayData("true"))
      handlePlay();
    }
  };


  const handleTimeUpdate = () => {


    if (audioRef.current === null) {
      setCurrentTime(0)
      setDuration(0)
    } else if (audioRef.current.currentTime === audioRef.current.duration) {
      // console.log("finish")
      changeTrack()
    }
    else {
      var time = window.localStorage.getItem("currents")
      // if (audioRef.current.currentTime < 1) {
      //   localStorage.setItem("currents", time)
      //   audioRef.current.currentTime = time
      //   setCurrentTime(time);
      //   setDuration(audioRef.current.duration);
      // } else {
      localStorage.setItem("currents", audioRef.current.currentTime)
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
    }

  };





  const handleSeek = (e) => {

    if (audioRef.current === null) {
      setCurrentTime(0)
    }
    else {


      if (isNaN(e.target.value)) {

        audioRef.current.currentTime = 0;
        setCurrentTime(0);

      }
      else {
        audioRef.current.currentTime = e.target.value;
        setCurrentTime(e.target.value);
      }

    }
  };

  useEffect(() => {
    audioRef?.current?.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      if (audioRef?.current !== null) {

      }
      // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [track]);





  function formatDuration(durationSeconds) {
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = Math.floor(durationSeconds % 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
  }




  const [value, setValue] = React.useState(30);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeTrack1 = () => {


    if (track === 0) {
      setTrack(props.audiosources.length - 1)

      dispatch(indexData(props.audiosources.length - 1));
    }
    else {
      setTrack(track - 1)

      dispatch(indexData(track - 1));
    }
  }

  useEffect(() => {
  }, [track])
  const changeTrack = () => {
    // console.log("ones")
    if (Number(track) + 1 === Number(props.audiosources.length)) {
      setTrack(0)
      // console.log("handle 1")
      handleStop()
      dispatch(indexData(0));
    }
    // else if (Number(track) + 1 === Number(props.audiosources.length)) {
    //   setTrack(0)

    //   dispatch(indexData(0));
    // }
    else {
      setTrack(track + 1)
      dispatch(indexData(track + 1));
    }
  }

  const volumeup = (
    <div className={styles.volumeslider}>

      <SpeedDial
        ariaLabel="SpeedDial basic example"
        // sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<img src={vols} alt="medium-volume--v1" style={{ width: '30px', height: '30px', borderRadius: '30px' }} />}

        value={value}
        className={styles.speed_dail}
      >

        <SpeedDialAction icon={<Slider aria-label="Volume"
          orientation="vertical"
          defaultValue={60}
          min={0}
          max={100}
          value={volume}
          onKeyDown={preventHorizontalKeyboardNavigation}
          className='newvolslider'
          onChange={(e) => { setVolume(e.target.value); localStorage.setItem("volume", e.target.value) }} />
        } />


      </SpeedDial>
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
        {/* <VolumeDown />
      <Slider aria-label="Volume"
        defaultValue={60}
        min={0}
        max={100}
        value={volume}
        onKeyDown={preventHorizontalKeyboardNavigation}
        className={'newvolslider'}
        onChange={(e) => setVolume(e.target.value)} />
        
      <VolumeUp /> */}

      </Stack>
      {/* {/ <Slider disabled defaultValue={30} aria-label="Disabled slider" /> /} */}



    </div>
  )

  return (<>
    <div className="track">
      {/* <Grid container spacing={0} className='music-track-grid' >
        <Grid xs={12} sm={12} md={3} lg={3} xl={1.5}>

        </Grid>
        <Grid xs={12} sm={12} md={9} lg={7} xl={9} className='track-grid'>
          <div className='track-time'>

            <Box sx={{ width: '80%', margin: 'auto' }}>

              <Slider
                aria-label="time-indicator"
                size="small"
                value={currentTime}
                min={0}
                step={1}
                max={duration}
                onChange={handleSeek}
                className='track-timer'

              />
      
              <audio ref={audioRef} src={props.audiosources[track]?.audio} />
              <div className='audio-timmer'>
                <div>
                  {formatDuration(currentTime)}

                </div>
                <div>
                  {currentTime === 0 ? '0:00' : formatDuration(duration)}

                </div>
              </div>

            </Box>

          </div>
          <div>
            <Stack direction="row" spacing={4} className='music-stack'>
              <div>
                <img src={s1} />
              </div>
              <div>
                <img src={s2}
                  onClick={() => { changeTrack1() }}
                />
              </div>
              <div >

                <Button
                  onClick={handlePlayPause}
                >
                  {isPlaying ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                </Button>
              </div>
              <div>
                <img src={s4} onClick={() => { changeTrack() }} />
              </div>
              <div>
                <img src={s5} onClick={()=>setIsloop(!isLoop)}/>
              </div>

            </Stack>

          </div>

        </Grid>
        <Grid xs={12} sm={12} md={3} lg={2} xl={1.5}>
          <div>
            <Box sx={{ width: "auto" }}>
            <p style={{color:'var(--clr-font)'}}>{props.audiosources[track]?.name ? props.audiosources[track]?.name : "Song Name"}</p>
              <Stack direction="row" spacing={2} className='music-stack'>
                <VolumeUp className='mui-svg' sx={{ color: 'white' }} />

                <Grid item xs>
                  <Slider
                    type="range"
                    min={0}
                    max={100}
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                    className='slider-class'
                  />
                </Grid>

                <div className='love-display'>
                  <img src={i1} alt='img' />
                  <img src={i2} alt='img' />
                </div>
              </Stack>




            </Box>
          </div>
        </Grid>
      </Grid> */}
    </div>
    {track != undefined
      && <Grid container spacing={0} className={styles.musiccontainer}>

        <div className={`content ${isVisible ? 'visible' : 'hidden'}`}>
          {isVisible ? <Grid xs={11} sm={11} md={9} lg={8} xl={7} >
            <div className={styles.musicmain} >

              <div className={styles.musicdiv}>
                <div className={styles.musicslider}>
                  <ZoomInMapIcon onClick={() => { toggleVisibility() }} />
                  <Stack direction="row" spacing={3} className={styles.musiccont}>
                    <div className={styles.musicimg}>
                      <img src={props.audiosources[track]?.image ? props.audiosources[track]?.image : bs2} alt="sad" />
                    </div>
                    <div className={styles.musicconthead}>
                      {props.audiosources[track]?.name ? props.audiosources[track]?.name : "Song Name"}
                      <div className={styles.musiccontpara}>

                      </div>

                    </div>
                    {isPlaying === false && <div style={{ width: '35px', height: '35px' }}>
                    </div>}

                    {isPlaying && <img src={wave} alt="wave" style={{ width: '35px', height: '35px' }} />}
                    <Stack direction="row" spacing={3} className={styles.stk} id='stk-respo'>

                      <SkipPreviousIcon
                        onClick={() => { changeTrack1() }}
                      />

                      <FastRewindIcon onClick={() => { handleFarward() }} />
                      <div onClick={handlePlayPause}>
                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                      </div>
                      <FastForwardIcon onClick={() => { handlebackward() }} />
                      {/* {track},{props?.audiosources.length} */}
                      <div>
                        <SkipNextIcon onClick={() => { changeTrack() }} />
                      </div>
                      <div>
                        <LoopIcon onClick={() => setIsloop(!isLoop)} />
                      </div>
                      <div className="stk-dis2">
                        {volumeup}
                      </div>
                    </Stack>


                    <Slider
                      disabled={false}
                      marks={false}
                      max={duration}
                      min={0}
                      step={1}
                      size="small"
                      valueLabelDisplay="on"
                      className="mslider"
                      value={currentTime}
                      onChange={handleSeek}
                    />
                  </Stack>

                </div>


                <Stack direction="row" spacing={3}>

                  <div className={styles.volumeslider}>
                    <Box sx={{ width: 200 }}>
                      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center" className="stk-dis">
                        {volumeup}
                      </Stack>

                    </Box>


                  </div>

                </Stack>
              </div>

            </div>

          </Grid> : <Grid xs={11} sm={11} md={12} lg={11} xl={11} >

            {/* <input type="file" accept="mp3/*" onChange={handleImageUpload} /> */}
            <div>
              <div className={styles.musicslider}>
                <Stack direction="row" spacing={3} className={styles.musiccontx2}>
                  <div className={styles.musicimg}>
                    <img src={props.audiosources[track]?.image ? props.audiosources[track]?.image : bs2} alt="sad" />
                  </div>
                  <div className={styles.musicconthead}>
                    {props.audiosources[track]?.name ? props.audiosources[track]?.name : "Song Name"}
                    <div className={styles.musiccontpara}>
                      {/* The Beach Boys */}
                    </div>
                  </div>
                  <div className={styles.musiccont3}>
                    <img src={mtn7} alt="src" />
                    <MoreHorizIcon onClick={handleClick} />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      className={styles.popover}
                    >
                      <div className={styles.mini_more}>
                        <div className={styles.playpause} onClick={handlePlayPause}>
                          {isPlaying ? "Pause" : "Play"}
                        </div>

                      </div>

                    </Popover>
                    <ZoomOutMapIcon onClick={toggleVisibility} />
                  </div>
                  <Slider
                    // disabled={false}
                    marks={false}
                    max={duration}
                    min={0}
                    step={1}
                    size="small"
                    valueLabelDisplay="on"
                    className="mslider"
                    value={currentTime}
                    onChange={handleSeek}
                  />
                </Stack>

              </div>

            </div>


          </Grid>
          }
        </div>
        <audio ref={audioRef} src={props.audiosources[track]?.audio} />
      </Grid>
    }

  </>);
}

export default Tracks;