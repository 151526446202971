import Grid from '@mui/material/Grid';
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import './Track.css'
import Stack from '@mui/material/Stack';
import s1 from "../../img/dster-webp/shuffle.webp"
import s2 from "../../img/dster-webp/backword.webp"
import s3 from "../../img/dster-webp/pass-play.webp"
import s4 from "../../img/dster-webp/farward.webp"
import s5 from "../../img/dster-webp/refresh.webp"
import s6 from "../../img/dster-webp/phause.webp"
import Slider from '@mui/material/Slider';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { useDropzone } from 'react-dropzone';
import WaveSurfer from 'wavesurfer.js';
import { Button } from '@mui/material';




function Track({ audiosource }) {


  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0)
  const volumes = localStorage.getItem("volume")
  const [volume, setVolume] = useState(volumes !== undefined ? volumes : 60);
  const audioRef = useRef(null);
  const [waveform, setWaveform] = useState(null);
  const waveformRef = useRef(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    const wavesurferInstance = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: 'violet',
      progressColor: 'purple',
    });

    wavesurferInstance.loadBlob(file);

    setWaveform(wavesurferInstance);
  };

  const handleTrim = () => {
    if (waveform) {
      const startTime = waveform.getCurrentTime();
      const endTime = waveform.getDuration();

      // Trim audio logic here, e.g., save a new audio file or update state
      console.log(`Trim audio from ${startTime} to ${endTime}`);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // // Function to seek to a specific time in audio.


  const handlePlay = () => {
    // localStorage.removeItem("current")
    if (audioRef.current.src !== null && audioRef.current.src !== undefined) {
      const audio = audioRef.current.play();
      localStorage.setItem("isPause", false)
      console.log(audio, "playasd")
      setIsPlaying(true);

    }
    console.log(audioRef.current.src, "playasd")

  };

  const handleFarward = () => {
    audioRef.current.currentTime -= 10;
    audioRef.current.play();
  }

  const handlebackward = () => {
    audioRef.current.currentTime += 10;
    audioRef.current.play();
  }
  const audio = localStorage.getItem("audiosource")

  useEffect(() => {
    console.log("asd")
    const isPause = localStorage.getItem("isPause")
    console.log(isPause, "asdd")
    if (audiosource !== undefined) {
      const current = localStorage.getItem("current")
      if (isPause && isPause === "false") {
        if (current !== undefined && current !== null && current !== 0) {
          audioRef.current.currentTime = Number(current)
          handlePlay()
        }
        else {
          audioRef.current.currentTime = currentTime
          handlePlay()
        }
      }
      else {

        if (current !== undefined && current !== null && current !== 0) {
          audioRef.current.currentTime = Number(current)

        }
        else {
          audioRef.current.currentTime = currentTime
        }

        handlePause()
        console.log(audiosource, typeof (isPause), "playasdd")

      }





    }
    else {
      handleStop()
    }

  }, [audiosource])

  const handlePause = () => {
    console.log(audioRef.current.src, "playasd2")

    localStorage.setItem("audiosource", audioRef.current.src)
    localStorage.setItem("current", audioRef.current.currentTime)

    localStorage.setItem("isPause", true)

    console.log("audiosource", audioRef.current.src)
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const handleStop = () => {
    console.log(audioRef.current.src, "playasd1")

    localStorage.setItem("audiosource", audioRef.current.src)
    localStorage.setItem("current", audioRef.current.currentTime)
    console.log("audiosource", audioRef.current.src)
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };


  const handleTimeUpdate = () => {

    if (audioRef.current === null) {
      setCurrentTime(0)
      setDuration(0)
    }
    else if (audioRef.current.currentTime === audioRef.current.duration) {
      console.log("finish")
      localStorage.setItem("current", 0)
      handlePause()
    }
    else {
      localStorage.setItem("current", audioRef.current.currentTime)
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);

    }

  };





  const handleSeek = (e) => {
    console.log(audioRef.current)
    if (audioRef.current === null) {
      setCurrentTime(0)
    }
    else {
      console.log(e.target.value, typeof (e.target.value), audioRef.current.currentTime)

      if (isNaN(e.target.value)) {

        audioRef.current.currentTime = 0;
        setCurrentTime(0);

      }
      else {
        audioRef.current.currentTime = e.target.value;
        setCurrentTime(e.target.value);
      }

    }
  };

  useEffect(() => {
    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      if (audioRef?.current !== null) {
        // handlePlay()

      }
      // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume, audioRef]);


  function formatDuration(durationSeconds) {
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = Math.floor(durationSeconds % 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
  }




  //   const duration = 200;
  //   const [minuts, setMinuts] = React.useState(duration);
  //   const [paused, setPaused] = React.useState(true);



  // let sound =  new Audio(vijay)


  //   const handlePlay = () => {
  //     setPaused(!paused)
  //     if(paused === false){
  //       sound.pause()
  //       console.log('set to false but not paused');
  //     }else{
  //      sound.play()
  //     }


  //     // setPaused(!paused)
  //   }


  //   const handleMusicTrack = (value) => {
  //     const minuts = Math.floor(value / 60)
  //     const seconds = value - minuts * 60
  //     return `${minuts}:${seconds < 10 ? `0${seconds}` : seconds}`
  //   }

  //   const [minutes, setMinutea] = React.useState(0);



  //   React.useEffect(() => {
  //     if (!paused) {

  //       const timer = setTimeout(() => {
  //         if (minutes < duration) {
  //           setMinutea(minutes + 1);
  //         } else {
  //           clearTimeout(timer);
  //         }
  //       }, 1000);
  //     }


  //   }, [minutes, paused]);
  const dropzoneStyles = {
    border: '2px dashed #ccc',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };

  const waveformStyles = {
    marginTop: '20px',
  };

  const [value, setValue] = React.useState(30);


  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };


  return (<>
    <div className="track">
      <Grid container spacing={0} className='music-track-grid' >
        <Grid xs={12} sm={12} md={3} lg={3} xl={1.5}>

        </Grid>
        <Grid xs={12} sm={12} md={9} lg={7} xl={9} className='track-grid'>
          <div className='track-time'>

            <Box sx={{ width: '80%', margin: 'auto' }}>

              <Slider
                aria-label="time-indicator"
                size="small"
                value={currentTime}
                min={0}
                step={1}
                max={duration}
                onChange={handleSeek}
                className='track-timer'

              />

              <audio ref={audioRef} src={audiosource} />
              <div className='audio-timmer'>
                <div>
                  {formatDuration(currentTime)}

                </div>
                <div>
                  {currentTime === 0 ? '0:00' : formatDuration(duration)}

                </div>
              </div>

            </Box>

          </div>
          <div>
            <Stack direction="row" spacing={4} className='music-stack'>
              <div className='audios'>
                <img src={s1} />
              </div>
              <div className='audios'>
                <img src={s2}
                  onClick={handleFarward}
                />
              </div>
              <div >

                <Button
                  onClick={handlePlayPause}
                >
                  {isPlaying ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                </Button>
              </div>
              <div className='audios'>
                <img src={s4} onClick={handlebackward} />
              </div>
              <div className='audios'>
                <img src={s5} />
              </div>

              {/* <div className='audios'>
                <input
                  type="range"
                  min={0}
                  max={100}
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                />
              </div> */}

            </Stack>

          </div>

        </Grid>

        <Grid xs={12} sm={12} md={3} lg={2} xl={1.5}>
          {/* <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag & drop an audio file here, or click to select one</p>
      </div>

      <div ref={waveformRef} style={waveformStyles}></div>

      <button onClick={handleTrim}>Trim Audio</button>
    </div> */}
          <div>
            <Box sx={{ width: "auto" }}>
              <p style={{ color: 'var(--clr-font)' }}>{localStorage.getItem("songName") !== undefined ? localStorage.getItem("songName") : "Song Name"}</p>
              <Stack direction="row" spacing={2} className='music-stack'>
                <VolumeUp className='mui-svg' sx={{ color: 'white' }} />

                <Grid item xs>
                  <Slider
                    type="range"
                    min={0}
                    max={100}
                    value={volume}
                    onChange={(e) => { setVolume(e.target.value); localStorage.setItem("volume", e.target.value) }}
                    className='slider-class'
                  />
                </Grid>


              </Stack>




            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  </>);
}

export default Track;