import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../Upload-Music/UploadMusic.css'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import '../Hoisting/Hoisting.css'
import { useState, useEffect, useRef } from "react";
import '../CreateVirtualMeet/CreateVirtualMeet.css'
import '../Colabration/Colabration.css'
import './VirtualMeet.css'
import artist1 from '../../img/dster-webp/artist1.webp'
import artist2 from '../../img/dster-webp/artist2.webp'
import artist3 from '../../img/dster-webp/artist3.webp'
import SendIcon from '@mui/icons-material/Send';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import s2 from "../../img/dster-webp/backstage.webp"
import s3 from "../../img/dster-webp/pass-play.webp"
import s4 from "../../img/dster-webp/farward.webp"
import s6 from "../../img/dster-webp/phause.webp"
import Slider from '@mui/material/Slider';
import io from "socket.io-client";
import vrmeet from '../../img/dster-webp/vrmeet-bg.webp'
import { useLocation } from "react-router-dom";
import Axios from "../../Axios";
import HeaderNew from "../HeaderNew/HeaderNew";
import consts from "../../Constant";


const drawerWidth = 280;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

// const socket = io.connect("http://143.110.253.140:3500")
const socket = io.connect(`${consts.SocketUrl}`)


function VirtualMeet() {
    const theme = useTheme();
    const [validate, setValidate] = useState('')

    const location = useLocation()

    const [userDetails, setUserDetails] = useState()
    const [rooms, setRooms] = useState()

    const [message, setMessage] = useState([]);

    const [updated, setUpdated] = useState('');

    const [audio, setAudio] = useState()

    useEffect(() => {
        if (location?.state?.data && location?.state?.user) {
            setUserDetails(location?.state?.user)
            setRooms(location?.state?.data)
            setMessage(location?.state?.data?.message)
        }
    }, [location])


    useEffect(() => {
        setAudio(rooms?.track_url)
        if (rooms?.room_id != undefined) {
            socket.emit('join', { room: rooms?.room_id })
        }
    }, [rooms])

    const [err, setErr] = useState(null)



    const user = window.localStorage.getItem('usrId')


    const [currentTime1, setCurrentTime1] = useState(0);
    const [duration1, setDuration1] = useState(0)
    const [isPlaying1, setIsPlaying1] = useState(false);

    const [owner, setOwner] = useState()

    const hiddenAudioInput1 = useRef(null)


    const audioRef1 = useRef(null);

    function formatDuration(durationSeconds) {
        // console.log(durationSeconds)
        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }
    const handleChangeSend = (event) => {
        setUpdated(event.target.value);
    };

    const handleClickSend = async (e) => {
        try {
            const date = new Date();
            const hour = date.getHours();
            console.log("🚀 ~ handleClickSend ~ hour:", hour)
            const min = date.getMinutes();
            console.log("🚀 ~ handleClickSend ~ min:", min)
            if (updated?.trim() !== '') {
                var payload = {
                    message: updated,
                    room: rooms?.room_id,
                    time: Date.now(),
                    date: `${hour}:${min}`
                }
                const { data } = await Axios.post('/followers/sendMessage', { id: rooms?._id, message: payload },
                    { headers: { Authorization: window.localStorage.getItem('iphephile') } })
                if (data?.success == true) {
                    // console.log('receivemessagesendmessage')
                    setMessage((list) => [...list, data?.result])
                    socket.emit('send', data?.result)
                }
                setUpdated('')
            }
        } catch (error) {
            console.log("🚀 ~ handleClickSend ~ error:", error)

        }

    };

    useEffect(() => {
        socket.on("receive", (data) => {
            setMessage((list) => [...list, data])
        });
    }, [])

    const handleSeek1 = (e) => {
        audioRef1.current.currentTime = e.target.value;
        setCurrentTime1(e.target.value);
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };

    const handleFarward1 = () => {
        audioRef1.current.currentTime = 0;
        audioRef1.current.play();
    }

    // const handlePause = () => {
    //     audioRef.current.pause();
    //     setIsPlaying(false);
    // };

    const handlePlay1 = () => {
        // handlePause1()
        try {
            // console.log("🚀 ~ handlePlay1 ~  audioRef1.current:", audioRef1.current)
            const data = audioRef1.current.play();
            // console.log(audio, "handlePlay1")
            setIsPlaying1(true);

        } catch (error) {
            console.log("🚀 ~ handlePlay1 ~ error:", error)


        }
    };

    const handlePlayPause1 = () => {
        if (isPlaying1) {
            window.localStorage.setItem('Playing', "false")
            var payload = {
                room: location?.state?.data?.room_id,
                status: "pause"
            }
            socket.emit('play', payload)
            handlePause1();
        } else {
            window.localStorage.setItem('Playing', "true")
            var payload = {
                room: location?.state?.data?.room_id,
                seconds: audioRef1.current.currentTime,
                status: "play"
            }
            socket.emit('play', payload)
            handlePlay1();
            // handlePause()
        }
    };

    const handlePause1 = () => {
        // window.localStorage.setItem('Playing', "false")
        audioRef1?.current?.pause();
        setIsPlaying1(false);
    };

    const checkPlayFunction = (data) => {
        if (window.localStorage.getItem('Playing') == "false") {
            // console.log('logsPlayFunction')
            handlePlay1()
            window.localStorage.setItem('Playing', "true")
        }
    }

    const checkPauseFunction = (data) => {
        // if (window.localStorage.getItem('Playing') == "true") {
        // console.log('logsPauseFunction')
        setTimeout(() => {
            window.localStorage.setItem('Playing', "false")
            handlePause1()
        }, 1000);
        // }
    }

    useEffect(() => {
        socket.on('play_receive', (data) => {
            // console.log(isPlaying1, "isPlaying1123")
            // handlePlayPause1()
            // console.log(data?.status, "logsstatus")
            if (data.status == "play") {
                checkPlayFunction(data)
            } else {
                checkPauseFunction(data)
            }
        })
    }, [])

    const handleTimeUpdate = () => {

        if (audioRef1.current === null) {
            setCurrentTime1(0)
            setDuration1(0)
        }
        else if (audioRef1?.current?.currentTime === audioRef1?.current?.duration) {
            // console.log("finish")
            localStorage.setItem("current", 0)
            handlePause1()
        }
        else {
            localStorage.setItem("current", audioRef1.current.currentTime)
            // console.log(location?.state?.data, "asfhsfd")
            setCurrentTime1(audioRef1.current.currentTime);
            setDuration1(audioRef1.current.duration);
        }
    };

    useEffect(() => {
        audioRef1.current.addEventListener("timeupdate", handleTimeUpdate);

        return () => {
            if (audioRef1?.current !== null) {
                // handlePlay()
            }
            // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, []);


    useEffect(() => {

        if (validate.length >= 3) {
            // console.log('yes');
            setErr(false)
        }
        //  else if (validate.trim() === '') {
        //     setErr(false)
        // }
        else {
            setErr(true)
        }

    }, [validate])

    function preventHorizontalKeyboardNavigation(event) {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.preventDefault();
        }
    }
    const [title, setTitle] = useState('virtual meet')

    return (

        <div className="Hoisting explore dashboard">
            {/* <SnackBar /> */}
            <Box sx={{ display: 'flex' }} className='display-block'>

                <HeaderNew title={title} />
                <Box component="main"
                    className="Hoisting-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>


                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 0 }}
                        className="UploadMusic-box3 create-exclusive"
                    >
                        <Container maxWidth="xl" >
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ p: 1 }}>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className="main frame-child">
                                                <div className="vrmeet-bg">
                                                    <img src={userDetails?.coverImages !== "" ? userDetails?.coverImage : vrmeet} alt='img' />
                                                </div>
                                                <div>
                                                    <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
                                                        <Grid xs={12} sm={12} md={12} lg={12} xl={2} sx={{ p: 1 }}>
                                                            <div className="vr-inner">
                                                                <img src={userDetails?.profilePicture} alt='' />
                                                            </div>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={12} xl={8} sx={{ p: 1 }}>

                                                            <div className="virtualmeet-music-main">
                                                                {/* <div>
                                                            <div>
                                                                <FavoriteIcon />
                                                            </div>
                                                            <RepeatIcon />
                                                        </div> */}
                                                                <div className="vr-music-track">


                                                                    <Stack direction="row" spacing={4} className='music-stack'>
                                                                        <div className='audios'>
                                                                            {/* <img src={s1} /> */}
                                                                        </div>
                                                                        <div className='audios'>
                                                                            <img src={s2}
                                                                                onClick={handleFarward1}
                                                                            />
                                                                        </div>
                                                                        <div >
                                                                            {audio ? (<>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        if (userDetails?._id == user) {
                                                                                            handlePlayPause1()
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {isPlaying1 ? <> {currentTime1 === duration1 ? <img src={s3} /> : <img src={s6} />}</> : (<><img src={s3} /> </>)}
                                                                                </Button>
                                                                            </>) : (<>

                                                                                <Button
                                                                                // onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying1 ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button></>)}

                                                                        </div>
                                                                        <div className='audios'>
                                                                            <img src={s4}
                                                                            // onClick={handlebackward} 
                                                                            />
                                                                        </div>
                                                                        <div className='audios'>
                                                                            {/* <img src={s5} /> */}
                                                                        </div>

                                                                        {/* <div className='audios'>
                <input
                  type="range"
                  min={0}
                  max={100}
                  value={volume}
                  onChange={(e) => setVolume(e.target.value)}
                />
              </div> */}

                                                                    </Stack>
                                                                    <div className='track-time'>
                                                                        <Box sx={{ width: '80%', margin: 'auto' }}>

                                                                            {audio ? <Slider
                                                                                aria-label="time-indicator"
                                                                                size="small"
                                                                                value={currentTime1}
                                                                                min={0}
                                                                                step={1}
                                                                                max={duration1}
                                                                                onChange={handleSeek1}

                                                                            /> : <Slider
                                                                                aria-label="time-indicator"
                                                                                size="small"
                                                                                value={0}
                                                                                min={0}
                                                                                step={1}
                                                                                max={0}


                                                                            />}

                                                                            <audio ref={audioRef1} src={audio} />
                                                                            <div className='audio-timmer'>
                                                                                {audio ? <div>
                                                                                    {formatDuration(currentTime1)}

                                                                                </div> : <></>}
                                                                                {audio ? <div>
                                                                                    {isNaN(duration1) ? '0:00' : formatDuration(duration1)}

                                                                                </div> : <></>}

                                                                            </div>
                                                                        </Box>
                                                                    </div>
                                                                    {/* <Box sx={{ height: 120 }}>
                                                                        <Slider
                                                                            sx={{
                                                                                '& input[type="range"]': {
                                                                                    WebkitAppearance: 'slider-vertical',
                                                                                },
                                                                            }}
                                                                            orientation="vertical"
                                                                            defaultValue={30}
                                                                            aria-label="Temperature"
                                                                            valueLabelDisplay="auto"
                                                                            onKeyDown={preventHorizontalKeyboardNavigation}
                                                                            className="slider-class2"
                                                                        />
                                                                    </Box> */}
                                                                </div>
                                                                {/* <button onClick={() => { handlePlay1() }} >test</button> */}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>


                            {/* </Grid> */}
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <div className="colab-msg-box">
                                    <div className="colab-avatar-cont vr">
                                        <Stack direction="row" spacing={-0.5}>
                                            <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist1} />
                                            <Avatar sx={{ width: 30, height: 30 }} alt="Travis Howard" src={artist2} />
                                            <Avatar sx={{ width: 30, height: 30 }} alt="Cindy Baker" src={artist3} />
                                        </Stack>
                                    </div>
                                    <div className="colab-chat-area vr">
                                        {message.map((todo, ind) => {
                                            return (

                                                <div className={todo?.id == user ? "chat-area-inner-text user vr" : "chat-area-inner-text vr"} key={ind}>
                                                    <div>
                                                        <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist3} />
                                                    </div>
                                                    <div className={todo?.id == user ? "collab-send-text-main2 user" : "collab-send-text-main"}>
                                                        <div className="username">{todo?.name}</div>
                                                        <div className="txt1">{todo?.message}</div>
                                                        <div className="time">{todo?.date}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div className="colab-text-input">

                                        <div>
                                            <input type="text" placeholder="Start typing..."
                                                value={updated}
                                                onChange={handleChangeSend}
                                                onKeyDown={handleEnterKeyPress}

                                            />
                                        </div>
                                        <div className="colab-send" onClick={() => { handleClickSend() }}>
                                            <SendIcon />
                                        </div>
                                    </div>
                                </div>
                            </Grid>


                            {/* </Grid> */}
                        </Container>


                        {/* 
                        <div>
                            <Track />
                        </div> */}
                    </Box>
                </Box>

            </Box >
        </div >
    );
}

export default VirtualMeet;