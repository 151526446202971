import Grid from "@mui/material/Grid";
import * as React from "react";
import { useState, useRef, useEffect } from "react";
// import "../Music-player/Player.css";
import "../Fanzone/Fanzone.css";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import SendProfile from "../ShareLink/SendProfile";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import Header from "../Header/Header";
import Box from "@mui/material/Box";
import Footer from "../Footer/Footer";
import "./BackStagePass.css";
import c5 from '../../img/dster-webp/bs1.webp'
import c6 from '../../img/dster-webp/bs2.webp'
import c7 from '../../img/dster-webp/bs3.webp'
import c8 from '../../img/dster-webp/bs4.webp'
import c9 from '../../img/dster-webp/er1.webp'
import c10 from '../../img/dster-webp/er2.webp'
import c11 from '../../img/dster-webp/er3.webp'
import c12 from '../../img/dster-webp/er4.webp'
import Cards from '../../img/dster-webp/card-1.webp';
import Cards2 from '../../img/dster-webp/card-2.webp';
import Cards1 from '../../img/dster-webp/card-3.webp';
import Cards3 from '../../img/dster-webp/card-4.webp';
import Cards4 from '../../img/dster-webp/card-5.webp';
import Cards5 from '../../img/dster-webp/card-6.webp';
import Cards6 from '../../img/dster-webp/card-7.webp';
import Cards7 from '../../img/dster-webp/card-8.webp';
import Cards8 from '../../img/dster-webp/card-9.webp';
import Cards9 from '../../img/dster-webp/card-10.webp';
import Cards10 from '../../img/dster-webp/card-11.webp';
import Cards11 from '../../img/dster-webp/card-12.webp';
import Cards12 from '../../img/dster-webp/card-12.webp';
import dt from '../../img/dster-webp/dt1.webp';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import i3 from '../../img/dster-webp/i3.webp'
import join from '../../img/dster-webp/join-icon.svg';
import ListIcon from "@mui/icons-material/List";
import icon from '../../img/dster-webp/c-icon.webp';
import { Link, useNavigate } from "react-router-dom";
import BackStageFanZone from "./BackStageFanZone";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import "slick-carousel/slick/slick-theme.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import CalenderNew from "../CalendarNew/CalendarNew";
import Axios from "../../Axios";
import { ToastContainer, toast } from "react-toastify";
import balanceAddress from "../../Web3/balanceAddress";
import balanceAbi from "../../Web3/balanceAbi.json";
import Web3 from "web3";
import consts from "../../Constant";
import nodata2 from "../../img/dster-webp/newnodata.gif";
import approveABI from '../../Web3/approveAbi.json'
import TradeAbi from '../../Web3/Abi/trade.json'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  cardbg: {
    borderRadius: "12px !important",
    background: "rgba(255, 255, 255, 0.10)",
    boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
    backdropFilter: "blur(37.5px)",
    padding: "0 !important",
  },

  pwrback: {
    borderRadius: "12px !important",
    border: "1px solid rgba(255, 255, 255, 0.20)",
    background: "#000000 !important",
  },

  learn: {
    borderRadius: "12px !important",
    background: "transparent !important",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important",
  },

  containWidth: {
    maxWidth: "1440px",
    margin: "auto",
    background: "none !important",
    padding: "0 !important",
    boxShadow: "none !important",

    "@media (max-width: 1199.98px)": {
      padding: "20px !important",
    },
  },
});

const card = [
  { img: `${Cards}`, name: "Jump" },
  { img: `${Cards1}`, name: "ManTra" },
  { img: `${Cards2}`, name: "Opera Odiosa ao Demônio da Putrefação" },
  { img: `${Cards3}`, name: "Blues-corn" },
  { img: `${Cards4}`, name: "Jump" },
  { img: `${Cards5}`, name: "Afro KingKong vs Godzilla" },
  { img: `${Cards6}`, name: "Jump" },
  { img: `${Cards7}`, name: "HOSTAGE Feat. Tshaka" },
  { img: `${Cards8}`, name: "Jump" },
  { img: `${Cards9}`, name: "ManTra" },
  { img: `${Cards10}`, name: "Jump" },
  { img: `${Cards11}`, name: "HOSTAGE Feat. Tshaka" },
  { img: `${Cards12}`, name: "Jump" },
];

const content4 = [
  { img: `${c5}`, txt1: "APOKI", txt2: "2.1K Followers" },
  { img: `${c6}`, txt1: "APOKI", txt2: "2.1K Followers" },
  { img: `${c7}`, txt1: "Kato On The Track", txt2: "2.1K Followers" },
  { img: `${c8}`, txt1: "Kato On The Track", txt2: "2.1K Followers" },
  { img: `${c5}`, txt1: "𝔟𝔟𝔶 𝔦𝔳𝔶", txt2: "2.1K Followers" },
  { img: `${c6}`, txt1: "𝔟𝔟𝔶 𝔦𝔳𝔶", txt2: "2.1K Followers" },
  { img: `${c7}`, txt1: "Matt OX", txt2: "2.1K Followers" },
  { img: `${c8}`, txt1: "Matt OX", txt2: "2.1K Followers" },
  // { img: `${c8}`, txt1: 'Matt OX', txt2: '2.1K Followers' },
];

const content5 = [
  { img: `${c9}`, txt1: "APOKI", txt2: "2.1K Followers" },
  { img: `${c10}`, txt1: "APOKI", txt2: "2.1K Followers" },
  { img: `${c11}`, txt1: "Kato On The Track", txt2: "2.1K Followers" },
  { img: `${c12}`, txt1: "Kato On The Track", txt2: "2.1K Followers" },

  // { img: `${c8}`, txt1: 'Matt OX', txt2: '2.1K Followers' },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const socket = io.connect("http://43.205.10.212", { path: "/usersocket" })

function BackStagePass() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [audioplays, setAudioplays] = useState([]);

  const [value, setValue] = React.useState(0);

  const [events, setEvents] = useState([]);
  const [events_tom, setEvents_tom] = useState([]);

  const [todays, setTodays] = useState([]);
  const [details, setDetails] = useState([]);
  const [detail, setDetail] = useState([]);

  const [tmrs, setTmrs] = useState([]);

  const [Artists, setArtists] = useState([]);
  const [collection, setCollection] = React.useState([]);
  const [openModelprofile, setOpenModelprofile] = React.useState(false);
  const handleOpenprofile = () => setOpenModelprofile(true);
  const handleModelCloseprofile = () => setOpenModelprofile(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const tokens = localStorage.getItem("iphephile");


  const getEvents = async () => {
    try {
      const { data } = await Axios.post(
        "/followers/getMyRooms",
        {},
        {
          headers: { Authorization: window.localStorage.getItem("iphephile") },
        }
      );
      const endOfDay = new Date();
      endOfDay.setUTCHours(23, 59, 59, 999);
      var todayTime = Math.round(endOfDay.getTime() / 1000);
      if (data?.success == true) {
        var todays = [];
        var tomorrows = [];
        var fullDetail = [];
        var tmr_details = [];
        var date_details = [];
        if (data?.result?.length > 0) {
          setDetail(data?.result);
          for (let i = 0; i < data?.result.length; i++) {
            const element = data?.result[i];
            var event = {};
            event.date = element.EventDate?.split(" ")[0];
            event.track_Name = element?.track_Name;
            tmr_details.push(event);

            // console.log(element.EventDate?.split(' ')[0], "to")
            if (!date_details.includes(element.EventDate?.split(" ")[0])) {
              date_details.push(element.EventDate?.split(" ")[0]);
            }

            if (element?.EventDate_stamp < todayTime) {
              todays.push(element);
            } else {
              tomorrows.push(element);
            }
          }
        }

        setEvents(todays);
        setEvents_tom(tomorrows);
        setTodays(date_details);
      } else {
        setEvents([]);
      }
    } catch (error) {
      console.log("🚀 ~ getEvents ~ error:", error);
    }
  };

  useEffect(() => {
    if (detail?.length > 0) {
      detail?.forEach((row, index) => {
        getDates(row?.EventDate?.split(" ")[0], row?.track_Name, row);
      });
    }
  }, [detail]);
  const getDates = async (date, name, details) => {
    try {
      const dateString = new Date(date).toLocaleDateString();
      setTmrs((prev) => ({
        ...prev,
        [dateString]: [...(prev[dateString] || []), name],
      }));
      setDetails((prev) => ({
        ...prev,
        [dateString]: [...new Set([...(prev[dateString] || []), details])],
      }));
      // setDetails((prev) => ({
      //     ...prev,
      //     [dateString]: [...(prev[dateString] || []), details],
      // }));
    } catch (error) {
      setTmrs((prev) => ({
        ...prev,
        [date]: "",
      }));
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const testLive = async (id) => {
    try {
      const { data } = await Axios.post(
        "/followers/checkLiveRoom",
        { room_id: id },
        {
          headers: { Authorization: window.localStorage.getItem("iphephile") },
        }
      );
      if (data?.success == true) {
        // get Amount to user to artist
        const web3 = new Web3(window.ethereum);
        const contractInstance = new web3.eth.Contract(
          balanceAbi,
          balanceAddress
        );
        const account = await window.ethereum.request({
          method: "eth_accounts",
        });
        var sendAmount;
        // navigate('/virtualMeet', { state: { data: data?.result, user: data?.user } })
        var authEndpoint = consts.Zoom_sign_api;
        var meetingNumber = data?.result?.zoom_data?.id;
        var role = 0;
        if (data?.result?.requestor1 == window.localStorage.getItem("usrId")) {
          role = 1;
          sendAmount = true;
        } else {
          role = 0;
          if (
            data?.result?.requestor2?.includes(
              window.localStorage.getItem("usrId")
            )
          ) {
            sendAmount = true;
          } else {
            sendAmount = await contractInstance.methods
              .transfer(
                "0x52307495A627c14DF5590556b0F4cFd994334b14",
                (Number(data?.result?.token) * 10 ** 18).toString()
              )
              .send({ from: account[0] });
            await Axios.post(
              "/followers/updateAmount",
              {
                Room_id: data?.result?._id,
              },
              {
                headers: {
                  Authorization: window.localStorage.getItem("iphephile"),
                },
              }
            );
          }
        }
        if (sendAmount) {
          fetch(authEndpoint, {
            method: "POST",
            headers: {
              Authorization: window.localStorage.getItem("iphephile"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              meetingNumber: meetingNumber,
              role: role,
            }),
          })
            .then((res) => res.json())
            .then((response) => {
              // navigate('/zoomconnect', { state: { data: data?.result, signature: response?.signature } })
              startMeeting(response.signature, data?.result, data?.currentUser);
            })
            .catch((error) => {
              toast.error("Error Joining Meeting");
            });
        } else {
          toast.error("Error Joining");
        }
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log("🚀 ~ testLive ~ error:", error);
      if (error?.code == 4001) {
        toast.error("Error Joining");
      }
    }
  };

  function startMeeting(signature, datas, user) {
    try {
      // var signature = location?.state?.signature

      const { ZoomMtg } = require("@zoom/meetingsdk");
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      document.getElementById("zmmtg-root").style.display = "block";
      // var datas = location?.state?.data
      ZoomMtg.init({
        leaveUrl: consts?.Zoom_redirectUrl,
        patchJsMedia: true,
        success: (success) => {
          ZoomMtg.join({
            signature: signature,
            sdkKey: consts?.Zoom_sdkKey,
            meetingNumber: datas?.zoom_data?.id,
            passWord: datas?.zoom_data?.password,
            userName: user?.name ? user.name : "Anonymous",
            userEmail: user?.email,
            tk: "",
            success: (success) => { },
            error: (error) => {
              console.log(error, "ramesh error");
            },
          });
        },
        error: (error) => {
          console.log(error, "err2");
        },
      });
    } catch (error) {
      console.log("🚀 ~ startMeeting ~ error:", error);
    }
  }

  // function startMeeting(signature) {
  //     var sdkKey = consts.Zoom_sdkKey
  //     var meetingNumber = '81874337673'
  //     var passWord = 'BtJr0Z'
  //     var userName = 'Lucky'
  //     var userEmail = 'ramesh.alpharive@gmail.com'
  //     var leaveUrl = consts.Zoom_redirectUrl
  //     document.getElementById('zmmtg-root').style.display = 'block'

  //     ZoomMtg.init({
  //         leaveUrl: leaveUrl,
  //         patchJsMedia: true,
  //         success: (success) => {
  //             console.log(success)

  //             ZoomMtg.join({
  //                 signature: signature,
  //                 sdkKey: sdkKey,
  //                 meetingNumber: meetingNumber,
  //                 passWord: passWord,
  //                 userName: userName,
  //                 userEmail: userEmail,
  //                 tk: registrantToken,
  //                 success: (success) => {
  //                     console.log(success)
  //                 },
  //                 error: (error) => {
  //                     console.log(error)
  //                 }
  //             })

  //         },
  //         error: (error) => {
  //             console.log(error)
  //         }
  //     })
  // }

  //Owl Carousel Settings
  const options = {
    margin: 30,
    items: 4,
    responsiveClass: true,
    // nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    loop: true,
    smartSpeed: 2000,
    autoHeightClass: "owl-height",
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  const navigateTo = () => {
    navigate("/polls_survey");
  };

  const getArtist = async () => {
    try {
      const { data } = await Axios.post(
        "/followers/get_all_users",
        { type: "artist" },
        {
          headers: { Authorization: window.localStorage.getItem("iphephile") },
        }
      );
      if (data?.success == true) {
        setArtists([]);
        var dts = data?.result;
        var arr = [];
        for (let i = 0; i < 17; i++) {
          const element = dts[i];
          if (element) {
            arr.push(element);
          } else {
            break;
          }
        }
        setArtists(arr);
      } else {
        setArtists([]);
      }
    } catch (error) {
      console.log("🚀 ~ getArtist ~ error:", error);
    }
  };

  useEffect(() => {
    getArtist();
  }, []);

  // const getCollections = async () => {
  //   try {
  //     const response = await Axios.get("users/getCollectionList");
  //     setCollection(response?.data?.result);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const getaudioPlays = async (index) => {
    try {

      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));

    } catch (error) {
      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  const getCollections = async () => {
    try {

      const datas = await Axios.post("/collection/get_collection", { type: value }, {

      })
        .then((res) => {

          if (res?.data?.success) {

            setCollection(res?.data?.result)

          }
          else {
            setCollection([])


          }
        }).catch((err) => {
          setCollection([])

        })

    } catch (error) {
      setCollection([])
    }

  }

  useEffect(() => {
    getCollections();
  }, [value]);

  useEffect(() => {
    if (collection) {
      collection.forEach((row, index) => {
        getaudioPlays(index);
      });
    }
  }, [collection])

  const checkTradeBuyAsset = async () => {
    try {
      console.log("ramesh")
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts()
      console.log("🚀 ~ Checks ~ accounts:", accounts)
      var nounce = await web3.eth.getTransactionCount("0xAf0932232d709d1Ce969043E77c74E69F186905d"); // signer Address
      console.log("🚀 ~ Checks ~ nounce:", nounce)
      var Ownermessage = web3.utils.soliditySha3(
        { type: 'address', value: consts.tradeContract }, // Contract Address
        { type: 'address', value: consts.nftmintContract }, // NFT Address
        { type: 'address', value: accounts[0] }, // seller Address
        { type: 'string', value: 'rameshtest' }, // tokenURI
        { type: 'uint256', value: nounce }); // nounce of signer
      console.log("🚀 ~ Checks ~ message:", Ownermessage)

      var OwnerSign = await web3.eth.accounts.sign(Ownermessage, '7b55df938ff43b96a75042a5b1bc4ee05946cbe113a8cee4e8dbd8195d5290d5') // signer privatekey
      console.log("🚀 ~ Checks ~ data:", OwnerSign)
      console.log(OwnerSign.signature.slice(0, 66))
      console.log(OwnerSign.signature.slice(66, 130))
      console.log(OwnerSign.signature.slice(130, 132))

      // SELLER SIGN   

      var nonce = await web3.eth.getTransactionCount('0x52307495A627c14DF5590556b0F4cFd994334b14');
      console.log("🚀 ~ checkTrade ~ nonce:", nonce)


      var SellerMessage = web3.utils.soliditySha3(
        { type: 'address', value: consts.nftmintContract }, // NFT Address
        { type: 'uint256', value: "11" }, //NFT ID
        { type: 'address', value: "0x8d008B313C1d6C7fE2982F62d32Da7507cF43551" }, // ERC20 Address
        { type: 'uint256', value: "2000000000000000000" }, // Amount
        { type: 'uint256', value: nonce }// nounce of signer)
        // new Buffer(nftmintContract, 1, "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238", 5, nonce)
      )




      console.log("🚀 ~ Checks ~ message:", SellerMessage)

      var SellerSign = await web3.eth.accounts.sign(SellerMessage, 'b82e2d77efb92996a0042373d924ecd2c161550c672c823bbbc910782c883faf')
      // var SellerSign = await web3.eth.sign(SellerMessage, accounts[0])

      console.log("🚀 ~ Checks ~ data:", SellerSign)
      console.log(accounts[0], "accounts")

      // var r = SellerSign.slice(0, 66)
      // var s = `0x${SellerSign.slice(66, 130)}`
      // var v = `0x${SellerSign.slice(130, 132)}`

      var r = SellerSign.r
      var s = SellerSign.s
      var v = SellerSign.v

      console.log(r, s, v, "resf")


      // BUYER SIGN   

      // var buyerNounce = await web3.eth.getTransactionCount(accounts[0]);
      // console.log("🚀 ~ checkTrade ~ buyerNounce:", buyerNounce)
      // var buyerMessage = web3.utils.soliditySha3(
      //     { type: 'address', value: nftmintContract }, // NFT Address
      //     { type: 'uint256', value: 2 }, //NFT ID
      //     { type: 'address', value: "0x779877A7B0D9E8603169DdbD7836e478b4624789" }, // ERC20 Address
      //     { type: 'uint256', value: 3 }, // Amount
      //     { type: 'uint256', value: 1 }, // quantity
      //     { type: 'uint256', value: buyerNounce }); // nounce of signer
      // console.log("🚀 ~ Checks ~ message:", buyerMessage)

      // var buyerSign = await web3.eth.accounts.sign(buyerMessage, ' ')
      // console.log("🚀 ~ Checks ~ data:", buyerSign)

      // // var r = buyerSign.slice(0, 66)
      // // var s = `0x${buyerSign.slice(66, 130)}`
      // // var v = `0x${buyerSign.slice(130, 132)}`

      // var r = buyerSign.r
      // var s = buyerSign.s
      // var v = buyerSign.v

      // console.log(r, s, v, "resf")

      // const recover = await web3.eth.accounts.recover({
      //     messageHash: buyerMessage,
      //     v: v,
      //     r: r,
      //     s: s
      // })
      // console.log("🚀 ~ checkTrade ~ recover:", recover)

      const ContractInstance = new web3.eth.Contract(TradeAbi, consts.tradeContract)
      // //( tokenURI, [royalty], [reciever (user)], [message v,r,s,nounce])

      // const methos = await ContractInstance.methods.executeBid([accounts[0], accounts[0], "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238", nftmintContract, 1, 1, false, 5, 2, "rameshtest", 1, [1], [accounts[0]], 1], [OwnerSign.v, OwnerSign.r, OwnerSign.s, nounce], [v, r, s, nonce]).send({


      // var methosss = await ContractInstance.methods.verifySellerSignPublic12(
      //     accounts[0],
      //     "1",
      //     "5",
      //     "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
      //     nftmintContract,
      //     [v, r, s, nonce.toString()]

      // ).call({ from: accounts[0] })
      // console.log("🚀 ~ //methos ~ methos:", methosss)

      var payload =
        [
          "0x52307495A627c14DF5590556b0F4cFd994334b14",  //seller
          accounts[0],  // buyer
          "0x8d008B313C1d6C7fE2982F62d32Da7507cF43551", //erc20Address
          "0x17579149b3126B55f7568363Eeb6b7551eE078C7",  //nftAddress
          "1",   //nftType
          "2000000000000000000",   //unit price
          false,   //skip royalty
          "3000000000000000000",  //amount
          "12", //tokenId
          "https://gateway.pinata.cloud/ipfs/QmZnrDUGYcf9uZ6dxGe5Ppn1TYVTWJBonjb28VMjNANUyJ",  //tokenURI
          "1",  //supply
          [3],  //royaltyfee
          [accounts[0]],   //royaltyreciever
          "1"   //quantity
        ]

      // const methos2 = await ContractInstance.methods.byCheck132(payload, [v, r, s, nonce.toString()]).call({
      //     from: accounts[0]
      // })
      // console.log("🚀 ~ methos2 ~ methos2:", methos2)
      // const methos1 = await ContractInstance.methods.byCheck(payload, [v, r, s, nonce.toString()]).call({
      //     from: accounts[0]
      // })
      // console.log("🚀 ~ methos1 ~ methos1:", methos1)



      const methos = await ContractInstance.methods.buyAsset(payload, ['0x1b', '0x200f78c9bb7fed9a8569e4153189a23142b0efd82d60937fb285c3971198eb80', '0x5ea8e4ea34aae152f611b1ee9d31a730e1a9d68e681473462a93512d7b24e5ad', '267']).send({
        from: accounts[0]
      })

      // const methos = await ContractInstance.methods.buyAsset(payload, [v, r, s, nonce]).send({
      //     from: accounts[0]
      // })
      console.log("🚀 ~ Checks ~ methos:", methos)

    } catch (error) {
      console.log("🚀 ~ checkTrade ~ error:", error)
    }
  }

  const signin = async (data, index) => {
    try {

      if (window.ethereum) {
        var nftmintContract = consts.nftmintContract

        setAudioplays((prev) => ({
          ...prev,
          [index]: 1,
        }));
        console.log(index, 'approve1')

        const web3 = new Web3(window.ethereum)


        const account = await window.ethereum.request({
          method: "eth_accounts",
        });

        console.log(index, 'approve2')

        const prices = 2 * Number(data.minimum_bid)
        const approveAmount = Number(data.minimum_bid) + prices

        console.log(web3.utils.toWei(`${data.minimum_bid}`, 'ether'), web3.utils.toWei(`${approveAmount}`, 'ether'), "data")

        const ContractInstance = new web3.eth.Contract(approveABI, data.token)
        var Approve = await ContractInstance.methods.approve(consts.transfer_proxy, web3.utils.toWei(`${approveAmount}`, 'ether')).send({
          from: account[0]
        }).then(async (resp) => {
          console.log(resp)
          var nonce = await web3.eth.getTransactionCount(account[0]);

          console.log(index, 'approve4')

          // var SellerMessage = web3.utils.soliditySha3(
          //   { type: 'address', value: consts.nftmintContract }, // NFT Address
          //   { type: 'uint256', value: "11" }, //NFT ID
          //   { type: 'address', value: "0x8d008B313C1d6C7fE2982F62d32Da7507cF43551" }, // ERC20 Address
          //   { type: 'uint256', value: "2000000000000000000" }, // Amount
          //   { type: 'uint256', value: nonce }// nounce of signer)
          // )

          // var SellerSign = await web3.eth.accounts.sign(SellerMessage, 'b82e2d77efb92996a0042373d924ecd2c161550c672c823bbbc910782c883faf')

          const buyContractInstance = new web3.eth.Contract(TradeAbi, consts.tradeContract)


          console.log(index, 'approve5')


          var payload =
            [
              data?.owner.toLowerCase(),  //seller
              account[0].toLowerCase(),  // buyer
              data?.token.toLowerCase(), //erc20Address
              consts.nftmintContract.toLocaleLowerCase(),  //nft Contract Address
              "1",   //nftType
              web3.utils.toWei(`${data.minimum_bid}`, 'ether'),   //unit price
              true,   //skip royalty
              web3.utils.toWei(String(prices), 'ether'),  //amount
              String(data.min_bid_erc20_token_id), //tokenId
              `${consts.Pinata_url}${data.metadata_hash}`,  //tokenURI
              "1",  //supply
              [1],  //royaltyfee
              [data?.owner.toLowerCase()],   //royaltyreciever
              "1"   //quantity
            ]


          // console.log(account[0], "accounts")
          console.log(data?.sellersign)

          var signParams = JSON.parse(data?.sellersign);
          console.log([signParams?.v, signParams?.r, signParams?.s, String(signParams?.nounce)], "signs")

          console.log(payload, "data")

          const methos = await buyContractInstance.methods.buyAsset(payload, [signParams?.v, signParams?.r, signParams?.s, String(signParams?.nounce)]).send({
            from: account[0]
          }).then(async (result) => {
            console.log(result)
            const datas = await Axios.post("/users/update_collection", {
              TokenId: String(data.min_bid_erc20_token_id),
              trasactionhistory: result,
            }, {
              headers: {
                Authorization: tokens,
              },
            })


              .then(async (res) => {

                if (res?.data?.success) {

                  await Axios.post("/collection/update_collection", {
                    id: data.id,
                    owner: account[0],
                    active: 2
                  })
                  getCollections();
                  toast.success("NFT Transferred Successfully")

                }
                else {
                  toast.error("Error While Transferring NFT")
                  setAudioplays((prev) => ({
                    ...prev,
                    [index]: 0,
                  }));

                }
              }).catch((err) => {
                toast.error("Error While Transferring NFT")
                console.log(err)
                setAudioplays((prev) => ({
                  ...prev,
                  [index]: 0,
                }));
              })
          }).catch((err) => {
            setAudioplays((prev) => ({
              ...prev,
              [index]: 0,
            }));
            toast.error(err?.message)
            console.log(err?.message)
          })
          console.log(methos)

        }).catch((err) => {
          toast.error(err?.message)
          setAudioplays((prev) => ({
            ...prev,
            [index]: 0,
          }));
          console.log(err.message, "Error")
        });
        console.log(index, 'approve3')
        // var nounce = await web3.eth.getTransactionCount(consts.nft_contract_owner_address); // signer Address

        //CONTRACT SIGN

        // var Ownermessage = web3.utils.soliditySha3(
        //   { type: 'address', value: consts.tradeContract }, // Contract Address
        //   { type: 'address', value: consts.nftmintContract }, // NFT Address
        //   { type: 'address', value: account[0] }, // seller Address
        //   { type: 'string', value: data?.image_hash }, // tokenURI
        //   { type: 'uint256', value: nounce }); // nounce of signer

        // var OwnerSign = await web3.eth.accounts.sign(Ownermessage, consts.nft_contract_owner_private) // signer privatekey

        // SELLER SIGN   


        // console.log(methos)

      }
    } catch (error) {
      console.log(error.message);
      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));
      if (error?.message) {
        toast.error(error.message)
      }
    }
  };

  const copyLink = (id) => {
    const message = `${consts.MainUrl}/join/${id}`;
    navigator.clipboard.writeText(message);
    toast.success("Copied Successfully");
    // setCopy(!copy);
  };

  const [copy, setCopy] = useState(false);

  const scrollTop = useRef()


  useEffect(() => {
    scrollTop.current.scrollIntoView()
  }, [])
  return (
    <>
      <ToastContainer position="top-right" />
      <div className="fanzone" sx={{ bgcolor: "#000" }}>
        <Grid container spacing={0}>
          <div className="playerBanner2">
            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.containWidth}>
                <Header />
              </Item>
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="music-player-banner-grid"
            >
              <Item className={classes.containWidth}>
                <div className="backstage-body">
                  <h1 className="player-platform" ref={scrollTop}>
                    Artist's Diary
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <rect
                          width="28"
                          height="28"
                          rx="14"
                          transform="matrix(-1 0 0 1 28 0)"
                          fill="white"
                        />
                        <path
                          d="M13.9993 2.33398C16.3068 2.33398 18.5624 3.01822 20.481 4.30017C22.3996 5.58212 23.8949 7.40421 24.7779 9.53601C25.661 11.6678 25.892 14.0136 25.4418 16.2767C24.9917 18.5398 23.8805 20.6186 22.2489 22.2502C20.6173 23.8818 18.5385 24.993 16.2754 25.4431C14.0123 25.8933 11.6665 25.6623 9.5347 24.7792C7.4029 23.8962 5.58082 22.4009 4.29887 20.4823C3.01692 18.5637 2.33268 16.3081 2.33268 14.0006C2.33268 12.4686 2.63445 10.9515 3.22075 9.53601C3.80705 8.12054 4.66642 6.83442 5.74977 5.75107C6.83312 4.66772 8.11924 3.80836 9.5347 3.22206C10.9502 2.63575 12.4673 2.33398 13.9993 2.33398ZM13.9993 23.334C15.8453 23.334 17.6498 22.7866 19.1847 21.761C20.7195 20.7355 21.9158 19.2778 22.6222 17.5724C23.3286 15.8669 23.5135 13.9903 23.1533 12.1798C22.7932 10.3693 21.9043 8.70628 20.599 7.40099C19.2937 6.0957 17.6307 5.20678 15.8202 4.84666C14.0097 4.48653 12.1331 4.67136 10.4276 5.37778C8.72219 6.08419 7.26452 7.28047 6.23896 8.81533C5.2134 10.3502 4.66601 12.1547 4.66601 14.0006C4.66601 16.476 5.64934 18.85 7.39968 20.6003C9.15002 22.3507 11.524 23.334 13.9993 23.334Z"
                          fill="url(#paint0_linear_1019_2751)"
                        />
                        <path
                          d="M13.9993 10.5013C13.355 10.5013 12.8327 9.97897 12.8327 9.33464C12.8327 8.6903 13.355 8.16797 13.9993 8.16797C14.6437 8.16797 15.166 8.6903 15.166 9.33464C15.166 9.97897 14.6437 10.5013 13.9993 10.5013Z"
                          fill="url(#paint1_linear_1019_2751)"
                        />
                        <path
                          d="M13.9993 11.668C14.3088 11.668 14.6055 11.7909 14.8243 12.0097C15.0431 12.2285 15.166 12.5252 15.166 12.8346V18.668C15.166 18.9774 15.0431 19.2741 14.8243 19.4929C14.6055 19.7117 14.3088 19.8346 13.9993 19.8346C13.6899 19.8346 13.3932 19.7117 13.1744 19.4929C12.9556 19.2741 12.8327 18.9774 12.8327 18.668V12.8346C12.8327 12.5252 12.9556 12.2285 13.1744 12.0097C13.3932 11.7909 13.6899 11.668 13.9993 11.668Z"
                          fill="url(#paint2_linear_1019_2751)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1019_2751"
                            x1="2.74935"
                            y1="23.2975"
                            x2="26.4616"
                            y2="21.4729"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#175AFF" />
                            <stop offset="1" stop-color="#FC5AFF" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_1019_2751"
                            x1="12.8743"
                            y1="10.2643"
                            x2="15.2456"
                            y2="10.0819"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#175AFF" />
                            <stop offset="1" stop-color="#FC5AFF" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_1019_2751"
                            x1="12.8743"
                            y1="19.0052"
                            x2="15.2585"
                            y2="18.9528"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#175AFF" />
                            <stop offset="1" stop-color="#FC5AFF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                  </h1>
                  {/* <div className="player-description">
                    Lorem ipsum dolor sit amet consectetur. Ipsum pellentesque
                    id arcu erat viverra sodales sed aliquam at.
                  </div> */}

                  {/* <div className="subimg">
                                    <img src={subimg} />
                                </div>
                                <div className="brief-instruct">
                                    <ul>
                                        <li>Support the creators you love – your money goes directly to them</li>
                                        <li>Access exclusive content from your favorite creators</li>
                                        <li>Connect and interact with the creators who inspire you</li>
                                    </ul>
                                </div> */}

                  {/* <div className="start-now-offer">
                                    <Button>Join the Backstage pass</Button>
                                </div> */}
                </div>
              </Item>
            </Grid>

            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0} className="backstage-container">
                <Grid xs={12} sm={8} md={6} lg={7} xl={8}>
                  {/* <div className="experience-process">
                                    <div className="process">
                                        <div className="process-main">Empowerment</div>
                                        <h6>Give voice to the artists you love - support your favorite artists through a monthly membership and unlock unique experiences.</h6>
                                    </div>

                                    <div className="process">
                                        <div className="process-main">Access</div>
                                        <h6>Connect with the artists you love in a whole new way - go backstage and see their creative process, get insight into their lives and access special tracks, remixes, performances and benefits.</h6>
                                    </div>

                                    <div className="process">
                                        <div className="process-main">Simplicity</div>
                                        <h6>A totally fluid experience - access the Backstage pass and the Player on one platform, listening and
                                            connecting with your favorite artists in one seamless environment.</h6>
                                    </div>

                                    <div className="process">
                                        <div className="process-main">Fairness</div>
                                        <h6>Pay the artist, tip the server – 85% goes directly to the artist, Dreamster take just 15%, less than a restaurant server.</h6>
                                    </div>
                                </div> */}

                  <Grid container spacing={0} className="backstage-artist-grid">
                    {Artists?.map((row, obj) => {
                      return (
                        <>
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={3}
                            sx={{ padding: "10px" }}
                          >
                            <Card
                              sx={{ maxWidth: 240, margin: "auto" }}
                              className="card-backstage"
                            >
                              <div className="cirle-img" title="green iguana">
                                <img
                                  src={
                                    row?.profilePicture != "" && !row?.profilePicture?.includes("localhost")
                                      ? row?.profilePicture
                                      : c7
                                  }
                                  alt="img"
                                />
                              </div>
                              <CardContent>
                                <div className="love-txt1">
                                  {row?.name != ""
                                    ? row?.name
                                    : `${row?.account?.slice(0, 10)}...`}
                                  {row?.exclusive == "true" ? (
                                    <img src={i3} />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div className="love-txt2">
                                  {row?.TotalFollowers} Plug Ins
                                </div>
                              </CardContent>
                              <div className="explore-more-btn2">
                                <Button
                                  onClick={(e) => {
                                    navigate(`/artistpage/${row?._id}`);
                                  }}
                                >
                                  View
                                </Button>
                              </div>
                            </Card>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>

                  <div className="discover-backstage">
                    {/* <div className="con">Discover the Backstage pass</div> */}
                    <div>
                      <svg
                        width="227"
                        height="81"
                        viewBox="0 0 227 81"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="40.4996"
                          cy="40.4999"
                          r="27.5"
                          transform="rotate(42.1646 40.4996 40.4999)"
                          stroke="url(#paint0_linear_115_14713)"
                          stroke-width="2"
                        />
                        <path
                          d="M226.707 41.7071C227.098 41.3166 227.098 40.6834 226.707 40.2929L220.343 33.9289C219.953 33.5384 219.319 33.5384 218.929 33.9289C218.538 34.3195 218.538 34.9526 218.929 35.3432L224.586 41L218.929 46.6569C218.538 47.0474 218.538 47.6806 218.929 48.0711C219.319 48.4616 219.953 48.4616 220.343 48.0711L226.707 41.7071ZM40 42L226 42L226 40L40 40L40 42Z"
                          fill="#FF0000"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_115_14713"
                            x1="67.9818"
                            y1="63.2108"
                            x2="10.056"
                            y2="58.7535"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#175AFF" />
                            <stop offset="1" stop-color="#FC5AFF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="start-now-offer">
                      <Button
                        onClick={() => {
                          navigate("/exploreartist");
                        }}
                      >
                        Explore Artists
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} sm={8} md={6} lg={5} xl={4}>
                  <div>
                    <h1 className="backstage-pollsurvey">Polls & Survey</h1>
                  </div>
                  <div className="backstage-participate">
                    <div className="participate-desc">
                      Fans can participate in polls and surveys using Dster
                      tokens.
                    </div>
                    <div>
                      {window.localStorage.getItem("role") == "artist" ? (
                        <Button
                          onClick={() => {
                            navigate("/pollshistory");
                          }}
                        >
                          Polls and Survey History
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            navigateTo();
                          }}
                        >
                          Participate
                        </Button>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className="owl-grid"
                sx={{ marginTop: "100px" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Item className={classes.containWidth}>
                    <div className="backstage-exclusive stak">
                      Stack Your Dster
                    </div>
                  </Item>
                </Grid>
                <Grid xs={11} sm={11} md={11} lg={7} xl={7}>
                  <Grid
                    container
                    spacing={0}
                    className="backstage-artist-grid2 new"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="back-staking">
                      Dreamster Staking is a mechanism offered by the
                      decentralized exchange, Dreamster. Dreamster Staking lets
                      investors can earn rewards by staking their Dster (dster)
                      tokens in return for a percentage of trading fees produced
                      on the Dreamster platform. Users of the platform are free
                      to withdraw their staked Dster tokens whenever they
                      choose, without incurring any fees or limits, thanks to
                      the network's flexible staking option.
                    </div>
                    <div className="start-now-offer stak">
                      <Button
                        onClick={() => {
                          navigate("/staking");
                        }}
                      >
                        Stake Now
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>

          <Grid container spacing={0} className="exclusive-grid">
            <div className="playerBanner3">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Item className={classes.containWidth}>
                  <div className="backstage-exclusive">Exclusive Release</div>
                </Item>
              </Grid>
              {/* <Container maxWidth="xl"> */}
              <Grid container spacing={0} className="owl-grid">
                <Grid xs={11} sm={11} md={11} lg={10} xl={9}>
                  <Grid
                    container
                    spacing={0}
                    className="backstage-artist-grid2"
                  >
                    <OwlCarousel
                      className="owl-theme owl-carousel nft"
                      {...options}
                    >
                      {content5.map((row, obj) => {
                        return (
                          <>
                            <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              sx={{ padding: "10px" }}
                            >
                              <Card
                                sx={{ maxWidth: 310, margin: "auto" }}
                                className="card-backstage"
                                onClick={(e) => {
                                  navigate("/premiumtracks", {
                                    state: `${row.txt1}`,
                                  });
                                }}
                              >
                                <div
                                  className="exclusive-img"
                                  title="green iguana"
                                >
                                  <img src={row.img} alt="img" />
                                </div>
                                <div className="earley-access-div">
                                  <Button className="earley-access">
                                    Earley Access
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 9 9"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_1019_2816)">
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M2.11806 8.14623C1.84904 8.14623 1.6178 7.95555 1.56653 7.69148C1.39277 6.7963 1.01221 4.83582 0.885396 4.18249C0.876228 4.13533 0.891801 4.08679 0.926688 4.05376C0.961542 4.02073 1.01086 4.00782 1.05744 4.01951C1.46065 4.12064 2.2924 4.32929 2.2924 4.32929C2.2924 4.32929 2.27554 3.27228 2.26755 2.7704C2.26674 2.71893 2.29415 2.6711 2.33901 2.64582C2.38388 2.62054 2.43899 2.62182 2.48261 2.64919C2.88768 2.90321 3.71251 3.42052 3.71251 3.42052L4.495 1.68555L5.27753 3.42052C5.27753 3.42052 6.10236 2.90321 6.50743 2.64919C6.55105 2.62182 6.60616 2.62054 6.65103 2.64582C6.69586 2.6711 6.7233 2.71893 6.72249 2.7704C6.71446 3.27228 6.69761 4.32929 6.69761 4.32929C6.69761 4.32929 7.52939 4.12064 7.9326 4.01951C7.97915 4.00782 8.0285 4.02073 8.06335 4.05376C8.09821 4.08679 8.11378 4.13533 8.10464 4.18249C7.9778 4.83582 7.59724 6.7963 7.42347 7.69148C7.37224 7.95555 7.14097 8.14623 6.87198 8.14623C5.80516 8.14623 3.18488 8.14623 2.11806 8.14623Z"
                                          fill="#FF9900"
                                        />
                                        <path
                                          d="M4.49345 1.96541C4.80373 1.96541 5.05526 1.71388 5.05526 1.40361C5.05526 1.09333 4.80373 0.841797 4.49345 0.841797C4.18317 0.841797 3.93164 1.09333 3.93164 1.40361C3.93164 1.71388 4.18317 1.96541 4.49345 1.96541Z"
                                          fill="#FFD600"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M7.55214 7.02148L7.4223 7.69035C7.37106 7.95442 7.13979 8.1451 6.87081 8.1451C5.80399 8.1451 3.1837 8.1451 2.11688 8.1451C1.84786 8.1451 1.61663 7.95442 1.56536 7.69035L1.43555 7.02148H7.55214Z"
                                          fill="#CC0FE0"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M4.4952 0.703125C4.10763 0.703125 3.79297 1.01779 3.79297 1.40539C3.79297 1.79297 4.10763 2.10763 4.4952 2.10763C4.88281 2.10763 5.19747 1.79297 5.19747 1.40539C5.19747 1.01779 4.88281 0.703125 4.4952 0.703125ZM4.4952 0.984046C4.72776 0.984046 4.91655 1.17284 4.91655 1.40539C4.91655 1.63795 4.72776 1.82674 4.4952 1.82674C4.26265 1.82674 4.07386 1.63795 4.07386 1.40539C4.07386 1.17284 4.26265 0.984046 4.4952 0.984046Z"
                                          fill="#FF9900"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M2.11787 8.28682C3.18469 8.28682 5.80497 8.28682 6.87179 8.28682C7.20803 8.28682 7.4971 8.04848 7.56118 7.71841L8.24232 4.20942C8.26062 4.11514 8.22947 4.01803 8.15977 3.95196C8.09003 3.88592 7.9914 3.86007 7.89823 3.88343L6.84078 4.14871L6.86272 2.77282C6.86437 2.66981 6.8095 2.57422 6.7198 2.52362C6.63007 2.47306 6.51988 2.47562 6.43261 2.53036L5.32986 3.22195L4.61849 1.90006C4.59401 1.85455 4.54652 1.82617 4.49481 1.82617C4.44314 1.82617 4.39564 1.85455 4.37114 1.90006L3.65977 3.22195L2.55705 2.53036C2.46978 2.47562 2.35959 2.47306 2.26986 2.52362C2.18013 2.57422 2.12528 2.66981 2.12694 2.77282L2.14888 4.14871L1.09143 3.88343C0.998261 3.86007 0.899632 3.88592 0.82989 3.95196C0.760183 4.01803 0.729036 4.11514 0.74734 4.20942L1.42847 7.71841C1.49255 8.04848 1.7816 8.28682 2.11787 8.28682ZM6.58187 2.76834L6.55699 4.32723C6.55628 4.37088 6.57594 4.41237 6.61015 4.43951C6.64436 4.46664 6.68926 4.47632 6.7316 4.4657L7.96655 4.15589C7.83974 4.80922 7.45918 6.7697 7.28542 7.66488C7.24699 7.86291 7.07353 8.00594 6.87179 8.00594H2.11783C1.91609 8.00594 1.74267 7.86291 1.70424 7.66488L1.02307 4.15589C1.42628 4.25705 2.25806 4.4657 2.25806 4.4657C2.3004 4.47632 2.34526 4.46664 2.37947 4.43951C2.41369 4.41237 2.43334 4.37088 2.43267 4.32723L2.40779 2.76834C2.81286 3.02236 3.63769 3.53968 3.63769 3.53968C3.67062 3.56031 3.71063 3.56641 3.74822 3.55646C3.7858 3.54652 3.81759 3.52148 3.83599 3.48723L4.49481 2.26299L5.15367 3.48723C5.17207 3.52148 5.20386 3.54652 5.24144 3.55646C5.27899 3.56641 5.31904 3.56031 5.35194 3.53968L6.58187 2.76834Z"
                                          fill="#FFD600"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M1.54472 7.1637H7.4436C7.52113 7.1637 7.58406 7.10077 7.58406 7.02324C7.58406 6.94574 7.52113 6.88281 7.4436 6.88281H1.54472C1.46723 6.88281 1.4043 6.94574 1.4043 7.02324C1.4043 7.10077 1.46723 7.1637 1.54472 7.1637Z"
                                          fill="#FFD600"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1019_2816">
                                          <rect
                                            width="9"
                                            height="9"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </Button>
                                </div>

                                <CardContent sx={{ paddingTop: "15px" }}>
                                  <div className="er-txt1">
                                    {/* {row.txt1} <img src={i3} />
                                     */}
                                    {row.txt1}
                                  </div>
                                  <div className="er-description">
                                    {/* {row.txt2} */}
                                    <div className="ed-detail">
                                      Event Description
                                    </div>
                                    <div className="er-timing">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_1019_2837)">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M9.50078 1.66328C5.17225 1.66328 1.66328 5.17225 1.66328 9.50078C1.66328 13.8293 5.17225 17.3383 9.50078 17.3383C13.8293 17.3383 17.3383 13.8293 17.3383 9.50078C17.3383 5.17225 13.8293 1.66328 9.50078 1.66328ZM0.238281 9.50078C0.238281 4.38524 4.38524 0.238281 9.50078 0.238281C14.6163 0.238281 18.7633 4.38524 18.7633 9.50078C18.7633 14.6163 14.6163 18.7633 9.50078 18.7633C4.38524 18.7633 0.238281 14.6163 0.238281 9.50078ZM9.50078 4.98828C9.89427 4.98828 10.2133 5.30728 10.2133 5.70078V9.20562L12.8546 11.847C13.1328 12.1253 13.1328 12.5763 12.8546 12.8546C12.5763 13.1328 12.1253 13.1328 11.847 12.8546L8.997 10.0046C8.86333 9.871 8.78828 9.68974 8.78828 9.50078V5.70078C8.78828 5.30728 9.10729 4.98828 9.50078 4.98828Z"
                                            fill="#B5BBCC"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1019_2837">
                                            <rect
                                              width="19"
                                              height="19"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      24-01-2024
                                    </div>
                                  </div>
                                  {/* 
                                                                    <div className="explore-more-btn2">
                                                                        <Button>direct MESSAGE</Button>
                                                                    </div> */}
                                </CardContent>
                              </Card>
                            </Grid>
                          </>
                        );
                      })}
                    </OwlCarousel>
                  </Grid>

                  <div className="discover-backstage exclusive">
                    {/* <div className="con">Discover the Backstage pass</div> */}
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="188"
                        height="104"
                        viewBox="0 0 188 104"
                        fill="none"
                      >
                        <circle
                          cx="94.7138"
                          cy="51.6004"
                          r="35.5317"
                          transform="rotate(42.1646 94.7138 51.6004)"
                          stroke="url(#paint0_linear_1019_2983)"
                          stroke-width="2"
                        />
                        <path
                          d="M0.292893 59.287C-0.0976311 59.6776 -0.0976311 60.3107 0.292893 60.7012L6.65685 67.0652C7.04738 67.4557 7.68054 67.4557 8.07107 67.0652C8.46159 66.6747 8.46159 66.0415 8.07107 65.651L2.41421 59.9941L8.07107 54.3373C8.46159 53.9468 8.46159 53.3136 8.07107 52.9231C7.68054 52.5325 7.04738 52.5325 6.65685 52.9231L0.292893 59.287ZM187.707 60.7013C188.098 60.3107 188.098 59.6776 187.707 59.2871L181.343 52.9231C180.953 52.5326 180.319 52.5326 179.929 52.9231C179.538 53.3136 179.538 53.9468 179.929 54.3373L185.586 59.9942L179.929 65.651C179.538 66.0415 179.538 66.6747 179.929 67.0652C180.319 67.4557 180.953 67.4557 181.343 67.0652L187.707 60.7013ZM1 60.9941L187 60.9942L187 58.9942L1 58.9941L1 60.9941Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1019_2983"
                            x1="129.941"
                            y1="80.7116"
                            x2="55.6908"
                            y2="74.9981"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#175AFF" />
                            <stop offset="1" stop-color="#FC5AFF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="exclusive-scroll">Scroll</div>
                  </div>
                </Grid>
              </Grid>

              {/* </Container> */}
            </div>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Item className={classes.containWidth}>
                <div className="backstage-nft">NFT Corner</div>
              </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box sx={{ width: "100%" }} className="nft-corner-tab">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example nft"
                    className="nft-tab-style"
                  >
                    <Tab label="Buy" {...a11yProps(0)} />
                    <Tab label="Sell" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="participate-card">
                    <Grid
                      container
                      spacing={0}
                      className=""
                      sx={{ justifyContent: "center" }}
                    >
                      {collection?.map((obj, index) => {
                        return (
                          <Grid item xs={12} sm={8} md={5} xl={3} lg={4}>
                            <Card className="card-participate">
                              <div className="img-card bckstage-card-img">
                                <img
                                  src={
                                    obj?.image_hash === null
                                      ? Cards12
                                      : `${consts.IPFS}${obj?.image_hash}`
                                  }
                                />
                              </div>
                              <div className="card-body">
                                <div className="card-title">
                                  <p>{obj?.name}</p>
                                </div>
                                <div className="toggle-icon">
                                  {/* <ListIcon /> */}
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="card-title">
                                  <p>
                                    {obj?.created_at
                                      ? new Date(obj.created_at).toLocaleString(
                                        undefined,
                                        {
                                          'hour12': true
                                        }
                                      )
                                      : "No date available"}
                                  </p>
                                </div>
                                {/* <div className="toggle-icon">
                                  <img src={icon} />
                                </div> */}
                              </div>

                              {audioplays[index] == 1 ?
                                <div className="join-btn">
                                  <div className="join-icon">
                                    <img src={join} />
                                  </div>
                                  <Link>Processing ...</Link>
                                </div> :
                                <div className="join-btn" onClick={() => { signin(obj, index) }}>
                                  <div className="join-icon">
                                    <img src={join} />
                                  </div>
                                  <Link>Buy</Link>


                                </div>}
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div className="participate-card">
                    <Grid
                      container
                      spacing={0}
                      className=""
                      sx={{ justifyContent: "center" }}
                    >
                      {collection?.map((obj, ind) => {
                        return (
                          <Grid item xs={12} sm={8} md={5} xl={3} lg={4}>
                            <Card className="card-participate">
                              <div className="img-card bckstage-card-img">
                                <img
                                  src={
                                    obj?.image_hash === null
                                      ? Cards12
                                      : `https://ipfs.io/ipfs/${obj?.image_hash}`
                                  }
                                />
                              </div>
                              <div className="card-body">
                                <div className="card-title">
                                  <p>{obj?.name}</p>
                                  {/* <p>mainnet</p> */}
                                </div>
                                <div className="toggle-icon">
                                  <ListIcon />
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="card-title">
                                  <p>
                                    {obj?.created_at
                                      ? new Date(obj.created_at).toLocaleString(
                                        undefined,
                                        {
                                          'hour12': true
                                        }
                                      )
                                      : "No date available"}
                                  </p>
                                </div>
                                <div className="toggle-icon">
                                  <img src={icon} />
                                </div>
                              </div>
                              <div className="join-btn">
                                <div className="join-icon">
                                  <img src={join} />
                                </div>
                                <Link to="#">Sell</Link>  {/* {obj?.instant_sale_price} */}
                              </div>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </CustomTabPanel>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <BackStageFanZone />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={0} className="calender-grid-cont">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={2}
                className="selected-date-grid"
              >
                <div className="date-calender-whole-div">
                  <div className="selected-date">
                    <span className="date">{new Date().getDate()}</span>
                    <span className="month">
                      {monthNames[new Date().getMonth()]}
                      <div>{new Date().getFullYear()}</div>
                    </span>
                  </div>

                  <div className="today">Today</div>
                  {events?.length > 0 ? (
                    <>
                      {events?.map((data, index) => (
                        <div className="today-inner-cont" key={index}>
                          <div className="inner-cont2">
                            <div className="inner-cont3">
                              <img src={dt} alt="dt" />
                              <div className="calander-song-genre">
                                {data?.track_Name}
                                <div className="country">
                                  {data?.description}
                                </div>
                                <div className="calender-timing">
                                  <div className="calender-date-n-time">
                                    {data?.EventDate?.split(" ")[0]}
                                  </div>
                                  <div className="calender-date-n-time">
                                    {data?.EventDate?.split(" ")[1]}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              onClick={() => {
                                testLive(data?._id);
                                window.localStorage.setItem("Playing", "false");
                              }}
                            >
                              <Button className="join">Join</Button>
                            </div>

                            <div className="copy">
                              <SendProfile
                                handleModelCloseprofile={handleModelCloseprofile}
                                handleOpenprofile={handleOpenprofile}
                                openModelprofile={openModelprofile}
                                id={`/join/${data?.room_id}`}
                              />

                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="newno">
                      <img src={nodata2} />
                    </div>
                  )}

                  <div className="today">Upcoming</div>
                  {events_tom?.length > 0 ? (
                    <>
                      {events_tom?.map((data, index) => (
                        <div className="today-inner-cont" key={index}>
                          <div className="inner-cont2">
                            <div className="inner-cont3">
                              <img src={dt} alt="dt" />
                              <div className="calander-song-genre">
                                {data?.track_Name}
                                <div className="country">
                                  {data?.description}
                                </div>
                                <div className="calender-timing">
                                  <div className="calender-date-n-time">
                                    {data?.EventDate?.split(" ")[0]}
                                  </div>
                                  <div className="calender-date-n-time">
                                    {data?.EventDate?.split(" ")[1]}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              onClick={() => {
                                testLive(data?._id);
                                window.localStorage.setItem("Playing", "false");
                              }}
                            >
                              <Button className="join">Join</Button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="newno">
                      <img src={nodata2} />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                <CalenderNew
                  events={details}
                  events_tom={events_tom}
                  tmrs={tmrs}
                  todays={todays}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <Footer />

          </Grid>
        </Grid>
      </div >
    </>
  );
}

export default BackStagePass;
