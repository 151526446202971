import React from "react";
import ava1 from "../../img/dster-webp/ava1.webp";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import Axios from "../../Axios";
import '../Upload-Music/UploadMusic.css'
import Container from '@mui/material/Container'
import nodata from '../../img/dster-webp/newnodata.gif'
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import '../Hoisting/Hoisting.css'
import { useState, useEffect, useRef } from "react";
import '../CreateVirtualMeet/CreateVirtualMeet.css'
import '../Colabration/Colabration.css'
import './PollsSurvey.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import HeaderNew from "../HeaderNew/HeaderNew";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Chip from '@mui/material/Chip';


function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const drawerWidth = 280;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'var(--clr-bcg)',
    // border: '2px solid #000',
    borderRadius: '10px',
    overflowY: 'scroll',
    height: '700px',
    boxShadow: 24,
    p: 4,
    '@media(max-width:575.98px)': {
        width: '80%',
        overflowY: 'scroll'
    },

};

function PollsHistory() {
    const theme = useTheme();
    const [validate, setValidate] = useState('')

    const [progress, setProgress] = React.useState(70);
    const [progress2, setProgress2] = React.useState(30);

    const [err, setErr] = useState(null)
    // history artist

    const navigate = useNavigate()
    const [polls, setPolls] = useState([]);
    const [poll, setPoll] = useState(false);

    const handleClosePoll = () => setPoll(false);
    const [surveys, setSurveys] = useState([])
    const [survey, setSurvey] = useState(false)
    const [onesurvey, setOnesurvey] = useState({})
    const [onepoll, setOnepoll] = useState({})


    const handleCloseSurbey = () => setSurvey(false);
    const tokens = localStorage.getItem("iphephile");
    const role = localStorage.getItem("role");


    const inputRef = useRef(null)


    const [message, setMessage] = useState([]);

    const [updated, setUpdated] = useState('');

    const handleOpenPoll = (row) => {

        setPoll(true);
        setOnepoll(row)

    }

    const handleOpenSurvey = (row) => {

        setSurvey(true)
        setOnesurvey(row)
    }



    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };


    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };

    const location = useLocation()
    useEffect(() => {



        if (location?.state) {
            setValue(location?.state?.value)
        }
        else {
            setValue(0)
        }

    }, [])



    const [title, setTitle] = useState('polls History')

    const [divs, setDivs] = useState([{ id: 1 }]);

    const addDiv = () => {
        const newDiv = { id: divs.length + 1 };
        setDivs([...divs, newDiv]);
    }


    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChangeCheck = (event) => {
        setSelectedValue(event.target.value);
    };

    const controlProps = (item, index) => ({

        checked: item === Number(index),
        // onChange: handleChangeCheck,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });


    const isMounted = useRef(false)

    useEffect(() => {

        if (!isMounted.current) {
            getPolls()
            getSurveys()
            isMounted.current = true
        } else {
        }

    }, [])
    //to get Polls history

    const getPolls = async () => {

        try {

            const datas = await Axios.post("/poll/get_poll", {}, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        setPolls(res?.data?.result)

                    }
                    else {
                        setPolls([])

                    }
                }).catch((err) => {
                    // console.log(err,"err")
                    setPolls([])

                })

        } catch (error) {
            console.log(error, "error");
            setPolls([])


        }

    }

    const getSurveys = async () => {

        try {

            const datas = await Axios.post("/poll/get_survey", {}, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        setSurveys(res?.data?.result)

                    }
                    else {
                        setSurveys([])

                    }
                }).catch((err) => {
                    // console.log(err,"err")
                    setSurveys([])

                })

        } catch (error) {
            console.log(error, "error");
            setSurveys([])


        }

    }

    const getValue = (a, b) => {
        const value = (b / a) * 100
        // console.log(value, "val")
        return value && !isNaN(value) && value !== "Infinity" ? value : 0
    }





    return (

        <div className="Hoisting explore dashboard">
            {/* <SnackBar /> */}
            <Box sx={{ display: 'flex' }} className='display-block'>

                <HeaderNew title={title} />
                <Box component="main"
                    className="Hoisting-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    <Grid container spacing={0} >
                        <Container maxWidth="xl">
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="poll-head-tab">

                                    <Box sx={{ width: '100%' }} className='nft-corner-tab'>
                                        <Box sx={{ borderColor: 'divider' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example nft" className="nft-tab-style polls">
                                                <Tab label="Poll" {...a11yProps(0)} />
                                                <Tab label="Survey" {...a11yProps(1)} />
                                            </Tabs>
                                        </Box>
                                    </Box>
                                </div>

                            </Grid>
                        </Container>
                    </Grid>
                    <CustomTabPanel value={value} index={0} className='custom-panel'>
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, p: 0 }}
                            className="UploadMusic-box3 create-exclusive"
                        >
                            <Container maxWidth="xl" >
                                <Grid container spacing={2} >
                                    {polls?.length > 0 ? <>{polls && polls?.map((row, index) => {
                                        return (
                                            <Grid xs={12} sm={12} md={12} lg={9} xl={6} sx={{ p: 1 }}>
                                                <div className="div-dropzone-1mxhs-1 history new" onClick={() => { handleOpenPoll(row) }}>
                                                    <div className="designer-info-frame-parent ">
                                                        <img
                                                            className="designer-info-frame"
                                                            loading="eager"
                                                            alt=""
                                                            src={row?.Artist?.profilePicture && !row?.Artist?.profilePicture?.includes('localhost') ? row?.Artist?.profilePicture : ava1}
                                                        />
                                                        <div className="eatsleepdesignrepeat">
                                                            <div className="kiiara-castillo-parent">
                                                                <div className="kiiara-castillo">{row?.Artist?.name}</div>
                                                                <div className="eatsleepdesignrepeat1">
                                                                    @{row?.Artist?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="histry-title">
                                                        <strong>Title:</strong> {row?.question ? row?.question : "-"}
                                                    </div>
                                                    <div className="display-flex">
                                                        <div className="progress-yes">
                                                            <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalYes))} /><div className="f-r-a-m-e">{row?.answers[0] ? row?.answers[0]?.toUpperCase() : "-"}</div>
                                                        </div>
                                                        <div className="progress-yes">
                                                            <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalNo))} /><div className="f-r-a-m-e">{row?.answers[1] ? row?.answers[1]?.toUpperCase() : "-"}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Grid>
                                        )
                                    })}</> :
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='feed-music-box'>
                                                <div className='music-detail'>


                                                    <div className='album-detail'>

                                                        <div className='no-music'>
                                                            <div className='time-music'>
                                                                <div className='newno'>
                                                                    <img src={nodata} />
                                                                </div>

                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>}

                                </Grid>
                            </Container>


                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, p: 0 }}
                            className="UploadMusic-box3 create-exclusive"
                        >
                            <Container maxWidth="xl" >
                                <Grid container spacing={2} >
                                    {surveys?.length > 0 ? <>{surveys && surveys?.map((row, index) => {
                                        return (
                                            <Grid xs={12} sm={12} md={12} lg={7} xl={6} sx={{ p: 1 }}>
                                                <div className="div-dropzone-1mxhs-1 history" onClick={() => { handleOpenSurvey(row) }}>
                                                    <div className="designer-info-frame-parent ">
                                                        <img
                                                            className="designer-info-frame"
                                                            loading="eager"
                                                            alt=""
                                                            src={row?.Artist?.profilePicture && !row?.Artist?.profilePicture?.includes('localhost') ? row?.Artist?.profilePicture : ava1}
                                                        />
                                                        <div className="eatsleepdesignrepeat">
                                                            <div className="kiiara-castillo-parent">
                                                                <div className="kiiara-castillo">{row?.Artist?.name}</div>
                                                                <div className="eatsleepdesignrepeat1">
                                                                    @{row?.Artist?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="histry-title">
                                                        <strong>Title:</strong> {row?.title ? row?.title : "-"}
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalFollowers), Number(row?.TotalUser))} /><div className="f-r-a-m-e">User Participated in this Survey</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <div className="f-r-a-m-e">Number Of Questions : {row?.TotalSurvey ? row?.TotalSurvey : '0'}</div>
                                                    </div>

                                                    {/* <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalStrongagree))} /><div className="f-r-a-m-e">Strongly Agree</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalAgree))} /><div className="f-r-a-m-e">Agree</div>
                                                    </div>

                                                    {/* <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalStrongagree))} /><div className="f-r-a-m-e">Strongly Agree</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalAgree))} /><div className="f-r-a-m-e">Agree</div>
                                                    </div>

                                                    {/* <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalStrongagree))} /><div className="f-r-a-m-e">Strongly Agree</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalAgree))} /><div className="f-r-a-m-e">Agree</div>
                                                    </div>

                                                    {/* <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalStrongagree))} /><div className="f-r-a-m-e">Strongly Agree</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalAgree))} /><div className="f-r-a-m-e">Agree</div>
                                                    </div>

                                                    {/* <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalStrongagree))} /><div className="f-r-a-m-e">Strongly Agree</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalAgree))} /><div className="f-r-a-m-e">Agree</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalDisagree))} /><div className="f-r-a-m-e">Disagree</div>
                                                    </div> */}
                                                </div>
                                            </Grid>
                                        )
                                    })}</> :
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='feed-music-box'>
                                                <div className='music-detail'>


                                                    <div className='album-detail'>

                                                        <div className='no-music'>
                                                            <div className='time-music'>
                                                                <div className='newno'>
                                                                    <img src={nodata} />
                                                                </div>

                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>}

                                </Grid>
                            </Container>


                        </Box>

                    </CustomTabPanel>
                </Box>
                <Modal
                    open={poll}
                    onClose={handleClosePoll}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="poll-model"
                >
                    <Box sx={style}>
                        <div className="back-icon-survey-hist">
                            <CancelTwoToneIcon onClick={handleClosePoll} />
                        </div>


                        {role === "artist" ?
                            <>
                                <div className="histry-title" style={{ "color": "black" }}>
                                    <strong>Title:</strong> {onepoll?.question ? onepoll?.question : "-"}
                                </div>
                                <div className="histry-title" style={{ "color": "black" }}>
                                    <strong>End Date:</strong> {onepoll?.end_date ? onepoll?.end_date : "-"}
                                </div>
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer sx={{ maxHeight: 440 }} className="polls-table" >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Total Followers</TableCell>
                                                    <TableCell align="center">No.of Followers Participated In this Poll</TableCell>
                                                    <TableCell align="center">No.of Agree</TableCell>
                                                    <TableCell align="center">No.of Disagree</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                <TableRow

                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                    <TableCell align="center">{onepoll?.TotalFollowers}</TableCell>
                                                    <TableCell align="center">{onepoll?.TotalUser}</TableCell>
                                                    <TableCell align="center">{onepoll?.TotalYes}</TableCell>
                                                    <TableCell align="center">{onepoll?.TotalNo}</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                <div className="chips">
                                    <Chip label={onepoll?.answers?.length > 0 ? `Answer : ${onepoll?.answers[0]}` : "Agree"} />
                                </div>
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer sx={{ maxHeight: 440 }} className="polls-table">
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Index</TableCell>
                                                    <TableCell align="center">Name</TableCell>
                                                    <TableCell align="center">Role</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            {onepoll?.Yes?.length > 0 ? <TableBody>
                                                {onepoll?.Yes?.map((row, index) => (
                                                    <TableRow
                                                        key={row._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center">{index + 1}</TableCell>

                                                        <TableCell align="center">
                                                            <div className="display-flex" style={{ justifyContent: 'center' }}>
                                                                <img
                                                                    className="designer-info-frame"
                                                                    loading="eager"
                                                                    alt=""
                                                                    src={row?.profilePicture && !row?.profilePicture?.includes('localhost') ? row?.profilePicture : ava1}
                                                                />
                                                                {row.name}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="center">{row.role}</TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody> : <>
                                                <TableBody>
                                                    <TableRow
                                                        // key={row._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center" colSpan={5}>
                                                            <div className="newno">
                                                                <img src={nodata} />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>

                                            </>}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                <div className="chips">
                                    <Chip label={onepoll?.answers?.length > 0 ? `Answer : ${onepoll?.answers[1]}` : "Disagree"} />
                                </div>
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                    <TableContainer sx={{ maxHeight: 440 }} className="polls-table">
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Index</TableCell>
                                                    <TableCell align="center">Name</TableCell>
                                                    <TableCell align="center">Role</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            {onepoll?.No?.length > 0 ? <TableBody>
                                                {onepoll?.No?.map((row, index) => (
                                                    <TableRow
                                                        key={row._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center">{index + 1}</TableCell>

                                                        <TableCell align="center">
                                                            <div className="display-flex" style={{ justifyContent: 'center' }}>
                                                                <img
                                                                    className="designer-info-frame"
                                                                    loading="eager"
                                                                    alt=""
                                                                    src={row?.profilePicture && !row?.profilePicture?.includes('localhost') ? row?.profilePicture : ava1}
                                                                />
                                                                {row.name}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="center">{row.role}</TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody> : <>
                                                <TableBody>
                                                    <TableRow
                                                        // key={row._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center" colSpan={5}>
                                                            <div className="newno">
                                                                <img src={nodata} />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </>}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </>
                            :
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }} className="polls-table">
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Total Followers</TableCell>
                                                <TableCell align="center">No.of Followers Participated In this Poll</TableCell>
                                                <TableCell align="center">No.of Agree</TableCell>
                                                <TableCell align="center">No.of Disagree</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow

                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >

                                                <TableCell align="center">{onepoll?.TotalFollowers}</TableCell>
                                                <TableCell align="center">{onepoll?.TotalUser}</TableCell>
                                                <TableCell align="center">{onepoll?.TotalYes}</TableCell>
                                                <TableCell align="center">{onepoll?.TotalNo}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        }




                    </Box>
                </Modal>
                <Modal
                    open={survey}

                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="back-icon-survey-hist">
                            <CancelTwoToneIcon onClick={handleCloseSurbey} />
                        </div>
                        {role === "artist" ? <div className="survey-questions">
                            {onesurvey?.Survey?.map((row, ind) => {
                                return (
                                    <div key={ind} className="div-dropzone-1mxhs-1 history survey-attc">
                                        <div className="histry-title"><span>{ind + 1}</span>
                                            {row?.question}
                                        </div>
                                        <div className="survey-check-main">
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                >
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalStrongagree))} /><div className="f-r-a-m-e">Strongly Agree</div>

                                                    </div>
                                                    <div className="progress-yes">

                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalAgree))} /><div className="f-r-a-m-e">Agree</div>
                                                    </div>
                                                    <div className="progress-yes">
                                                        <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalDisagree))} /><div className="f-r-a-m-e">Disagree</div>
                                                    </div>
                                                </RadioGroup>
                                            </FormControl>


                                        </div>
                                    </div>
                                )

                            })}

                        </div> :
                            <div className="survey-questions">
                                {onesurvey?.Survey?.map((row, ind) => {
                                    return (
                                        <div key={ind} className="div-dropzone-1mxhs-1 history survey-attc">
                                            <div className="histry-title"><span>{ind + 1}</span>
                                                {row?.question}
                                            </div>
                                            <div className="survey-check-main">
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                    >
                                                        {row?.isStrongagree === true ? <> <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalStrongagree))} /><div className="f-r-a-m-e">Strongly Agree</div> </> : <></>}

                                                        {/* <FormControlLabel control={<Radio {...controlProps(onesurvey?.isStrongagree, ind)} color="success" />} label="Strongly Agree" /> */}


                                                        {row?.isAgree === true ? <> <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalAgree))} /><div className="f-r-a-m-e">Agree</div> </> : <></>}

                                                        {/* <FormControlLabel control={<Radio {...controlProps(onesurvey?.isAgree, ind)} color="secondary" />} label="Agree" /> */}


                                                        {row?.isdisAgree === true ? <> <CircularProgressWithLabel value={getValue(Number(row?.TotalUser), Number(row?.TotalDisagree))} /><div className="f-r-a-m-e">Disagree</div> </> : <></>}

                                                        {/* <FormControlLabel control={<Radio {...controlProps(onesurvey?.isDisagree, ind)} sx={{ color: pink[800], '&.Mui-checked': { color: pink[600], }, }} />} label="Disagree" /> */}


                                                    </RadioGroup>
                                                </FormControl>


                                            </div>
                                        </div>
                                    )

                                })}

                            </div>}


                    </Box>
                </Modal>
            </Box>
        </div>
    );
}

export default PollsHistory;