import React, { useState } from 'react'
import { Grid } from '@mui/material'
import Web3 from 'web3';
import BalanceABI from '../../Web3/balanceAbi.json'
import balanceAddress from '../../Web3/balanceAddress'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header/Header'
import sp1 from '../../img/dster-webp/sp1.webp'
import sp2 from '../../img/dster-webp/sp2.webp'
import sp3 from '../../img/dster-webp/sp3.webp'
import sp4 from '../../img/dster-webp/sp4.webp'
import sp5 from '../../img/dster-webp/sp5.webp'
import sp6 from '../../img/dster-webp/sp6.webp'
import i from '../../img/dster-webp/i.webp'
import es1 from '../../img/dster-webp/es1.webp'
import es2 from '../../img/dster-webp/es2.webp'
import es3 from '../../img/dster-webp/es3.webp'
import { Button } from '@mui/material'

const key = [
    {
        title: "Exclusive Access",
        desc: "Enjoy content and experiences that are reserved only for dedicated fans who hold Dreamster tokens",
        img: `${es1}`
    },
    {
        title: "Direct Interacton",
        desc: "Engage directly with your favorite artists, creating memorable and personal connections.",
        img: `${es2}`
    },
    {
        title: "Unique Rewards",
        desc: " Benefit from exclusive merchandise, NFTs, and early access to new music, making your fan experience truly special.",
        img: `${es3}`
    }
];

function SupportPage() {

    const navigate = useNavigate();
    const [balance, setBalance] = useState(false)
    const tokens = localStorage.getItem("iphephile");


    const [feat, setFeat] = useState([
        { title: 'Live Virtual Face-to-Face Meetngs with Artsts', desc: 'Participate in live virtual events where you can interact with your favorite musicians face-to-face. Ask questions, share your thoughts, and enjoy a truly personal experience.', img: `${sp1}` },
        { title: 'Access to Unreleased or Exclusive Music', desc: 'Get early access to unreleased tracks or exclusive music that isn’t available to the general public. Be the first to hear new songs and special editions.', img: `${sp2}` },
        { title: 'Exclusive Merchandise and NFTs', desc: 'Gain access to limited edition merchandise and unique NFTs that are only available to Backstage Pass holders. Show your support with exclusive gear and collecGbles.', img: `${sp3}` },

        { title: 'Artst Diaries', desc: 'Dive into the personal world of your favorite artists by accessing their diaries. Read about their thoughts, inspirations, and daily activities, offering a behindthe-scenes look at their lives.', img: `${sp4}` },
        { title: 'Partcipaton in Polls', desc: 'Have a say in your favorite artists decisions by participating in polls. Vote on song choices, album covers, tour locations, and more, helping shape the artist’s creaGve journey.', img: `${sp5}` },
        { title: 'Stake Dreamster Tokens', desc: 'This is where you can lock your Dreamster tokens to earn more for periods of 30, 60, 90 days and more. Annual percentage will change based on market prices.', img: `${sp6}` },
    ])

    const navigateToBack = async () => {
        try {
            if (tokens) {
                if (window.ethereum) {
                    const web3Instance = new Web3(window.ethereum);
                    const browserChainId = await web3Instance.eth.getChainId()

                    await window.ethereum.enable();
                    // setWeb3(web3Instance);

                    await window.ethereum.request({
                        method: "eth_requestAccounts",
                    });
                    const accounts = await web3Instance.eth.getAccounts();
                    if (Number(browserChainId) === 56) {
                        const contractInstance = new web3Instance.eth.Contract(
                            BalanceABI,
                            balanceAddress
                        );
                        // walletbalance - >  const balance = await contractInstance.methods.balanceOf(accounts[0]).call()
                        const balances = await contractInstance.methods.balanceOf(accounts[0]).call()
                        var bal = Number(balances)
                        console.log(balances)
                        if (bal >= 0) {
                            navigate('/backstagepass')
                        }
                        else {
                            toast.error("Insufficient Fund In Dster Token(BSC Network)")
                            setBalance(false)
                        }



                        //  return bal
                    }
                    else {
                        onChangeNetwork(56)
                    }


                }
            }
            else {
                // toast.error("Please select")
                navigate('/backstagepass')
            }
        } catch (err) {
            console.log(err)
        }


    };

    const navigateToLogin = () => {
        const stateData = { login: 3 };
        navigate(0)
    };

    const onChangeNetwork = async (chainIds) => {
        try {
            const WEB = new Web3(window.ethereum);

            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: WEB.utils.toHex(chainIds) }]
            });
            navigateToBack()

        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                var data = {
                    "name": "BNB Smart Chain Mainnet",
                    "chain": "BSC",
                    "rpc": [
                        "https://bsc-dataseed1.bnbchain.org",
                        "https://bsc-dataseed2.bnbchain.org",
                        "https://bsc-dataseed3.bnbchain.org",
                        "https://bsc-dataseed4.bnbchain.org",
                        "https://bsc-dataseed1.defibit.io",
                        "https://bsc-dataseed2.defibit.io",
                        "https://bsc-dataseed3.defibit.io",
                        "https://bsc-dataseed4.defibit.io",
                        "https://bsc-dataseed1.ninicoin.io",
                        "https://bsc-dataseed2.ninicoin.io",
                        "https://bsc-dataseed3.ninicoin.io",
                        "https://bsc-dataseed4.ninicoin.io",
                        "https://bsc.publicnode.com",
                        "wss://bsc.publicnode.com",
                        "wss://bsc-ws-node.nariox.org"
                    ],
                    "faucets": [],
                    "nativeCurrency": {
                        "name": "BNB Chain Native Token",
                        "symbol": "BNB",
                        "decimals": 18
                    },
                    "infoURL": "https://www.bnbchain.org/en",
                    "shortName": "bnb",
                    "chainId": 56,
                    "networkId": 56,
                    "slip44": 714,
                    "explorers": [
                        {
                            "name": "bscscan",
                            "url": "https://bscscan.com",
                            "standard": "EIP3091"
                        }
                    ]
                }


                // console.log(chainLists1, "chainlist")
                const WEB = new Web3(window.ethereum);
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: data?.chain,
                            chainId: WEB.utils.toHex(chainIds),
                            nativeCurrency: data?.nativeCurrency,
                            rpcUrls: [data?.rpc[0]]
                        }
                    ]
                });
            }
            else if (err.code === 4001) {
                navigate(0)
            }
            else {
                console.log(err)
            }
        }
    }
    return (
        <div className='Supportpage'>
            <Grid container spacing={0}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='support-banner'>
                        <div className='support-flex'>
                            <div className='bck-pass'>
                                Backstage Pass
                            </div>
                            <div className='bck-desc'>
                                is designed to bring fans closer to their favorite artists like never before. By holding Dreamster tokens, you can unlock a world of unique experiences and exclusive content. Here's how it works:
                            </div>
                            <div className='how-access'>
                                How to Access the Backstage Pass Features
                            </div>
                            <div className='bck-desc'>
                                To take full advantage of the Backstage Pass, you need to hold Dreamster tokens in your MetaMask wallet. We are also working on integrating the Dreamster Wallet, which will provide even more seamless access in the near future.
                            </div>
                        </div>

                    </div>
                    <Grid container spacing={0} style={{ justifyContent: 'center' }}>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                            <div className='exciting-bck'>
                                Exciting Backstage Pass Features
                            </div>
                            {feat.map((row, ind) => {
                                return (
                                    <div className='support-feat'>
                                        <div className='feat-title'>
                                            {row.title}
                                        </div>
                                        <div className='feat-desc'>
                                            {row.desc}
                                        </div>
                                        <div className='feat-img'>
                                            <img src={row.img} />
                                        </div>
                                    </div>
                                )

                            })}


                        </Grid>
                    </Grid>

                    <Grid container spacing={0} className="feat-cont">
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='how-tostart'>
                                How to Get Started
                            </div>
                        </Grid>
                        {key.map((row, ind) => {
                            return (
                                <Grid xs={12} sm={12} md={6} lg={6} xl={4}>
                                    <div className="key-box">
                                        <div className="key-num-div">
                                            <div className="key-num">
                                                {(ind + 1).toString().padStart(2, "0")}
                                            </div>
                                        </div>
                                        <div className="key-icon">{row.title}</div>
                                        <div className="key-desc">{row.desc}</div>
                                        <div className='es-img'>
                                            <img src={row.img} />
                                        </div>
                                        <div className="bottom-color"></div>
                                    </div>
                                </Grid>
                            );
                        })}
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='i-img'>
                                <img src={i} />
                                <div className='i-desc'>
                                    Join the Dreamster community today and elevate your music experience with the Backstage Pass! Get closer to your favorite artists and enjoy a range of exclusive perks by holding Dreamster tokens.
                                    <div className='support-btn'>
                                        <Button onClick={() => { navigateToBack() }}>
                                            Explore it
                                        </Button>
                                    </div>
                                </div>

                            </div>

                        </Grid>
                    </Grid>


                </Grid>

            </Grid>
        </div>
    )
}

export default SupportPage
