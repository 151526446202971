import React, { useRef, useState } from "react";
import BalanceABI from "../../Web3/balanceAbi.json";
import balanceAddress from "../../Web3/balanceAddress";
import Web3 from "web3";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../Explore/Explore.css";
import "./Notify.css";
import Axios from "../../Axios";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SidebarNew from '../SidebarNew/SidebarNew'


const drawerWidth = 280;

const title = "Welcome";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "15px",
  "@media(max-width:767.98px)": {
    width: "95%",
  },
};

function Notify() {
  const theme = useTheme();
  const [prf, setPrf] = React.useState(false);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState();
  const requestAcc = useRef(true);
  const [metaAccount, setMetaAccount] = useState();
  const [balance, setBalance] = useState();
  const tokens = localStorage.getItem("iphephile");
  const [name, setName] = React.useState();

  const handleChange1 = (event, value) => {
    setPage(value);
  };

  const [rows, setRows] = useState();

  const navigate = useNavigate();

  const getMyNotifications = async (datas) => {
    try {
      const { data } = await Axios.post(
        "/notification/get_my_notification",
        {
          type: datas,
          skip: limit * (page - 1),
          limit: limit,
        },
        {
          headers: { Authorization: tokens },
        }
      );
      if (data?.success == true) {
        setRows(data?.result);
        setCount(Math.ceil(data?.count / limit));
      } else {
        setRows([]);
      }
    } catch (error) {
      console.log("🚀 ~ getMyNotifications ~ error:", error);
    }
  };

  const [age, setAge] = React.useState(10);
  const [age1, setAge1] = React.useState(false);

  React.useEffect(() => {
    if (tokens) {
      if (age == 10) {
        getMyNotifications("All");
      } else if (age == 20) {
        getMyNotifications("read");
      } else if (age == 30) {
        getMyNotifications("Unread");
      }
    }
  }, [age1, page]);

  const handleChange = (event) => {
    setPage(1);
    setAge(event.target.value);
    if (event.target.value == 10) {
      getMyNotifications("All");
    } else if (event.target.value == 20) {
      getMyNotifications("read");
    } else if (event.target.value == 30) {
      getMyNotifications("Unread");
    }
  };

  const [open, setOpen] = React.useState(false);
  const [popmsg, setPopmsg] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const updatnotification = async (id) => {
    try {
      const datas = await Axios.post(
        "/notification/update_my_notification",
        {
          _id: id,
        },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setAge(age);
            setAge1(!age1);
            setPrf(!prf);
          } else {
            setAge(age);
            setAge1(!age1);
            setPrf(!prf);
          }
        })
        .catch((err) => {
          console.log(err, "err");

          setAge1(!age1);
          setPrf(!prf);
        });
    } catch (error) {
      setAge1(!age1);
      setPrf(!prf);
    }
  };
  const changeNotify = (params) => {
    if (params?.detail == "Create Poll") {
      navigate("/polls_survey", { state: 0 });
    } else if (params?.detail == "Create Survey") {
      navigate("/polls_survey", { state: 1 });
    } else if (params?.detail === "Create Room") {
      navigate(`/join/${params?.room_id}`);
    }
  };

  const navigateToBack = async () => {
    try {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        const browserChainId = await web3Instance.eth.getChainId();

        await window.ethereum.enable();
        // setWeb3(web3Instance);

        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const accounts = await web3Instance.eth.getAccounts();
        if (Number(browserChainId) === 56) {
          const contractInstance = new web3Instance.eth.Contract(
            BalanceABI,
            balanceAddress
          );
          const balances = await contractInstance.methods
            .balanceOf(accounts[0])
            .call();

          var bal = Number(balances);
          if (bal >= 0) {
            navigate("/backstagepass");
          } else {
            toast.error("Insufficient Fund In Dster Token(BSC Network)");
            setBalance(false);
          }

          //  return bal
        } else {
          //onChangeNetwork(56)
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [title, setTitle] = useState("Notification");

  return (
    <div className="explore notify">
      <Box sx={{ display: "flex" }}>
        {/* <HeaderNew prf={prf} setPrf={setPrf} title={title} /> */}
        <SidebarNew prf={prf} setPrf={setPrf} title={title} name={name} setName={setName} />


        <Box
          component="main"
          className="explore-box"
          sx={{
            flexGrow: 1,
            py: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            marginTop: "50px",
            marginBottom: "100px",
          }}
        >
          <Grid container spacing={0} className="heading-container">
            <Container maxWidth="lg">
              <Grid xs={12} sm={12} md={8} lg={8} xl={12}>
                <div className="feeds"></div>
              </Grid>
            </Container>
          </Grid>

          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
            className="explore-box2"
          >
            <Container maxWidth="lg">
              <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      // label="Age"
                      onChange={handleChange}
                      className="read-select"
                    >
                      <MenuItem value={10}>All</MenuItem>
                      <MenuItem value={20}>Read</MenuItem>
                      <MenuItem value={30}>Unread</MenuItem>
                    </Select>
                  </FormControl>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Message</TableCell>
                          <TableCell>Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows?.length > 0 ? (
                          <>
                            {rows?.map((row, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  key={index}
                                  className={
                                    row?.seen == false
                                      ? "cls-bold"
                                      : "cls-normal"
                                  }
                                  onClick={() => {
                                    updatnotification(row?._id);
                                    changeNotify(row);
                                    setPopmsg(row);
                                  }}
                                >
                                  {/* setOpen(true); */}
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{row?.sender?.name}</TableCell>
                                  <TableCell>{row?.message}</TableCell>
                                  <TableCell>
                                    {new Date(row?.createdAt).toLocaleString(
                                      undefined,
                                      { hour12: true }
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        ) : (
                          <TableRow hover role="checkbox">
                            <TableCell>No Notification Found</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {rows?.length > 0 ? (
                    <Pagination
                      count={count}
                      page={page}
                      color="secondary"
                      onChange={handleChange1}
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                className="message-popover"
              >
                <Box sx={{ ...style }} className="msg-pop-inner-main-div">
                  <div>
                    <div className="noftigy-title-fixed">
                      <NotificationsIcon /> Notification
                      <HighlightOffIcon
                        className="notify-close"
                        onClick={handleClose}
                      />
                    </div>
                    <div className="notify-pop">
                      <div className="noti-pop-msg">
                        Name:<span>{popmsg?.sender?.name}</span>
                      </div>
                      <div className="noti-pop-msg">
                        Details:<span>{popmsg?.detail}</span>
                      </div>
                      <div className="noti-pop-msg">
                        Message:<span>{popmsg?.message}</span>
                      </div>
                      <div className="noti-pop-msg">
                        Time:<span>{popmsg?.createdAt?.split("T")[0]}</span>
                      </div>

                      <Button
                        onClick={() => {
                          changeNotify(popmsg);
                        }}
                        className="button"
                        disableElevation={true}
                        variant="contained"
                        sx={{
                          color: "#fff",
                          fontSize: "18",
                          background:
                            "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                          borderRadius: "72.4637680053711px",
                          "&:hover": {
                            background:
                              "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                          },
                          marginTop: "240px",
                          width: 220,
                          height: 37,
                        }}
                      >{`Redirect `}</Button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </Container>

            {/* <div>
                            <Track  audiosource={vijay}/>
                        </div> */}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Notify;
