import React, { useState, useEffect } from 'react'
import SidebarNew from '../SidebarNew/SidebarNew'
import { Box, Grid } from '@mui/material'
import './PlayListNew.css'
import { Button, useTheme, useMediaQuery } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import playlistimg from '../../img/dster-webp/playlistimg.webp'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Popover from '@mui/material/Popover';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import card from '../../img/dster-webp/card-4.webp'
import DynamicCut from './DynamicCut';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from '../../Axios'
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Musiccontrol from '../Music-Control/Musiccontrol';
import SendTrack from "../ShareLink/SendTrack";
import { FolderDelete } from '@mui/icons-material';
import { setLogLevel } from 'firebase/app';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'var(--clr-bcg)',
    borderRadius: '12px',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const style3 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'var(--clr-bcg)',
    borderRadius: '12px',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const drawerWidth = 300;

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];


function PlayListNew() {

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const location = useLocation()
    const tokens = localStorage.getItem("iphephile");

    const existingAudio = localStorage.getItem("audiosource");
    const audio = JSON.parse(existingAudio)
    const isPause = localStorage.getItem('isPause')

    if (audio?.length > 0 && isPause == 'false') {
        localStorage.setItem("isPause", false);
    }
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);
    const [playlisterr, setplaylisterr] = React.useState(null);
    const [modeerr, setModeerr] = useState(null);
    const [mode, setMode] = useState();
    const [image, setImage] = useState(null);
    const [ids, setIds] = useState()
    const [add, setAdd] = useState(false)
    const [music, setMusic] = React.useState([]);
    const [usermusic, setUsermusic] = React.useState([]);
    const [count, setCount] = React.useState()
    const [filetype, setFileType] = useState('sasd', true)
    const [imageerr, setImageerr] = useState(null)
    const [audioDurations, setAudioDurations] = useState([]);
    const [songs, setSongs] = useState([])
    const [musicType, setMusicType] = useState()
    const [album, setAlbum] = useState()
    const [likedSongs, setLikedSongs] = useState([])
    const [value, setValue] = useState(0)
    const [isHovered, setIsHovered] = useState(false);
    const [values, setValues] = React.useState(true);
    const [audiosource, setAudiosource] = useState();
    // const [isplay, setisplay] = useState(false);
    const [isplay, setisplay] = useState();
    const [dataId, setdataId] = useState()
    const [audioplays, setAudioplays] = useState([]);
    const [isAlive, setIsAlive] = useState()
    const [play, setPlay] = useState([]);
    const [name, setName] = React.useState();
    const [audioSourceArr, setAudioSourceArr] = useState([])
    const [tracks, setTracks] = useState(Number(localStorage.getItem("tracks")))
    const [playingIndex, setPlayingIndex] = useState();
    const [component, setComponent] = useState('')
    const [likedSongsCounts, setLikedSongsCount] = useState(0)
    const [savedSongsCount, setSavedSongsCount] = useState(0)
    const [userPin, setUserPin] = useState()

    const [users, setUsers] = useState({})
    const [sample, setSample] = useState();

    const [song_id, setSong_id] = useState()
    const [type, setType] = useState()
    const [Id, set_id] = useState()

    // console.log(audiosource, "audiosource");


    const mood = [
        'Peaceful',
        'Romantic',
        'Sentimental',
        'Tender',
        'Easygoing',
        'Yearning',
        'Sophisticated',
        'Sensual',
        'Cool',
        'Gritty',
        'Meancholy',
        'Serious',
        'Brooding',
        'Fiery',
        'Defiant',
        'Aggressive',
        'Rowdy',
        'Exicted',
        'Energizing',
        'Empowering',
        'Stirring',
        'Upbeat',
        'Other'
    ]

    const handleError = () => {
        setplaylist('')
        setDescription('')
        setImage(null)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // handleOpen()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose3 = () => {
        setAnchorEl2(null);
    };

    const [anchorEl3, setAnchorEl3] = useState(null);

    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClick4 = () => {
        setAnchorEl3(null);
    };

    const [anchorEl4, setAnchorEl4] = useState(null);

    const handleClickOpenEl4 = (event) => {
        setAnchorEl4(event.currentTarget);
    };

    const handleClickCloseEl4 = () => {
        setAnchorEl4(null);
    };


    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;

    const open3 = Boolean(anchorEl3);
    const id3 = open3 ? 'simple-popover' : undefined;

    const open4 = Boolean(anchorEl4);
    const id4 = open4 ? 'simple-popover' : undefined;


    const [playlists, setPlaylists] = useState([
        { id: 1, name: 'Playlist #1', creator: 'First Name' }
    ]);
    // const [PlaylistDetails, setPlaylistDetails] = useState({ id: 1, name: 'Playlist #1', creator: 'First Name' })
    const [PlaylistDetails, setPlaylistDetails] = useState({ id: '', img: '', name: '', creator: '', music_type: '', description: '' })

    const [playlist, setplaylist] = React.useState();
    const [description, setDescription] = React.useState();
    const [imageUrl, setImageUrl] = useState();


    const update = () => {
        setplaylist(PlaylistDetails.name !== '' ? PlaylistDetails.name : '')
        setImageUrl(PlaylistDetails.img !== '' ? PlaylistDetails.img : '')
        setMusicType(PlaylistDetails.music_type !== '' ? PlaylistDetails.music_type : '')
        setDescription(PlaylistDetails?.description !== '' ? PlaylistDetails.description : '')
    }

    const handlePlayPause = (index) => {
        setPlayingIndex(index)
    }

    const sampleIndex = () => {
    }

    // Function to get a song from playist

    const getplayLists = async (id) => {
        try {
            await Axios.post(
                "/users/get_my_playlist",
                {
                    playname: name,
                    id: id
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setLikedSongs([])
                        setSongs(res?.data?.song?.[0]?.songDetails)
                        setUserPin(res?.data?.song?.[0]?.userPin)
                        setType('playlist')

                        set_id(res?.data?.song?.[0]?._id)
                        // const audioArray = res?.data?.song?.[0]?.songDetails?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: item?.songUser?.name,
                        //     userId: item?.songUser?._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav,
                        //     play_id: item?.play_id,
                        //     component: 'playlist'
                        // }))
                        const audioArray = res?.data?.song?.[0]?.songDetails?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'playlist'
                        }))
                        setAudioSourceArr(audioArray)
                        // setComponent('playlist')
                        setPlaylistDetails({
                            ...PlaylistDetails,
                            id: res?.data?.song?.[0]?._id,
                            img: res?.data?.song?.[0]?.image,
                            name: res?.data?.song?.[0]?.playlist,
                            creator: res?.data?.song?.[0]?.createUser?.name,
                            // music_type: res?.data?.result?.[0]?.music_type,
                            music_type: 'playlist',
                            description: res?.data?.song?.[0]?.description
                        })
                        setUsermusic([])
                    } else {
                        setSongs([])
                    }
                })
                .catch((err) => {
                    // console.log(err,"err")
                });
        } catch (error) {
            console.log(error, "error");
        }
    };

    const getOneAlbum = async (id) => {
        try {
            var findData = {
                album_id: id,
                name: name
            }
            await Axios.post("/users/get_one_album", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {

                        // setMusic(res?.data?.result[0])
                        // var source = []

                        // if (res?.data?.result[0].songDetails.length > 0) {
                        //     for (var i = 0; i < res?.data?.result[0].songDetails.length; i++) {
                        //         var song = {}
                        //         song.name = res?.data?.result[0].songDetails[i].song_name
                        //         // song.audio = res?.data?.result[0].songDetails[i]?.audio
                        //         if (res?.data?.result[0].songDetails[i]?.nft_type === "nor") {
                        //             song.audio = res?.data?.result[0].songDetails[i]?.audio
                        //         }
                        //         else {
                        //             song.audio = res?.data?.result[0].songDetails[i]?.pre_audio

                        //         }
                        //         source.push(song)
                        //     }
                        // }
                        // setAudiosources(source)
                        // settracksongs(res?.data?.musics)

                        // if (res?.data?.result[0].SongDetails.length > 1) {
                        setUsermusic([])
                        setLikedSongs([])
                        setType('album')
                        setSongs(res?.data?.result[0].songDetails)
                        set_id(res?.data?.result?.[0]?._id)
                        setUserPin(res?.data?.result?.[0]?.userPin)

                        // const audioArray = res?.data?.result?.[0]?.songDetails?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: item?.songUser?.name,
                        //     userId: item?.songUser?._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav,
                        //     album_id: item?.album_id,
                        //     component: 'album'
                        // }))

                        const audioArray = res?.data?.result?.[0]?.songDetails?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'album'
                        }))
                        setAudioSourceArr(audioArray)
                        // setComponent('album')
                        setPlaylistDetails({
                            ...PlaylistDetails,
                            id: res?.data?.result?.[0]?._id,
                            img: res?.data?.result?.[0]?.image,
                            name: res?.data?.result?.[0]?.album_name,
                            creator: res?.data?.result?.[0]?.createUser?.name,
                            // music_type: res?.data?.result?.[0]?.music_type,
                            music_type: 'album',
                            description: res?.data?.result?.[0]?.description
                        })
                        // } else {
                        //     setSongs([])
                        // }
                        // setCount(res?.data?.count)

                    }
                    else {
                        // setMusic([])
                        // settracksongs([])
                        setSongs([])

                    }
                }).catch((err) => {
                    console.log(err, "err")

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const getOneTrack = async (id) => {
        try {
            var findData = { song_id: id, name: name };

            if (tokens != null) {
                const datas = await Axios.post("/users/get_one_music", findData, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {

                        if (res?.data?.success) {
                            setUsermusic([])
                            setLikedSongs([])
                            setSongs(res?.data?.result?.musicList);
                            setUserPin(res?.data?.result?.musicList?.[0]?.userPin)

                            // const audioArray = res?.data?.result?.musicList?.map(item => ({
                            //     _id: item?._id,
                            //     audio: item?.audio,
                            //     song_name: item?.song_name,
                            //     name: item?.createUser?.name,
                            //     userId: item?.createUser?._id,
                            //     image: item?.image,
                            //     repeat: item?.repeat,
                            //     shuffle: item?.shuffle,
                            //     fav: item?.userFav,
                            //     component: 'track'
                            // }))
                            const audioArray = res?.data?.result?.musicList?.map(item => ({
                                _id: item?._id,
                                audio: item?.audio,
                                component: 'track'
                            }))

                            setAudioSourceArr(audioArray)
                            setType('track')
                            // setComponent('track')
                            setPlaylistDetails({
                                ...PlaylistDetails,
                                id: res?.data?.result?.musicList?.[0]?._id,
                                img: res?.data?.result?.musicList?.[0]?.image,
                                name: res?.data?.result?.musicList?.[0]?.song_name,
                                creator: res?.data?.result?.musicList?.[0]?.createUser?.name,
                                music_type: res?.data?.result?.musicList?.[0]?.music_type,
                                description: res?.data?.result?.musicList?.[0]?.description
                            })
                            // if (res?.data?.result?.musicList[0].SongDetails.length > 1) {
                            //     setUsermusic(res?.data?.result?.musicuserList);
                            // } else {
                            //     setUsermusic(res?.data?.result?.musicuserList);
                            // }
                            // setCount(res?.data?.count);
                        } else {
                            //toast.error(err?.response?.data?.message)
                            setSongs([]);
                        }
                    })
                    .catch((err) => {
                        // console.log(err,"err")
                    });
            } else {
                const datas = await Axios.post("/users/get_one_mus", findData, {})
                    .then((res) => {
                        if (res?.data?.success) {
                            setLikedSongs([])
                            setSongs(res?.data?.result?.musicList[0]);
                            // getAudioDuration(res?.data?.result?.musicList[0]?.audio);

                            // if (res?.data?.result?.musicList[0].SongDetails.length > 1) {
                            //     setUsermusic(res?.data?.result?.musicuserList);
                            // } else {
                            //     setUsermusic(res?.data?.result?.musicuserList);
                            // }
                            // setCount(res?.data?.count);
                        } else {
                            //toast.error(err?.response?.data?.message)
                            setSongs([]);
                        }
                    })
                    .catch((err) => {
                        // console.log(err,"err")
                    });
            }
        } catch (error) {
            // console.log(error,"error");
        }
    };


    // Function to add a new playlist



    // const addPlaylist = () => {
    //     const newPlaylist = {
    //         id: playlists.length + 1, // Simple ID generation
    //         name: `Playlist #${playlists.length + 1}`,
    //         creator: 'New Creator' // You can modify this to take user input
    //     };
    //     setPlaylists([...playlists, newPlaylist]);
    //     handleClose()
    // };


    const removePlaylist = (id) => {
        setPlaylists(playlists.filter(item => item.id == id));
        setPlaylistDetails()
    }


    const getMusic = async () => {
        try {
            await Axios.post(
                "/users/get_music_library",
                // {
                //     name: name,
                //     status: status,
                // },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        // setTrack(res?.data?.result?.musicList);
                        // setAlbum(res?.data?.result?.musicAlbum);
                        setPlay(res?.data?.result?.musicPlay);
                    } else {
                        // setTrack([]);
                        // setAlbum([]);
                        setPlay([]);
                    }
                })
                .catch((err) => {
                    if (err?.response?.data?.message == "Admin Blocked You") {
                        localStorage.clear();
                        navigate('/');
                        toast.error('Admin Blocked You');
                    }
                });
        } catch (error) {
            console.log(error, "getmusic error");
        }
    };
    // Function to add a new playlist
    const addPlaylist = async () => {
        try {
            const create_data = {
                playlist: `Playlist #${play.length + 1}`,
            }
            await Axios.post("/users/create_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        handleClose3()
                        setValue(1)
                        getMusic()
                    }
                    else {
                        // toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }


    };


    const [folder, setFolder] = useState([
        // { id: 1, name: 'Playlist #1', creator: 'First Name' }
    ]);



    const [folderName, setFolderName] = useState()
    const [folderNameerr, setFolderNameerr] = useState()


    // Function to add a new playlist
    const addFolder = async () => {
        try {
            if (folderName == undefined || folderName == '') {
                setFolderNameerr("Please Enter Folder Name")
            }
            else {
                const response = await Axios.post('/users/create_folder', { name: folderName },
                    {
                        headers: {
                            Authorization: tokens,
                        },
                    })
                if (response?.data?.success) {
                    getFolder()
                    setValue(0)
                    setIsAlive(false)
                    toast.success(response?.data?.message)
                    handleClose3()
                }
            }
        } catch (error) {
            console.log(error, 'err');
        }
    };

    const getFolder = async () => {
        try {
            const res = await Axios.post('/users/getFolder', {},
                {
                    headers: {
                        Authorization: tokens,
                    },
                })
            if (res?.data?.success) {
                setFolder(res?.data?.result)
                setPlaylists(res?.data?.result?.[0]?.playlist)
            }
        } catch (error) {
            console.log(error, 'err');
        }
    }

    // const addFolder = () => {
    //     const newFolder = {
    //         id: folder.length + 1, // Simple ID generation
    //         name: `Playlist #${folder.length + 1}`,
    //         creator: 'New Creator' // You can modify this to take user input
    //     };
    //     setFolder([...folder, newFolder]);
    //     handleClose()
    // };


    const [inFolder, setInFolder,] = useState(false)

    const handleInFolder = () => {
        setInFolder(!inFolder)

    }

    //edit details modal
    const [model, setModel] = useState(false);
    const handleOpen = () => { handleClose3(); setModel(true); }
    const handleClose2 = () => { setModel(false) };

    //creaqte playlist modal
    const [model1, setModel1] = useState(false);
    const handleOpen1 = () => { handleClose3(); setModel1(true); }
    const handleClose4 = () => { setModel1(false) };

    //delete playlist modal
    const [model2, setModel2] = useState(false);
    const handleOpen2 = () => { handleClose3(); setModel2(true); }
    const handleClose5 = () => { setModel2(false) };

    //create folder modal

    const [model3, setModel3] = useState(false);
    const handleOpen3 = () => { setModel3(true) }
    const handleClose6 = () => { setModel3(false) };




    // const handleImageUpload = (event) => {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();

    //     console.log(reader, "result of file");

    //     reader.onloadend = () => {
    //         setImageUrl(reader.result);
    //     };

    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // };

    const handlePickMood = (e) => {
        setMode(e.target.value)
        setModeerr(null)
    };

    const handleImageUpload = async (event) => {

        const files = event.target.files[0];
        setFileType(files.name, true)
        try {
            let formData = await new FormData();
            formData.append('image', files)
            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {
                    setImageUrl(data.result);
                    setImageerr(null)
                }
            } else {
                setImage(null)
                setImageerr("Only jpg, jpeg, png files are allowed!");
            }
        } catch (error) {
            console.log(error, "err");
        }
    }

    const getOnePlaylist = async (data) => {
        try {

            var findData = {
                album_id: data
            }

            const datas = await Axios.post("/users/get_one_playlist", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        if (res?.data?.result.length == 0) {
                            setAdd(true)
                        }
                        else {
                            setAdd(false)
                            setModel(false)
                            setMusic(res?.data?.result[0])
                            var source = []

                            if (res?.data?.result[0].songDetails.length > 0) {
                                for (var i = 0; i < res?.data?.result[0].songDetails.length; i++) {
                                    var song = {}
                                    song.name = res?.data?.result[0].songDetails[i].song_name
                                    song.audio = res?.data?.result[0].songDetails[i]?.audio
                                    source.push(song)
                                }
                            }
                            if (res?.data?.result[0].songDetails.length > 0) {
                                // const audioArray = res?.data?.result[0]?.songDetails?.map(item => ({
                                //     _id: item?._id,
                                //     audio: item?.audio,
                                //     song_name: item?.song_name,
                                //     name: users?.name,
                                //     userId: users?._id,
                                //     image: item?.image,
                                //     repeat: item?.repeat,
                                //     shuffle: item?.shuffle,
                                //     fav: item?.userFav,
                                //     play_id: item?.play_id,
                                //     component: 'oneplaylist'
                                // }))
                                const audioArray = res?.data?.result[0]?.songDetails?.map(item => ({
                                    _id: item?._id,
                                    audio: item?.audio,
                                    component: 'oneplaylist'
                                }))
                                setAudioSourceArr(audioArray)
                                // setComponent('oneplaylist')
                                setUsermusic(res?.data?.result[0]?.songDetails)
                                setPlaylistDetails({
                                    ...PlaylistDetails,
                                    id: res?.data?.result?.[0]?._id,
                                    img: res?.data?.result?.[0]?.image,
                                    name: res?.data?.result?.[0]?.playlist,
                                    creator: res?.data?.result?.[0]?.createUser?.name,
                                    // music_type: res?.data?.result?.[0]?.music_type,
                                    music_type: 'playlist',
                                    description: res?.data?.result?.[0]?.description
                                })

                            } else {
                                setUsermusic(res?.data?.result)
                            }
                            setCount(res?.data?.count)
                        }
                    }
                    else {
                        setAdd(true)
                        //toast.error(err?.response?.data?.message)
                        setMusic([])
                        // settracksongs([])

                    }
                }).catch((err) => {
                    // console.log(err,"err")
                    setAdd(true)

                })

        } catch (error) {
            // console.log(error,"error");
        }
    }

    const editPlay = async () => {
        try {
            if (!playlist) {
                setplaylisterr("Playlist Name is Required")
            }
            // else if (!mode) {
            //     setModeerr("Please Enter Song Mood")
            // }
            // else if (!image) {
            //     setImageerr("Please Select Image File")
            // }
            else {
                var music = []
                if (location?.state?.song_id !== undefined) {
                    music.push(location?.state?.song_id)
                }

                const create_data = {
                    song_history: music,
                    playlist: playlist,
                    image: imageUrl,
                    song_moods: mode,
                    description: description
                }
                await Axios.post("/users/create_playlist", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            toast.success('PlayList Created Successfully')
                            getOnePlaylist(res?.data?.result?._id)
                            setIds(res?.data?.result?._id)
                            handleError()
                            handleClose4()
                            const row = res?.data?.result
                            navigate(location.pathname, { replace: true, state: null });
                            // navigate('/createplaylist', { state: { row } })
                        }
                        else {
                            toast.error(res?.data?.message)

                        }
                    }).catch((err) => {
                        toast.error(err?.response?.data?.message)

                    })
            }
        } catch (error) {
            console.log(error, "error");
        }

    }



    useEffect(() => {
        setTracks(tracks !== undefined ? tracks : Number(localStorage.getItem("tracks")))
        if (location?.state?.value === 'recent') {
            handleOpen1();
        }
        getMusic()
    }, [tokens, name])




    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener("loadedmetadata", () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };


    const getaudioPlays = async (audioSrc, index) => {
        try {
            if (audioSrc === localStorage.getItem("songName")) {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 1,
                }));
            } else {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 0,
                }));
            }
        } catch (error) {
            console.error(error);
            setAudioplays((prev) => ({
                ...prev,
                [index]: 0,
            }));
        }
    };



    const getUserFav = async () => {
        try {
            const find_data = {
                explore: "My Favourites",
                name: name,
            };
            if (tokens) {
                await Axios.post("/users/get_user_favourite", find_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            setLikedSongs([])
                            setSongs(res?.data?.result);
                            setType('liked')
                            setLikedSongsCount(res?.data?.result?.length)
                            // const audioArray = res?.data?.result?.map(item => ({
                            //     _id: item?._id,
                            //     audio: item?.audio,
                            //     song_name: item?.song_name,
                            //     name: item?.createUser?.name,
                            //     userId: item?.createUser?._id,
                            //     image: item?.image,
                            //     repeat: item?.repeat,
                            //     shuffle: item?.shuffle,
                            //     fav: item?.userFav,
                            //     component: 'fav'
                            // }))
                            const audioArray = res?.data?.result?.map(item => ({
                                _id: item?._id,
                                audio: item?.audio,
                                component: 'fav'
                            }))
                            setAudioSourceArr(audioArray)
                            setComponent('fav')
                        } else {
                            setSongs([]);
                        }
                    })
                    .catch((err) => {
                        setLikedSongs([])
                        setSongs([]);
                        if (err?.response?.data?.message == "Admin Blocked You") {
                            localStorage.clear();
                            navigate('/');
                            toast.error('Admin Blocked You');
                        }
                    });
            }
            else {
                await Axios.post("/users/get_user_favourites", find_data)
                    .then((res) => {
                        if (res?.data?.success) {
                            setSongs(res?.data?.result);
                            setLikedSongsCount(res?.data?.result?.length)
                            // const audioArray = res?.data?.result?.map(item => ({
                            //     _id: item?._id,
                            //     audio: item?.audio,
                            //     song_name: item?.song_name,
                            //     name: item?.createUser?.name,
                            //     userId: item?.createUser?._id,
                            //     image: item?.image,
                            //     repeat: item?.repeat,
                            //     shuffle: item?.shuffle,
                            //     fav: item?.userFav,
                            //     component: 'fav'
                            // }))
                            const audioArray = res?.data?.result?.map(item => ({
                                _id: item?._id,
                                audio: item?.audio,
                                component: 'fav'
                            }))
                            setAudioSourceArr(audioArray)
                            setComponent('fav')
                        } else {
                            setSongs([]);
                        }
                    })
                    .catch((err) => {
                        setSongs([]);
                        if (err?.response?.data?.message == "Admin Blocked You") {
                            localStorage.clear();
                            navigate('/');
                            toast.error('Admin Blocked You');
                        }
                    });
            }

        } catch (error) {
            // console.log(error, "error");
        }
    };


    // const getUserFav = async () => {
    //     try {
    //         const find_data = {
    //             explore: "My Favourites",
    //             name: name,
    //         };
    //         if (tokens) {
    //             const response = await Axios.post("/users/getUserFavourite", find_data, {
    //                 headers: {
    //                     Authorization: tokens,
    //                 },
    //             })
    //             if (response?.data?.success) {
    //                 setSongs([])
    //                 setComponent('fav')
    //                 setLikedSongs(response?.data?.result)
    //                 setLikedSongsCount(response?.data?.result?.length)
    //                 // let musics
    //                 // for (let i = 0; i < response?.data?.result?.length; i++) {
    //                 //     if (i == tracks) {
    //                 //         musics = { ...response?.data?.result?.[i].musics }
    //                 //     }
    //                 // }

    //                 // let audioArray = []
    //                 // const musicsdata = {
    //                 //     _id: musics?.[0]?._id,
    //                 //     audio: musics?.[0]?.audio,
    //                 //     song_name: musics?.[0]?.song_name,
    //                 //     name: musics?.[0]?.createUser?.name,
    //                 //     image: musics?.[0]?.image,
    //                 //     repeat: musics?.[0]?.repeat,
    //                 //     shuffle: musics?.[0]?.shuffle,
    //                 //     fav: musics?.[0]?.userFav
    //                 // }
    //                 // audioArray.splice(tracks, 0, musicsdata);
    //                 // setAudioSourceArr(audioArray)
    //             }
    //             else {
    //                 setLikedSongs([])
    //                 setLikedSongsCount(0)
    //             }

    //         }

    //     } catch (error) {
    //         console.log(error, "error");
    //     }
    // };


    // const getUserSaved = async () => {
    //     try {
    //         const find_data = {
    //             explore: "Saved Songs",
    //             name: name,
    //         };
    //         if (tokens) {
    //             await Axios.post("/users/get_user_favourite", find_data, {
    //                 headers: {
    //                     Authorization: tokens,
    //                 },
    //             })
    //                 .then((res) => {
    //                     if (res?.data?.success) {
    //                         setSongs(res?.data?.result);
    //                         setSavedSongsCount(res?.data?.result?.length)
    //                         const audioArray = res?.data?.result?.map(item => ({
    //                             _id: item?._id,
    //                             audio: item?.audio,
    //                             song_name: item?.song_name,
    //                             name: item?.createUser?.name,
    //                             image: item?.image,
    //                             repeat: item?.repeat,
    //                             shuffle: item?.shuffle,
    //                             fav: item?.userFav
    //                         }))
    //                         setAudioSourceArr(audioArray)
    //                         setComponent('saved')
    //                     } else {
    //                         setSongs([]);
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     // console.log(err, "errsss")
    //                     setSongs([]);
    //                     if (err?.response?.data?.message == "Admin Blocked You") {
    //                         localStorage.clear();
    //                         navigate('/');
    //                         toast.error('Admin Blocked You');
    //                     }
    //                 });
    //         }
    //         else {
    //             await Axios.post("/users/get_user_favourites", find_data)
    //                 .then((res) => {
    //                     if (res?.data?.success) {
    //                         setSongs(res?.data?.result);
    //                         setSavedSongsCount(res?.data?.result?.length)
    //                         const audioArray = res?.data?.result?.map(item => ({
    //                             _id: item?._id,
    //                             audio: item?.audio,
    //                             song_name: item?.song_name,
    //                             name: item?.createUser?.name,
    //                             image: item?.image,
    //                             repeat: item?.repeat,
    //                             shuffle: item?.shuffle,
    //                             fav: item?.userFav
    //                         }))
    //                         setAudioSourceArr(audioArray)
    //                         setComponent('saved')
    //                     } else {
    //                         setSongs([]);
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     // console.log(err, "errsss")
    //                     setSongs([]);
    //                     if (err?.response?.data?.message == "Admin Blocked You") {
    //                         localStorage.clear();
    //                         navigate('/');
    //                         toast.error('Admin Blocked You');
    //                     }
    //                 });
    //         }

    //     } catch (error) {
    //         // console.log(error, "error");
    //     }
    // };

    const getUserSaved = async () => {
        try {
            const find_data = {
                explore: "Saved Songs",
                name: name,
            };
            if (tokens) {
                const response = await Axios.post("/users/getUserFavourite", find_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                if (response?.data?.success) {
                    setType('saved')
                    setSongs([])
                    setLikedSongs(response.data.result)
                    setSavedSongsCount(response.data.result.length)
                }
                else {
                    setLikedSongs([])
                    setSongs([])
                    setSavedSongsCount(0)
                }
            }

        } catch (error) {
            // console.log(error, "error");
        }
    };

    useEffect(() => {
        if (usermusic?.length > 0) {
            usermusic?.forEach((row, index) => {
                getAudioDuration(row?.audio);
                getaudioPlays(row?.song_name, index);

            });
        }
        if (songs?.length > 0) {
            songs?.forEach((row, index) => {
                getAudioDuration(row?.audio);
                getaudioPlays(row?.song_name, index);

            });
        }
    }, [usermusic, songs, values]);

    const updatePlay = async () => {
        try {
            let payload
            if (musicType == 'track') {
                payload = {
                    id: PlaylistDetails.id,
                    music_type: musicType,
                    track_name: playlist,
                    song_name: playlist,
                    image: imageUrl,
                    description: description
                }
            }
            else if (musicType == 'album') {
                payload = {
                    id: PlaylistDetails.id,
                    music_type: musicType,
                    album_name: playlist,
                    image: imageUrl,
                    description: description
                }
            }
            else if (musicType == 'playlist') {
                payload = {
                    id: PlaylistDetails.id,
                    music_type: musicType,
                    playlist: playlist,
                    image: imageUrl,
                    description: description
                }
            }

            const res = await Axios.post('/users/updatePlaylist', payload, {
                headers: {
                    Authorization: tokens,
                },
            })

            if (res?.data?.success) {
                toast.success(res.data.message)
                if (musicType == 'track') {
                    getOneTrack(res?.data?.result?._id)
                }
                else if (musicType == 'album') {
                    getOneAlbum(res?.data?.result?._id)
                }
                else if (musicType == 'playlist') {
                    getOnePlaylist(res?.data?.result?._id)
                    getplayLists(res?.data?.result?._id)
                }
                setIsAlive(false)
                handleClose2()
            }
            else {
                console.log(res.data.message);

            }
        } catch (error) {
            console.log(error);
        }
    }

    const setAudio = (data, i) => {

        // localStorage.setItem("audiosource", data.audio);
        localStorage.setItem("audiosource", JSON.stringify(audioSourceArr));
        // localStorage.setItem("songName", data.song_name);
        // localStorage.setItem("Image", data.image);
        localStorage.setItem("songName", audioSourceArr?.[i]?.song_name);
        localStorage.setItem("name", audioSourceArr?.[i]?.name);
        localStorage.setItem("Image", audioSourceArr?.[i]?.image);
        localStorage.setItem("current", 0);
        localStorage.setItem("tracks", i);
        localStorage.setItem("isPause", false);
        setAudiosource(data.audio);
        localStorage.setItem("playsong", "false");
        setValues(!values);
        setisplay(true);
        setdataId(data._id)
    };

    const createMusic = async (data) => {
        try {
            const create_data = {
                song_id: data,
            };

            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        // getMusic()
                    } else {
                        toast.error(res?.data?.message);
                    }
                })
                .catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message);
                });
        } catch (error) {
            // console.log(error, "error");
        }
    };

    const deleteSongs = async () => {

        try {
            const payload = {
                id: PlaylistDetails.id,
                key: musicType
            }

            const res = await Axios.post('/users/deletetrack', payload, {
                headers: {
                    Authorization: tokens
                }
            })

            if (res.data.success) {
                toast.success(res.data.message)
                handleClose5()
                setIsAlive(false)
                setPlaylistDetails({})
                setSongs([])
                getMusic()
            }
            else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log(error, 'err');
        }
    }

    const pinList = async () => {

        try {
            const payload = {
                id: PlaylistDetails.id,
                music_type: PlaylistDetails.music_type
            }

            const res = await Axios.post('/users/pinSongs', payload, {
                headers: {
                    Authorization: tokens
                }
            })

            if (res.data.success) {
                toast.success(res.data.message)
                handleClose3()
                setIsAlive(false)
                if (PlaylistDetails.music_type == 'track') {
                    getOneTrack(res.data.result._id)
                }
                else if (PlaylistDetails.music_type == 'album') {
                    getOneAlbum(res.data.result._id)
                }
                else if (PlaylistDetails.music_type == 'playlist') {
                    getplayLists(res.data.result._id)
                }

            }
            else {
                toast.error(res.data.message)
                handleClose3()
                setIsAlive(false)
                if (PlaylistDetails.music_type == 'track') {
                    getOneTrack(res.data.result._id)
                }
                else if (PlaylistDetails.music_type == 'album') {
                    getOneAlbum(res.data.result._id)
                }
                else if (PlaylistDetails.music_type == 'playlist') {
                    getplayLists(res.data.result._id)
                }
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    function convertToInternationalCurrencySystem(labelValue) {

        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9

            ? (Math.abs(Number(labelValue)) / 1.0e+9) + "B"
            // Six Zeroes for Millions 
            : Math.abs(Number(labelValue)) >= 1.0e+6

                ? (Math.abs(Number(labelValue)) / 1.0e+6) + "M"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                    ? (Math.abs(Number(labelValue)) / 1.0e+3) + "K"

                    : Math.abs(Number(labelValue));
    }


    useEffect(() => {
        if (location?.state?.data == 'get') {
            getUserFav()
            setPlaylistDetails({
                ...PlaylistDetails,
                name: "Liked Songs",
                img: '',
                creator: '',
                music_type: "",
                description: ''
            })
        }
        else if (location?.state?.data == 'save') {
            getUserSaved()
            setPlaylistDetails({
                ...PlaylistDetails,
                name: "Saved Songs",
                img: '',
                creator: '',
                music_type: "",
                description: ''
            })
        }
        else {
            const row = location?.state?.row
            setPlaylistDetails({ ...PlaylistDetails, id: row?._id, img: row?.image, name: row?.song_name, creator: row?.createUser?.[0]?.name, music_type: row?.music_type, description: row?.description })
            if (location?.state?.music_type == 'track') {
                getOneTrack(location?.state?.data)
            }
            else if (location?.state?.music_type == 'album') {
                setPlaylistDetails({ ...PlaylistDetails, id: row?._id, img: row?.image, name: row?.album_name, creator: row?.createUser?.[0].name, music_type: 'album', description: row?.description })
                getOneAlbum(location?.state?.data)
            }
            else if (location?.state?.music_type == 'playlist') {
                getplayLists(location?.state?.data)
                setPlaylistDetails({ ...PlaylistDetails, id: row?._id, img: row?.image, name: row?.playlist, creator: row?.createUser?.[0].name, music_type: 'playlist', description: row?.description })
            }

        }
    }, [location?.state?.data, name, users])

    // console.log(location?.state?.data, PlaylistDetails, 'logs');

    // useEffect(() => {
    //     if (PlaylistDetails?.name == 'Tracks') {
    //         navigate('', { state: { data: null } })
    //     }

    // }, [PlaylistDetails])


    useEffect(() => {
        getUserFavCount()
        getUserSavedCount()
    }, [likedSongsCounts, savedSongsCount])

    useEffect(() => {
        if (location.state == null) {
            getUserFav()
            setPlaylistDetails({
                ...PlaylistDetails,
                name: "Liked Songs",
                img: '',
                creator: '',
                music_type: "",
                description: ''
            })
        }
    }, [])

    //remove song from playlist 

    const removesong = async (id) => {
        try {
            const payload = {
                id: id,
                type: type,
                playId: Id
            }
            const response = await Axios.post('/users/removesong', payload, {
                headers: {
                    Authorization: tokens
                }
            })
            if (response?.data?.success) {
                toast.success(response?.data?.message)
                if (type == 'playlist') {
                    getplayLists(response?.data?.result?._id)
                }
                else {
                    getOneAlbum(response?.data?.result?._id)
                }
                setIsAlive(false)
                handleClick4()
            }
            else {
                toast.error(response?.data?.message)
                handleClick4()
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }


    const getUserFavCount = async () => {
        try {
            const find_data = {
                explore: "My Favourites",
                name: name,
            };
            if (tokens) {
                await Axios.post("/users/get_user_favourite", find_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            setLikedSongsCount(res?.data?.result?.length)
                        } else {
                            setLikedSongsCount(0);
                        }
                    })
                    .catch((err) => {
                        if (err?.response?.data?.message == "Admin Blocked You") {
                            localStorage.clear();
                            navigate('/');
                            toast.error('Admin Blocked You');
                        }
                    });
            }
            // else {
            //     await Axios.post("/users/get_user_favourites", find_data)
            //         .then((res) => {
            //             if (res?.data?.success) {
            //                 setLikedSongsCount(res?.data?.result?.length)
            //             } else {
            //                 setLikedSongsCount(0)
            //             }
            //         })
            //         .catch((err) => {
            //             if (err?.response?.data?.message == "Admin Blocked You") {
            //                 localStorage.clear();
            //                 navigate('/');
            //                 toast.error('Admin Blocked You');
            //             }
            //         });
            // }

        } catch (error) {
            // console.log(error, "error");
        }
    };


    const getUserSavedCount = async () => {
        try {
            const find_data = {
                explore: "Saved Songs",
                name: name,
            };
            if (tokens) {
                await Axios.post("/users/getUserFavourite", find_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        if (res?.data?.success) {
                            setSavedSongsCount(res?.data?.result?.length)
                        } else {
                            setSavedSongsCount(0);
                        }
                    })
                    .catch((err) => {
                        if (err?.response?.data?.message == "Admin Blocked You") {
                            localStorage.clear();
                            navigate('/');
                            toast.error('Admin Blocked You');
                        }
                    });
            }
            // else {
            //     await Axios.post("/users/get_user_favourites", find_data)
            //         .then((res) => {
            //             if (res?.data?.success) {
            //                 setSavedSongsCount(res?.data?.result?.length)
            //             } else {
            //                 setSavedSongsCount(0);
            //             }
            //         })
            //         .catch((err) => {
            //             if (err?.response?.data?.message == "Admin Blocked You") {
            //                 localStorage.clear();
            //                 navigate('/');
            //                 toast.error('Admin Blocked You');
            //             }
            //         });
            // }

        } catch (error) {
            // console.log(error, "error");
        }
    };

    //share songs
    const [openModel2, setOpenModel2] = React.useState(false);
    const handleOpenShare = () => setOpenModel2(true);
    const handleModelClose2 = () => setOpenModel2(false);

    const [send, setSend] = useState();
    const sendTracks = (data) => {
        // handleClose();
        if (tokens) {
            handleClose3()
            handleOpenShare();
            setSend(data);
        }
        else {
            toast.error("Please Login to Share Songs")
            setTimeout(() => {
                navigate('/login')
            }, 2000)
        }
    };


    //move to folder

    // const [anchorEl4, setAnchorEl4] = useState(null);

    // const handlePopoverOpen3 = (event) => {
    //     setAnchorEl4(event.currentTarget);
    // };

    // const handlePopoverClose3 = () => {
    //     setAnchorEl4(null);
    // };

    // const openSi = Boolean(anchorEl4);
    // const id4 = openSi ? 'simple-popover' : undefined;

    const movetofolder = async (id) => {
        try {
            const payload = {
                id: PlaylistDetails.id,
                music_type: PlaylistDetails.music_type,
                folderId: id
            }
            const response = await Axios.post('/users/movetofolder', payload, {
                headers: {
                    Authorization: tokens
                }
            })
            if (response?.data?.success) {
                toast.success(response?.data?.message)
                setIsAlive(false)
                setValue(0)
                // handlePopoverClose3()
                handleClickCloseEl4()
                handleClose3()
            }
            else {
                toast.error(response?.data?.message)
                handleClickCloseEl4()
                handleClose3()
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const getMusicLibrary = async () => {
        try {
            await Axios.post(
                "/users/get_music_library",
                {
                    name: name,
                    // status: status,
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    if (res?.data?.success) {
                        setSongs(res?.data?.result?.musicList);
                        // const audioArray = res?.data?.result?.musicList?.map(item => ({
                        //     _id: item?._id,
                        //     audio: item?.audio,
                        //     song_name: item?.song_name,
                        //     name: item?.songUser?.name,
                        //     userId: item?.songUser?._id,
                        //     image: item?.image,
                        //     repeat: item?.repeat,
                        //     shuffle: item?.shuffle,
                        //     fav: item?.userFav,
                        //     play_id: item?.play_id,
                        //     component: 'alltracks'
                        // }))

                        const audioArray = res?.data?.result?.musicList?.map(item => ({
                            _id: item?._id,
                            audio: item?.audio,
                            component: 'alltracks'
                        }))
                        setAudioSourceArr(audioArray)
                        // setComponent('alltracks')
                    } else {
                        setSongs([]);
                    }
                })
                .catch((err) => {
                    if (err?.response?.data?.message == "Admin Blocked You") {
                        localStorage.clear();
                        navigate('/');
                        toast.error('Admin Blocked You');
                    }
                });
        } catch (error) {
            console.log(error, "getmusic error");
        }
    };




    return (
        <>
            <ToastContainer />
            <div className='playlistnew'>

                <Box sx={{ display: isLgUp ? 'flex' : 'block' }}>
                    <SidebarNew name={name} setName={setName} />
                    <Box
                        component="main"
                        // className="explore-box"
                        sx={{
                            flexGrow: 1,
                            py: 1,
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            marginTop: "80px",
                            marginBottom: "100px",
                        }}
                    >

                        <Grid container spacing={0} >
                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={9}

                            >

                                <Stack direction="row" spacing={1}>
                                    <div className='arrowbck'>
                                        <ArrowBackIosNewIcon onClick={(() => { navigate(-1) })} />
                                    </div>
                                    {/* <div className='arrowbck'>
                                        <ArrowForwardIosIcon onClick={(() => { navigate(+1) })} />
                                    </div> */}

                                </Stack>
                                <div className="display-1 margin-top">
                                    <div className="palylistimg">

                                        {PlaylistDetails?.name?.toLowerCase() === 'liked songs' ? <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="53" height="53" rx="5" fill="url(#paint0_linear_4451_6757)" />
                                            <g clip-path="url(#clip0_4451_6757)">
                                                <path d="M37.4375 23.9609C37.4375 30.7969 27.3018 36.3301 26.8701 36.5586C26.7563 36.6198 26.6292 36.6518 26.5 36.6518C26.3708 36.6518 26.2436 36.6198 26.1299 36.5586C25.6982 36.3301 15.5625 30.7969 15.5625 23.9609C15.5643 22.3557 16.2028 20.8167 17.3379 19.6816C18.473 18.5465 20.0119 17.9081 21.6172 17.9062C23.6338 17.9062 25.3994 18.7734 26.5 20.2393C27.6006 18.7734 29.3662 17.9062 31.3828 17.9062C32.9881 17.9081 34.527 18.5465 35.6621 19.6816C36.7972 20.8167 37.4357 22.3557 37.4375 23.9609Z" fill="white" />
                                            </g>
                                            <defs>
                                                <linearGradient id="paint0_linear_4451_6757" x1="52.0536" y1="47.6172" x2="-1.80722" y2="43.4727" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#175AFF" />
                                                    <stop offset="1" stop-color="#FC5AFF" />
                                                </linearGradient>
                                                <clipPath id="clip0_4451_6757">
                                                    <rect width="25" height="25" fill="white" transform="translate(14 14)" />
                                                </clipPath>
                                            </defs>
                                        </svg> : PlaylistDetails?.name?.toLowerCase() === 'saved songs' ? <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="53" height="53" rx="5" fill="url(#paint0_linear_4964_4915)" />
                                            <path d="M18 17C18 15.8954 18.8954 15 20 15H33C34.1046 15 35 15.8954 35 17V34.3237C35 35.9087 33.2442 36.8639 31.9135 36.0029L27.5865 33.203C26.9253 32.7752 26.0747 32.7752 25.4135 33.203L21.0865 36.0029C19.7558 36.8639 18 35.9087 18 34.3237V17Z" fill="white" />
                                            <defs>
                                                <linearGradient id="paint0_linear_4964_4915" x1="52.0536" y1="47.6172" x2="-1.80722" y2="43.4727" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#175AFF" />
                                                    <stop offset="1" stop-color="#FC5AFF" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <img src={PlaylistDetails?.img ? PlaylistDetails?.img : playlistimg} alt="playlist" />}
                                    </div>
                                    <div className="plalistname">
                                        <div className="plsjist">
                                            {/* {PlaylistDetails?.music_type ? PlaylistDetails?.music_type : "Playlist"} */}
                                            {PlaylistDetails?.music_type}
                                        </div>
                                        <div className="pls-h">
                                            {/* {PlaylistDetails?.name ? PlaylistDetails?.name.length > 15 ? PlaylistDetails?.name.slice(0, 15) + '...' : PlaylistDetails?.name : "Player #1"} */}
                                            {PlaylistDetails?.name?.length > 15 ? PlaylistDetails?.name.slice(0, 15) + '...' : PlaylistDetails?.name}

                                        </div>
                                        <div className="frstname">
                                            {/* {PlaylistDetails?.creator ? PlaylistDetails?.creator : "Creator"} */}
                                            {PlaylistDetails?.creator}
                                        </div>
                                        <div className="frstname">
                                            {PlaylistDetails?.description ? PlaylistDetails?.description : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="display-2">
                                    <div className="">
                                        {
                                            // location?.state?.data == 'get' || location?.state?.data == 'save' || PlaylistDetails?.name == 'Liked Songs' || PlaylistDetails?.name == 'Saved Songs' ?
                                            localStorage.getItem('role') == 'artist' ?
                                                type == 'liked' || type == 'saved' || type == 'tracksongs' || type == 'albumsongs' || type == 'playlistsongs' ?
                                                    <></> :
                                                    <MoreHorizIcon onClick={handleClick2} /> : <></>
                                        }
                                    </div>
                                    <div className="display-1">
                                        {/* <div className="frstname">
                                            List
                                        </div>
                                        <div className="">
                                            <FormatListBulletedIcon />
                                        </div> */}
                                    </div>
                                </div>
                                <hr style={{ borderColor: '#ffffff5c' }} />


                                <TableContainer className='tablecont-playlist'>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell align="center">Date Added</TableCell>
                                                <TableCell align="center">Most Listen</TableCell>
                                                {likedSongs?.length > 0 ? <></> :
                                                    <TableCell align="center">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_4416_2693)">
                                                                <path d="M11.7841 20.5331C16.6258 20.5331 20.5507 16.6082 20.5507 11.7666C20.5507 6.92493 16.6258 3 11.7841 3C6.9425 3 3.01758 6.92493 3.01758 11.7666C3.01758 16.6082 6.9425 20.5331 11.7841 20.5331Z" stroke="white" stroke-width="1.40265" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M11.6895 7.16016V12.274H16.8033" stroke="white" stroke-width="1.40265" stroke-linecap="round" stroke-linejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_4416_2693">
                                                                    <rect width="23.3775" height="23.3775" fill="white" transform="translate(0 0.585938)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </TableCell>
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {['', '', '', '', ''].map((row, i) => { */}
                                            {usermusic?.length > 0 ?
                                                usermusic.map((row, i) => {
                                                    return (
                                                        <TableRow
                                                            // key={row.name}
                                                            sx={{ '&:lastchild td, &:last-child th': { border: 0 } }}
                                                            onMouseEnter={() => setIsHovered(true)}
                                                            onMouseLeave={() => setIsHovered(false)}
                                                        >
                                                            <TableCell align="center" className='plybt-div'>
                                                                {isHovered ?
                                                                    // audioDurations[row?.audio] !== undefined &&
                                                                    <>
                                                                        {/* {audioplays[i] === 0 || */}
                                                                        {playingIndex !== i ||
                                                                            isplay === false ? (
                                                                            <PlayArrowIcon className='plybt'
                                                                                onClick={() => {
                                                                                    setAudio(row, i);
                                                                                    // createMusic(row?._id);
                                                                                    setAudiosource(row);
                                                                                    setTracks(i)
                                                                                    handlePlayPause(i)
                                                                                }} />

                                                                        ) : (
                                                                            <PauseIcon
                                                                                onClick={() => {
                                                                                    setisplay(false);
                                                                                }} />
                                                                        )}
                                                                    </>
                                                                    :
                                                                    i + 1}</TableCell>
                                                            <TableCell align="center">
                                                                <div className="display-1">
                                                                    <div className="tb-img">
                                                                        <img src={row?.image ? row?.image : card} alt="" />
                                                                    </div>
                                                                    <div className="wecant">
                                                                        {/* We can't be friends */}
                                                                        {row?.song_name > 15 ? row?.song_name.slice(0, 15) + '...' : row?.song_name}
                                                                        <div className="inge-nmae">
                                                                            {/* Singer Name */}
                                                                            {row?.createUser?.name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <div className="dtead">
                                                                    {/* 22/01/2024, at 06.30pm {row?.createdAt} */}
                                                                    {`${moment(row?.createdAt).format('DD/MM/YYYY')}, at ${moment(row?.createdAt).format('hh.mmA').toLowerCase()}`}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <div className="dtead">
                                                                    {convertToInternationalCurrencySystem(row?.plays)} listened
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <div className="dtead">
                                                                    {/* 3:39 */} {audioDurations[row?.audio] != undefined ? audioDurations[row?.audio] : null}
                                                                </div>
                                                            </TableCell>
                                                            {/* <TableCell align="center" className='plybt-div'>
                                                                {isHovered && type !== 'track' && type !== 'liked' ?
                                                                    <MoreHorizIcon onClick={(e) => { handleClick3(e); setSong_id(row?._id) }} />
                                                                    :
                                                                    <></>
                                                                }
                                                                
                                                            </TableCell> */}
                                                        </TableRow>
                                                    )
                                                })
                                                :
                                                songs?.length > 0 ?
                                                    songs && songs.map((row, i) => {
                                                        return (
                                                            <TableRow
                                                                // key={row.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                onMouseEnter={() => setIsHovered(true)}
                                                                onMouseLeave={() => setIsHovered(false)}
                                                                className='cursor'

                                                            >
                                                                <TableCell align="left" className='plybt-div'>{isHovered ?
                                                                    audioDurations[row?.audio] !== undefined &&
                                                                    <>
                                                                        {/* {audioplays[i] === 0 || */}
                                                                        {playingIndex !== i ||
                                                                            isplay === false ? (
                                                                            <PlayArrowIcon className='plybt'
                                                                                onClick={() => {
                                                                                    setAudio(row, i);
                                                                                    createMusic(row?._id);
                                                                                    setAudiosource(row?.audio);
                                                                                    setTracks(i)
                                                                                    handlePlayPause(i)
                                                                                }}


                                                                            />

                                                                        ) : (
                                                                            <PauseIcon
                                                                                onClick={() => {
                                                                                    setisplay(false);
                                                                                }}
                                                                                className='plybt'
                                                                            />
                                                                        )}
                                                                    </>

                                                                    : i + 1}</TableCell>
                                                                <TableCell align="center">
                                                                    <div className="display-1">
                                                                        <div className="tb-img">
                                                                            <img src={row?.image && !row?.image?.includes('localhost') ? row?.image : card} alt="" />
                                                                        </div>
                                                                        <div className="wecant">
                                                                            {/* We can't be friends */}
                                                                            {row?.song_name.length > 15 ? row?.song_name.slice(0, 15) + '...' : row?.song_name}
                                                                            <div className="inge-nmae">
                                                                                {/* Singer Name */}
                                                                                {row?.createUser?.name}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div className="dtead">
                                                                        {/* 22/01/2024, at 06.30pm {row?.createdAt} */}
                                                                        {`${moment(row?.createdAt).format('DD/MM/YYYY')}, at ${moment(row?.createdAt).format('hh.mmA').toLowerCase()}`}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div className="dtead">
                                                                        {convertToInternationalCurrencySystem(row?.plays)} listened
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <div className="dtead">
                                                                        {/* 3:39 */} {audioDurations[row?.audio] != undefined ? audioDurations[row?.audio] : 0.00}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell align="center" className='plybt-div'>
                                                                    {isHovered && type !== 'track' && type !== 'liked' && type !== 'tracksongs' && localStorage.getItem('role') == 'artist' ?
                                                                        <MoreHorizIcon onClick={(e) => { handleClick3(e); setSong_id(row?._id) }} />
                                                                        :
                                                                        <></>
                                                                    }

                                                                    <Popover
                                                                        id={id3}
                                                                        open={open3}
                                                                        anchorEl={anchorEl3}
                                                                        onClose={handleClick4}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'left',
                                                                        }}

                                                                    >
                                                                        <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }} onClick={() => { removesong(song_id) }}>
                                                                            <div className=' display-1 cursor' >
                                                                                <div className=''>
                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <g clip-path="url(#clip0_4446_2782)">
                                                                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_4446_2782">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className='frstname' >
                                                                                        {type == 'playlist' ? "Remove from Playlist" : "Remove from Album"}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Popover>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }) :
                                                    likedSongs?.length > 0 ?
                                                        likedSongs && likedSongs.map((row, i) => {
                                                            return (
                                                                row?.PlaylistDetails ?
                                                                    <TableRow
                                                                        // key={row.name}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        onMouseEnter={() => setIsHovered(true)}
                                                                        onMouseLeave={() => setIsHovered(false)}
                                                                        onClick={() => {
                                                                            navigate(`/album/${row?.musics?.[0]?._id}`)
                                                                        }}
                                                                    >
                                                                        {/* <TableCell align="center" className='plybt-div'>{isHovered ?
                                                                            audioDurations[row?.audio] !== undefined &&
                                                                            <>
                                                                                {audioplays[i] === 0 ||
                                                                                {playingIndex !== i ||
                                                                                    isplay === false ? (
                                                                                    <PlayArrowIcon className='plybt'
                                                                                        onClick={() => {
                                                                                            setAudio(row, i);
                                                                                            createMusic(row?._id);
                                                                                            setAudiosource(row?.audio);
                                                                                            setTracks(i)
                                                                                            handlePlayPause(i)
                                                                                        }} />

                                                                                ) : (
                                                                                    <PauseIcon
                                                                                        onClick={() => {
                                                                                            setisplay(false);
                                                                                        }} />
                                                                                )}
                                                                            </>

                                                                            : i + 1}
                                                                        </TableCell> */}
                                                                        <TableCell align="center" className='plybt-div'>{isHovered ?
                                                                            <>
                                                                                {playingIndex !== i ||
                                                                                    isplay === false ? (
                                                                                    <PlayArrowIcon className='plybt'
                                                                                    // onClick={() => {
                                                                                    //     navigate(`/album/${row?.musics?.[0]?._id}`)
                                                                                    // }} 
                                                                                    />

                                                                                ) : (
                                                                                    <PauseIcon
                                                                                        onClick={() => {
                                                                                            setisplay(false);
                                                                                        }} />
                                                                                )}
                                                                            </>

                                                                            : i + 1}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <div className="display-1">
                                                                                <div className="tb-img">
                                                                                    <img src={row?.PlaylistDetails?.image && !row?.PlaylistDetails?.image?.includes('localhost') ? row?.PlaylistDetails?.image : card} alt="" />
                                                                                </div>
                                                                                <div className="wecant"
                                                                                // onClick={() => { navigate(`/album/${row?.musics?.[0]?._id}`) }}
                                                                                >
                                                                                    {/* We can't be friends */}
                                                                                    {row?.PlaylistDetails?.playlist?.length > 15 ? row?.PlaylistDetails?.playlist?.slice(0, 15) + '...' : row?.PlaylistDetails?.playlist}
                                                                                    <div className="inge-nmae">
                                                                                        {/* Singer Name */}
                                                                                        {row?.musics?.[0]?.createUser?.name}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <div className="dtead">
                                                                                {/* 22/01/2024, at 06.30pm {row?.createdAt} */}
                                                                                {`${moment(row?.createdAt).format('DD/MM/YYYY')}, at ${moment(row?.createdAt).format('hh.mmA').toLowerCase()}`}
                                                                            </div>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <div className="dtead">
                                                                                {convertToInternationalCurrencySystem(row?.PlaylistDetails?.plays)} listened
                                                                            </div>
                                                                        </TableCell>
                                                                        {/* <TableCell align="center">
                                                                            <div className="dtead">
                                                                                {"Playlist"}
                                                                            </div>
                                                                        </TableCell> */}
                                                                        {/* <TableCell align="center" className='plybt-div'>
                                                                            {isHovered && row?.PlaylistSong ?
                                                                                <MoreHorizIcon onClick={(e) => { handleClick3(e); setSong_id(row?._id) }} />
                                                                                :
                                                                                <></>
                                                                            }

                                                                            <Popover
                                                                                id={id3}
                                                                                open={open3}
                                                                                anchorEl={anchorEl3}
                                                                                onClose={handleClick4}
                                                                                anchorOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'left',
                                                                                }}

                                                                            >
                                                                                <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }} onClick={() => { removesong(song_id) }}>
                                                                                    <div className=' display-1 cursor' >
                                                                                        <div className=''>
                                                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_4446_2782)">
                                                                                                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                </g>
                                                                                                <defs>
                                                                                                    <clipPath id="clip0_4446_2782">
                                                                                                        <rect width="20" height="20" fill="white" />
                                                                                                    </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div className='frstname' >
                                                                                                Remove from Playlist
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Popover>
                                                                        </TableCell> */}
                                                                    </TableRow> :
                                                                    row?.AlbumDetails ?
                                                                        <TableRow
                                                                            // key={row.name}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            onMouseEnter={() => setIsHovered(true)}
                                                                            onMouseLeave={() => setIsHovered(false)}
                                                                            onClick={() => {
                                                                                navigate(`/album/${row?.musics?.[0]?._id}`)
                                                                            }}
                                                                        >
                                                                            <TableCell align="center" className='plybt-div'>{isHovered ?
                                                                                <>
                                                                                    {playingIndex !== i ||
                                                                                        isplay === false ? (
                                                                                        <PlayArrowIcon className='plybt'
                                                                                        // onClick={() => {
                                                                                        //     navigate(`/album/${row?.musics?.[0]?._id}`)
                                                                                        // }} 
                                                                                        />

                                                                                    ) : (
                                                                                        <PauseIcon
                                                                                            onClick={() => {
                                                                                                setisplay(false);
                                                                                            }} />
                                                                                    )}
                                                                                </>

                                                                                : i + 1}
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <div className="display-1">
                                                                                    <div className="tb-img">
                                                                                        <img src={row?.AlbumDetails?.image && !row?.AlbumDetails?.image?.includes('localhost') ? row?.AlbumDetails?.image : card} alt="" />
                                                                                    </div>
                                                                                    <div className="wecant"
                                                                                    // onClick={() => { navigate(`/album/${row?.musics?.[0]?._id}`) }}
                                                                                    >
                                                                                        {/* We can't be friends */}
                                                                                        {row?.AlbumDetails?.album_name?.length > 15 ? row?.AlbumDetails?.album_name?.slice(0, 15) + '...' : row?.AlbumDetails?.album_name}
                                                                                        <div className="inge-nmae">
                                                                                            {/* Singer Name */}
                                                                                            {row?.musics?.[0]?.createUser?.name}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <div className="dtead">
                                                                                    {/* 22/01/2024, at 06.30pm {row?.createdAt} */}
                                                                                    {`${moment(row?.createdAt).format('DD/MM/YYYY')}, at ${moment(row?.createdAt).format('hh.mmA').toLowerCase()}`}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell align="center">
                                                                                <div className="dtead">
                                                                                    {convertToInternationalCurrencySystem(row?.AlbumDetails?.plays)} listened
                                                                                </div>
                                                                            </TableCell>
                                                                            {/* <TableCell align="center">
                                                                                <div className="dtead">
                                                                                     {audioDurations[row?.audio] != undefined ? audioDurations[row?.audio] : 0.00}
                                                                                </div>
                                                                            </TableCell> */}
                                                                            {/* <TableCell align="center">
                                                                                <div className="dtead">
                                                                                    {"Album"}
                                                                                </div>
                                                                            </TableCell> */}
                                                                            {/* <TableCell align="center" className='plybt-div'>
                                                                                {isHovered && row?.PlaylistSong ?
                                                                                    <MoreHorizIcon onClick={(e) => { handleClick3(e); setSong_id(row?._id) }} />
                                                                                    :
                                                                                    <></>
                                                                                }

                                                                                <Popover
                                                                                    id={id3}
                                                                                    open={open3}
                                                                                    anchorEl={anchorEl3}
                                                                                    onClose={handleClick4}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'bottom',
                                                                                        horizontal: 'right',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'left',
                                                                                    }}

                                                                                >
                                                                                    <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }} onClick={() => { removesong(song_id) }}>
                                                                                        <div className=' display-1 cursor' >
                                                                                            <div className=''>
                                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <g clip-path="url(#clip0_4446_2782)">
                                                                                                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </g>
                                                                                                    <defs>
                                                                                                        <clipPath id="clip0_4446_2782">
                                                                                                            <rect width="20" height="20" fill="white" />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                </svg>
                                                                                            </div>
                                                                                            <div>
                                                                                                <div className='frstname' >
                                                                                                    Remove from Playlist
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Popover>
                                                                            </TableCell> */}
                                                                        </TableRow> :
                                                                        row?.musics ?
                                                                            <TableRow
                                                                                // key={row.name}
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                onMouseEnter={() => setIsHovered(true)}
                                                                                onMouseLeave={() => setIsHovered(false)}
                                                                                onClick={() => {
                                                                                    navigate(`/album/${row?.musics?.[0]?._id}`)
                                                                                }}
                                                                            >
                                                                                <TableCell align="center" className='plybt-div'>{isHovered ?
                                                                                    // audioDurations[row?.musics?.[0]?.audio] !== undefined &&
                                                                                    <>
                                                                                        {/* {audioplays[i] === 0 || */}
                                                                                        {playingIndex !== i ||
                                                                                            isplay === false ? (
                                                                                            <PlayArrowIcon className='plybt'
                                                                                            // onClick={() => {
                                                                                            //     // setAudio(row?.musics?.[0], i);
                                                                                            //     // createMusic(row?.musics?.[0]?._id);
                                                                                            //     // setAudiosource(row?.musics?.[0]?.audio);
                                                                                            //     // setTracks(i)
                                                                                            //     // handlePlayPause(i)
                                                                                            //     navigate(`/album/${row?.musics?.[0]?._id}`)
                                                                                            // }} 
                                                                                            />

                                                                                        ) : (
                                                                                            <PauseIcon
                                                                                                onClick={() => {
                                                                                                    setisplay(false);
                                                                                                }} />
                                                                                        )}
                                                                                    </>

                                                                                    : i + 1}
                                                                                </TableCell>
                                                                                {/* <TableCell align="center" className='plybt-div'>{i + 1}</TableCell> */}
                                                                                <TableCell align="center">
                                                                                    <div className="display-1">
                                                                                        <div className="tb-img">
                                                                                            <img src={row?.musics?.[0]?.image && !row?.musics?.[0]?.image?.includes('localhost') ? row?.musics?.[0]?.image : card} alt="" />
                                                                                        </div>
                                                                                        <div className="wecant">
                                                                                            {/* We can't be friends */}
                                                                                            {row?.musics?.[0]?.song_name?.length > 15 ? row?.musics?.[0]?.song_name?.slice(0, 15) + '...' : row?.musics?.[0]?.song_name}
                                                                                            <div className="inge-nmae">
                                                                                                {/* Singer Name */}
                                                                                                {row?.createUser?.name}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <div className="dtead">
                                                                                        {/* 22/01/2024, at 06.30pm {row?.createdAt} */}
                                                                                        {`${moment(row?.createdAt).format('DD/MM/YYYY')}, at ${moment(row?.createdAt).format('hh.mmA').toLowerCase()}`}
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <div className="dtead">
                                                                                        {convertToInternationalCurrencySystem(row?.musics?.[0]?.plays)} listened
                                                                                    </div>
                                                                                </TableCell>
                                                                                {/* <TableCell align="center">
                                                                                <div className="dtead">
                                                                                    3:39 {audioDurations[row?.audio] != undefined ? audioDurations[row?.audio] : 0.00}
                                                                                    {"Track"}
                                                                                </div>
                                                                            </TableCell> */}
                                                                                {/* <TableCell align="center" className='plybt-div'>
                                                                                    {isHovered && row?.PlaylistSong ?
                                                                                        <MoreHorizIcon onClick={(e) => { handleClick3(e); setSong_id(row?._id) }} />
                                                                                        :
                                                                                        <></>
                                                                                    }

                                                                                    <Popover
                                                                                        id={id3}
                                                                                        open={open3}
                                                                                        anchorEl={anchorEl3}
                                                                                        onClose={handleClick4}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'right',
                                                                                        }}
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'left',
                                                                                        }}

                                                                                    >
                                                                                        <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }} onClick={() => { removesong(song_id) }}>
                                                                                            <div className=' display-1 cursor' >
                                                                                                <div className=''>
                                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <g clip-path="url(#clip0_4446_2782)">
                                                                                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                            <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                            <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        </g>
                                                                                                        <defs>
                                                                                                            <clipPath id="clip0_4446_2782">
                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                            </clipPath>
                                                                                                        </defs>
                                                                                                    </svg>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div className='frstname' >
                                                                                                        Remove from Playlist
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Popover>
                                                                                </TableCell> */}
                                                                            </TableRow> :
                                                                            <TableRow
                                                                                // key={row.name}
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                onMouseEnter={() => setIsHovered(true)}
                                                                                onMouseLeave={() => setIsHovered(false)}

                                                                            >
                                                                                {/* <TableCell align="center" className='plybt-div'>{isHovered ?
                                                                                audioDurations[row?.audio] !== undefined &&
                                                                                <>
                                                                                    {audioplays[i] === 0 ||
                                                                                        { playingIndex !== i ||
                                                                                        isplay === false ? (
                                                                                        <PlayArrowIcon className='plybt'
                                                                                            onClick={() => {
                                                                                                setAudio(row, i);
                                                                                                createMusic(row?._id);
                                                                                                setAudiosource(row?.audio);
                                                                                                setTracks(i)
                                                                                                handlePlayPause(i)
                                                                                            }} />

                                                                                    ) : (
                                                                                        <PauseIcon
                                                                                            onClick={() => {
                                                                                                setisplay(false);
                                                                                            }} />
                                                                                    )}
                                                                                </>

                                                                                : i + 1}
                                                                            </TableCell> */}
                                                                                <TableCell align="center" className='plybt-div'>{isHovered ?
                                                                                    <>
                                                                                        {/* {audioplays[i] === 0 || */}
                                                                                        {playingIndex !== i ||
                                                                                            isplay === false ? (
                                                                                            <PlayArrowIcon className='plybt'
                                                                                                onClick={() => {
                                                                                                    navigate(`/album/${row?._id}`)
                                                                                                }} />

                                                                                        ) : (
                                                                                            <PauseIcon
                                                                                                onClick={() => {
                                                                                                    setisplay(false);
                                                                                                }} />
                                                                                        )}
                                                                                    </>

                                                                                    : i + 1}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <div className="display-1">
                                                                                        <div className="tb-img">
                                                                                            <img src={row?.image && !row?.image?.includes('localhost') ? row?.image : card} alt="" />
                                                                                        </div>
                                                                                        <div className="wecant" onClick={() => { navigate(`/album/${row?._id}`) }}>
                                                                                            {/* We can't be friends */}
                                                                                            {row?.song_name?.length > 15 ? row?.song_name?.slice(0, 15) + '...' : row?.song_name}
                                                                                            <div className="inge-nmae">
                                                                                                {/* Singer Name */}
                                                                                                {row?.createUser?.name}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <div className="dtead">
                                                                                        {/* 22/01/2024, at 06.30pm {row?.createdAt} */}
                                                                                        {`${moment(row?.createdAt).format('DD/MM/YYYY')}, at ${moment(row?.createdAt).format('hh.mmA').toLowerCase()}`}
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    <div className="dtead">
                                                                                        {convertToInternationalCurrencySystem(row?.plays)} listened
                                                                                    </div>
                                                                                </TableCell>
                                                                                {/* <TableCell align="center">
                                                                                <div className="dtead">
                                                                                    3:39 {audioDurations[row?.audio] != undefined ? audioDurations[row?.audio] : 0.00}
                                                                                    {"Track"}
                                                                                </div>
                                                                            </TableCell> */}
                                                                                {/* <TableCell align="center" className='plybt-div'>
                                                                                    {isHovered && row?.PlaylistSong ?
                                                                                        <MoreHorizIcon onClick={(e) => { handleClick3(e); setSong_id(row?._id) }} />
                                                                                        :
                                                                                        <></>
                                                                                    }

                                                                                    <Popover
                                                                                        id={id3}
                                                                                        open={open3}
                                                                                        anchorEl={anchorEl3}
                                                                                        onClose={handleClick4}
                                                                                        anchorOrigin={{
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'right',
                                                                                        }}
                                                                                        transformOrigin={{
                                                                                            vertical: 'top',
                                                                                            horizontal: 'left',
                                                                                        }}

                                                                                    >
                                                                                        <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }} onClick={() => { removesong(song_id) }}>
                                                                                            <div className=' display-1 cursor' >
                                                                                                <div className=''>
                                                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <g clip-path="url(#clip0_4446_2782)">
                                                                                                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                            <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                            <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        </g>
                                                                                                        <defs>
                                                                                                            <clipPath id="clip0_4446_2782">
                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                            </clipPath>
                                                                                                        </defs>
                                                                                                    </svg>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div className='frstname' >
                                                                                                        Remove from Playlist
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Popover>
                                                                                </TableCell> */}
                                                                            </TableRow>
                                                            )
                                                        }) :

                                                        <TableRow
                                                            // key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="center" colSpan={5}>
                                                                <img width="100" height="100" src="https://img.icons8.com/external-filled-outline-berkahicon/100/external-al-eid-al-fitr-filled-outline-berkahicon-15.png" alt="external-al-eid-al-fitr-filled-outline-berkahicon-15" />
                                                                <div style={{ color: "white", textAlign: 'center' }}>No Songs Found</div>
                                                            </TableCell>
                                                        </TableRow>
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid xs={12} sx={12} md={12} lg={12} xl={3}>
                                <DynamicCut
                                    getplayLists={getplayLists}
                                    setPlaylistDetails={setPlaylistDetails}
                                    PlaylistDetails={PlaylistDetails}
                                    getOneAlbum={getOneAlbum}
                                    getOneTrack={getOneTrack}
                                    getUserFav={getUserFav}
                                    tabValue={value}
                                    setTabValue={setValue}
                                    isAlive={isAlive}
                                    setIsAlive={setIsAlive}
                                    getUserSaved={getUserSaved}
                                    name={name}
                                    usermusic={usermusic}
                                    setUsermusic={setUsermusic}
                                    setAudioSourceArr={setAudioSourceArr}
                                    likedSongsCounts={likedSongsCounts}
                                    savedSongsCount={savedSongsCount}
                                    setType={setType}
                                    type={type}
                                    getMusicLibrary={getMusicLibrary}
                                    setSongs={setSongs}
                                />
                            </Grid>
                        </Grid>
                        {/* <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}

                        >
                            <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                                <div className=' display-1 cursor'
                                    onClick={addPlaylist}
                                >
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                                        </svg>

                                    </div>
                                    <div>
                                        <div className='frstname' >
                                            Create PlayList
                                        </div>

                                    </div>

                                </div>
                                <div className=' display-1 cursor' onClick={addFolder} >
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.64102 4.99967L9.30768 6.66634H16.666V14.9997H3.33268V4.99967H7.64102ZM8.33268 3.33301H3.33268C2.41602 3.33301 1.67435 4.08301 1.67435 4.99967L1.66602 14.9997C1.66602 15.9163 2.41602 16.6663 3.33268 16.6663H16.666C17.5827 16.6663 18.3327 15.9163 18.3327 14.9997V6.66634C18.3327 5.74967 17.5827 4.99967 16.666 4.99967H9.99935L8.33268 3.33301Z" fill="#636363" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div className='frstname' >
                                            Create a playlist folder
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </Popover> */}
                        <Popover
                            id={id2}
                            open={open2}
                            anchorEl={anchorEl2}
                            onClose={handleClose3}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}

                        >
                            <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>
                                {/* <div className=' display-1 cursor' >
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_4446_2782)">
                                                <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M4.98438 15.5757C5.45462 14.6494 6.17216 13.8715 7.05745 13.3281C7.94275 12.7847 8.96123 12.4971 10 12.4971C11.0388 12.4971 12.0572 12.7847 12.9425 13.3281C13.8278 13.8715 14.5454 14.6494 15.0156 15.5757" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4446_2782">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div>
                                        <div className='frstname' >
                                            Remove from profile
                                        </div>

                                    </div>

                                </div> */}

                                {/* <hr style={{ borderColor: '#ffffff5c' }} /> */}
                                <div className=' display-1 cursor' onClick={() => { handleOpen(); update() }}>
                                    <div className=''>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 15.8751H1.75C1.58424 15.8751 1.42527 15.8093 1.30806 15.692C1.19085 15.5748 1.125 15.4159 1.125 15.2501V11.7587C1.12508 11.5932 1.19082 11.4344 1.30781 11.3173L10.9422 1.68291C11.0594 1.56579 11.2183 1.5 11.384 1.5C11.5497 1.5 11.7086 1.56579 11.8258 1.68291L15.3172 5.17198C15.4343 5.28917 15.5001 5.44808 15.5001 5.61377C15.5001 5.77946 15.4343 5.93837 15.3172 6.05557L5.5 15.8751Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M14.875 15.875H5.5" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.625 4L13 8.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div className='frstname'>
                                            Edit details
                                        </div>
                                    </div>
                                </div>
                                <div className=' display-1 cursor' onClick={() => { handleOpen2(); update() }}>
                                    <div className=''>
                                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.875 3.375H1.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.125 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.875 7.125V12.125" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.625 3.375V15.25C13.625 15.4158 13.5592 15.5747 13.4419 15.6919C13.3247 15.8092 13.1658 15.875 13 15.875H3C2.83424 15.875 2.67527 15.8092 2.55806 15.6919C2.44085 15.5747 2.375 15.4158 2.375 15.25V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M11.125 3.375V2.125C11.125 1.79348 10.9933 1.47554 10.7589 1.24112C10.5245 1.0067 10.2065 0.875 9.875 0.875H6.125C5.79348 0.875 5.47554 1.0067 5.24112 1.24112C5.0067 1.47554 4.875 1.79348 4.875 2.125V3.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>


                                    </div>
                                    <div>
                                        <div className='frstname'>
                                            Delete
                                        </div>

                                    </div>

                                </div>
                                {/* <hr style={{ borderColor: '#ffffff5c' }} />
                                <div className=' display-1 cursor' onClick={() => { addPlaylist() }}>
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.66602 1.66699H18.3327V10.8337H16.666V3.33366H3.33268V16.667H10.8327V18.3337H1.66602V1.66699ZM9.99935 5.83366H13.3327V7.50033H11.666V11.667C11.6657 12.1976 11.4966 12.7144 11.1832 13.1426C10.8697 13.5707 10.4281 13.888 9.92234 14.0485C9.41657 14.2091 8.87284 14.2045 8.36984 14.0355C7.86683 13.8665 7.43064 13.5419 7.1244 13.1085C6.81815 12.6752 6.65773 12.1556 6.66635 11.6251C6.67497 11.0945 6.85218 10.5804 7.17234 10.1573C7.49251 9.73412 7.93901 9.4238 8.44724 9.27124C8.95547 9.11867 9.49906 9.13178 9.99935 9.30866V5.83366ZM9.99935 11.667C9.99935 11.446 9.91155 11.234 9.75527 11.0777C9.59899 10.9215 9.38703 10.8337 9.16602 10.8337C8.945 10.8337 8.73304 10.9215 8.57676 11.0777C8.42048 11.234 8.33268 11.446 8.33268 11.667C8.33268 11.888 8.42048 12.1 8.57676 12.2562C8.73304 12.4125 8.945 12.5003 9.16602 12.5003C9.38703 12.5003 9.59899 12.4125 9.75527 12.2562C9.91155 12.1 9.99935 11.888 9.99935 11.667ZM16.666 12.5003V15.0003H19.166V16.667H16.666V19.167H14.9993V16.667H12.4993V15.0003H14.9993V12.5003H16.666Z" fill="#636363" />
                                        </svg>

                                    </div>
                                    <div>
                                        <div className='frstname' >
                                            Create PlayList
                                        </div>

                                    </div>

                                </div>
                                <div className=' display-1 cursor' onClick={() => { addFolder() }} >
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_4446_2802)">
                                                <path d="M3.125 10H16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10 3.125V16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4446_2802">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </div>
                                    <div>
                                        <div className='frstname' >
                                            Create a Playlist folder
                                        </div>

                                    </div>

                                </div> */}
                                {/* <div className=' display-1 cursor' >
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.8227 10.3539L12.6275 12.1587L12.1596 12.6266L10.3549 10.8218L10.0013 10.4682L9.64775 10.8218L7.84297 12.6266L7.37508 12.1587L9.17986 10.3539L9.53341 10.0003L9.17986 9.64677L7.37508 7.84199L7.84297 7.3741L9.64775 9.17888L10.0013 9.53243L10.3549 9.17888L12.1596 7.3741L12.6275 7.84199L10.8227 9.64677L10.4692 10.0003L10.8227 10.3539ZM2.83464 10.0003C2.83464 13.9515 6.05016 17.167 10.0013 17.167C13.9524 17.167 17.168 13.9515 17.168 10.0003C17.168 6.04918 13.9524 2.83366 10.0013 2.83366C6.05016 2.83366 2.83464 6.04918 2.83464 10.0003ZM2.16797 10.0003C2.16797 5.66813 5.66911 2.16699 10.0013 2.16699C14.3335 2.16699 17.8346 5.66813 17.8346 10.0003C17.8346 14.3325 14.3335 17.8337 10.0013 17.8337C5.66911 17.8337 2.16797 14.3325 2.16797 10.0003Z" stroke="#636363" />
                                        </svg>


                                    </div>
                                    <div>
                                        <div className='frstname' >
                                            Exclude from your taste profile
                                        </div>

                                    </div>

                                </div> */}
                                <div className=' display-1 cursor' onClick={(e) => { handleClickOpenEl4(e); getFolder() }} >
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_4446_2807)">
                                                <path d="M17.5 6.875V15.6945C17.5 15.8419 17.4415 15.9831 17.3373 16.0873C17.2331 16.1915 17.0919 16.25 16.9445 16.25H3.125C2.95924 16.25 2.80027 16.1842 2.68306 16.0669C2.56585 15.9497 2.5 15.7908 2.5 15.625V5C2.5 4.83424 2.56585 4.67527 2.68306 4.55806C2.80027 4.44085 2.95924 4.375 3.125 4.375H7.29141C7.42664 4.375 7.55822 4.41886 7.66641 4.5L10 6.25H16.875C17.0408 6.25 17.1997 6.31585 17.3169 6.43306C17.4342 6.55027 17.5 6.70924 17.5 6.875Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4446_2807">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div>
                                        <div className='frstname' >
                                            Move to folder
                                        </div>

                                    </div>



                                </div>
                                <Popover
                                    id={id4}
                                    open={open4}
                                    anchorEl={anchorEl4}
                                    onClose={handleClickCloseEl4}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                // onClose={handlePopoverClose3}
                                // disableRestoreFocus
                                // className='siPopover'
                                >
                                    <div className="createplylist" style={{ padding: '10px', borderRadius: '10px' }}>


                                        <div className=' display-1 cursor' >
                                            <div className=''>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_4446_2802)">
                                                        <path d="M3.125 10H16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10 3.125V16.875" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4446_2802">
                                                            <rect width="20" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </div>
                                            {/* <NavLink to='/playlistnew' state={song_id}> */}
                                            <div className='frstname cursor'
                                                //  onClick={() => { addFolder() }}
                                                onClick={() => { handleOpen3() }}
                                            >
                                                New Folder
                                            </div>
                                            {/* </NavLink> */}

                                        </div>

                                        <hr style={{ borderColor: '#ffffff5c' }} />
                                        {
                                            folder.length > 0 ?
                                                folder?.map((row, ind) => (
                                                    <div className=' display-1 cursor'
                                                        onClick={() => { movetofolder(row?._id); }}
                                                    >
                                                        <div className=''>
                                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.5 15.8751H1.75C1.58424 15.8751 1.42527 15.8093 1.30806 15.692C1.19085 15.5748 1.125 15.4159 1.125 15.2501V11.7587C1.12508 11.5932 1.19082 11.4344 1.30781 11.3173L10.9422 1.68291C11.0594 1.56579 11.2183 1.5 11.384 1.5C11.5497 1.5 11.7086 1.56579 11.8258 1.68291L15.3172 5.17198C15.4343 5.28917 15.5001 5.44808 15.5001 5.61377C15.5001 5.77946 15.4343 5.93837 15.3172 6.05557L5.5 15.8751Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M14.875 15.875H5.5" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                <path d="M8.625 4L13 8.375" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            {/* <img src={row?.image} /> */}
                                                        </div>
                                                        <div>
                                                            <div className='frstname'  >
                                                                {row?.folder_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) :
                                                <><p>You Don't have Folders</p></>
                                        }

                                        <hr style={{ borderColor: '#ffffff5c' }} />
                                    </div>
                                </Popover>
                                <Modal
                                    open={model3}
                                    // onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    className="modal-style"
                                >
                                    <Box sx={style3}>
                                        <div className="display-2">
                                            <div className="edtdet">
                                                Create Folder
                                            </div>
                                            <div className="cls" onClick={handleClose6}>
                                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="var(--clr-font)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>

                                        </div>
                                        <div className="margin-top display-1">

                                            <div className="mdoel-inpu">
                                                <TextField id="outlined-basic" value={folderName} placeholder="Name" variant="outlined" onChange={(e) => { setFolderName(e.target.value) }} />
                                                {folderNameerr && <p>{folderNameerr}</p>}
                                            </div>
                                        </div>
                                        <div className="sve text-center">
                                            <Button onClick={() => { addFolder() }}>
                                                Save
                                            </Button>
                                        </div>
                                        <div className="by-precee" style={{ color: 'var(--clr-font)' }}>
                                            By proceeding, you agree to give Dreamster access to the image you choose to Upload Please make sure you have the right to upload the image.
                                        </div>
                                    </Box>
                                </Modal>
                                <div className=' display-1 cursor' onClick={() => { update(); pinList(); }} >
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_4446_2811)">
                                                <path d="M17.9422 6.68291C18.0594 6.56571 18.1252 6.40681 18.1252 6.24112C18.1252 6.07543 18.0594 5.91652 17.9422 5.79932L13.2032 1.05791C13.086 0.940792 12.9271 0.875 12.7614 0.875C12.5957 0.875 12.4368 0.940792 12.3196 1.05791L7.84303 5.54698C7.84303 5.54698 5.67506 4.46338 3.35943 6.33213C3.29077 6.3871 3.23447 6.45593 3.19424 6.53415C3.154 6.61236 3.13072 6.69818 3.12593 6.78601C3.12114 6.87383 3.13493 6.96168 3.16642 7.04381C3.19791 7.12593 3.24637 7.20049 3.30865 7.2626L11.7383 15.6915C11.8015 15.7542 11.8773 15.8027 11.9606 15.8337C12.044 15.8647 12.133 15.8776 12.2218 15.8715C12.3105 15.8653 12.3969 15.8403 12.4752 15.7981C12.5535 15.7559 12.6219 15.6974 12.6758 15.6267C13.3313 14.7548 14.361 12.9634 13.4657 11.1735L17.9422 6.68291Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.52266 11.4775L3.75 15.2502" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4446_2811">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </div>
                                    <div>
                                        <div className='frstname'  >
                                            {
                                                userPin == false ?
                                                    type == 'track' ? 'Pin Track'
                                                        : type == 'album' ? 'Pin Album'
                                                            : 'Pin Playlist'
                                                    : "Remove From PinList"
                                            }

                                        </div>

                                    </div>

                                </div>
                                {/* <hr style={{ borderColor: '#ffffff5c' }} />
                                <div className=' display-1 cursor' onClick={() => { sendTracks(PlaylistDetails?.id) }}>
                                    <div className=''>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_4446_2816)">
                                                <path d="M2.41876 14.525C3.70469 13.1555 7.08048 10.25 11.8773 10.25V14L18.1273 7.75L11.8773 1.5V5.25C7.75235 5.25 2.46407 9.19141 1.87735 14.2766C1.86923 14.3424 1.88228 14.4091 1.9146 14.4671C1.94693 14.525 1.99686 14.5712 2.05717 14.5988C2.11747 14.6265 2.18502 14.6343 2.25003 14.621C2.31504 14.6077 2.37413 14.5741 2.41876 14.525Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_4446_2816">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>


                                    </div>
                                    <div>
                                        <div className='frstname' >
                                            Share
                                        </div>

                                    </div>

                                </div> */}
                            </div>
                        </Popover>
                        <Modal
                            open={model}
                            // onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className="modal-style"
                        >
                            <Box sx={style}>
                                <div className="display-2">
                                    <div className="edtdet">
                                        Edit details
                                    </div>
                                    <div className="cls" onClick={handleClose2}>
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="#4C4C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>

                                </div>
                                <div className="margin-top display-1">

                                    <Button
                                        className='lbk'
                                        component="label"
                                        role={undefined}
                                        // variant="contained"
                                        tabIndex={-1}
                                    // startIcon={<CloudUploadIcon />}
                                    >
                                        <div className="palylistimg cursor">
                                            <img src={imageUrl ? imageUrl : playlistimg} alt="playlist" />
                                        </div>
                                        <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                                        <div className='chsimg'>
                                            Choose Image
                                        </div>
                                    </Button>
                                    {imageerr && <p>{imageerr}</p>}
                                    <div className="mdoel-inpu">
                                        <TextField id="outlined-basic"
                                            value={playlist}
                                            placeholder="Name"
                                            variant="outlined"
                                            onChange={(e) => { setplaylist(e.target.value); setplaylisterr('') }} />
                                        {playlisterr && <p style={{ color: "red" }}>{playlisterr}</p>}

                                        {/* <Select
                                        displayEmpty
                                        value={mode}
                                        onChange={handlePickMood}
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            if (selected && selected.length === 0) {
                                                return <MenuItem >Pick a Mood</MenuItem>;
                                            }
                                            return selected;
                                        }}
                                        className='pick-mood'
                                        // MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        {mood.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                id='pick-mood'
                                            // style={getStyles(name, personName, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}

                                    </Select> */}

                                        <div className="mdoel-text">
                                            <TextField
                                                id="outlined-multiline-static"
                                                placeholder="Description"
                                                multiline
                                                rows={4}
                                                value={description}
                                                onChange={(e) => { setDescription(e.target.value) }}
                                            // defaultValue="Default Value"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sve">
                                    <Button
                                        onClick={() => { updatePlay() }}
                                    >
                                        Save
                                    </Button>
                                </div>
                                <div className="by-precee" style={{ color: 'var(--clr-font)' }}>
                                    By proceeding, you agree to give Dreamster access to the image you choose to Upload Please make sure you have the right to upload the image.
                                </div>
                            </Box>
                        </Modal>
                        <Modal
                            open={model1}
                            // onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className="modal-style"
                        >
                            <Box sx={style}>
                                <div className="display-2">
                                    <div className="edtdet">
                                        Create Playlist
                                    </div>
                                    <div className="cls" onClick={handleClose4}>
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="#4C4C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>

                                </div>
                                <div className="margin-top display-1">

                                    <Button
                                        className='lbk'
                                        component="label"
                                        role={undefined}
                                        // variant="contained"
                                        tabIndex={-1}
                                    // startIcon={<CloudUploadIcon />}
                                    >
                                        <div className="palylistimg cursor">
                                            <img src={imageUrl ? imageUrl : playlistimg} alt="playlist" />
                                        </div>
                                        <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                                        <div className='chsimg'>
                                            Choose Image
                                        </div>
                                    </Button>
                                    {imageerr && <p>{imageerr}</p>}
                                    <div className="mdoel-inpu">
                                        <TextField id="outlined-basic" value={playlist} placeholder="Name" variant="outlined" onChange={(e) => { setplaylist(e.target.value) }} />
                                        {playlisterr && <p>{playlisterr}</p>}
                                        <div className="mdoel-text">
                                            <TextField
                                                id="outlined-multiline-static"
                                                placeholder="Description"
                                                multiline
                                                rows={4}
                                            // defaultValue="Default Value"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sve">
                                    <Button onClick={() => { editPlay() }}>
                                        Save
                                    </Button>
                                </div>
                                <div className="by-precee" style={{ color: 'var(--clr-font)' }}>
                                    By proceeding, you agree to give Dreamster access to the image you choose to Upload Please make sure you have the right to upload the image.
                                </div>
                            </Box>
                        </Modal>
                        <Modal
                            open={model2}
                            // onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            className="modal-style"
                        >
                            <Box sx={style}>
                                <div className="display-2">
                                    <div className="edtdet">
                                        Are You Sure To Delete This {musicType?.charAt(0).toUpperCase() + musicType?.slice(1)}
                                    </div>
                                    <div className="cls" onClick={handleClose5}>
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.0625 0.9375L0.9375 9.0625M0.9375 0.9375L9.0625 9.0625" stroke="#4C4C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>

                                </div>
                                <div className="sve">
                                    <Button
                                        onClick={() => { deleteSongs() }}
                                    >
                                        Delete
                                    </Button>
                                </div>
                                {/* <div className="by-precee" style={{ color: 'var(--clr-font)' }}>
                                    By proceeding, you agree to give Dreamster access to the image you choose to Upload Please make sure you have the right to upload the image.
                                </div> */}



                            </Box>
                        </Modal>
                    </Box>
                </Box>
                <SendTrack
                    handleModelClose2={handleModelClose2}
                    handleOpen2={handleOpenShare}
                    openModel2={openModel2}
                    id={`/album/${send}`}
                />
                {(localStorage.getItem("playsong") === "false" && audiosource) ? (
                    <Musiccontrol
                        // audiosource={audiosource}
                        audiosource={audioSourceArr}
                        tracks={tracks}
                        isplay={sample}
                        setisplay={setSample}
                        setPlay={setisplay}
                        Play={isplay}
                        data={dataId}
                        handlePlay={sampleIndex}
                        handlePlay1={handlePlayPause}
                        getUserFav={getUserFav}
                        getOneAlbum={getOneAlbum}
                        getplayLists={getplayLists}
                        getOnePlaylist={getOnePlaylist}
                        getOneTrack={getOneTrack}
                        component={component}
                        getUserSaved={getUserSaved}
                        isAlive={isAlive}
                        setIsAlive={setIsAlive}
                        getMusicLibrary={getMusicLibrary}
                    />
                ) : (
                    <>
                        {(localStorage.getItem("playsong") === "false" && audio !== null) ? (
                            <Musiccontrol
                                audiosource={audio}
                                tracks={tracks}
                                isplay={sample}
                                setisplay={setSample}
                                setPlay={setisplay}
                                Play={isplay}
                                data={dataId}
                                handlePlay={sampleIndex}
                                handlePlay1={handlePlayPause}
                                getUserFav={getUserFav}
                                getOneAlbum={getOneAlbum}
                                getplayLists={getplayLists}
                                getOneTrack={getOneTrack}
                                component={component}
                                getUserSaved={getUserSaved}
                                isAlive={isAlive}
                                setIsAlive={setIsAlive}
                                getMusicLibrary={getMusicLibrary}
                            />
                        ) : (
                            <></>
                        )}{" "}
                    </>
                )}

            </div>
        </>
    )
}

export default PlayListNew
