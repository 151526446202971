import React, { useEffect, useState, useRef } from 'react'
import './LoginNew.css'
import dmlogo from '../../img/dster-webp/dwhite.webp'
import { Button, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Axios from '../../Axios'
import { ToastContainer, toast } from 'react-toastify'


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function RegisterType() {

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const lgScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));


    const navigate = useNavigate()
    const [select, setSelect] = useState(null)
    const [selectErr, setSelectErr] = useState(null)

    const [islogin2, setIslogin2] = useState(false);

    const location = useLocation();
    const isMounted = useRef(false);

    const email = useRef(null);

    let user = location?.state?.user
    console.log(user, 'usre');

    const handleSelect = (val) => {
        setSelect(val)
    }

    // console.log(select, 'select');

    const [open, setOpen] = useState(false)

    // const handleChk = () => {
    //     setChk(!chk)
    //     setAgreeError('')
    // }

    const [screen, setScreen] = useState(false)

    const handleScreen = () => {
        setScreen(false)
    }



    //register integration



    useEffect(() => {
        email?.current?.focus();
        if (!isMounted.current) {
            if (location?.state?.emailVerify === 0) {
                toast.success("Email Verified Successfully Please Login", {
                    icon: "👏",
                });
                navigate(`/`, { state: { emailVerify: 2 } });
            } else if (location?.state?.emailVerify === 1) {
                toast.error("Email Already Verified");
                navigate(`/`, { state: { emailVerify: 2 } });
            } else if (location?.state?.login === 0) {
                toast.error("Please Login");

                navigate({ state: { login: 2 } });

                setTimeout(() => {
                    setOpen(true);
                }, 1500);
            } else if (location?.state?.email) {
                // toast.error(location?.state?.email)
                email.current.value = location?.state?.email;
            }
            isMounted.current = true;
        } else {
            // console.log("fetched", location?.state)
        }
    }, []);


    const tokens = localStorage.getItem("iphephile");



    const handleRegister = async (value) => {
        try {


            if (user) {
                if (select == null) {
                    setSelectErr('Please Select User Type')
                }
                else {
                    const registerdata = {
                        name: user.name,
                        firstname: user.firstname,
                        lastname: user.lastname,
                        email: user.email,
                        // signup_type: user.signup_type,
                        signin_type: user.signin_type,
                        typeUser: select,

                    };
                    // console.log(registerdata, 'registerdata');

                    setIslogin2(true);

                    await Axios.post("/register", registerdata)
                        .then((res) => {
                            if (res.data.success) {
                                toast.success(res.data.message);
                                window.localStorage.setItem(
                                    "iphephile",
                                    res?.data?.result?.token
                                );
                                window.localStorage.setItem(
                                    "usrId",
                                    res?.data?.result?.user?._id
                                );
                                window.localStorage.setItem(
                                    "role",
                                    res?.data?.result?.user?.role
                                );
                                window.localStorage.setItem("isPauses", false);
                                window.localStorage.setItem("currents", 0);

                                setTimeout(() => {
                                    setIslogin2(false);
                                    navigate("/");
                                }, 2000);
                            } else {
                                toast.error(res.data.message);
                                setTimeout(() => {
                                    setIslogin2(false);
                                }, 2000);
                            }
                        })
                        .catch((error) => {
                            console.log(error);

                            toast.error(error?.response?.data?.message);

                            setTimeout(() => {
                                setIslogin2(false);
                            }, 2000);
                        });
                }
            } else {
                console.log("user");
            }
        } catch (error) {
            console.log(error, 'err');

        }
    }

    const register = async () => {

        try {
            if (user) {
                if (select == null) {
                    setSelectErr('Please Select User Type')
                }
                else {
                    setIslogin2(true);
                    const registerdata = {
                        name: user.name,
                        firstname: user.firstname,
                        lastname: user.lastname,
                        email: user.email,
                        // signup_type: user.signup_type,
                        signin_type: user.signin_type,
                        typeUser: select,
                        password: user.password,
                        repassword: user.repassword,
                    };

                    const { data } = await Axios.post("/register", registerdata);
                    if (data.success) {
                        if (data.result) {
                            toast.success(data?.message);
                            localStorage.clear();
                            await localStorage.setItem("regMail", user.email);

                            setTimeout(() => {
                                navigate('/register', { state: { otp: true } })
                                setIslogin2(false);
                            }, 2000);
                        }
                    }
                }
            }

        } catch (error) {
            console.log(error, "err")
        }
    };



    return (
        <>
            <ToastContainer />
            <div className='registerNew'>
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid xs={12} sm={12} md={12} lg={10} xl={7} className={`${!mdScreen && 'grd-bcg'} grd2`}>
                        <Grid container style={{ justifyContent: 'center', height: !mdScreen && '100%', alignItems: !mdScreen && 'center', backdropFilter: !mdScreen && 'blur(10px)' }}>
                            <Grid xs={12} sm={9} md={7} lg={6} xl={6}>
                                <div className={!mdScreen ? "dmlogo text-center" : "dmlogo text-center"}>
                                    <img src={dmlogo} alt="dmlogo" />
                                </div>
                                <div className={!mdScreen ? "margin-top text-center" : "margin-top"}>

                                    <div className="welcm text-center">
                                        Welcome to Dreamster  👋
                                    </div>
                                    <div className="mg-top-10 welcm-desc text-center">
                                        Kindly fill in your details below to create an account
                                    </div>
                                    <div className="mg-top-10 welcm-desc display-1 cursor" >
                                        <div className='display-1' onClick={() => { navigate('/register') }}>
                                            <ArrowBackIcon />
                                            <div className="">Back</div>
                                        </div>

                                    </div>
                                </div>
                                <div className="margin-top">
                                    <div className={`${select === 'user' ? 'usr' : 'art'}prf display-2 cursor`}
                                        style={{ margin: !mdScreen && '10px auto', width: !smScreen && '80%' }}

                                        onClick={(() => { handleSelect('user'); setSelectErr(null) })}
                                    >
                                        <div className="display-1">
                                            <div className="" >
                                                {select === 'user' ? <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                                    <g clip-path="url(#clip0_4985_4870)">
                                                        <path d="M25.375 0H5.375C2.61358 0 0.375 2.23858 0.375 5V25C0.375 27.7614 2.61358 30 5.375 30H25.375C28.1364 30 30.375 27.7614 30.375 25V5C30.375 2.23858 28.1364 0 25.375 0Z" fill="url(#paint0_linear_4985_4870)" />
                                                        <path d="M19.7422 10.948C20.1729 10.5173 20.8712 10.5173 21.302 10.948C21.7327 11.3788 21.7327 12.0771 21.302 12.5078L14.6843 19.1255C14.2536 19.5562 13.5552 19.5562 13.1245 19.1255L9.44804 15.449C9.01732 15.0183 9.01732 14.3199 9.44804 13.8892C9.87877 13.4585 10.5771 13.4585 11.0078 13.8892L13.9044 16.7858L19.7422 10.948Z" fill="#1C1C1C" />
                                                    </g>
                                                    <defs>
                                                        <linearGradient id="paint0_linear_4985_4870" x1="29.8393" y1="26.9531" x2="-0.647957" y2="24.6072" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#175AFF" />
                                                            <stop offset="1" stop-color="#FC5AFF" />
                                                        </linearGradient>
                                                        <clipPath id="clip0_4985_4870">
                                                            <rect x="0.375" width="30" height="30" rx="15" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <g clip-path="url(#clip0_4985_4886)">
                                                        <path d="M25 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V25C0.5 27.4853 2.51472 29.5 5 29.5H25C27.4853 29.5 29.5 27.4853 29.5 25V5C29.5 2.51472 27.4853 0.5 25 0.5Z" fill="#1C1C1C" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="white" />
                                                    <defs>
                                                        <clipPath id="clip0_4985_4886">
                                                            <rect width="30" height="30" rx="15" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>}
                                            </div>
                                            <div className="">
                                                <div className={`${select === 'user' ? 'usr' : 'art'}prf-txt`}>
                                                    User Profile
                                                </div>
                                                <ul className={`${select === 'user' ? 'use' : 'art'}prf-ul`}>
                                                    <li>
                                                        Free Listen & Buy Songs
                                                    </li>
                                                    <li>
                                                        Join Community
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={`${select === 'user' ? 'use' : 'art'}ico`} style={{ padding: !smScreen && "8px 16px" }}>
                                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.125 6.25C10.8509 6.25 12.25 4.85089 12.25 3.125C12.25 1.39911 10.8509 0 9.125 0C7.39911 0 6 1.39911 6 3.125C6 4.85089 7.39911 6.25 9.125 6.25Z" fill={select !== 1 ? "url(#paint0_linear_4985_4897)" : "#fff"} />
                                                <path d="M17.4386 11.5513L13.0636 7.80125C12.837 7.60689 12.5483 7.50003 12.2498 7.5H5.9998C5.70128 7.50003 5.41263 7.60689 5.18605 7.80125L0.811054 11.5513C0.680385 11.6634 0.574285 11.8013 0.499406 11.9563C0.424527 12.1113 0.382483 12.2802 0.375911 12.4522C0.369339 12.6243 0.39838 12.7958 0.461213 12.9561C0.524047 13.1164 0.619318 13.262 0.741054 13.3837L4.1248 16.7675V13.2325L3.46355 12.5712L5.3748 10.9325V18.75C5.3748 19.0815 5.5065 19.3995 5.74092 19.6339C5.97534 19.8683 6.29328 20 6.6248 20H11.6248C11.9563 20 12.2743 19.8683 12.5087 19.6339C12.7431 19.3995 12.8748 19.0815 12.8748 18.75V10.9325L14.7861 12.5712L14.1248 13.2325V16.7675L17.5086 13.3837C17.6303 13.262 17.7256 13.1164 17.7884 12.9561C17.8512 12.7958 17.8803 12.6243 17.8737 12.4522C17.8671 12.2802 17.8251 12.1113 17.7502 11.9563C17.6753 11.8013 17.5692 11.6634 17.4386 11.5513Z" fill={select !== 1 ? "url(#paint0_linear_4985_4897)" : "#fff"} />
                                            </svg>

                                        </div>
                                    </div>
                                    <div className={`${select === 'artist' ? 'usr' : 'art'}prf display-2 cursor`}
                                        style={{ margin: !mdScreen && '10px auto', width: !smScreen && '80%' }}
                                        onClick={(() => { handleSelect('artist'); setSelectErr(null) })}
                                    >
                                        <div className="display-1">
                                            <div className="" >
                                                {select === 'artist' ? <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
                                                    <g clip-path="url(#clip0_4985_4870)">
                                                        <path d="M25.375 0H5.375C2.61358 0 0.375 2.23858 0.375 5V25C0.375 27.7614 2.61358 30 5.375 30H25.375C28.1364 30 30.375 27.7614 30.375 25V5C30.375 2.23858 28.1364 0 25.375 0Z" fill="url(#paint0_linear_4985_4870)" />
                                                        <path d="M19.7422 10.948C20.1729 10.5173 20.8712 10.5173 21.302 10.948C21.7327 11.3788 21.7327 12.0771 21.302 12.5078L14.6843 19.1255C14.2536 19.5562 13.5552 19.5562 13.1245 19.1255L9.44804 15.449C9.01732 15.0183 9.01732 14.3199 9.44804 13.8892C9.87877 13.4585 10.5771 13.4585 11.0078 13.8892L13.9044 16.7858L19.7422 10.948Z" fill="#1C1C1C" />
                                                    </g>
                                                    <defs>
                                                        <linearGradient id="paint0_linear_4985_4870" x1="29.8393" y1="26.9531" x2="-0.647957" y2="24.6072" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#175AFF" />
                                                            <stop offset="1" stop-color="#FC5AFF" />
                                                        </linearGradient>
                                                        <clipPath id="clip0_4985_4870">
                                                            <rect x="0.375" width="30" height="30" rx="15" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                    <g clip-path="url(#clip0_4985_4886)">
                                                        <path d="M25 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V25C0.5 27.4853 2.51472 29.5 5 29.5H25C27.4853 29.5 29.5 27.4853 29.5 25V5C29.5 2.51472 27.4853 0.5 25 0.5Z" fill="#1C1C1C" />
                                                    </g>
                                                    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="white" />
                                                    <defs>
                                                        <clipPath id="clip0_4985_4886">
                                                            <rect width="30" height="30" rx="15" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>}
                                            </div>
                                            <div className="">
                                                <div className={`${select === 'artist' ? 'usr' : 'art'}prf-txt`}>
                                                    Artist Profile
                                                </div>
                                                <ul className={`${select === 'artist' ? 'use' : 'art'}prf-ul`}>
                                                    <li>
                                                        Publish Songs & Earn
                                                    </li>
                                                    <li>
                                                        Owner of Backstage pass & Mint
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={`${select === 'artist' ? 'use' : 'art'}ico`} style={{ padding: !smScreen && "8px 16px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M17.375 0.625C17.125 0.25 16.75 0 16.25 0H3.75C3.25 0 2.875 0.25 2.625 0.625C0 5.625 0 5.875 0 6.25C0 7.625 1.125 8.75 2.5 8.75V18.75C2.5 19.5 3 20 3.75 20H16.25C17 20 17.5 19.5 17.5 18.75V8.75C18.875 8.75 20 7.625 20 6.25C20 5.875 20 5.625 17.375 0.625ZM12.5 17.5V12.5H7.5V17.5H5V8.375C5.375 8.625 5.75 8.75 6.25 8.75C7 8.75 7.625 8.375 8.125 7.875C8.625 8.375 9.25 8.75 10 8.75C10.75 8.75 11.375 8.375 11.875 7.875C12.375 8.375 13 8.75 13.75 8.75C14.25 8.75 14.625 8.625 15 8.375V17.5H12.5Z" fill={select !== 2 ? "url(#paint0_linear_4985_4897)" : "#fff"} />
                                                <defs>
                                                    <linearGradient id="paint0_linear_4985_4897" x1="19.6429" y1="17.9688" x2="-0.681972" y2="16.4048" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#175AFF" />
                                                        <stop offset="1" stop-color="#FC5AFF" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                        </div>
                                    </div>
                                    {selectErr && <p style={{ color: 'red' }}>{selectErr}</p>}
                                    {/* <div className="alrdy margin-top">
                                            Already have an account? <span className='cursor' onClick={(() => { navigate('/login') })}>Log in</span>
                                        </div> */}
                                    <div className="logins margin-top regis-g text-center" >

                                        {islogin2 === false ? (
                                            <Button onClick={() => {
                                                if (location?.state?.type == 'google') {
                                                    handleRegister()
                                                }
                                                else {
                                                    register()
                                                }
                                            }}>
                                                <div className="display-1">
                                                    <div className="">
                                                        Confirm
                                                    </div>
                                                </div>
                                            </Button>
                                        ) : (
                                            <Button>Processing ...</Button>
                                        )}
                                    </div>
                                    {/* <div className="alrdy margin-top">
                                        Don't have verify Email?
                                        <span style={{ marginLeft: '5px' }} className='cursor' onClick={() => { setVerifyEmail(true); setScreen(true); setRegComponent(false) }}>Resend Verification Mail</span>
                                    </div> */}
                                </div>


                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </div >
        </>
    )
}

export default RegisterType
