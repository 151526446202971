import React, { useEffect, useState, useRef } from "react";
import "./Home.css";
import { Grid } from "@mui/material";
import Header from "../Header/Header";
import { Button } from "@mui/material";
import lap from "../../img/dster-webp/laptop.webp";
import banner_moving_float_img from "../../img/dster-webp/banner-moving-float-img.webp";
import Footer from "../Footer/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dog from "../../img/dster-webp/dog.webp";
import dg2 from "../../img/dster-webp/dg2.webp";
import dg3 from "../../img/dster-webp/dg3.webp";
import dtoken from "../../img/dster-webp/dster-token.webp";


const howarr = [
  {
    cont: "Create an account using email or a Metamask wallet.",
    img: `${dog}`,
  },
  {
    cont: "Artist/Creators can upload free music or create music as a NFT on the platform.",
    img: `${dg2}`,
  },
  {
    cont: "Start listening and add music to your library for free or buy the token if it is required",
    img: `${dg3}`,
  },
  {
    cont: "Instead of “Following a user page” you can “Connect and become Plugged” Following is a thing of the past and Dreamsters connect and get plugged with one another.",
    img: `${dg3}`,
  },
];

const key = [
  {
    title: "Exclusive NFT Access",
    desc: "With Dreamster Tokens in your digital wallet, you gain exclusive access to limited edition NFTs that hold unparalleled value and uniqueness. Dive into a treasure trove of digital art, music, and more thats reserved for token holders.",
  },
  {
    title: "Enhanced Social Engagement",
    desc: "Dreamster Tokens arent just a digital currency theyre your passport to a thriving community of like-minded enthusiasts. Use tokens to engage in discussions, participate in events, and make your mark within the Dreamster ecosystem",
  },
  {
    title: "Seamless Transactions",
    desc: "Experience the convenience of frictionless transactions within the Dreamster platform. Whether youre acquiring NFTs or participating in community activities, Dreamster Tokens streamline your interactions, making your digital journey smoother than ever.",
  },
];

function Home() {
  const navigate = useNavigate();
  const history = useLocation();
  const isMounted = useRef(false);
  const emails = useRef(null);
  const [emailerr, setemailerr] = useState(null);
  const [email, setEmail] = useState();

  useEffect(() => {
    console.log(history?.state, "history");
    if (!isMounted.current) {
      if (history?.state?.passwordUpdated == 0) {
        toast.success("Password Updated Successfully");
        isMounted.current = true;
      } else if (history?.state?.passwordUpdated == 1) {
        toast.success("Please Try Again");
        isMounted.current = true;
      } else if (history?.state?.emailVerify === 0) {
        toast.success("Email Verified Successfully Please Login", {
          icon: "👏",
        });
        navigate(`/`, { state: { emailVerify: 2 } });
      } else if (history?.state?.emailVerify === 1) {
        toast.error("Email Already verified");
        navigate(`/`, { state: { emailVerify: 2 } });
      } else if (history?.state?.login === 0) {
        toast.error("Please Login");

        console.log(typeof history?.state.login, "history");

        navigate(`/`, { state: { login: 2 } });
      } else if (history?.state?.login === 3) {
        toast.error("Insufficient Token Balance");

        navigate(`/`, { state: { login: 2 } });
      } else {
        isMounted.current = true;
      }
    }
  }, []);

  const navigateTo = () => {
    navigate("/register");
  };

  const tokens = localStorage.getItem("iphephile");
  const emailhandleChange = (event) => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const inputValue = event?.target?.value;

    if (inputValue.trim() === "") {
      setemailerr("");
    } else if (!emailRegex.test(inputValue)) {
      setemailerr("*Invalid Email");
    } else {
      setEmail(event.target.value);
      setemailerr("");
    }
  };
  const joinNow = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      setemailerr("*Please Enter Email");
    } else if (!emailRegex.test(email)) {
      setemailerr("*Invalid Email");
    } else {
      setemailerr("");
      navigate(`/register`, { state: { email: email } });
    }
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  return (
    <>
      <div className="home" sx={{ bgcolor: "#000" }}>
        <ToastContainer limit={1} autoClose={3000} />

        <div className="header-banner-sections">
          {/* <video
            src={bgVideo}
            autoPlay
            loop
            muted
            typr="video/mp4"
            id="video"
          /> */}
          <Grid container style={{ justifyContent: "center" }}>
            <Grid
              className="header-project-landing"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Header />
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="banner-section-part"
            >
              <div className="experience">Epic MUSIC Experience</div>
              <div className="description description-banner">
                Where Musicians upload music for free or create as a Digital
                music asset so fans now own and listen to the music they love.
                Go backstage with your favorite Musician using our dope
                backstage pass feature!
              </div>
              {tokens ? (
                <> </>
              ) : (
                <div className="start-now-btn">
                  <Button
                    onClick={() => {
                      navigateTo();
                    }}
                  >
                    Start Free Now
                  </Button>
                </div>
              )}
              <div className="banner_moving_float_img">
                <a href="#belowbannersection">
                  <img
                    src={banner_moving_float_img}
                    alt="banner_moving_float_img"
                  />
                </a>
              </div>
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          className="player-offers-section"
          id="belowbannersection"
        >
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="description2 player-offers-section-description">
              <div className="text">
                <div className="beyond-strem">Beyond Streaming</div>
                Dreamster’s Music Streaming platform is a visionary ecosystem
                that empowers artists and fans, bridging the gap between
                creativity and appreciation. It is a world where music becomes
                an immersive experience. Artists wield the power to tokenize
                their creations, gain control, and have fair recognition.
                Collectors, on the other hand, can unlock exciting rewards, such
                as direct artist connections, virtual concert access, and
                immersive VIP experiences, by owning specific collections from
                Dreamster’s NFT marketplace.
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="cont2">
              <div className="laptop-img">
                <img src={lap} />
              </div>
            </div>
            <Grid container spacing={0} style={{ justifyContent: "center" }}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="product-bg"
              >
                <div className="how-works pdt">Products</div>
                <Grid container spacing={0} sx={{ justifyContent: "center" }}>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Grid container spacing={0} className="gd-2">
                      <div className="how-works product-title-new">
                        Music NFT Streaming Platform
                      </div>
                      <Grid xs={12} sm={12} md={8} lg={6} xl={6}>
                        <div className="test-div">
                          <div className="test-inner">
                            <div className="pdt-1">
                              Dreamster Social NFT marketplace seamlessly blends
                              social interaction with the world of non-fungible
                              tokens. Designed exclusively for NFT enthusiasts
                              like you, Dreamster offers a one-of-a-kind
                              platform where creativity, community, and
                              captivating experiences converge.
                            </div>
                            <div className="pdt-1">
                              Immerse yourself in a realm where NFT ownership
                              takes on a whole new dimension. By acquiring our
                              exclusive NFTs, you gain access to unparalleled
                              experiences within our innovative music streaming
                              platform. Let the harmony of blockchain technology
                              and music transport you to uncharted audio
                              adventures.
                            </div>
                            <div className="pdt-1">
                              More than just a marketplace, it's a thriving
                              community where like-minded NFT aficionados gather
                              to share, explore, and celebrate their passion.
                              Connect with fellow collectors, artists, and
                              creators who share your enthusiasm for the digital
                              art revolution.
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div className="dummy-banner"></div>
              </Grid>

              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="product-bg2"
              >
                <div className="how-works pdt"></div>
                <Grid container spacing={0} sx={{ justifyContent: "center" }}>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Grid container spacing={0} className="gd-1">
                      <div className="how-works product-title-new2">
                        Music and Video Streaming Platform
                      </div>
                      <Grid xs={12} sm={12} md={8} lg={6} xl={6}>
                        <div className="test-div">
                          <div className="test-inner">
                            <div className="pdt-1">
                              Dive into an ocean of sound and visuals as our
                              platform brings you an extensive library of
                              tracks, albums, and playlists from across genres.
                              Whether you're into soothing melodies or
                              heart-pounding beats, our curated collection has
                              something for every mood and moment.
                            </div>
                            <div className="pdt-1">
                              Elevate your entertainment experience with our
                              state-of-the-art music and video streaming
                              platform. Designed to cater to the discerning
                              tastes of modern enthusiasts, our platform
                              seamlessly combines the best of music and video
                              content, ensuring an immersive and dynamic journey
                              every time you hit play.
                            </div>
                            <div className="pdt-1">
                              Collectors can gain access to exclusive virtual
                              adventures tied to the NFT collection, and enjoy
                              electrifying live virtual concerts that bring them
                              closer to their favorite artists. Be the first to
                              own limited edition NFTs, adding rare gems to your
                              collection that hold special significance in the
                              NFT universe.
                            </div>
                            <div className="pdt-1">
                              While artists retain artistic control over their
                              creations and engage directly with their audience,
                              eliminating intermediaries. Benefit from a fair
                              revenue share on every NFT sale, ensuring artistic
                              journey is not just appreciated but also
                              financially rewarding. Artist's creativity finds a
                              supportive home where it can flourish and connect
                              with collectors who truly value their work.
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div className="dummy-banner">
                  <Grid container spacing={0} className="gd-2">
                    <Grid xs={12} sm={12} md={7} lg={5} xl={3}>
                      <div className="test-div lan-us">
                        <div className="test-inner lan-join">
                          <div className="bg-rxt">Join us today</div>
                          <span style={{ color: "#fff" }}>
                            and let the journey begin.
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="dtoken">
                  <img src={dtoken} alt="dtoken" />
                </div>
                <div className="dtoken-txt1">Token</div>
                <div className="dtoken-txt2">DSTER</div>
                <div className="dtoken-btn">
                  Dreamster Tokens are your gateway to a world of limitless
                  digital potential. As a cutting-edge digital currency within
                  the Dreamster ecosystem, these tokens offer a new dimension of
                  experiences that go beyond the ordinary. Seamlessly integrated
                  with our NFT social marketplace, Dreamster Tokens empower you
                  to explore, engage, and elevate your digital journey like
                  never before.
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                <div className="whitepaper">
                  <div className="key-feature1">Key Features</div>
                  {/* <div className="key-feture2">Key Features</div> */}
                </div>
                <Grid container spacing={0} className="feat-cont">
                  {key.map((row, ind) => {
                    return (
                      <Grid xs={12} sm={12} md={6} lg={6} xl={4}>
                        <div className="key-box">
                          <div className="key-num-div">
                            <div className="key-num">
                              {(ind + 1).toString().padStart(2, "0")}
                            </div>
                          </div>
                          <div className="key-icon">{row.title}</div>
                          <div className="key-desc">{row.desc}</div>

                          <div className="bottom-color"></div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="how-works new">how does it work?</div>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="how-bg">
                {/* <div>
                                <img src={howbg} alt='' />
                            </div> */}
                <Grid container spacing={0} sx={{ justifyContent: "flex-end" }}>
                  <Grid xs={12} sm={12} md={12} lg={8} xl={10}>
                    <Grid container spacing={0}>
                      {howarr.map((row, ind) => {
                        return (
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={5}
                            xl={5}
                            sx={{ padding: "10px" }}
                            key={ind}
                            className={
                              ind === 0
                                ? "ind-style"
                                : ind === 2
                                  ? "ind-style2"
                                  : (ind === 0, 1, 2, 3)
                                    ? "normal-style"
                                    : null
                            }
                          >
                            <div className="h-c1">
                              <div className="h-num">
                                {0}
                                {ind + 1}
                              </div>
                              <div className="h-c1-cont">{row.cont}</div>
                              <div className="dog">
                                <img src={row.img} alt="dog" />
                              </div>
                            </div>
                          </Grid>
                        );
                      })}

                    </Grid>


                  </Grid>
                </Grid>
                <div className="buy-cryptobtn">
                  <Button onClick={() => { window.open('https://tokenpresale.dreamster.io', '_blank'); }}>
                    Buy Crypto
                  </Button>
                </div>
                <div className="how-desc">
                  Users can add music that is free to their library if the
                  artist allows, or the artist & label can require you to hold
                  the NFT to their music ensuring that ownership is both
                  authentic and practical granting them special access to their
                  owned music. Now musicians can be safeguarded against piracy,
                  generate proper revenue, distribute their music on their
                  terms. Music lovers actually own music as token and can stack
                  their catalog along with having a secondary market to resell
                  the valuable music to someone else. With Dreamster we unlock
                  your digital assets that you have paid for allowing everyone
                  to win!
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="how-bg2">
                {/* <div>
                                <img src={howbg} alt='' />
                            </div> */}
                <div className="how-to">How to Use?</div>
                <Grid container spacing={0}>
                  <Grid xs={12} sm={12} md={12} lg={7} xl={8}>
                    <div className="through-the">
                      <div className="through-c1">
                        Through the use of the Dreamster Token, the platforms
                        native cryptocurrency offers more than transactions.
                      </div>
                      <div className="through-c2">
                        <ul>
                          <li>
                            It acts as a key to unlock the 'Backstage Pass’
                            features for fans granting them opportunities to
                            interact with their favorite artists. This includes
                            meet ups, access to a schedule of events and early
                            or exclusive previews of unreleased tracks and more
                          </li>
                          <li>
                            Holders of the Dreamster token have opportunities to
                            earn rewards by staking their tokens on the
                            platform. By staking users help the network grow,
                            and also receive extra tokens or special perks.
                            These perks may include NFT releases or increased
                            access to artist driven events and content.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <div className="how-desc2">
                  The Dreamster Music Player provides a music experience that
                  emphasizes genuine digital ownership through NFTs ensuring
                  that download privileges are reserved for owners. The
                  Dreamster Token opens up a world of interactions and
                  experiences with artists while the platforms design
                  accommodates both promotional content and the chance for users
                  to earn rewards through staking. This setup fosters an vibrant
                  environment, for music enthusiasts and artists alike.
                </div>
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid xs={12} sm={12} md={12} lg={10} xl={9}>
                    {/* <div className="faq-main">
                      <div className="how-to faq">FAQs?</div>
                      {accord.map((row, ind) => {
                        return (
                          <Accordion
                            expanded={expanded === `panel ${ind + 1}`}
                            onChange={handleChange(`panel ${ind + 1}`)}
                            className="accord-home"
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel4bh-content"
                              id="panel4bh-header"
                            >
                              <Typography sx={{
                                flexShrink: 1

                              }}>
                                {row.title}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{row.desc}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </div> */}
                  </Grid>
                </Grid>

                <div className="cont3 join-us-main">
                  <div className="updated">
                    <div className="txt1">Stay updated</div>
                    <div className="txt2">
                      Join our community of music lovers and get the latest
                      updates on the
                      <div className="txt2">new Dreamster Player.</div>
                    </div>
                  </div>

                  {tokens ? (
                    <></>
                  ) : (
                    <div className="txt-field">
                      <input
                        placeholder="Your email address"
                        inputRef={emails}
                        onChange={(event) => {
                          emailhandleChange(event);
                        }}
                        id="outlined-adornment-weight"
                      />

                      <Button
                        onClick={() => {
                          joinNow();
                        }}
                      >
                        Join
                      </Button>
                    </div>
                  )}

                  {emailerr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>
                      {emailerr}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Home;
