import React, { useEffect, useRef } from "react";
import Axios from "../../Axios"
import { useLocation, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

export default function TOkenverify() {
  const path = useLocation();
  const navigate = useNavigate();
  const token = path.pathname.split("/")[2]
  const isMounted = useRef(false)

  // console.log(token,'token');

  useEffect(() => {
    if (!isMounted.current) {
      console.log(token)

      getVerify()
      isMounted.current = true
    }
    else {
      console.log("Run")
    }
  }, []);

  const getVerify = async () => {
    console.log("Verify")
    try {
      await Axios.post(`/verify`, '',
        {
          headers: {
            Authorization: token,
          },
        }
      ).then((res) => {
        console.log(res, 'res')
        if (res?.data?.success) {
          navigate(`/`, { state: { emailVerify: 0, message: res?.data?.success } })
        }
        else {
          navigate(`/`, { state: { emailVerify: 1, message: res?.data?.success } })
        }

      }).catch((err) => {
        console.log(err, 'err')
        navigate(`/`, { state: { emailVerify: 1 } })
      })
    } catch (error) {
      console.log(error, 'error')
      navigate(`/`, { state: { emailVerify: 1 } })
    }
  }
  return <></>;
}
