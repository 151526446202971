import { Box, Grid } from '@mui/material'
import React, { useState, useRef, useEffect } from 'react'
import SidebarNew from '../SidebarNew/SidebarNew'
import './LibraryNew.css'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CircularProgress from '@mui/material/CircularProgress';
import PauseIcon from '@mui/icons-material/Pause';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Musiccontrol from '../Music-Control/Musiccontrol';
import { useTheme, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from '../../Axios'
import { ToastContainer, toast } from "react-toastify";
import ava1 from "../../img/dster-webp/ava1.webp";



const drawerWidth = 300;





function LibraryNew() {

    const [playload, setPlayload] = useState(false)
    const [music, setMusic] = useState([]);
    const [album, setAlbum] = useState([]);
    const [play, setPlay] = useState([]);
    const tokens = localStorage.getItem("iphephile");
    const audio = localStorage.getItem("audiosource");
    const [audiosource, setAudiosource] = useState();
    // const [isplay, setisplay] = useState(0);
    const [values, setValues] = useState(true);
    const [type, setType] = useState('music')
    // const [audioplays, setAudioplays] = useState([]);
    const [audioplays, setAudioplays] = useState();
    const [audioplays1, setAudioplays1] = useState();
    const [audioplays2, setAudioplays2] = useState();
    const [audioDurations, setAudioDurations] = useState([]);
    const [limit, setLimit] = React.useState(4);
    const [limit1, setLimit1] = React.useState(4);
    const [limit2, setLimit2] = React.useState(4);
    const [see, setSee] = useState(false);
    const [see1, setSee1] = useState(false);
    const [see2, setSee2] = useState(false);
    const [name, setName] = React.useState();
    const [id, setId] = useState()
    const navigate = useNavigate()

    const changeLimit = () => {
        if (see === false) {
            setLimit(20);
        } else {
            setLimit(4);
        }
        setSee(!see);
    };

    const changeLimit1 = () => {
        if (see1 === false) {
            setLimit1(20);
        } else {
            setLimit1(4);
        }
        setSee1(!see1);
    };

    const changeLimit2 = () => {
        if (see2 === false) {
            setLimit2(20);
        } else {
            setLimit2(4);
        }
        setSee2(!see2);
    };


    const getaudioPlays = async (index) => {
        // console.log(index, 'getaudioplays');
        try {
            setAudioplays(index)
        } catch (error) {
            console.error(error);
        }
    };

    const getaudioPlays1 = async (index) => {
        // console.log(index, 'getaudioplays1');
        try {
            setAudioplays1(index)
        } catch (error) {
            console.error(error);
        }
    };

    const getaudioPlays2 = async (index) => {
        // console.log(index, 'getaudioplay2');
        try {
            setAudioplays2(index)
        } catch (error) {
            console.error(error);
        }
    };

    // console.log(audioplays, 'audioplays');


    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener("loadedmetadata", () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };

    // const handlePlayLoad = () => {

    //     setPlayload(true)
    //     const timer = setTimeout(() => {
    //         setPlayload(false)
    //     }, 3000);

    //     return () => clearTimeout(timer);
    // }




    const [playCard, setPlaycard] = useState([
        { img: 'https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw' },
        { img: "https://imgs.search.brave.com/HvqPR3gn8GFFQ9J8-sokBi0jvSJWz364dV9mPZGoUyc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9hZnJpY2FuLXdv/bWFuLXNpbmdpbmct/d2hpbGUtbGlzdGVu/aW5nLW11c2ljLWhl/YWRwaG9uZXMtaXNv/bGF0ZWQtZ3JleS1i/YWNrZ3JvdW5kLWNv/cHktc3BhY2VfNjEz/NjUyLTQ0MC5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw" },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
        { img: "https://imgs.search.brave.com/krx_xPUZGcu2ldKw1qreusemorEtlIMuaqCo4SPdnZY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by93/aW5zb21lLWNhcmVm/cmVlLWJsYWNrLWdp/cmwtbGlzdGVuaW5n/LW11c2ljLXN0dWRp/by1zaG90LWpvY3Vu/ZC1hZnJpY2FuLWZl/bWFsZS1tb2RlbC1w/b3NpbmctaGVhZHBo/b25lc18xOTc1MzEt/MTExNTkuanBnP3Np/emU9NjI2JmV4dD1q/cGc" }
    ])
    const [custmPlay, setCustmplay] = useState([
        { img: 'https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw' },
        { img: "https://imgs.search.brave.com/HvqPR3gn8GFFQ9J8-sokBi0jvSJWz364dV9mPZGoUyc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9hZnJpY2FuLXdv/bWFuLXNpbmdpbmct/d2hpbGUtbGlzdGVu/aW5nLW11c2ljLWhl/YWRwaG9uZXMtaXNv/bGF0ZWQtZ3JleS1i/YWNrZ3JvdW5kLWNv/cHktc3BhY2VfNjEz/NjUyLTQ0MC5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw" },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
        { img: "https://imgs.search.brave.com/krx_xPUZGcu2ldKw1qreusemorEtlIMuaqCo4SPdnZY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by93/aW5zb21lLWNhcmVm/cmVlLWJsYWNrLWdp/cmwtbGlzdGVuaW5n/LW11c2ljLXN0dWRp/by1zaG90LWpvY3Vu/ZC1hZnJpY2FuLWZl/bWFsZS1tb2RlbC1w/b3NpbmctaGVhZHBo/b25lc18xOTc1MzEt/MTExNTkuanBnP3Np/emU9NjI2JmV4dD1q/cGc" },
        { img: 'https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw' },
        { img: "https://imgs.search.brave.com/krx_xPUZGcu2ldKw1qreusemorEtlIMuaqCo4SPdnZY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by93/aW5zb21lLWNhcmVm/cmVlLWJsYWNrLWdp/cmwtbGlzdGVuaW5n/LW11c2ljLXN0dWRp/by1zaG90LWpvY3Vu/ZC1hZnJpY2FuLWZl/bWFsZS1tb2RlbC1w/b3NpbmctaGVhZHBo/b25lc18xOTc1MzEt/MTExNTkuanBnP3Np/emU9NjI2JmV4dD1q/cGc" },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
    ])
    // const [play, setPlay] = useState(false)


    const [playingIndex, setPlayingIndex] = useState(null);

    const data = useLocation()
    const result = data.state
    // console.log(playload, 'playload');
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isLgDn = useMediaQuery(theme.breakpoints.up('lg'));
    const childRef = useRef();
    const token = localStorage.getItem("iphephile")

    const handleChange = async (value) => {
        setType(value)
    }

    // console.log(name, 'name');

    const handleClick = () => {
        if (childRef.current) {
            const event = { type: 'click' };  // Example event object
            childRef.current.toggleDrawer(true)(event);
        }
    };

    const setAudio = (data) => {
        // console.log(data, 'data');
        localStorage.setItem("audiosource", data.audio);
        localStorage.setItem("songName", data.song_name);
        localStorage.setItem("Image", data.image);
        localStorage.setItem("current", 0);
        localStorage.setItem("isPause", false);
        setAudiosource(data.audio);
        localStorage.setItem("tracks", 0);
        localStorage.setItem("playsong", "false");
        setPlayload(true);
        setValues(!values);
        setId(data._id)
    };

    const createMusic = async (data) => {
        try {
            const create_data = {
                song_id: data,
            };
            // console.log(create_data);

            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res");
                    if (res?.data?.success) {
                        //   toast.success(res?.data?.message)

                        console.log(res?.data?.message);
                    } else {
                        // toast.error(res?.data?.message);
                    }
                })
                .catch((err) => {
                    console.log(err, "err");
                    // toast.error(err?.response?.data?.message);
                });
        } catch (error) {
            console.log(error, "error");
        }
    };

    // to get All songs,
    const getMusic = async () => {
        try {
            const datas = await Axios.post(
                "/users/get_music_library",
                {
                    name: name,
                    // status: status,
                },
                {
                    headers: {
                        Authorization: tokens,
                    },
                }
            )
                .then((res) => {
                    // console.log(res?.data?.result?.musicList,"res")
                    if (res?.data?.success) {
                        setMusic(res?.data?.result?.musicList);
                        setAlbum(res?.data?.result?.musicAlbum);
                        setPlay(res?.data?.result?.musicPlay);
                        // setCount(res?.data?.result.length);
                    } else {
                        setMusic([]);
                        setAlbum([]);
                        setPlay([]);
                    }
                })
                .catch((err) => {
                    // console.log(err,"err")
                    if (err?.response?.data?.message == "Admin Blocked You") {
                        localStorage.clear();
                        navigate('/');
                        toast.error('Admin Blocked You');
                    }
                });
        } catch (error) {
            // console.log(error,"error");
        }
    };

    useEffect(() => {
        if (token) {
            getMusic()
        }
        else {
            // toast.error('please login')
            // setTimeout(() => {
            //     navigate('/login')
            // }, 2000)
        }
    }, [limit, limit1, limit2, name])

    // useEffect(() => {
    //     if (music.length > 0 && type == 'music') {
    //         console.log('if');
    //         music.forEach((row, index) => {
    //             getaudioPlays(row?.song_name, index);
    //             getAudioDuration(row?.duration)
    //         });
    //     }
    //     if (album.length > 0 && type == 'album') {
    //         console.log('else');
    //         album.forEach((row, index) => {
    //             getaudioPlays1(row?.songDetails?.song_name, index);
    //             getAudioDuration(row?.songDetails?.song_name)
    //         });
    //     }
    //     if (play.length > 0 && type == 'play') {
    //         console.log('else if');
    //         play.forEach((row, index) => {
    //             getaudioPlays2(row?.songDetails?.song_name, index);
    //             getAudioDuration(row?.songDetails?.song_name)
    //         });
    //     }
    // }, [music, values, album, play, type])



    return (
        <>
            <ToastContainer />
            <div className='playernew'>
                <Box sx={{ display: isLgUp ? 'flex' : 'block' }}>
                    <SidebarNew name={name} setName={setName} />
                    <Box
                        component="main"
                        // className="explore-box"
                        sx={{
                            flexGrow: 1,
                            py: 1,
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            marginTop: "80px",
                            marginBottom: "100px",
                        }}
                    >

                        <Grid container spacing={0}>
                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            // sx={{ padding: "15px" }}
                            >
                                <div>
                                    <div className='display-2 pad-10 mg-top-30'>

                                        <div className='display-1'>
                                            <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.28571 23.2853C7.65265 23.2853 9.57143 21.3665 9.57143 18.9996C9.57143 16.6326 7.65265 14.7139 5.28571 14.7139C2.91878 14.7139 1 16.6326 1 18.9996C1 21.3665 2.91878 23.2853 5.28571 23.2853Z" stroke="url(#paint0_linear_3889_4513)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.57129 19V1C10.8095 1 12.0355 1.24388 13.1794 1.71771C14.3234 2.19154 15.3628 2.88604 16.2383 3.76156C17.1138 4.63709 17.8083 5.67649 18.2822 6.82041C18.756 7.96434 18.9999 9.19039 18.9999 10.4286V10.4286" stroke="url(#paint1_linear_3889_4513)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_3889_4513" x1="-0.105121" y1="13.3952" x2="12.3346" y2="14.158" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_3889_4513" x1="8.35566" y1="-1.76924" x2="22.0767" y2="-1.32851" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <div className='discover-genre'>
                                                Tracks
                                            </div>

                                        </div>
                                        <div className='showmoewnew cursor' onClick={() => { changeLimit() }}>
                                            {
                                                music?.length > 0 ?
                                                    see ? `Show Less >>` : ` Show more >>` :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <Grid container spacing={0} onClick={() => { handleChange('music') }}>
                                        {
                                            music?.length > 0 ?

                                                music?.slice(0, limit)?.map((row, i) => {
                                                    return (
                                                        <Grid
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={3}
                                                        // sx={{ padding: "15px" }}

                                                        >

                                                            <div className='player-carosuel'>
                                                                {/* <OwlCarousel className='owl-theme' loop margin={8} nav     {...options}>
                                                    <div class="card-container">
                                                        <div class="card">
                                                            <div class="front-content">
                                                                <img src="https://images.news18.com/ibnlive/uploads/2024/01/lionel-messi-argentina-10-afp-feature-2024-01-93564821771ec963da4952cc3d6eccee.jpg" alt='mes' />
                                                            </div>
                                                            <div class="content">
                                                                <p class="heading display-2" >
                                                                    <div className='acost' >
                                                                        Acoustic
                                                                        <div className='acosttrack'>
                                                                            120 tracks
                                                                        </div>
                                                                    </div>
                                                                    <Box sx={{ position: 'relative', display: 'inline-flex' }} onClick={handlePlayLoad} key={i}>
                                                                        {playload && <CircularProgress disableShrink />}
                                                                        <Box
                                                                            sx={{
                                                                                top: playload == true ? 0 : 0,
                                                                                left: playload == true ? 0 : -40,
                                                                                bottom: 0,
                                                                                right: 0,
                                                                                position: 'absolute',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',

                                                                            }}
                                                                            key={i}
                                                                        >
                                                                            {playload ? <PauseIcon /> : <PlayArrowIcon />}
                                                                        </Box>
                                                                    </Box>
                                                                </p>
                                                                <p>
                                                                    Lorem ipsum dolor sit amet, consectetur adipii
                                                                    voluptas ten mollitia pariatur odit, ab
                                                                    minus ratione adipisci accusamus vel est excepturi laboriosam magnam
                                                                    necessitatibus dignissimos molestias.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </OwlCarousel> */}
                                                                <div class="card" style={{ backgroundImage: `url(${row?.image ? row?.image : ava1})`, }}>
                                                                    <button class="mail">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                            class="lucide lucide-mail">
                                                                            <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                                                                            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <div class="profile-pic">

                                                                        <img src={row?.image ? row?.image : ava1}
                                                                            id="svg2" width="666.66669" height="666.66669" />
                                                                    </div>
                                                                    <div class="bottom">
                                                                        <div class="content new">
                                                                            <div class="name">{row?.song_name.length > 15 ? `${row?.song_name.slice(0, 15)}...` : row?.song_name}</div>
                                                                            <div class="about-me">{row?.createUser?.[0].name}</div>
                                                                        </div>
                                                                        <div class="bottom-bottom">
                                                                            <p class="heading display-2" >
                                                                                <div className='acost' >
                                                                                    {/* {row?.song_name} */}
                                                                                    {row?.song_name.length > 15 ? `${row?.song_name.slice(0, 15)}...` : row?.song_name}
                                                                                    <div className='acosttrack'>
                                                                                        {`${music?.length} tracks`}
                                                                                    </div>
                                                                                </div>

                                                                            </p>
                                                                            <Box sx={{ position: 'relative', display: 'inline-flex' }}
                                                                                // onClick={handlePlayLoad}
                                                                                key={i}>
                                                                                {audioplays != i || playload && type === 'music' && <CircularProgress disableShrink />}
                                                                                <Box
                                                                                    sx={{
                                                                                        top: playload == true ? 0 : 0,
                                                                                        left: playload == true ? 0 : -40,
                                                                                        bottom: 0,
                                                                                        right: 0,
                                                                                        position: 'absolute',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',

                                                                                    }}
                                                                                    key={i}
                                                                                >
                                                                                    {/* {playload ? <PauseIcon /> : <PlayArrowIcon />} */}
                                                                                    {audioplays != i || playload === false ?
                                                                                        <PlayArrowIcon
                                                                                            onClick={() => {
                                                                                                setAudio(row);
                                                                                                getaudioPlays(i)
                                                                                                createMusic(row?._id)
                                                                                                setAudiosource(row?.audio)
                                                                                            }} />
                                                                                        :
                                                                                        <PauseIcon
                                                                                            onClick={() => {
                                                                                                setPlayload(false)
                                                                                                setAudioplays()
                                                                                            }}
                                                                                        />}
                                                                                </Box>
                                                                            </Box>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Grid>
                                                    )
                                                })
                                                :
                                                <div>
                                                    <p style={{ color: 'white' }}>No Data Found</p>
                                                </div>
                                        }



                                    </Grid>
                                    <div className='display-2 pad-10 mg-top-30'>
                                        <div className='display-1'>
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.85728 13H6.143C5.19622 13 4.42871 13.7675 4.42871 14.7143V21.5714C4.42871 22.5182 5.19622 23.2857 6.143 23.2857H7.85728C8.80406 23.2857 9.57157 22.5182 9.57157 21.5714V14.7143C9.57157 13.7675 8.80406 13 7.85728 13Z" stroke="url(#paint0_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M18.1424 13H16.4282C15.4814 13 14.7139 13.7675 14.7139 14.7143V21.5714C14.7139 22.5182 15.4814 23.2857 16.4282 23.2857H18.1424C19.0892 23.2857 19.8567 22.5182 19.8567 21.5714V14.7143C19.8567 13.7675 19.0892 13 18.1424 13Z" stroke="url(#paint1_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M1 16.4286V12.1429C1 9.18759 2.17398 6.35336 4.26367 4.26367C6.35336 2.17398 9.18759 1 12.1429 1C15.0981 1 17.9324 2.17398 20.022 4.26367C22.1117 6.35336 23.2857 9.18759 23.2857 12.1429V16.4286" stroke="url(#paint2_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_3889_4519" x1="3.76564" y1="11.4176" x2="11.2505" y2="11.6471" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_3889_4519" x1="14.0508" y1="11.4176" x2="21.5357" y2="11.6471" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_3889_4519" x1="-1.87331" y1="-1.37363" x2="30.3389" y2="1.47955" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <div className='discover-genre'>
                                                Albums
                                            </div>

                                        </div>
                                        <div className='showmoewnew cursor' onClick={() => { changeLimit1() }}>
                                            {
                                                album?.length > 0 ?
                                                    see1 ? `Show Less >>` : ` Show more >>` : <></>}
                                        </div>
                                    </div>
                                    <Grid container spacing={0} onClick={() => { handleChange('album') }}>
                                        {
                                            album?.length > 0 ?

                                                album?.slice(0, limit1)?.map((row, i) => {
                                                    return (
                                                        <Grid
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={3}
                                                        // sx={{ padding: "15px" }}

                                                        >

                                                            <div className='player-carosuel'>
                                                                {/* <OwlCarousel className='owl-theme' loop margin={8} nav     {...options}>
                                                        <div class="card-container">
                                                            <div class="card">
                                                                <div class="front-content">
                                                                    <img src="https://images.news18.com/ibnlive/uploads/2024/01/lionel-messi-argentina-10-afp-feature-2024-01-93564821771ec963da4952cc3d6eccee.jpg" alt='mes' />
                                                                </div>
                                                                <div class="content">
                                                                    <p class="heading display-2" >
                                                                        <div className='acost' >
                                                                            Acoustic
                                                                            <div className='acosttrack'>
                                                                                120 tracks
                                                                            </div>
                                                                        </div>
                                                                        <Box sx={{ position: 'relative', display: 'inline-flex' }} onClick={handlePlayLoad} key={i}>
                                                                            {playload && <CircularProgress disableShrink />}
                                                                            <Box
                                                                                sx={{
                                                                                    top: playload == true ? 0 : 0,
                                                                                    left: playload == true ? 0 : -40,
                                                                                    bottom: 0,
                                                                                    right: 0,
                                                                                    position: 'absolute',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',

                                                                                }}
                                                                                key={i}
                                                                            >
                                                                                {playload ? <PauseIcon /> : <PlayArrowIcon />}
                                                                            </Box>
                                                                        </Box>
                                                                    </p>
                                                                    <p>
                                                                        Lorem ipsum dolor sit amet, consectetur adipii
                                                                        voluptas ten mollitia pariatur odit, ab
                                                                        minus ratione adipisci accusamus vel est excepturi laboriosam magnam
                                                                        necessitatibus dignissimos molestias.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel> */}
                                                                <div class="card" style={{ backgroundImage: `url(${row?.image ? row?.image : ava1})`, }}>
                                                                    <button class="mail">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                            class="lucide lucide-mail">
                                                                            <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                                                                            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <div class="profile-pic">

                                                                        <img src={row?.image ? row.image : ava1}
                                                                            id="svg2" width="666.66669" height="666.66669" />
                                                                    </div>
                                                                    <div class="bottom">
                                                                        <div class="content new">
                                                                            <div class="name">
                                                                                {/* {row?.songDetails?.song_name} */}
                                                                                {/* {row?.songDetails?.song_name.length > 15 ? `${row?.songDetails?.song_name.slice(0, 15)}...` : row?.songDetails?.song_name} */}

                                                                            </div>
                                                                            <div class="about-me">{row?.createUser?.[0].name}</div>
                                                                        </div>
                                                                        <div class="bottom-bottom">
                                                                            <p class="heading display-2" >
                                                                                <div className='acost' >
                                                                                    {/* {row?.songDetails?.song_name.length > 15 ? `${row?.songDetails?.song_name.slice(0, 15)}...` : row?.songDetails?.song_name} */}
                                                                                    <div className='acosttrack'>
                                                                                        {`${album.length} albums`}
                                                                                    </div>
                                                                                </div>

                                                                            </p>
                                                                            <Box sx={{ position: 'relative', display: 'inline-flex' }}
                                                                                // onClick={handlePlayLoad} 
                                                                                key={i}>
                                                                                {audioplays1 != i || playload && type === 'album' && <CircularProgress disableShrink />}
                                                                                <Box
                                                                                    sx={{
                                                                                        top: playload == true ? 0 : 0,
                                                                                        left: playload == true ? 0 : -40,
                                                                                        bottom: 0,
                                                                                        right: 0,
                                                                                        position: 'absolute',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',

                                                                                    }}
                                                                                    key={i}
                                                                                >
                                                                                    {/* {playload ? <PauseIcon /> : <PlayArrowIcon />} */}
                                                                                    {audioplays1 != i || playload === false ?
                                                                                        <PlayArrowIcon
                                                                                            onClick={() => {
                                                                                                setAudio(row?.songDetails);
                                                                                                createMusic(row?._id);
                                                                                                getaudioPlays1(i)
                                                                                                setAudiosource(row?.songDetails?.audio)
                                                                                            }} />
                                                                                        :
                                                                                        <PauseIcon
                                                                                            onClick={() => {
                                                                                                setPlayload(false)
                                                                                                setAudioplays1()
                                                                                            }}
                                                                                        />}
                                                                                </Box>
                                                                            </Box>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Grid>
                                                    )
                                                })
                                                :
                                                <div>
                                                    <p style={{ color: 'white' }}>No Data Found</p>
                                                </div>
                                        }



                                    </Grid>
                                    <div className='display-2 pad-10 mg-top-30'>
                                        <div className='display-1'>
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.85728 13H6.143C5.19622 13 4.42871 13.7675 4.42871 14.7143V21.5714C4.42871 22.5182 5.19622 23.2857 6.143 23.2857H7.85728C8.80406 23.2857 9.57157 22.5182 9.57157 21.5714V14.7143C9.57157 13.7675 8.80406 13 7.85728 13Z" stroke="url(#paint0_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M18.1424 13H16.4282C15.4814 13 14.7139 13.7675 14.7139 14.7143V21.5714C14.7139 22.5182 15.4814 23.2857 16.4282 23.2857H18.1424C19.0892 23.2857 19.8567 22.5182 19.8567 21.5714V14.7143C19.8567 13.7675 19.0892 13 18.1424 13Z" stroke="url(#paint1_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M1 16.4286V12.1429C1 9.18759 2.17398 6.35336 4.26367 4.26367C6.35336 2.17398 9.18759 1 12.1429 1C15.0981 1 17.9324 2.17398 20.022 4.26367C22.1117 6.35336 23.2857 9.18759 23.2857 12.1429V16.4286" stroke="url(#paint2_linear_3889_4519)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_3889_4519" x1="3.76564" y1="11.4176" x2="11.2505" y2="11.6471" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_3889_4519" x1="14.0508" y1="11.4176" x2="21.5357" y2="11.6471" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_3889_4519" x1="-1.87331" y1="-1.37363" x2="30.3389" y2="1.47955" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#B5179E" />
                                                        <stop offset="1" stop-color="#7209B7" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <div className='discover-genre'>
                                                Playlist
                                            </div>

                                        </div>
                                        <div className='showmoewnew cursor' onClick={() => { changeLimit2() }}>
                                            {
                                                play?.length > 0 ?
                                                    see2 ? `Show Less >>` : ` Show more >>` :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <Grid container spacing={0} onClick={() => { handleChange('play') }}>
                                        {
                                            play?.length > 0 ?

                                                play?.slice(0, limit2)?.map((row, i) => {
                                                    return (
                                                        <Grid
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={3}
                                                        // sx={{ padding: "15px" }}

                                                        >

                                                            <div className='player-carosuel'>
                                                                {/* <OwlCarousel className='owl-theme' loop margin={8} nav     {...options}>
                                                        <div class="card-container">
                                                            <div class="card">
                                                                <div class="front-content">
                                                                    <img src="https://images.news18.com/ibnlive/uploads/2024/01/lionel-messi-argentina-10-afp-feature-2024-01-93564821771ec963da4952cc3d6eccee.jpg" alt='mes' />
                                                                </div>
                                                                <div class="content">
                                                                    <p class="heading display-2" >
                                                                        <div className='acost' >
                                                                            Acoustic
                                                                            <div className='acosttrack'>
                                                                                120 tracks
                                                                            </div>
                                                                        </div>
                                                                        <Box sx={{ position: 'relative', display: 'inline-flex' }} onClick={handlePlayLoad} key={i}>
                                                                            {playload && <CircularProgress disableShrink />}
                                                                            <Box
                                                                                sx={{
                                                                                    top: playload == true ? 0 : 0,
                                                                                    left: playload == true ? 0 : -40,
                                                                                    bottom: 0,
                                                                                    right: 0,
                                                                                    position: 'absolute',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',

                                                                                }}
                                                                                key={i}
                                                                            >
                                                                                {playload ? <PauseIcon /> : <PlayArrowIcon />}
                                                                            </Box>
                                                                        </Box>
                                                                    </p>
                                                                    <p>
                                                                        Lorem ipsum dolor sit amet, consectetur adipii
                                                                        voluptas ten mollitia pariatur odit, ab
                                                                        minus ratione adipisci accusamus vel est excepturi laboriosam magnam
                                                                        necessitatibus dignissimos molestias.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </OwlCarousel> */}
                                                                <div class="card" style={{ backgroundImage: `url(${row?.image ? row?.image : ava1})`, }}>
                                                                    <button class="mail">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                            class="lucide lucide-mail">
                                                                            <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                                                                            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <div class="profile-pic">

                                                                        <img src={row?.image ? row?.image : ava1} alt='ava1'
                                                                            id="svg2" width="666.66669" height="666.66669" />
                                                                    </div>
                                                                    <div class="bottom">
                                                                        <div class="content new">
                                                                            {/* <div class="name">{row?.songDetails?.song_name.length > 15 ? `${row?.songDetails?.song_name.slice(0, 15)}...` : row?.songDetails?.song_name}</div> */}
                                                                            <div class="about-me">{row?.createUser?.[0].name}</div>
                                                                        </div>
                                                                        <div class="bottom-bottom">
                                                                            <p class="heading display-2" >
                                                                                <div className='acost' >
                                                                                    {/* {row?.songDetails?.song_name.length > 15 ? `${row?.songDetails?.song_name.slice(0, 15)}...` : row?.songDetails?.song_name} */}
                                                                                    <div className='acosttrack'>
                                                                                        {`${play.length} Playlist`}
                                                                                    </div>
                                                                                </div>

                                                                            </p>
                                                                            <Box sx={{ position: 'relative', display: 'inline-flex' }}
                                                                                // onClick={handlePlayLoad}
                                                                                key={i}>
                                                                                {audioplays2 != i || playload && type === 'play' && <CircularProgress disableShrink />}
                                                                                <Box
                                                                                    sx={{
                                                                                        top: playload == true ? 0 : 0,
                                                                                        left: playload == true ? 0 : -40,
                                                                                        bottom: 0,
                                                                                        right: 0,
                                                                                        position: 'absolute',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',

                                                                                    }}
                                                                                    key={i}
                                                                                >
                                                                                    {/* {playload ? <PauseIcon /> : <PlayArrowIcon />} */}
                                                                                    {audioplays2 != i || playload === false ?
                                                                                        <PlayArrowIcon
                                                                                            onClick={() => {
                                                                                                setAudio(row?.songDetails);
                                                                                                createMusic(row?._id);
                                                                                                getaudioPlays2(i)
                                                                                                setAudiosource(row?.songDetails?.audio)
                                                                                            }} />
                                                                                        :
                                                                                        <PauseIcon
                                                                                            onClick={() => {
                                                                                                setPlayload(false)
                                                                                                setAudioplays2()
                                                                                            }}
                                                                                        />}

                                                                                </Box>
                                                                            </Box>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Grid>
                                                    )
                                                })
                                                :
                                                <div>
                                                    <p style={{ color: 'white' }}>No Data Found</p>
                                                </div>
                                        }



                                    </Grid>
                                </div>
                            </Grid>


                        </Grid>
                        {(localStorage.getItem("playsong") === "false" && audiosource) ===
                            true ? (
                            <Musiccontrol
                                audiosource={audiosource}
                                isplay={playload}
                                setisplay={setPlayload}
                                data={id}
                            />
                        ) : (
                            <>
                                {(localStorage.getItem("playsong") === "false" &&
                                    audio !== null) === true ? (
                                    <Musiccontrol
                                        audiosource={audio}
                                        isplay={playload}
                                        setisplay={setPlayload}
                                        data={id}
                                    />
                                ) : (
                                    <></>
                                )}{" "}
                            </>
                        )}
                        {/* <Musiccontrol ref={childRef} /> */}
                    </Box>
                </Box >
            </div >
        </>
    )
}

export default LibraryNew
