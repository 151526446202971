import React, { useEffect, useRef } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { Avatar } from "@mui/material";
import ava from "../../img/dster-webp/doublex.webp";
import ava1 from "../../img/dster-webp/ava1.webp";
import Axios from "../../Axios";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import s2 from "../../img/dster-webp/backword.webp"
import s3 from "../../img/dster-webp/pass-play.webp"
import s4 from "../../img/dster-webp/farward.webp"
import s6 from "../../img/dster-webp/phause.webp"
import vijay from '../../img/dster-webp/vijay.mp3'
import Slider from '@mui/material/Slider';
import Header from '../Header/Header';
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const drawerWidth = 280;

const closeModal = () => {
    $("#collectionModal").hide();
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const northamerica = [
    'Jazz',
    'Blues',
    'Country',
    'Hip-Hop',
    'Rock n Roll',
    'R&B (Rhythm and Blues)',
    'Gospel',
    'Bluegrass',
    'Folk',
    'Electronic',
    'Indie',
    'Reggae ',
    'Metal',
    'Reggae ',
    'Soul',

];

const latinamerica = [
    'Salsa',
    'Reggaeton',
    'Bossa Nova',
    'Tango',
    'Mariachi',

];


const europe = [
    'Classical',
    'Techno',
    'Bossa Nova',
    'EDM (Electronic Dance Music)',
    'Flamenco',
    'Balkan Folk',
]

const africa = [
    'Afrobeat',
    'Highlife',
    'Kwaito',
    'Mbalax',
    'Taarab',
]

const asia = [
    'Bollywood Music',
    'K-Pop',
    'J-Pop',
    'Qawwali',
    'Peking Opera',
]

const middleeast = [
    'Arabic Pop',
    'Dabke',
    'Turkish Folk Music',
    'Raï',
    'Fado',
]

const oceania = [
    'Aboriginal Folk Music',
    'Pasifika',
    'Contemporary Australian Rock',
    'Maori Music',
    'Hawaiian Music',
]

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}




function MintNft() {
    const navigate = useNavigate()
    const classes = useStyles();

    const [classNamebox, setclassNamebox] = useState(1);
    const [NFTOwnerShip, setNFTOwnership] = useState(0);
    const [OpenEveryone, setOpenEveryone] = useState(0);
    const [ClaimDate, setClaimDate] = useState("");
    const [ClaimLimit, setClaimLimit] = useState(0);
    const [ClaimDateerr, setClaimDateerr] = useState(null);
    const [ClaimLimiterr, setClaimLimiterr] = useState(null);
    const [preview, setpreview] = useState()
    const [audio, setAudio] = useState()
    const [previewerr, setpreviewerr] = useState()
    const [audioerr, setAudioerr] = useState()
    const [collectionData, setCollectionData] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [song, setsong] = useState();
    const [category, setCategory] = useState();
    const [ISRC, setISRC] = useState("");
    const [NFTLink, setNFTLink] = useState("");
    const [description, setDescription] = useState()
    const [imageerr, setImageerr] = useState(null)
    const [songerr, setsongerr] = useState(null);
    const [categoryerr, setCategoryerr] = useState(null);
    const [ISRCerr, setISRCerr] = useState(null);
    const [NFTLinkerr, setNFTLinkerr] = useState(null);
    const [descriptionerr, setDescriptionerr] = useState(null)

    const [age, setAge] = React.useState('');

    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    var create_data
    const [SelectedCategory, setSelectedCategory] = useState("");
    const tab_2 = () => {
        if (!song) {
            console.log("Please Enter song Title")
            setsongerr("Please Enter song Title")
        }
        else if (!ISRC) {
            setISRCerr("Please Enter IRSC")
        }
        else if (!NFTLink) {
            setNFTLinkerr("Please Enter NFT Link")
        }
        else if (!category) {
            setCategoryerr("Please Enter Category")
        }
        else if (!description) {
            setDescriptionerr("Please Enter Description")
        }
        else if (!image) {
            setImageerr("Please Select Image File")
        }
        else if (!preview) {
            setpreviewerr("Please Select preview Audio File")
        }
        else if (!audio) {
            setAudioerr("Please Select preview Audio File")
        }
        else {

            create_data = {
                song_name: song,
                ISRC: ISRC,
                nft_link: NFTLink,
                category: category,
                description: description,
                image: image,
                pre_audio: preview,
                audio: audio,
                nftclaim: ClaimLimit,
                opentoeveryone: opento,
            }
            console.log(create_data)
            $(".de_tab_content").hide();
            $(".de_tab_content_2").show();
            $(".de_tab_content_3").hide();
            setclassNamebox(2);
        }

    };
    const showCollection = (value) => {
        if (OpenEveryone == 1) {
            setNFTOwnership(0);
            toast.error(
                "You can Choose Only One limitation at a time for your NFT Minting"
            );
            return false;
        } else if (value == 0 && OpenEveryone == 0) {
            $("#showCollection").show();
            setNFTOwnership(1);
        } else {
            $("#showCollection").hide();
            setNFTOwnership(0);
        }
    };
    const OpentoEveryOne = (value) => {
        if (NFTOwnerShip == 1) {
            setOpenEveryone(0);
            toast.error(
                "You can Choose Only One limitation at a time for your NFT Minting"
            );
            return false;
        }
        value == 0 && NFTOwnerShip == 0 ? setOpenEveryone(1) : setOpenEveryone(0);
    };

    const OpentoEveryOnes = () => {
        setOpento(!opento)
    };

    const createMusic = async () => {
        try {
            if (!ClaimDate) {
                setClaimDateerr("Please Enter Claim Date")

            }
            else if (!ClaimLimit) {
                console.log("Please Enter Claim Limit")
                setClaimLimiterr("Please Enter Claim Limit")
            }

            else {
                create_data = {
                    song_name: song,
                    ISRC: ISRC,
                    nft_link: NFTLink,
                    categorey: category,
                    description: description,
                    image: image,
                    nft_type: 'per',
                    pre_audio: preview,
                    audio: audio,
                    nftclaim: ClaimLimit,
                    opentoeveryone: opento,
                }
                console.log(create_data)

                await Axios.post("/users/uploadmusic", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {
                        console.log(res, "res")
                        if (res?.data?.success) {
                            toast.success('Music NFT Created Successfully')
                            setTimeout(() => {
                                navigate('/')
                            }, 2000);

                        }
                        else {
                            toast.error(res?.data?.message)
                            tab_1()
                        }
                    }).catch((err) => {
                        console.log(err, "err")
                        toast.error(err?.response?.data?.message)
                        tab_1()
                    })
            }
        } catch (error) {
            console.log(error, "error");
        }

    }

    const tab_1 = () => {
        $(".de_tab_content_2").hide();
        $(".de_tab_content").show();
        $(".de_tab_content_3").hide();
        setclassNamebox(1);
    };
    const tab_3 = () => {
        $(".de_tab_content_2").hide();
        $(".de_tab_content").hide();
        $(".de_tab_content_3").show();
        setclassNamebox(3);
    };
    const changeTitle = (value) => {
        value == "" ? $("#title").html("Music") : $("#title").html(value)
        setsong(value)
        setsongerr(null)
    };
    const changeDesc = (value) => {
        $("#DESC").html(value);
        setDescription(value)
        setDescriptionerr(null)
    };

    const changeISRC = (value) => {
        setISRC(value);
        setISRCerr(null)
    };

    const changeLink = (value) => {
        setNFTLink(value);
        setNFTLinkerr(null)
    };

    const changeCategory = (value) => {
        setSelectedCategory(value);
    };

    const changeClaimDate = (value) => {
        setClaimDate(value);
        setClaimDateerr(null)
    };

    const changeClaimLimit = (value) => {
        setClaimLimit(value);
        setClaimLimiterr(null)
    };
    const getCollectionModal = (value) => {
        value == "new_collection"
            ? $("#collectionModal").show()
            : $("#collectionModal").hide();
    };

    const makePreview = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const checkLimit = async (event) => {
        const file = event.target.files[0];
        const audio = new Audio();
        audio.src = URL.createObjectURL(file);

        audio.addEventListener("loadedmetadata", () => {
            const duration = audio.duration;
            if (duration > 30) {
                event.target.value = null;
                toast.error("Please upload a preview File less than 30 secs");
                return false;
            }
        });
    };

    const checkLimits = async (audiofile) => {
        const file = audiofile;
        const audio = new Audio();
        audio.src = URL.createObjectURL(file);

        audio.addEventListener("loadedmetadata", () => {
            const duration = audio.duration;
            if (duration > 30) {
                audiofile = null;
                toast.error("Please upload a preview File less than 30 secs");
                return false;
            }
        });
    };



    // console.log(category);
    const [opento, setOpento] = React.useState(false);
    const [opentoerr, setOpentoerr] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const [personName, setPersonName] = React.useState('');

    const handleChange = (e) => {
        setPersonName(e.target.value)
        console.log('value', e.target.value);
        setCategory(e.target.value)
        setCategoryerr(null)
    };

    // get image

    const [profileData, setProfileData] = useState({});
    const [updateName, setUpdateName] = useState();
    const tokens = localStorage.getItem("iphephile");
    const [image, setImage] = useState(null);
    const [filetype, setFileType] = useState('')
    const [audiotype, setAudioType] = useState('')

    const [filetype1, setFileType1] = useState('')
    const [audiotype1, setAudioType1] = useState('')

    const handleAudioClick = (event) => {
        hiddenAudioInput.current.click();
        setAudioType(true)
    };

    const handleAudioClick1 = (event) => {
        hiddenAudioInput1.current.click();
        setAudioType1(true)
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const hiddenFileInput = useRef(null);
    const hiddenAudioInput = useRef(null)
    const hiddenAudioInput1 = useRef(null)
    const HandleNameUpdate = (e) => {
        setUpdateName(e.target.value);
        // setnameerr("");
    };
    const handleAudio = async (event) => {
        const files = event.target.files[0];
        setAudioType(files.name)
        console.log(files.name)
        try {
            let formData = await new FormData();
            formData.append("image", files);
            //checkLimits(files)
            // if(!checkLimits){
            //     console.log("duration")
            // }

            const { data } = await Axios.post("/updateDp", formData);
            if (data.result) {

                setpreview(data.result);
                setpreviewerr(null)
            }
        } catch (error) {
            console.log(error, "err");
        }
    }


    const handleAudio1 = async (event) => {
        const files = event.target.files[0];
        setAudioType1(files.name)
        console.log(files.name)

        try {
            let formData = await new FormData();
            formData.append("image", files);


            const { data } = await Axios.post("/updateDp", formData);
            if (data.result) {

                setAudio(data.result);
                setAudioerr(null)
            }
        } catch (error) {
            console.log(error, "err");
        }
    }

    const handleImageChange = async (event) => {
        const files = event.target.files[0];


        setFileType(files.name)
        console.log(filetype);
        try {
            let formData = await new FormData();
            formData.append("image", files);

            const { data } = await Axios.post("/updateDp", formData);
            if (data.result) {
                // setLogo(data.result)
                // console.log(data.result,'Total BalanceTotal BalanceTotal Balance');
                setImage(data.result);
                setImageerr(null)
                const updateData = {
                    name: profileData.name,
                    // phone: profileData?.result?.phone,
                    profilePicture: data.result,
                };
                // await Axios.post("/updateProfile", updateData).then((res) => {
                //     console.log(res, "res-imagsss")
                //     if (res.data.success) {
                //         toast.success("Image uploaded Successfully");
                //         getProfile();
                //         // window.location.reload();
                //         setProfileData(res.data)
                //     }
                // });
            }
        } catch (error) {
            console.log(error, "err");
        }
    };



    const getProfile = async () => {
        try {
            await Axios.get(`/profile/getprofile`, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setProfileData(res?.data?.result);
                        setUpdateName(res?.data?.result?.name);
                    } else {
                        toast.error("Can't Fetch Detail");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };

    //Audio preview

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0)

    const [isPlaying1, setIsPlaying1] = useState(false);
    const [currentTime1, setCurrentTime1] = useState(0);
    const [duration1, setDuration1] = useState(0)

    const audioRef = useRef(null);
    const audioRef1 = useRef(null);

    // Function to seek to a specific time in audio.


    const handlePlay = () => {
        audioRef.current.play();
        setIsPlaying(true);
    };

    const handlePlay1 = () => {
        audioRef1.current.play();
        setIsPlaying1(true);
    };

    const handleFarward = () => {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
    }

    const handleFarward1 = () => {
        audioRef1.current.currentTime = 0;
        audioRef1.current.play();
    }

    const handlePause = () => {
        audioRef.current.pause();
        setIsPlaying(false);
    };
    const handlePause1 = () => {
        audioRef1.current.pause();
        setIsPlaying1(false);
    };


    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause();
        } else {
            handlePlay();
        }
    };

    const handlePlayPause1 = () => {
        if (isPlaying1) {
            handlePause1();
        } else {
            handlePlay1();
        }
    };


    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
        setDuration(audioRef.current.duration);
    };

    const handleTimeUpdate1 = () => {
        setCurrentTime1(audioRef1.current.currentTime);
        setDuration1(audioRef1.current.duration);
    };




    const handleSeek = (e) => {
        audioRef.current.currentTime = e.target.value;
        setCurrentTime(e.target.value);
    };

    const handleSeek1 = (e) => {
        audioRef1.current.currentTime = e.target.value;
        setCurrentTime1(e.target.value);
    };

    useEffect(() => {
        audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
        return () => {
            // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        };


    }, []);

    useEffect(() => {
        audioRef1.current.addEventListener("timeupdate", handleTimeUpdate1);
        return () => {
            // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        };

    }, []);




    function formatDuration(durationSeconds) {
        console.log(durationSeconds)
        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }

    function formatDuration1(durationSeconds) {
        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }


    return (

        <div className="upload hedaer-bg">
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }}>
                {/* <Header2 /> */}
                <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '100px' }}
                    className="upload-bg">
                    <Container maxWidth="lg">
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Item className={classes.containWidth}>
                                <Header />
                            </Item>
                        </Grid>
                    </Container>
                    <div className="main no-bottom no-top" id="content">


                        <div className="select-method">
                            <div className="row wow fadeIn justify-content-center align-items-center">
                                <div className="col-md-9">
                                    <form id="form-create-item" className="form-border" method="post" action="https://gigaland.io/email.php">
                                        <div className="field-set">
                                            <Link to="/create"><ArrowBackIcon /></Link>
                                            <h5>Select method</h5>
                                            <div className="de_tab tab_methods">
                                                <ul className="de_nav">
                                                    <li className={classNamebox == 1 ? "active" : ""} >
                                                        {/* onClick={(e) => tab_1() */}
                                                        <span>
                                                            {/* <FontAwesomeIcon icon={faTag} size="1.2x" />{" "} */}
                                                            &nbsp;Utility
                                                        </span>
                                                    </li>
                                                    <li className={classNamebox == 2 ? "active" : ""}  >
                                                        <span>
                                                            {" "}
                                                            {/* <FontAwesomeIcon icon={faHourglass1} />{" "} */}
                                                            &nbsp;Requirements
                                                        </span>
                                                    </li>

                                                </ul>

                                                {/* <div className="spacer-40"></div> */}
                                                <div className="de_tab_content">
                                                    <p>
                                                        {" "}
                                                        {/* <FontAwesomeIcon icon={faExclamationCircle} /> */}
                                                        &nbsp;New to Dreamster ?check out our tips for creators
                                                    </p>
                                                </div>

                                                <div className="de_tab_content">
                                                    <h5>
                                                        Information <span style={{ color: "red" }}> * </span>
                                                    </h5>

                                                    <Grid container spacing={0} >
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <div id="tab_opt_1">
                                                                <h5 className="h5-custom">Song Name</h5>
                                                                <input type="text" name="item_price" onChange={(e) => changeTitle(e.target.value)}
                                                                    style={{ borderRadius: "25px" }}
                                                                    id="Title"
                                                                    className="form-control"
                                                                    placeholder="Your Title here"
                                                                />
                                                                {songerr !== null ? (
                                                                    <div className="errordiv">{songerr}</div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <div id="tab_opt_1">
                                                                <h5 className="h5-custom">
                                                                    International Standard Recording Code(ISRC)
                                                                </h5>
                                                                <input type="text" name="isrc" onChange={(e) => changeISRC(e.target.value)}
                                                                    style={{ borderRadius: "25px" }}
                                                                    id="Title"
                                                                    className="form-control"
                                                                    placeholder="Your ISRC here"
                                                                />
                                                                {ISRCerr !== null ? (
                                                                    <div className="errordiv">{ISRCerr}</div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </Grid>

                                                    </Grid>



                                                    <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <div id="tab_opt_1">
                                                                <h5 className="h5-custom">
                                                                    Enter the NFT Collection link
                                                                </h5>
                                                                <input type="text" name="platform_link" onChange={(e) => changeLink(e.target.value)}
                                                                    style={{ borderRadius: "25px" }}
                                                                    id="Title"
                                                                    className="form-control"
                                                                    placeholder="Your Platform Link here"
                                                                />
                                                                {NFTLinkerr !== null ? (
                                                                    <div className="errordiv">{NFTLinkerr}</div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <div id="tab_opt_1" className="select-input">
                                                                <FormControl fullWidth>
                                                                    <h5 className="h5-custom">Song Category</h5>
                                                                    <Select
                                                                        // multiple
                                                                        displayEmpty

                                                                        value={personName}
                                                                        onChange={handleChange}
                                                                        input={<OutlinedInput />}
                                                                        renderValue={(selected) => {
                                                                            if (selected.length === 0) {
                                                                                return <MenuItem disabled>*Pick a Genre</MenuItem>;
                                                                            }

                                                                            return selected;
                                                                        }}

                                                                        MenuProps={MenuProps}
                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                    >

                                                                        <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem>
                                                                        {northamerica.map((name) => (
                                                                            <MenuItem
                                                                                key={name}
                                                                                value={name}
                                                                                style={getStyles(name, personName, theme)}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        ))}
                                                                        <MenuItem className="select-genre" disabled><strong>Latin America</strong></MenuItem>
                                                                        {latinamerica.map((name) => (
                                                                            <MenuItem
                                                                                key={name}
                                                                                value={name}
                                                                                style={getStyles(name, personName, theme)}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        ))}
                                                                        <MenuItem className="select-genre" disabled><strong>Europe</strong></MenuItem>
                                                                        {europe.map((name) => (
                                                                            <MenuItem
                                                                                key={name}
                                                                                value={name}
                                                                                style={getStyles(name, personName, theme)}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        ))}
                                                                        <MenuItem className="select-genre" disabled><strong>Africa</strong></MenuItem>
                                                                        {africa.map((name) => (
                                                                            <MenuItem
                                                                                key={name}
                                                                                value={name}
                                                                                style={getStyles(name, personName, theme)}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        ))}
                                                                        <MenuItem className="select-genre" disabled ><strong>Asia</strong></MenuItem>
                                                                        {asia.map((name) => (
                                                                            <MenuItem
                                                                                key={name}
                                                                                value={name}
                                                                                style={getStyles(name, personName, theme)}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        ))}
                                                                        <MenuItem className="select-genre" disabled ><strong>Middle East</strong></MenuItem>
                                                                        {middleeast.map((name) => (
                                                                            <MenuItem
                                                                                key={name}
                                                                                value={name}
                                                                                style={getStyles(name, personName, theme)}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        ))}
                                                                        <MenuItem className="select-genre" disabled ><strong>Oceania</strong></MenuItem>
                                                                        {oceania.map((name) => (
                                                                            <MenuItem
                                                                                key={name}
                                                                                value={name}
                                                                                style={getStyles(name, personName, theme)}
                                                                            >
                                                                                {name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                    {categoryerr !== null ? (
                                                                        <div className="errordiv">{categoryerr}</div>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </FormControl>
                                                            </div>
                                                        </Grid>

                                                    </Grid>


                                                    <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                            <div id="tab_opt_1" className="description-part">
                                                                <h5>Description </h5>
                                                                <textarea
                                                                    name="postContent"
                                                                    onChange={(e) => changeDesc(e.target.value)}
                                                                    placeholder="Explain what your NFT is about ( Max 500 characters )"
                                                                    rows={4}
                                                                    cols={40}
                                                                />
                                                                {descriptionerr !== null ? (
                                                                    <div className="errordiv">{descriptionerr}</div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </Grid>
                                                    </Grid>




                                                    <Grid container spacing={2}>

                                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Preview Images </h5>
                                                                {/* <FontAwesomeIcon icon={faCloudUploadAlt} /> */}

                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <div
                                                                                className="profile-img"
                                                                                onClick={handleClick}
                                                                                style={{ cursor: "pointer" }}
                                                                            >
                                                                                {/* <div className='profile-img-inner'>
                                {image ? (
                                <img src={URL.createObjectURL(image)} alt="upload-profile-image" className="img-display-after" />
                                ) : (
                                <img src={subBg} alt="upload-profile-image" className="img-display-before" />
                                )}
                                </div> */}

                                                                                <div className="preview-img profile-banner-size">
                                                                                    {image ? (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={image}
                                                                                            sx={{
                                                                                                width: 250,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={ava1}
                                                                                            sx={{
                                                                                                width: 250,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    <div class="edit1">
                                                                                        <CloudUploadIcon />
                                                                                        <div>Upload Image</div>
                                                                                    </div>

                                                                                </div>

                                                                                <input
                                                                                    id="image-upload-input"
                                                                                    type="file"
                                                                                    onChange={handleImageChange}
                                                                                    ref={hiddenFileInput}
                                                                                    style={{ display: "none" }}
                                                                                />
                                                                                {/* <Button
                                className="image-upload-button"
                                id="image-upload-button"
                                onClick={handleUploadButtonClick}
                                >
                                <CloudUploadIcon/>
                                </Button> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">File type: PNG, JPG, GIF</p>
                                                                <div style={{ color: 'var(--clr-font)' }}>ChoosenFile:{filetype}</div>
                                                            </div>
                                                            {imageerr !== null ? (
                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{imageerr}asd</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Preview Audio </h5>
                                                                {/* <FontAwesomeIcon icon={faVolumeUp} /> */}
                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <div
                                                                                className="profile-img"
                                                                                onClick={handleAudioClick}
                                                                                style={{ cursor: "pointer" }}
                                                                            >

                                                                                <div className="preview-img profile-banner-size">
                                                                                    {profileData.profilePicture ? (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={profileData.profilePicture}
                                                                                            sx={{
                                                                                                width: 250,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={ava}
                                                                                            sx={{
                                                                                                width: 250,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    )}


                                                                                    <div class="edit1">
                                                                                        <CloudUploadIcon />
                                                                                        <div>Upload Audio</div>
                                                                                    </div>
                                                                                    {/* {profileData.profilePicture ? (
                                <Avatar
                                    alt="Remy Sharp"
                                    src={profileData.profilePicture}
                                    sx={{
                                        width: 250,
                                        height: 250,
                                        border: "3px solid #fff",
                                    }}
                                />
                            ) : (
                            <Avatar
                                alt="Remy Sharp"
                                src={ava}
                                sx={{
                                    width: 250,
                                    height: 250,
                                    border: "3px solid #fff",
                                }}
                            />
                             )} 


                            <div class="edit1">
                                <CloudUploadIcon />
                                <div>Upload Audio</div>
                            </div> */}

                                                                                </div>

                                                                                {/* <input
                            id="image-upload-input"
                            type="file"
                            onChange={handleAudio}
                            ref={hiddenAudioInput}
                            style={{ display: "none" }}
                        /> */}
                                                                                {/* <Button
className="image-upload-button"
id="image-upload-button"
onClick={handleUploadButtonClick}
>
<CloudUploadIcon/>
</Button> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">Filetype: {audiotype}</p>
                                                                {/* <div className="preview-div">FileType: {audiotype}</div> */}

                                                                <div className='track-time'>

                                                                    <Box sx={{ width: '80%', margin: 'auto' }}>

                                                                        <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={currentTime}
                                                                            min={0}
                                                                            step={1}
                                                                            max={duration}
                                                                            onChange={handleSeek}

                                                                        />
                                                                        <audio ref={audioRef} src={preview ? preview : vijay} />
                                                                        <div className='audio-timmer'>
                                                                            <div>
                                                                                {formatDuration(currentTime)}

                                                                            </div>
                                                                            <div>
                                                                                {formatDuration(duration - currentTime)}

                                                                            </div>
                                                                        </div>

                                                                    </Box>

                                                                </div>
                                                                <div>
                                                                    <Stack direction="row" spacing={4} className='music-stack'>
                                                                        <div>
                                                                            {/* <img src={s1} /> */}
                                                                        </div>
                                                                        <div>
                                                                            <img src={s2}
                                                                                onClick={handleFarward}
                                                                            />
                                                                        </div>
                                                                        <div >
                                                                            {audiotype ? (<>
                                                                                <Button
                                                                                    onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button>
                                                                            </>) : (<>

                                                                                <Button
                                                                                // onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button></>)}

                                                                        </div>
                                                                        <div>
                                                                            <img src={s4} />
                                                                        </div>
                                                                        <div>
                                                                            {/* <img src={s5} /> */}
                                                                        </div>

                                                                    </Stack>

                                                                </div>


                                                                <div className="audio-div">
                                                                    <input type="file" id="preview" onChange={handleAudio}
                                                                        accept="audio/*"
                                                                        ref={hiddenAudioInput}
                                                                        className="btn-main fle_choose_btn"
                                                                    // placeholder="Browse"
                                                                    />
                                                                </div>



                                                            </div>
                                                            {previewerr !== null ? (
                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{previewerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>


                                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Audio </h5>
                                                                {/* <FontAwesomeIcon icon={faVolumeUp} /> */}
                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <div
                                                                                className="profile-img"
                                                                                onClick={handleAudioClick1}
                                                                                style={{ cursor: "pointer" }}
                                                                            >

                                                                                <div className="preview-img profile-banner-size">
                                                                                    {profileData.profilePicture ? (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={profileData.profilePicture}
                                                                                            sx={{
                                                                                                width: 250,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={ava}
                                                                                            sx={{
                                                                                                width: 250,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    )}


                                                                                    <div class="edit1">
                                                                                        <CloudUploadIcon />
                                                                                        <div>Upload Audio</div>
                                                                                    </div>
                                                                                    {/* {profileData.profilePicture ? (
                                <Avatar
                                    alt="Remy Sharp"
                                    src={profileData.profilePicture}
                                    sx={{
                                        width: 250,
                                        height: 250,
                                        border: "3px solid #fff",
                                    }}
                                />
                            ) : (
                            <Avatar
                                alt="Remy Sharp"
                                src={ava}
                                sx={{
                                    width: 250,
                                    height: 250,
                                    border: "3px solid #fff",
                                }}
                            />
                             )} 


                            <div class="edit1">
                                <CloudUploadIcon />
                                <div>Upload Audio</div>
                            </div> */}

                                                                                </div>

                                                                                {/* <input
                            id="image-upload-input"
                            type="file"
                            onChange={handleAudio}
                            ref={hiddenAudioInput}
                            style={{ display: "none" }}
                        /> */}
                                                                                {/* <Button
className="image-upload-button"
id="image-upload-button"
onClick={handleUploadButtonClick}
>
<CloudUploadIcon/>
</Button> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">Filetype: {audiotype1}</p>
                                                                {/* <div className="preview-div" >FileType: {audiotype1}</div> */}

                                                                <div className='track-time'>

                                                                    <Box sx={{ width: '80%', margin: 'auto' }}>

                                                                        <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={currentTime1}
                                                                            min={0}
                                                                            step={1}
                                                                            max={duration1}
                                                                            onChange={handleSeek1}

                                                                        />
                                                                        <audio ref={audioRef1} src={audio ? audio : vijay} />

                                                                        <div className='audio-timmer'>
                                                                            <div>
                                                                                {formatDuration(currentTime1)}

                                                                            </div>
                                                                            <div>
                                                                                {duration1 - currentTime1 !== "NaN" ? formatDuration(duration1 - currentTime1) : '0:00'}

                                                                            </div>
                                                                        </div>

                                                                    </Box>

                                                                </div>
                                                                <div>
                                                                    <Stack direction="row" spacing={4} className='music-stack'>
                                                                        <div>
                                                                            {/* <img src={s1} /> */}
                                                                        </div>
                                                                        <div>
                                                                            <img src={s2}
                                                                                onClick={handleFarward1}
                                                                            />
                                                                        </div>
                                                                        <div >
                                                                            {audiotype1 ? (<>
                                                                                <Button
                                                                                    onClick={handlePlayPause1}
                                                                                >
                                                                                    {isPlaying1 ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button>
                                                                            </>) : (<>

                                                                                <Button
                                                                                // onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying1 ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button></>)}

                                                                        </div>
                                                                        <div>
                                                                            <img src={s4} />
                                                                        </div>
                                                                        <div>
                                                                            {/* <img src={s5} /> */}
                                                                        </div>

                                                                    </Stack>

                                                                </div>



                                                                <div className="audio-div">
                                                                    <input type="file" id="preview" onChange={handleAudio1}
                                                                        accept="audio/*"
                                                                        ref={hiddenAudioInput1}
                                                                        className="btn-main fle_choose_btn"
                                                                    // placeholder="Browse"
                                                                    />
                                                                </div>



                                                            </div>
                                                            {audioerr !== null ? (
                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{audioerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>

                                                    </Grid>


                                                    <div className="spacer-single"></div>

                                                    {/* <input type="button" onClick={() => tab_2()} i
                                                        className="btn btn-upload"
                                                        value="Next"
                                                    /> */}
                                                    <Button onClick={() => tab_2()} i className="btn btn-upload" style={{ "marginTop": "30px" }}>Next</Button>
                                                    <div className="spacer-single"></div>
                                                </div>
                                                <div className="spacer-single"></div>

                                                <div className="de_tab_content_2" style={{ display: "none" }}>
                                                    <h5>
                                                        Required Fields
                                                        <span style={{ color: "red" }}> * </span>

                                                    </h5>
                                                    <p>
                                                        set the Requirements users need to meet to mint your
                                                        Dreamster
                                                    </p>

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                            <div id="tab_opt_1" className="set-date">
                                                                <label>
                                                                    Set date and time for claiming the song
                                                                </label>
                                                                <input type="date" name="item_price" id="Title" className="form-control date-input" onChange={(e) =>
                                                                    changeClaimDate(e.target.value)
                                                                }
                                                                />
                                                                {ClaimDateerr !== null ? (
                                                                    <div className="errordiv" style={{ "marginTop": "10px" }}>{ClaimDateerr}</div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                            <div id="tab_opt_1" className="set-date">
                                                                <label>
                                                                    Total NFT Claim
                                                                </label>
                                                                <input type="number" name="item_price" id="Title" onChange={(e) => changeClaimLimit(e.target.value)} className="form-control date-input"
                                                                    placeholder="Total NFT Claim" />
                                                                {ClaimLimiterr !== null ? (
                                                                    <div className="errordiv" style={{ "marginTop": "10px" }}>{ClaimLimiterr}</div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </Grid>

                                                    </Grid>


                                                    <div className="switch-with-title" style={{ borderRadius: "30px" }}>

                                                        <h5> {/* <FontAwesomeIcon icon={faGlobe} /> &nbsp;Open to */}Open to Everyone
                                                        </h5>
                                                        <div className="de-switch 1">
                                                            {/* <input type="checkbox" id="switch-unlock7" checked={OpenEveryone == 1 ?? false} onClick={(e) =>
                                                                OpentoEveryOne(e.target.value)}
                                                                value={OpenEveryone == "0" ? "0" : "1"}
                                                                className="checkbox"
                                                            /> */}
                                                            <Switch {...label} onClick={() => OpentoEveryOnes()} />
                                                            {opentoerr !== null ? (
                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{opentoerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <p className="p-info">
                                                        Anyone with your wallet can mint your utility
                                                    </p>
                                                    <div className="spacer-single"></div>

                                                    <p style={{ color: "grey" }}>
                                                        ---------------------- OR -----------------------
                                                    </p>
                                                    <div className="spacer-single"></div>
                                                    <div id="tab_opt_1" style={{
                                                        border: "1px solid #dddddd",
                                                        padding: "30px",
                                                        borderRadius: "30px",
                                                    }}>
                                                        <div className="" style={{ borderRadius: "30px" }}>
                                                            <div className="switch-with-title">
                                                                <h5>
                                                                    {" "}
                                                                    {/* <FontAwesomeIcon icon={faMusic} /> &nbsp;NFT */}
                                                                    Ownership
                                                                </h5>
                                                                <div className="de-switch 2">

                                                                    <Switch {...label} id="switch-unlock8" checked={NFTOwnerShip == 1 ?? false}
                                                                        onClick={(e) => showCollection(e.target.value)}
                                                                        value={NFTOwnerShip == 0 ? "0" : "1"}
                                                                        className="checkbox" />
                                                                </div>
                                                            </div>

                                                            <p className="p-info">
                                                                Require users to hold certain NFTs in their Wallet
                                                            </p>
                                                            <div className="submit-btn2">
                                                                <Button i className="btn btn-upload" onClick={() => createMusic()}>Submit</Button>
                                                            </div>

                                                            <div id="showCollection" style={{ display: "none" }}>
                                                                <h5>
                                                                    Choose Collection
                                                                </h5>
                                                                <div className="clearfix"></div>
                                                                <p className="p-info">
                                                                    Anyone with your wallet can mint your utility
                                                                </p>
                                                                <div id="item_collection" className="dropdown fullwidth mb20 new-collection">
                                                                    <Button onClick={handleOpen}>Add New Collection</Button>
                                                                </div>
                                                                <Modal
                                                                    open={open}
                                                                    onClose={handleClose}
                                                                    aria-labelledby="modal-modal-title"
                                                                    aria-describedby="modal-modal-description"

                                                                >
                                                                    <Box sx={style} className="collection-popup">
                                                                        <p id="modal-modal-title" component="h2" className="collection-para">
                                                                            Find Collection
                                                                        </p>
                                                                        <div className='popup ' >
                                                                            <CloseIcon onClick={handleClose} />
                                                                        </div>
                                                                        <p id="modal-modal-description" sx={{ mt: 2 }} className="des-para">
                                                                            Specify a contract address or a marketplace collection URL <Link to="">(Read more)</Link>
                                                                        </p>
                                                                        <div className="details-collection">
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                                    <input type="text" name="item_price" id="Title" className="form-control date-input"
                                                                                        placeholder="Address" />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                                                    <div className="select-coin">
                                                                                        <FormControl fullWidth>

                                                                                            <Select id="demo-simple-select" value={age} onChange={handleChange2} >
                                                                                                <MenuItem value={10}>Ethereum</MenuItem>
                                                                                                <MenuItem value={20}>Polygon</MenuItem>
                                                                                                <MenuItem value={30}>Binance</MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item sm={12} md={6} lg={6} xl={6}>
                                                                                    <Stack spacing={2} direction="row">
                                                                                        <Button>Submit</Button>
                                                                                        <Button>Cancel</Button>
                                                                                    </Stack>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </Box>
                                                                </Modal>
                                                            </div>
                                                        </div>


                                                    </div>





                                                </div>

                                                <div className="de_tab_content_3" style={{ display: "none" }}>
                                                    <h5 style={{ color: "white" }}>
                                                        Required Fields{" "}
                                                        <span style={{ color: "red" }}> * </span>
                                                    </h5>

                                                    <div id="tab_opt_1" style={{
                                                        border: "1px solid rgb(34, 34, 39)",
                                                        padding: "30px",
                                                        borderRadius: "30px",
                                                    }}>
                                                        <h5 style={{ color: "white" }}>Drop Details</h5>
                                                        <p>
                                                            choose a drop method and provide all relavent
                                                            details
                                                        </p>
                                                        <div className="switch-with-title" style={{ borderRadius: "30px" }}>
                                                            <h5 style={{ color: "white" }}>
                                                                {" "}
                                                                {/* <FontAwesomeIcon icon={faServer} /> */}
                                                                &nbsp;&nbsp;First Come, First Serve
                                                            </h5>
                                                            <div className="de-switch">
                                                                <input type="checkbox" id="switch-unlock2" className="checkbox" />
                                                                <label for="switch-unlock2"></label>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            <p className="p-info">
                                                                The first user who meet the requirements can
                                                                mint,priiority will be given to earliest arrivals
                                                            </p>
                                                        </div>
                                                        <div className="spacer-single"></div>

                                                        <div className="switch-with-title" style={{ borderRadius: "30px" }}>
                                                            <h5 style={{ color: "white" }}>
                                                                {" "}
                                                                {/* <FontAwesomeIcon icon={faDiceSix} /> */}
                                                                &nbsp;&nbsp;Raffle
                                                            </h5>
                                                            <div className="de-switch">
                                                                <input type="checkbox" id="switch-unlock1" className="checkbox" />
                                                                <label for="switch-unlock1"></label>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                            <p className="p-info">
                                                                Users who meet the requirements can enter a
                                                                raffle,after raffle is completed users can able to
                                                                mint
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="spacer-single"></div>
                                                    <div id="tab_opt_1" style={{
                                                        border: "1px solid #dddddd",
                                                        padding: "30px",
                                                        borderRadius: "30px",
                                                    }}>
                                                        <h5 style={{ color: "white" }}>
                                                            Price <span style={{ color: "red" }}>*</span>
                                                        </h5>
                                                        <p>
                                                            set the price users will pay per utility..Enter 0
                                                            for free mint
                                                        </p>
                                                        <input type="text" name="item_price" id="item_price" style={{ borderRadius: "25px" }}
                                                            className="form-control" placeholder="Amount" />

                                                        <div className="spacer-single"></div>

                                                        <div className="switch-with-title" style={{ borderRadius: "30px" }}>
                                                            <h5 style={{ color: "white" }}>
                                                                <i className="fa fa- fa-unlock-alt id-color-2 icon_padlock"></i>
                                                                Single Price
                                                            </h5>
                                                            <div className="de-switch">
                                                                <p>--Matic</p>
                                                            </div>
                                                            <div className="spacer-single"></div>
                                                            <h5 style={{ color: "white" }}>
                                                                <i className="fa fa- fa-unlock-alt id-color-2 icon_padlock"></i>
                                                                Dreamster  Fee &nbsp;{" "}
                                                                {/* <FontAwesomeIcon icon={faExclamationCircle} /> */}
                                                            </h5>
                                                            <div className="de-switch">
                                                                <p>2.5%</p>
                                                            </div>
                                                            <div className="spacer-single"></div>
                                                            <h5 style={{ color: "white" }}>
                                                                <i className="fa fa- fa-unlock-alt id-color-2 icon_padlock"></i>
                                                                Total payable Fee
                                                            </h5>
                                                            <div className="de-switch">
                                                                <p>10 Matic</p>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </div>
                                                    <div className="spacer-single"></div>

                                                    <input type="button" id="submit" className="btn btn-upload" value="Submit" />
                                                    <div className="spacer-single"></div>
                                                </div>
                                            </div>

                                            <div className="spacer-20"></div>
                                            {/* <div className="modal"> */}
                                            <div id="collectionModal" className="find-collection-modal">
                                                <div className="p-8">
                                                    <h3 className="text-ds-dark-primary text-2xl font-semibold text-left">
                                                        Find collection
                                                    </h3>
                                                    <p className="text-gray-500 text-base mb-4 font-medium">
                                                        Specify a contract address or a marketplace collection
                                                        URL (
                                                        <a className="underline" href="#" target="_blank">
                                                            read more
                                                        </a>
                                                        )
                                                    </p>
                                                    <div id="tab_opt_1">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <input type="text" name="item_price" style={{ borderRadius: "12px", width: "50%", padding: "10px", marginBottom: "10px", border: "1px solid #bcbcbc" }}
                                                                    id="collectionContract" className="input" placeholder="Your Contract Address here" />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <select className="btn-selector" id="collectionChain"
                                                                    style={{ borderRadius: "12px", width: "50%", padding: "10px" }}>
                                                                    Select Collection
                                                                    <option value="ETH">
                                                                        Ethereum
                                                                    </option>
                                                                    <option value="MATIC">
                                                                        Polygon
                                                                    </option>
                                                                    <option value="BNB">
                                                                        Binance
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="cancel-btn">
                                                        <a className="btn btn-sucess" onClick={() => closeModal()}  >
                                                            Cancel
                                                        </a>
                                                    </div>


                                                    {/* Rest of the modal content */}
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>

                    </div>
                </Box>
            </Box>
        </div>

    );
}

export default MintNft;
