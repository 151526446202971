import React, { useEffect, useState } from "react";

import Web3 from 'web3';
import { useNavigate } from "react-router-dom";
import BalanceABI from '../../Web3/balanceAbi.json'
import balanceAddress from '../../Web3/balanceAddress'




function CheckBalance({ children }) {
  const [balance, setBalance] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    navigateToBack();
  }, [])


  const navigateToBack = async () => {
    try {
      if (window.ethereum) {
        const web3Instance = new Web3(window.ethereum);
        //console.log(web3Instance)
        const browserChainId = await web3Instance.eth.getChainId()
        //console.log("Number(balances)", browserChainId)

        await window.ethereum.enable();
        // setWeb3(web3Instance);

        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const accounts = await web3Instance.eth.getAccounts();
        if (Number(browserChainId) === 56) {
          const contractInstance = new web3Instance.eth.Contract(
            BalanceABI,
            balanceAddress
          );
          // walletbalance - >  const balance = await contractInstance.methods.balanceOf(accounts[0]).call()
          const balances = await contractInstance.methods.balanceOf(balanceAddress).call()

          console.log(Number(balances), balanceAddress, "browserChainId")
          var bal = Number(balances)
          if (bal > 0) {
            setBalance(true)
          }
          else {
            navigate(-1)
            setBalance(false)
          }



          //  return bal
        }
        else {
          onChangeNetwork(56)
        }


      }
    } catch (err) {
      console.log(err)
    }
  };

  const navigateToLogin = () => {
    const stateData = { login: 3 };
    navigate(-1)
  };

  const onChangeNetwork = async (chainIds) => {
    try {
      const WEB = new Web3(window.ethereum);

      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: WEB.utils.toHex(chainIds) }]
      });
      navigateToBack()

    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        var data = {
          "name": "BNB Smart Chain Mainnet",
          "chain": "BSC",
          "rpc": [
            "https://bsc-dataseed1.bnbchain.org",
            "https://bsc-dataseed2.bnbchain.org",
            "https://bsc-dataseed3.bnbchain.org",
            "https://bsc-dataseed4.bnbchain.org",
            "https://bsc-dataseed1.defibit.io",
            "https://bsc-dataseed2.defibit.io",
            "https://bsc-dataseed3.defibit.io",
            "https://bsc-dataseed4.defibit.io",
            "https://bsc-dataseed1.ninicoin.io",
            "https://bsc-dataseed2.ninicoin.io",
            "https://bsc-dataseed3.ninicoin.io",
            "https://bsc-dataseed4.ninicoin.io",
            "https://bsc.publicnode.com",
            "wss://bsc.publicnode.com",
            "wss://bsc-ws-node.nariox.org"
          ],
          "faucets": [],
          "nativeCurrency": {
            "name": "BNB Chain Native Token",
            "symbol": "BNB",
            "decimals": 18
          },
          "infoURL": "https://www.bnbchain.org/en",
          "shortName": "bnb",
          "chainId": 56,
          "networkId": 56,
          "slip44": 714,
          "explorers": [
            {
              "name": "bscscan",
              "url": "https://bscscan.com",
              "standard": "EIP3091"
            }
          ]
        }


        // console.log(chainLists1, "chainlist")
        const WEB = new Web3(window.ethereum);
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: data?.chain,
              chainId: WEB.utils.toHex(chainIds),
              nativeCurrency: data?.nativeCurrency,
              rpcUrls: [data?.rpc[0]]
            }
          ]
        });
      }
      else if (err.code === 4001) {
        navigate(-1)
      }
      else {
        console.log(err)
      }
    }
  }



  return balance === true ? children : navigateToLogin()
}

export default CheckBalance;
