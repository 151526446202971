import React, { useEffect, useRef } from "react";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useDropzone } from 'react-dropzone';
import WaveSurfer from 'wavesurfer.js';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { Avatar } from "@mui/material";
import ava from "../../img/dster-webp/ava1.webp";
import ava1 from "../../img/dster-webp/ava1.webp";
import Axios from "../../Axios";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import s2 from "../../img/dster-webp/backword.webp"
import s3 from "../../img/dster-webp/pass-play.webp"
import s4 from "../../img/dster-webp/farward.webp"
import s6 from "../../img/dster-webp/phause.webp"
import Slider from '@mui/material/Slider';
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import SwipeableViews from 'react-swipeable-views';
import HeaderNew from "../HeaderNew/HeaderNew";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const drawerWidth = 280;

const closeModal = () => {
    $("#collectionModal").hide();
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const mood = [
    'Peaceful',
    'Romantic',
    'Sentimental',
    'Tender',
    'Easygoing',
    'Yearning',
    'Sophisticated',
    'Sensual',
    'Cool',
    'Gritty',
    'Meancholy',
    'Serious',
    'Brooding',
    'Fiery',
    'Defiant',
    'Aggressive',
    'Rowdy',
    'Exicted',
    'Energizing',
    'Empowering',
    'Stirring',
    'Upbeat',
    'Other'


]
const northamerica = [
    'Jazz',
    'Blues',
    'Country',
    'Hip-Hop',
    'Rock n Roll',
    'R&B (Rhythm and Blues)',
    'Gospel',
    'Bluegrass',
    'Folk',
    'Electronic',
    'Indie',
    'Reggae ',
    'Metal',
    'Reggae ',
    'Soul',

];

const latinamerica = [
    'Salsa',
    'Reggaeton',
    'Bossa Nova',
    'Tango',
    'Mariachi',

];


const europe = [
    'Classical',
    'Techno',
    'Bossa Nova',
    'EDM (Electronic Dance Music)',
    'Flamenco',
    'Balkan Folk',
]

const africa = [
    'Afrobeat',
    'Highlife',
    'Kwaito',
    'Mbalax',
    'Taarab',
]

const asia = [
    'Bollywood Music',
    'K-Pop',
    'J-Pop',
    'Qawwali',
    'Peking Opera',
]

const middleeast = [
    'Arabic Pop',
    'Dabke',
    'Turkish Folk Music',
    'Raï',
    'Fado',
]

const oceania = [
    'Aboriginal Folk Music',
    'Pasifika',
    'Contemporary Australian Rock',
    'Maori Music',
    'Hawaiian Music',
]

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const dropzoneStyles = {
    border: '2px dashed #ccc',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

const waveformStyles = {
    marginTop: '20px',
};

function Upload() {

    const [value, setValue] = React.useState(0);



    useEffect(() => {
        if (String(location?.state?.value) !== "NaN" && String(location?.state?.value) !== "undefined") {

            setValue(location?.state?.value)
        }
        else {
            setValue(0)
        }
        getMyList()
    }, [])


    const handleUploadTrack = (event, newValue) => {
        setValue(newValue);
        setExistid()
        setName('')
        if (newValue === 0) {
            setmusictype("track")
        } else if (newValue === 1) {
            setmusictype("album")
        } else if (newValue === 2) {
            setmusictype("playlist")
        }
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const navigate = useNavigate()
    const classes = useStyles();
    const location = useLocation()
    const [classNamebox, setclassNamebox] = useState(1);

    const [ClaimDate, setClaimDate] = useState("");
    const [ClaimLimit, setClaimLimit] = useState(0);
    const [ClaimDateerr, setClaimDateerr] = useState(null);
    const [ClaimLimiterr, setClaimLimiterr] = useState(null);
    const [preview, setpreview] = useState()
    const [audio, setAudio] = useState()
    const [audioFile, setAudioFile] = useState(null);
    const [previewerr, setpreviewerr] = useState()
    const [audioerr, setAudioerr] = useState()
    const [collectionData, setCollectionData] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [song, setsong] = useState();
    const [music_type, setmusictype] = useState("track");
    const [album_name, setAlbum] = useState();
    const [playlist, setPlay] = useState();
    const [category, setCategory] = useState();
    const [ISRC, setISRC] = useState("");
    const [NFTLink, setNFTLink] = useState("");
    const [description, setDescription] = useState()
    const [imageerr, setImageerr] = useState(null)
    const [albumimageerr, setAlbumimageerr] = useState(null)
    const [songerr, setsongerr] = useState(null);
    const [categoryerr, setCategoryerr] = useState(null);
    const [mode, setMode] = useState('');
    const [existid, setExistid] = useState();
    const [modeerr, setModeerr] = useState(null);
    const [other, setOther] = useState(null);
    const [othererr, setothererr] = useState(null);
    const [ISRCerr, setISRCerr] = useState(null);
    const [NFTLinkerr, setNFTLinkerr] = useState(null);
    const [descriptionerr, setDescriptionerr] = useState(null)
    const [requirefield, setRequiredField] = useState(false)
    const [albumlist, setAlbumList] = useState([])
    const [playslist, setPlaysList] = useState([])
    const handleRequirefield = () => {
        setRequiredField(!requirefield)
    }


    var create_data
    const [SelectedCategory, setSelectedCategory] = useState("");

    const OpentoEveryOnes = () => {
        setOpento(!opento)
    };

    useEffect(() => {
        getMyList()
    }, [])

    const getMyList = async () => {
        try {

            await Axios.post("/users/get_my_list", '', {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        setAlbumList(res?.data?.result?.musicAlbum)
                        setPlaysList(res?.data?.result?.musicPlay)

                    }
                    else {
                        setAlbumList([])
                        setPlaysList([])
                    }
                }).catch((err) => {
                    // console.log(err, "err")


                })

        } catch (error) {
            // console.log(error, "error");
        }

    }

    const createMusic = async () => {
        try {
            if (!song) {
                setsongerr("Please Enter song Title")
            }
            else if (!ISRC) {
                setISRCerr("Please Enter IRSC")
            }
            else if (!NFTLink) {
                setNFTLinkerr("Please Enter NFT Link")
            }
            else if (!category) {
                setCategoryerr("Please Enter Category")
            }
            else if (!mode) {
                setModeerr("Please Enter Song Mood")
            }
            else if (mode === "Other" && !other) {
                setothererr("Please Enter Song Mood")
            }
            else if (!description) {
                setDescriptionerr("Please Enter Description")
            }
            else if (!image) {
                setImageerr("Please Select Image File")
            }
            else if (!audio) {
                setAudioerr("Please Select Audio File")
            }
            else if (!ClaimDate) {
                setClaimDateerr("Please Enter Claim Date")

            }
            else if (!ClaimLimit) {
                setClaimLimiterr("Please Enter Claim Limit")
            }

            else if (ClaimLimit <= 0) {
                setClaimLimiterr("Please Enter Claim Limit more than 0")
            }

            else {
                create_data = {
                    song_name: song,
                    ISRC: ISRC,
                    nft_link: NFTLink,
                    categorey: category,
                    description: description,
                    image: image,
                    albumimage: albumimage ? albumimage : image,
                    nft_type: 'nor',
                    // pre_audio: preview,
                    audio: audio,
                    music_type: music_type,
                    album_name: album_name,
                    playlist: playlist,
                    nftclaim: ClaimLimit,
                    opentoeveryone: opento,
                    song_moods: mode === "Other" ? other : mode,
                    existid: existid

                }
                setLoading(true)

                await Axios.post("/users/uploadmusic", create_data, {
                    headers: {
                        Authorization: tokens,
                    },
                })
                    .then((res) => {

                        if (res?.data?.success) {
                            toast.success('Music Uploaded Successfully', {
                                duration: 1000, // Set the duration for which the toast is visible
                            })

                            setTimeout(() => {
                                navigate('/feed')
                            }, 2000);

                        }
                        else {
                            toast.error(res?.data?.message)
                            // tab_1()
                            setLoading(false)

                        }
                    }).catch((err) => {
                        setLoading(false)

                        // console.log(err, "err")
                        if (err?.response?.data?.message == "Admin Blocked You") {
                            localStorage.clear();
                            navigate('/');
                            toast.error('Admin Blocked You');
                        } else {
                            toast.error(err?.response?.data?.message)
                        }
                        //  tab_1()
                    })
            }
        } catch (error) {
            console.log(error, "error");
            setLoading(false)

        }

    }

    const tab_1 = () => {
        $(".de_tab_content_2").hide();
        $(".de_tab_content").show();
        $(".de_tab_content_3").hide();
        setclassNamebox(1);
    };
    const tab_3 = () => {
        $(".de_tab_content_2").hide();
        $(".de_tab_content").hide();
        $(".de_tab_content_3").show();
        setclassNamebox(3);
    };
    const changeTitle = (value) => {
        value == "" ? $("#title").html("Music") : $("#title").html(value)
        setsong(value)
        setsongerr(null)
    };
    const changeDesc = (value) => {
        $("#DESC").html(value);
        setDescription(value)
        setDescriptionerr(null)
    };

    const changeISRC = (value) => {
        setISRC(value);
        setISRCerr(null)
    };

    const changeLink = (value) => {
        setNFTLink(value);
        setNFTLinkerr(null)
    };

    const changeCategory = (value) => {
        setSelectedCategory(value);
    };

    const changeClaimDate = (value) => {
        setClaimDate(value);
        setClaimDateerr(null)
    };

    const changeClaimLimit = (value) => {
        setClaimLimit(value);
        setClaimLimiterr(null)
    };
    const getCollectionModal = (value) => {
        value == "new_collection"
            ? $("#collectionModal").show()
            : $("#collectionModal").hide();
    };

    const makePreview = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const [opento, setOpento] = React.useState(false);
    const [opentoerr, setOpentoerr] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [previousAlbum, setPreviousAlbum] = useState(false)
    const handlePreviousAlbum = () => {
        setExistid()
        setName('')
        setPreviousAlbum(!previousAlbum)
    }
    const theme = useTheme();
    const [personName, setPersonName] = React.useState('');
    const [name, setName] = React.useState('');
    const handleChange = (e) => {
        setCategory(e.target.value)
        setCategoryerr(null)
    };
    const handleChange1 = (e) => {


        setMode(e.target.value)
        setModeerr(null)
    };

    const handleChangesAlbum = (e) => {
        console.log(e.target.value, 'value');
        setmusictype("album")
        setName(e.target.value.album_name)
        setExistid(e.target.value._id)
        // setModeerr(null)
    };
    const handleChangesPlay = (e) => {
        setmusictype("playlist")
        setName(e.target.value.playlist)
        setExistid(e.target.value._id)
        // setModeerr(null)
    };
    // get image

    const [profileData, setProfileData] = useState({});
    const [updateName, setUpdateName] = useState();
    const tokens = localStorage.getItem("iphephile");
    const [image, setImage] = useState(null);
    const [albumimage, setAlbumimage] = useState(null);
    const [filetype, setFileType] = useState(true)
    const [audiotype, setAudioType] = useState('')
    const [loading, setLoading] = useState(false)

    const [filetype1, setFileType1] = useState('')
    const [audiotype1, setAudioType1] = useState('')

    const handleAudioClick = (event) => {
        hiddenAudioInput.current.click();
        setAudioType(true)
    };

    const handleAudioClick1 = (event) => {
        hiddenAudioInput1.current.click();
        setAudioType1(true)
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    const handleClick1 = (event) => {
        hiddenFileInput1.current.click();
    };

    const hiddenFileInput = useRef(null);
    const hiddenFileInput1 = useRef(null);
    const hiddenAudioInput = useRef(null)
    const hiddenAudioInput1 = useRef(null)
    const HandleNameUpdate = (e) => {
        setUpdateName(e.target.value);
        // setnameerr("");
    };

    // const handleAudio = async (event) => {
    //     const files = event.target.files[0];
    //     setAudioType(files?.name)
    //     setDuration(0)
    //     setCurrentTime(0)
    //     try {
    //         //const vdata = await checkLimit(event)
    //         var fileName = files?.name;
    //         var idxDot = fileName.lastIndexOf(".") + 1;
    //         var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    //         if (extFile == "mp3" || extFile == "MP3") {
    //             let formData = await new FormData();
    //             formData.append("image", files);
    //             const audio = new Audio();
    //             audio.src = URL.createObjectURL(files);

    //             audio.addEventListener("loadedmetadata", async () => {
    //                 const duration = audio.duration;

    //                 if (duration > 30) {
    //                     event.target.value = null;

    //                     const { data } = await Axios.post("/updatemp3", formData);
    //                     if (data.result) {

    //                         setpreview(data.result);
    //                         setpreviewerr(null)
    //                         getAudioDuration(data.result)
    //                     }
    //                     else {
    //                         setpreview();
    //                         setpreviewerr("Audio file did not Uploaded")
    //                     }
    //                 } else {

    //                     const { data } = await Axios.post("/updateDp", formData);
    //                     if (data.result) {

    //                         setpreview(data.result);
    //                         setpreviewerr(null)
    //                     }
    //                     else {
    //                         setpreview();
    //                         setpreviewerr("Audio file did not Uploaded")
    //                     }
    //                 }
    //             });
    //         }
    //         else {
    //             setpreview();
    //             setpreviewerr("Only mp3 files are allowed!")
    //         }




    //     } catch (error) {
    //         // console.log(error, "err");
    //     }
    // }

    const handleAudio1 = async (event) => {
        const files = event.target.files[0];
        setAudioFile(files)
        setAudioType1(files?.name)
        setIsPlaying1(false)
        // handleFarward1()
        handlePause1()
        setAudio()
        audioRef1.current.value = 0
        setDuration1(0)
        setCurrentTime1(0)

        try {
            let formData = await new FormData();
            formData.append("image", files);

            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "mp3" || extFile == "MP3") {
                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {

                    setAudio(data.result);
                    setAudioerr(null)
                    getAudioDuration(data.result)
                }
                else {
                    setAudio();
                    setAudioerr("Audio file did not upload")
                }
            }
            else {
                setAudio();
                setAudioerr("Only mp3 files are allowed!")
            }


        } catch (error) {
            // console.log(error, "err");
        }
    }



    const handleImageChange = async (event) => {
        const files = event.target.files[0];



        try {
            let formData = await new FormData();

            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                formData.append("image", files);
                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {
                    // setLogo(data.result)
                    setFileType(files?.name, true)
                    setImage(data.result);
                    setImageerr(null)
                    const updateData = {
                        name: profileData.name,
                        // phone: profileData?.result?.phone,
                        profilePicture: data.result,
                    };

                }
            } else {
                setImage(null)
                setImageerr("Only jpg, jpeg, png and gif files are allowed!");
            }
        } catch (error) {
            // console.log(error, "err");
        }
    };
    const handleAlbumImageChange = async (event) => {
        const files = event.target.files[0];

        try {
            let formData = await new FormData();

            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                formData.append("image", files);

                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {
                    // setLogo(data.result)

                    setAlbumimage(data.result);
                    setAlbumimageerr(null)


                }
            } else {
                setAlbumimage(null)
                setAlbumimageerr("Only jpg, jpeg, png and gif files are allowed!");
            }


        } catch (error) {
            // console.log(error, "err");
        }
    };


    const [waveform, setWaveform] = useState(null);
    const waveformRef = useRef(null);

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        const wavesurferInstance = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: 'violet',
            progressColor: 'purple',
        });

        wavesurferInstance.loadBlob(file);
        setWaveform(wavesurferInstance);
    };



    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    //Audio preview

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0)

    const [isPlaying1, setIsPlaying1] = useState(false);
    const [currentTime1, setCurrentTime1] = useState(0);
    const [duration1, setDuration1] = useState(0)

    // const audioRef = useRef(null);
    const audioRef1 = useRef(null);

    // Function to seek to a specific time in audio.


    // const handlePlay = () => {
    //     // handlePause()
    //     audioRef.current.play();
    //     setIsPlaying(true);
    // };

    const handlePlay1 = () => {
        // handlePause1()
        audioRef1.current.play();
        setIsPlaying1(true);
    };

    // const handleFarward = () => {
    //     audioRef.current.currentTime = 0;
    //     audioRef.current.play();
    // }

    const handleFarward1 = () => {
        audioRef1.current.currentTime = 0;
        audioRef1.current.play();
    }

    // const handlePause = () => {
    //     audioRef.current.pause();
    //     setIsPlaying(false);
    // };
    const handlePause1 = () => {
        audioRef1.current.pause();
        setIsPlaying1(false);
    };


    const handlePlayPause = () => {

        if (isPlaying) {
            // handlePause();
        } else {
            // handlePlay();
            handlePause1()
        }
    };

    const handlePlayPause1 = () => {

        if (isPlaying1) {
            handlePause1();
        } else {
            handlePlay1();
            // handlePause()
        }
    };


    // const handleTimeUpdate = () => {
    //     setCurrentTime(audioRef.current.currentTime);
    //     setDuration(audioRef.current.duration);
    // };

    const handleTimeUpdate1 = () => {

        setCurrentTime1(audioRef1.current.currentTime);
        setDuration1(audioRef1.current.duration);
    };


    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener('loadedmetadata', () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                // audioRef1.current.duration = durationInMinutes
                // audioRef1.current.currentTime1 = 0
                handleTimeUpdate1()
                setDuration(durationInMinutes);
            });
        } catch (error) {
            console.error(error);
            setDuration(0);
            setDuration1(0);
        }
    };

    // const handleSeek = (e) => {
    //     audioRef.current.currentTime = e.target.value;
    //     setCurrentTime(e.target.value);
    // };

    const handleSeek1 = (e) => {
        audioRef1.current.currentTime = e.target.value;
        setCurrentTime1(e.target.value);
    };

    useEffect(() => {
        // audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
        // return () => {
        //     // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        // };


    }, []);


    useEffect(() => {
        audioRef1.current.addEventListener("timeupdate", handleTimeUpdate1);
        return () => {
            // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        };

    }, []);





    function formatDuration(durationSeconds) {

        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }

    function formatDuration1(durationSeconds) {
        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }

    return (

        <div className="upload hedaer-bg">
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }} className='display-block'>
                <HeaderNew title={"Upload"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '100px' }}
                    className="upload-bg">
                    <Container maxWidth="lg">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        </Grid>
                    </Container>
                    {/* <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag & drop an audio file here, or click to select one</p>
      </div>

      <div style={waveformStyles}></div>
    
      <button onClick={()=>{handleTrim(10,20)}}>Trim Audio and Save</button>
    </div> */}
                    <div className="main no-bottom no-top" id="content">


                        <div className="select-method">
                            <div className="row wow fadeIn justify-content-center align-items-center">
                                <div className="col-md-9">
                                    <form id="form-create-item" className="form-border" method="post" action="https://gigaland.io/email.php">
                                        <div className="field-set">
                                            {/* <ArrowBackIcon className="back-arrow" onClick={(() => { navigate('/create', { state: { value } }) })} /> */}
                                            {/* <h5>Select method</h5> */}
                                            <div className="de_tab tab_methods">


                                                <div className="de_tab_content">


                                                    <Grid container spacing={2} sx={{ textAlign: 'center' }}>

                                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Preview Images </h5>
                                                                {/* <FontAwesomeIcon icon={faCloudUploadAlt} /> */}

                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                <div
                                                                                    className="profile-img"
                                                                                    onClick={handleClick}
                                                                                    style={{ cursor: "pointer" }}
                                                                                >
                                                                                    {/* <div className='profile-img-inner'>
                                {image ? (
                                <img src={URL.createObjectURL(image)} alt="upload-profile-image" className="img-display-after" />
                                ) : (
                                <img src={subBg} alt="upload-profile-image" className="img-display-before" />
                                )}
                                </div> */}

                                                                                    <div className="preview-img profile-banner-size">
                                                                                        {image ? (
                                                                                            <Avatar
                                                                                                alt="Remy Sharp"
                                                                                                src={image}
                                                                                                sx={{
                                                                                                    width: 220,
                                                                                                    height: 250,
                                                                                                    border: "3px solid #fff",
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <Avatar
                                                                                                alt="Remy Sharp"
                                                                                                src={ava1}
                                                                                                sx={{
                                                                                                    width: 220,
                                                                                                    height: 250,
                                                                                                    border: "3px solid #fff",
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                        <div class="edit1">
                                                                                            <CloudUploadIcon />
                                                                                            <div>Upload Image</div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <input
                                                                                        id="image-upload-input"
                                                                                        type="file"
                                                                                        accept="image/*"
                                                                                        onChange={handleImageChange}
                                                                                        ref={hiddenFileInput}
                                                                                        style={{ display: "none" }}
                                                                                    />
                                                                                </div>
                                                                            </Grid>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">File type: PNG, JPG, GIF</p>
                                                                <div style={{ color: 'var(--clr-font)', wordWrap: "break-word" }}>ChoosenFile:{filetype}</div>
                                                            </div>
                                                            {imageerr !== null ? (
                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{imageerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>

                                                        {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Preview Audio </h5>
                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <div
                                                                                className="profile-img"
                                                                                onClick={handleAudioClick}
                                                                                style={{ cursor: "pointer" }}
                                                                            >

                                                                                <div className="preview-img profile-banner-size">
                                                                                    {image ? (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={image}
                                                                                            sx={{
                                                                                                width: 220,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={ava}
                                                                                            sx={{
                                                                                                width: 220,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    )}


                                                                                    <div class="edit1">
                                                                                        <CloudUploadIcon />
                                                                                        <div>Upload Audio</div>
                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">Filetype: {audiotype}</p>

                                                                <div className='track-time'>

                                                                    <Box sx={{ width: '80%', margin: 'auto' }}>

                                                                        {preview ? <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={currentTime}
                                                                            min={0}
                                                                            step={1}
                                                                            max={duration}
                                                                            onChange={handleSeek}

                                                                        /> : <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={0}
                                                                            min={0}
                                                                            step={1}
                                                                            max={0}


                                                                        />}
                                                                        <audio ref={audioRef} src={preview} />
                                                                        <div className='audio-timmer'>
                                                                            {preview ? <div>
                                                                                {formatDuration(currentTime)}

                                                                            </div> : <></>}
                                                                            {preview ? <div>
                                                                                {isNaN(duration) ? '0:00' : formatDuration(duration)}

                                                                            </div> : <></>}
                                                                        </div>

                                                                    </Box>

                                                                </div>
                                                                <div>
                                                                    <Stack direction="row" spacing={4} className='music-stack'>
                                                                        <div>
                                                                        </div>
                                                                        <div>
                                                                            <img src={s2}
                                                                                onClick={handleFarward}
                                                                            />
                                                                        </div>
                                                                        <div >
                                                                            {audiotype && preview ? (<>
                                                                                <Button
                                                                                    onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying ? <> {currentTime === duration ? <img src={s3} /> : <img src={s6} />}</> : (<><img src={s3} /> </>)}
                                                                                </Button>
                                                                            </>) : (<>

                                                                                <Button
                                                                                >
                                                                                    {isPlaying ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button></>)}

                                                                        </div>
                                                                        <div>
                                                                            <img src={s4} />
                                                                        </div>
                                                                        <div>
                                                                        </div>

                                                                    </Stack>

                                                                </div>


                                                                <div className="audio-div" style={{ display: "none" }}>
                                                                    <input type="file" id="preview" onChange={handleAudio}
                                                                        accept="audio/*"
                                                                        ref={hiddenAudioInput}
                                                                        className="btn-main fle_choose_btn"
                                                                    />
                                                                </div>



                                                            </div>
                                                            {previewerr !== null ? (
                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{previewerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid> */}


                                                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Audio </h5>
                                                                {/* <FontAwesomeIcon icon={faVolumeUp} /> */}
                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <div
                                                                                className="profile-img"
                                                                                onClick={handleAudioClick1}
                                                                                style={{ cursor: "pointer" }}
                                                                            >

                                                                                <div className="preview-img profile-banner-size">
                                                                                    {image ? (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={image}
                                                                                            sx={{
                                                                                                width: 220,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={ava}
                                                                                            sx={{
                                                                                                width: 220,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    )}


                                                                                    <div class="edit1">
                                                                                        <CloudUploadIcon />
                                                                                        <div>Upload Audio</div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">Filetype: {audiotype1}</p>
                                                                {/* <div className="preview-div" >FileType: {audiotype1}</div> */}

                                                                <div className='track-time'>

                                                                    <Box sx={{ width: '80%', margin: 'auto' }}>

                                                                        {audio ? <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={currentTime1}
                                                                            min={0}
                                                                            step={1}
                                                                            max={duration1}
                                                                            onChange={handleSeek1}

                                                                        /> : <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={0}
                                                                            min={0}
                                                                            step={1}
                                                                            max={0}


                                                                        />}
                                                                        <audio ref={audioRef1} src={audio} />

                                                                        <div className='audio-timmer'>
                                                                            {audio ? <div>
                                                                                {formatDuration(currentTime1)}

                                                                            </div> : <></>}
                                                                            {audio ? <div>
                                                                                {isNaN(duration1) ? '0:00' : formatDuration(duration1)}

                                                                            </div> : <></>}
                                                                        </div>

                                                                    </Box>

                                                                </div>
                                                                <div>
                                                                    <Stack direction="row" spacing={4} className='music-stack'>
                                                                        <div>
                                                                            {/* <img src={s1} /> */}
                                                                        </div>
                                                                        <div>
                                                                            <img src={s2}
                                                                                onClick={handleFarward1}
                                                                            />
                                                                        </div>
                                                                        <div >
                                                                            {audiotype1 && audio ? (<>
                                                                                <Button
                                                                                    onClick={handlePlayPause1}
                                                                                >
                                                                                    {isPlaying1 ? <> {currentTime1 === duration1 ? <img src={s3} /> : <img src={s6} />}</> : (<><img src={s3} /> </>)}
                                                                                </Button>
                                                                            </>) : (<>

                                                                                <Button
                                                                                // onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying1 ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button></>)}

                                                                        </div>
                                                                        <div>
                                                                            <img src={s4} />
                                                                        </div>
                                                                        <div>
                                                                            {/* <img src={s5} /> */}
                                                                        </div>

                                                                    </Stack>

                                                                </div>



                                                                <div className="audio-div" style={{ display: "none" }}>
                                                                    <input type="file" id="preview" onChange={handleAudio1}
                                                                        accept="audio/*"
                                                                        ref={hiddenAudioInput1}
                                                                        className="btn-main fle_choose_btn"
                                                                    // placeholder="Browse"
                                                                    />
                                                                </div>



                                                            </div>
                                                            {audioerr !== null ? (
                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{audioerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>

                                                    </Grid>


                                                    <div className="spacer-single"></div>

                                                    {/* <input type="button" onClick={() => tab_2()} i
                                                        className="btn btn-upload"
                                                        value="Next"
                                                    /> */}
                                                    {/* <Button onClick={() => tab_2()} i className="btn btn-upload" style={{ "marginTop": "30px" }}>Next</Button> */}
                                                    <div className="spacer-single"></div>
                                                </div>

                                                {filetype ? (<>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: '15px' }}   >
                                                            <div className="spacer-single">
                                                                <Tabs
                                                                    value={value}
                                                                    onChange={handleUploadTrack}
                                                                    aria-label="icon position tabs example"
                                                                    className="mint-nft-tab"
                                                                    variant="fullWidth"
                                                                // aria-label="full width tabs example"
                                                                >

                                                                    <Tab icon={<AudiotrackIcon />} iconPosition="start" label="Track" {...a11yProps(0)} />
                                                                    <Tab icon={<LibraryMusicIcon />} iconPosition="start" label="Album" {...a11yProps(1)} />
                                                                    <Tab icon={<QueueMusicIcon />} iconPosition="start" label="Playlist" {...a11yProps(2)} />
                                                                </Tabs>

                                                                <SwipeableViews
                                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                                    index={value}
                                                                    onChangeIndex={handleChangeIndex}
                                                                >
                                                                    <TabPanel value={value} index={0} className="upload-mint-music-tab-whole-div" >
                                                                        <div className="de_tab_content">
                                                                            <Grid container spacing={0}  >
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">Song Name</h5>
                                                                                        <input type="text" name="item_price" onChange={(e) => changeTitle(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Title here"
                                                                                        />
                                                                                        {songerr !== null ? (
                                                                                            <div className="errordiv">{songerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            International Standard Recording Code(ISRC)
                                                                                        </h5>
                                                                                        <input type="text" name="isrc" onChange={(e) => changeISRC(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your ISRC here"
                                                                                        />
                                                                                        {ISRCerr !== null ? (
                                                                                            <div className="errordiv">{ISRCerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            Enter the NFT Collection link
                                                                                        </h5>
                                                                                        <input type="text" name="platform_link" onChange={(e) => changeLink(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Platform Link here"
                                                                                        />
                                                                                        {NFTLinkerr !== null ? (
                                                                                            <div className="errordiv">{NFTLinkerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Song Category</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={category}
                                                                                                onChange={handleChange}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Genre</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem>
                                                                                                {northamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Latin America</strong></MenuItem>
                                                                                                {latinamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Europe</strong></MenuItem>
                                                                                                {europe.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Africa</strong></MenuItem>
                                                                                                {africa.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Asia</strong></MenuItem>
                                                                                                {asia.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Middle East</strong></MenuItem>
                                                                                                {middleeast.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Oceania</strong></MenuItem>
                                                                                                {oceania.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                            {categoryerr !== null ? (
                                                                                                <div className="errordiv">{categoryerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Pick a Mood</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={mode}
                                                                                                onChange={handleChange1}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Mood</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                {mood.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}

                                                                                            </Select>
                                                                                            {modeerr !== null ? (
                                                                                                <div className="errordiv">{modeerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                {mode === "Other" ? <>
                                                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="description-part">
                                                                                            <h5>Other </h5>
                                                                                            <textarea
                                                                                                name="postContent"
                                                                                                onChange={(e) => { setOther(e.target.value); setothererr(null) }}
                                                                                                placeholder="Others"

                                                                                            />
                                                                                            {othererr !== null ? (
                                                                                                <div className="errordiv">{othererr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                </> : <></>}
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="description-part">
                                                                                        <h5>Description </h5>
                                                                                        <textarea
                                                                                            name="postContent"
                                                                                            onChange={(e) => changeDesc(e.target.value)}
                                                                                            placeholder="Explain what your NFT is about ( Max 500 characters )"
                                                                                            rows={4}
                                                                                            cols={40}
                                                                                        />
                                                                                        {descriptionerr !== null ? (
                                                                                            <div className="errordiv">{descriptionerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>


                                                                            </Grid>
                                                                        </div>
                                                                        <div className="de_tab_content_2" >
                                                                            <h5>
                                                                                Required Fields
                                                                                <span style={{ color: "red" }}> * </span>
                                                                                <Switch {...label} onClick={handleRequirefield} />

                                                                            </h5>

                                                                            {requirefield ? (<>
                                                                                <p>
                                                                                    set the Requirements users need to meet to mint your
                                                                                    Dreamster
                                                                                </p>

                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Set date and time for claiming the song
                                                                                            </label>
                                                                                            <input type="date" name="item_price" id="Title" className="form-control date-input" onChange={(e) =>
                                                                                                changeClaimDate(e.target.value)
                                                                                            }
                                                                                            />
                                                                                            {ClaimDateerr !== null ? (
                                                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{ClaimDateerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Total NFT Claim
                                                                                            </label>
                                                                                            <input
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setClaimLimiterr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                name="item_price" id="Title" onChange={(e) => changeClaimLimit(e.target.value)} className="form-control date-input"
                                                                                                placeholder="Total NFT Claim" />
                                                                                            {ClaimLimiterr !== null ? (
                                                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{ClaimLimiterr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>

                                                                                </Grid>


                                                                                <div className="switch-with-title" style={{ borderRadius: "30px" }}>

                                                                                    <h5> {/* <FontAwesomeIcon icon={faGlobe} /> &nbsp;Open to */}Open to Everyone
                                                                                    </h5>
                                                                                    <div className="de-switch 1">
                                                                                        {/* <input type="checkbox" id="switch-unlock7" checked={OpenEveryone == 1 ?? false} onClick={(e) =>
                                                                OpentoEveryOne(e.target.value)}
                                                                value={OpenEveryone == "0" ? "0" : "1"}
                                                                className="checkbox"
                                                            /> */}
                                                                                        <Switch {...label} checked={opento} onClick={() => OpentoEveryOnes()} />
                                                                                        {opentoerr !== null ? (
                                                                                            <div className="errordiv" style={{ "marginTop": "10px" }}>{opentoerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="submit-btn2">
                                                                                    {loading == true ? <Button className="btn btn-upload">Processing ...</Button> :
                                                                                        <Button className="btn btn-upload" onClick={() => createMusic()}>Submit</Button>}
                                                                                </div>


                                                                                <div className="spacer-single"></div>


                                                                            </>) : null}





                                                                        </div>
                                                                    </TabPanel>
                                                                    <TabPanel value={value} index={1} className="upload-mint-music-tab-whole-div">
                                                                        <div className="de_tab_content">
                                                                            <Grid container spacing={0}  >
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3} >
                                                                                    <div className="d-create-file minnft-innertab">
                                                                                        <div className="profile-img-outer1 minnft">
                                                                                            <div className="image-upload-container">
                                                                                                <div className="box-decoration">
                                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                                        <div
                                                                                                            className="profile-img"
                                                                                                            onClick={handleClick1}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                        >


                                                                                                            <div className="preview-img profile-banner-size bg-bug">
                                                                                                                {image ? (
                                                                                                                    <Avatar
                                                                                                                        alt="Remy Sharp"
                                                                                                                        src={albumimage ? albumimage : image}
                                                                                                                        sx={{
                                                                                                                            width: 220,
                                                                                                                            height: 250,
                                                                                                                            border: "3px solid #fff",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <Avatar
                                                                                                                        alt="Remy Sharp"
                                                                                                                        src={ava1}
                                                                                                                        sx={{
                                                                                                                            width: 220,
                                                                                                                            height: 250,
                                                                                                                            border: "3px solid #fff",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )}
                                                                                                                <div class="edit1">
                                                                                                                    <CloudUploadIcon />
                                                                                                                    <div>Upload Cover Image</div>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                            <input
                                                                                                                id="image-upload-input"
                                                                                                                type="file"
                                                                                                                accept="image/*"
                                                                                                                onChange={(e) => { handleAlbumImageChange(e) }}
                                                                                                                ref={hiddenFileInput1}
                                                                                                                style={{ display: "none" }}
                                                                                                            />
                                                                                                            {/* <Button
                                className="image-upload-button"
                                id="image-upload-button"
                                onClick={handleUploadButtonClick}
                                >
                                <CloudUploadIcon/>
                                </Button> */}
                                                                                                        </div>
                                                                                                        {albumimageerr !== null ? (
                                                                                                            <div className="errordiv" style={{ "marginTop": "10px" }}>{albumimageerr}</div>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )}
                                                                                                    </Grid>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={9} >
                                                                                    <div id="tab_opt_1" className="album-tab">
                                                                                        <h5 className="h5-custom2">Song Name</h5>
                                                                                        <input type="text" name="item_price" onChange={(e) => changeTitle(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Title here"
                                                                                        />
                                                                                        {songerr !== null ? (
                                                                                            <div className="errordiv">{songerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                    <div id="tab_opt_1" className="album-tab">
                                                                                        <h5 className="h5-custom2">
                                                                                            International Standard Recording Code(ISRC)
                                                                                        </h5>
                                                                                        <input type="text" name="isrc" onChange={(e) => changeISRC(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your ISRC here"
                                                                                        />
                                                                                        {ISRCerr !== null ? (
                                                                                            <div className="errordiv">{ISRCerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                   
                                                                                </Grid> */}

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            Enter the NFT Collection link
                                                                                        </h5>
                                                                                        <input type="text" name="platform_link" onChange={(e) => changeLink(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Platform Link here"
                                                                                        />
                                                                                        {NFTLinkerr !== null ? (
                                                                                            <div className="errordiv">{NFTLinkerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Song Category</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={category}
                                                                                                onChange={handleChange}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Genre</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem>
                                                                                                {northamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Latin America</strong></MenuItem>
                                                                                                {latinamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Europe</strong></MenuItem>
                                                                                                {europe.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Africa</strong></MenuItem>
                                                                                                {africa.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Asia</strong></MenuItem>
                                                                                                {asia.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Middle East</strong></MenuItem>
                                                                                                {middleeast.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Oceania</strong></MenuItem>
                                                                                                {oceania.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                            {categoryerr !== null ? (
                                                                                                <div className="errordiv">{categoryerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Pick a Mood</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={mode}
                                                                                                onChange={handleChange1}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Mood</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                {mood.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}

                                                                                            </Select>
                                                                                            {categoryerr !== null ? (
                                                                                                <div className="errordiv">{categoryerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div  >
                                                                                        <h5>
                                                                                            Add to Previous Album
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} checked={previousAlbum} onClick={handlePreviousAlbum} />
                                                                                            {/* <FormControlLabel required control={<Switch {...label}  onClick={handlePreviousAlbum}  />} label="Required" /> */}
                                                                                        </h5>
                                                                                        {previousAlbum ? (<>
                                                                                            {albumlist.length > 0 ? <>
                                                                                                <FormControl fullWidth>
                                                                                                    <Select
                                                                                                        // multiple
                                                                                                        displayEmpty

                                                                                                        value={name}
                                                                                                        onChange={handleChangesAlbum}
                                                                                                        input={<OutlinedInput />}
                                                                                                        renderValue={(selected) => {
                                                                                                            if (selected && selected.length === 0) {
                                                                                                                return <MenuItem disabled>Previous Album</MenuItem>;
                                                                                                            }

                                                                                                            return selected;
                                                                                                        }}

                                                                                                        MenuProps={MenuProps}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >

                                                                                                        {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                        {albumlist.length > 0 && albumlist.map((row, index) => (
                                                                                                            <MenuItem
                                                                                                                key={row?._id}
                                                                                                                value={row}

                                                                                                                style={getStyles(row?._id, personName, theme)}
                                                                                                            >
                                                                                                                {row?.album_name}
                                                                                                            </MenuItem>
                                                                                                        ))}





                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </> : <>
                                                                                                No Previous Album
                                                                                            </>}
                                                                                        </>) : <div id="tab_opt_1">
                                                                                            <h5 className="h5-custom2">
                                                                                                Album Name
                                                                                            </h5>
                                                                                            <input type="text" name="platform_link" onChange={(e) => { setmusictype("album"); setAlbum(e.target.value) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Your Album Name Here"
                                                                                            />

                                                                                        </div>}
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="description-part">
                                                                                        <h5 >Description </h5>
                                                                                        <textarea
                                                                                            name="postContent"
                                                                                            onChange={(e) => changeDesc(e.target.value)}
                                                                                            placeholder="Explain what your NFT is about ( Max 500 characters )"
                                                                                            rows={4}
                                                                                            cols={40}
                                                                                        />
                                                                                        {descriptionerr !== null ? (
                                                                                            <div className="errordiv">{descriptionerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>


                                                                            </Grid>
                                                                        </div>
                                                                        <div className="de_tab_content_2" >
                                                                            <h5>
                                                                                Required Fields
                                                                                <span style={{ color: "red" }}> * </span>
                                                                                <Switch {...label} onClick={handleRequirefield} />

                                                                            </h5>

                                                                            {requirefield ? (<>
                                                                                <p>
                                                                                    set the Requirements users need to meet to mint your
                                                                                    Dreamster
                                                                                </p>

                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Set date and time for claiming the song
                                                                                            </label>
                                                                                            <input type="date" name="item_price" id="Title" className="form-control date-input" onChange={(e) =>
                                                                                                changeClaimDate(e.target.value)
                                                                                            }
                                                                                            />
                                                                                            {ClaimDateerr !== null ? (
                                                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{ClaimDateerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Total NFT Claim
                                                                                            </label>
                                                                                            <input
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setClaimLimiterr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                name="item_price" id="Title" onChange={(e) => changeClaimLimit(e.target.value)} className="form-control date-input"
                                                                                                placeholder="Total NFT Claim" />
                                                                                            {ClaimLimiterr !== null ? (
                                                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{ClaimLimiterr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>

                                                                                </Grid>


                                                                                <div className="switch-with-title" style={{ borderRadius: "30px" }}>

                                                                                    <h5> {/* <FontAwesomeIcon icon={faGlobe} /> &nbsp;Open to */}Open to Everyone
                                                                                    </h5>
                                                                                    <div className="de-switch 1">

                                                                                        <Switch {...label} checked={opento} onClick={() => OpentoEveryOnes()} />
                                                                                        {opentoerr !== null ? (
                                                                                            <div className="errordiv" style={{ "marginTop": "10px" }}>{opentoerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="submit-btn2">
                                                                                    {loading == true ? <Button className="btn btn-upload">Processing ...</Button> :
                                                                                        <Button i className="btn btn-upload" onClick={() => createMusic()}>Submit</Button>}
                                                                                </div>

                                                                                <div className="spacer-single"></div>

                                                                            </>) : null}





                                                                        </div>
                                                                    </TabPanel>
                                                                    <TabPanel value={value} index={2} className="upload-mint-music-tab-whole-div">
                                                                        <div className="de_tab_content">
                                                                            <Grid container spacing={0}  >
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3} >
                                                                                    <div className="d-create-file minnft-innertab">
                                                                                        <div className="profile-img-outer1 minnft">
                                                                                            <div className="image-upload-container">
                                                                                                <div className="box-decoration">
                                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                                        <div
                                                                                                            className="profile-img"
                                                                                                            onClick={handleClick1}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                        >


                                                                                                            <div className="preview-img profile-banner-size bg-bug">
                                                                                                                {image ? (
                                                                                                                    <Avatar
                                                                                                                        alt="Remy Sharp"
                                                                                                                        src={albumimage ? albumimage : image}
                                                                                                                        sx={{
                                                                                                                            width: 220,
                                                                                                                            height: 250,
                                                                                                                            border: "3px solid #fff",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <Avatar
                                                                                                                        alt="Remy Sharp"
                                                                                                                        src={ava1}
                                                                                                                        sx={{
                                                                                                                            width: 220,
                                                                                                                            height: 250,
                                                                                                                            border: "3px solid #fff",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )}
                                                                                                                <div class="edit1">
                                                                                                                    <CloudUploadIcon />
                                                                                                                    <div>Upload Cover Image</div>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                            <input
                                                                                                                id="image-upload-input"
                                                                                                                type="file"
                                                                                                                accept="image/*"
                                                                                                                onChange={(e) => { handleAlbumImageChange(e) }}
                                                                                                                ref={hiddenFileInput1}
                                                                                                                style={{ display: "none" }}
                                                                                                            />

                                                                                                        </div>
                                                                                                        {albumimageerr !== null ? (
                                                                                                            <div className="errordiv" style={{ "marginTop": "10px" }}>{albumimageerr}</div>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )}
                                                                                                    </Grid>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={9} >
                                                                                    <div id="tab_opt_1" className="album-tab">
                                                                                        <h5 className="h5-custom2">Song Name</h5>
                                                                                        <input type="text" name="item_price" onChange={(e) => changeTitle(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Title here"
                                                                                        />
                                                                                        {songerr !== null ? (
                                                                                            <div className="errordiv">{songerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                    <div id="tab_opt_1" className="album-tab">
                                                                                        <h5 className="h5-custom2">
                                                                                            International Standard Recording Code(ISRC)
                                                                                        </h5>
                                                                                        <input type="text" name="isrc" onChange={(e) => changeISRC(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your ISRC here"
                                                                                        />
                                                                                        {ISRCerr !== null ? (
                                                                                            <div className="errordiv">{ISRCerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>


                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            Enter the NFT Collection link
                                                                                        </h5>
                                                                                        <input type="text" name="platform_link" onChange={(e) => changeLink(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Platform Link here"
                                                                                        />
                                                                                        {NFTLinkerr !== null ? (
                                                                                            <div className="errordiv">{NFTLinkerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Song Category</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={category}
                                                                                                onChange={handleChange}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Genre</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem>
                                                                                                {northamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Latin America</strong></MenuItem>
                                                                                                {latinamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Europe</strong></MenuItem>
                                                                                                {europe.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Africa</strong></MenuItem>
                                                                                                {africa.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Asia</strong></MenuItem>
                                                                                                {asia.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Middle East</strong></MenuItem>
                                                                                                {middleeast.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Oceania</strong></MenuItem>
                                                                                                {oceania.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                            {categoryerr !== null ? (
                                                                                                <div className="errordiv">{categoryerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Pick a Mood</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={mode}
                                                                                                onChange={handleChange1}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Mood</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                {mood.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}

                                                                                            </Select>
                                                                                            {modeerr !== null ? (
                                                                                                <div className="errordiv">{modeerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div  >
                                                                                        <h5>
                                                                                            Add to Previous PlayList
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} checked={previousAlbum} onClick={handlePreviousAlbum} />

                                                                                        </h5>
                                                                                        {previousAlbum ? (<>

                                                                                            {playslist.length > 0 ? <>
                                                                                                <FormControl fullWidth>
                                                                                                    <Select

                                                                                                        displayEmpty

                                                                                                        value={name}
                                                                                                        onChange={handleChangesPlay}
                                                                                                        input={<OutlinedInput />}
                                                                                                        renderValue={(selected) => {
                                                                                                            if (selected && selected.length === 0) {
                                                                                                                return <MenuItem disabled>Previous Album</MenuItem>;
                                                                                                            }

                                                                                                            return selected;
                                                                                                        }}

                                                                                                        MenuProps={MenuProps}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >


                                                                                                        {playslist && playslist.map((row, index) => (
                                                                                                            <MenuItem
                                                                                                                key={row?._id}
                                                                                                                value={row}

                                                                                                                style={getStyles(row?._id, personName, theme)}
                                                                                                            >
                                                                                                                {row?.playlist}
                                                                                                            </MenuItem>
                                                                                                        ))}

                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </> : <>
                                                                                                No Previous PlayList
                                                                                            </>}


                                                                                        </>) : <div id="tab_opt_1">
                                                                                            <h5 className="h5-custom2">
                                                                                                Play List Name
                                                                                            </h5>
                                                                                            <input type="text" name="platform_link" onChange={(e) => { setmusictype("playlist"); setPlay(e.target.value) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Your Playlist Name Here"
                                                                                            />

                                                                                        </div>}
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="description-part">
                                                                                        <h5>Description </h5>
                                                                                        <textarea
                                                                                            name="postContent"
                                                                                            onChange={(e) => changeDesc(e.target.value)}
                                                                                            placeholder="Explain what your NFT is about ( Max 500 characters )"
                                                                                            rows={4}
                                                                                            cols={40}
                                                                                        />
                                                                                        {descriptionerr !== null ? (
                                                                                            <div className="errordiv">{descriptionerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                        </div>
                                                                        <div className="de_tab_content_2" >
                                                                            <h5>
                                                                                Required Fields
                                                                                <span style={{ color: "red" }}> * </span>
                                                                                <Switch {...label} onClick={handleRequirefield} />

                                                                            </h5>

                                                                            {requirefield ? (<>
                                                                                <p>
                                                                                    set the Requirements users need to meet to mint your
                                                                                    Dreamster
                                                                                </p>

                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Set date and time for claiming the song
                                                                                            </label>
                                                                                            <input type="date" name="item_price" id="Title" className="form-control date-input" onChange={(e) =>
                                                                                                changeClaimDate(e.target.value)
                                                                                            }
                                                                                            />
                                                                                            {ClaimDateerr !== null ? (
                                                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{ClaimDateerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Total NFT Claim
                                                                                            </label>
                                                                                            <input
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setClaimLimiterr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                name="item_price" id="Title" onChange={(e) => changeClaimLimit(e.target.value)} className="form-control date-input"
                                                                                                placeholder="Total NFT Claim" />
                                                                                            {ClaimLimiterr !== null ? (
                                                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{ClaimLimiterr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>

                                                                                </Grid>


                                                                                <div className="switch-with-title" style={{ borderRadius: "30px" }}>

                                                                                    <h5> {/* <FontAwesomeIcon icon={faGlobe} /> &nbsp;Open to */}Open to Everyone
                                                                                    </h5>
                                                                                    <div className="de-switch 1">

                                                                                        <Switch {...label} checked={opento} onClick={() => OpentoEveryOnes()} />
                                                                                        {opentoerr !== null ? (
                                                                                            <div className="errordiv" style={{ "marginTop": "10px" }}>{opentoerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="submit-btn2">
                                                                                    {loading == true ? <Button className="btn btn-upload">Processing ...</Button> :
                                                                                        <Button i className="btn btn-upload" onClick={() => createMusic()}>Submit</Button>}
                                                                                </div>


                                                                                <div className="spacer-single"></div>


                                                                            </>) : null}





                                                                        </div>
                                                                    </TabPanel>
                                                                </SwipeableViews>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                </>) : null}




                                            </div>


                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>

                    </div>
                </Box>
            </Box>
        </div>

    );
}

export default Upload;
