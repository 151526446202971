import styles from "./NewMusicTrack.module.css"
import React, { useState, useEffect, useRef } from 'react'
import Stack from '@mui/material/Stack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import mtn7 from "../../img/dster-webp/mtn7.webp"
import bs2 from "../../img/dster-webp/bs2.webp"
import { Grid } from "@mui/material";
import Slider from '@mui/material/Slider';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Popover from '@mui/material/Popover';
import wave from "../../img/dster-webp/musicwave.gif"
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import vols from "../../img/dster-webp/volume-up.webp"


function NewMusicTrack(props) {
  const [sound, setSound] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (sound === true) {
        setSound(false);
      }
    }, 5000);
  }, [sound]);

  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const [vol, setVol] = React.useState(30);

  const handleChange = (event, newValue) => {
    setVol(newValue);
  };


  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0)
  const volumes = localStorage.getItem("volume")
  const [volume, setVolume] = useState(volumes !== null ? volumes : 100);
  const [source, setSource] = useState();
  const audioRef = useRef(null);
  const [waveform, setWaveform] = useState(null);
  const waveformRef = useRef(null);



  const handleTrim = () => {
    if (waveform) {
      const startTime = waveform.getCurrentTime();
      const endTime = waveform.getDuration();

      // Trim audio logic here, e.g., save a new audio file or update state
    }
  };


  // // Function to seek to a specific time in audio.


  const handlePlay = () => {


    if (audioRef.current.src !== null && audioRef.current.src !== undefined) {
      const audio = audioRef?.current?.play();
      if (audio !== undefined) {
        audio.then(() => {
          // Autoplay started
          localStorage.setItem("isPause", false)
          props?.setisplay(true)

          setIsPlaying(true);
        }).catch(error => {
          // Autoplay was prevented.
          handlePlay()
          // video.muted = true;
          // video.play();
        });
      }

    }


  };

  const handleFarward = () => {
    audioRef.current.currentTime -= 10;
    audioRef.current.play();
  }

  const handlebackward = () => {
    audioRef.current.currentTime += 10;
    audioRef.current.play();
  }
  const audio = localStorage.getItem("props.audiosource")

  useEffect(() => {
    const isPause = localStorage.getItem("isPause")
    if (props.audiosource !== undefined) {
      const current = localStorage.getItem("current")
      if (isPause && isPause === "false") {
        if (current !== undefined && current !== null && current !== 0) {
          audioRef.current.currentTime = Number(current)
          handlePlay()
        }
        else {
          audioRef.current.currentTime = currentTime
          handlePlay()
        }
      }
      else {
        if (current !== undefined && current !== null && current !== 0) {
          audioRef.current.currentTime = Number(current)

        }
        else {
          audioRef.current.currentTime = currentTime
        }
        handlePause()

      }

    }
    else {
      handleStop()
    }
  }, [props.audiosource, props.value])

  // console.log(audioRef, 'ref');


  const handlePause = () => {
    localStorage.setItem("props.audiosource", audioRef.current.src)
    localStorage.setItem("current", audioRef.current.currentTime)

    localStorage.setItem("isPause", true)

    audioRef.current.pause();
    setIsPlaying(false);
    props?.setisplay(false)
  };

  const handleStop = () => {
    localStorage.setItem("props.audiosource", audioRef.current.src)
    localStorage.setItem("current", audioRef.current.currentTime)
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };
  useEffect(() => {
    const isPause = localStorage.getItem("isPause")
    if (props?.isplay === false || isPause === "true") {
      handlePause();
    } else {
      handlePlay();
    }

  }, [props?.isplay])



  const handleTimeUpdate = () => {

    if (audioRef.current === null) {
      setCurrentTime(0)
      setDuration(0)
    }
    else if (audioRef.current.currentTime === audioRef.current.duration) {

      localStorage.setItem("current", 0)
      handlePause()
    }
    else {
      localStorage.setItem("current", audioRef.current.currentTime)
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);

    }

  };
  const handleSeek = (e) => {

    if (audioRef.current === null) {
      setCurrentTime(0)
    }
    else {

      if (isNaN(e.target.value)) {

        audioRef.current.currentTime = 0;
        setCurrentTime(0);

      }
      else {
        audioRef.current.currentTime = e.target.value;
        setCurrentTime(e.target.value);
      }

    }
  };

  useEffect(() => {
    audioRef.current.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      if (audioRef?.current !== null) {
        // handlePlay()

      }
      // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume, audioRef]);


  function formatDuration(durationSeconds) {
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = Math.floor(durationSeconds % 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${minutes}:${formattedSeconds}`;
  }




  const dropzoneStyles = {
    border: '2px dashed #ccc',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };

  const waveformStyles = {
    marginTop: '20px',
  };

  const [value, setValue] = React.useState(30);


  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };


  const volumeup = (
    <div className={styles.volumeslider}>

      <SpeedDial
        ariaLabel="SpeedDial basic example"
        // sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<img src={vols} alt="medium-volume--v1" style={{ width: '30px', height: '30px', borderRadius: '30px' }} />}

        value={value}
        className={styles.speed_dail}
      >

        <SpeedDialAction icon={<Slider aria-label="Volume"
          orientation="vertical"
          defaultValue={100}
          min={0}
          max={100}
          value={volume}
          onKeyDown={preventHorizontalKeyboardNavigation}
          className='newvolslider'
          onChange={(e) => { setVolume(e.target.value); localStorage.setItem("volume", e.target.value) }} />
        } />


      </SpeedDial>
      <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">


      </Stack>
      {/* {/ <Slider disabled defaultValue={30} aria-label="Disabled slider" /> /} */}



    </div>
  )

  return (

    <Grid container spacing={0} className={styles.musiccontainer}>

      <div className={`content ${isVisible ? 'visible' : 'hidden'}`}>
        {isVisible ? <Grid xs={11} sm={11} md={9} lg={7} xl={5} >
          <div className={styles.musicmain} >

            <div className={styles.musicdiv}>
              <div className={styles.musicslider}>
                <ZoomInMapIcon onClick={toggleVisibility} />
                <Stack direction="row" spacing={3} className={styles.musiccont} >

                  <div className={styles.musicimg}>
                    <img src={localStorage.getItem("Image") !== undefined ? localStorage.getItem("Image") : bs2} alt="sad" />
                  </div>
                  <div className={styles.musicconthead}>
                    <span>{localStorage.getItem("songName") !== undefined ? localStorage.getItem("songName") : "Song Name"}</span>

                    <div className={styles.musiccontpara}>
                    </div>
                  </div>
                  {isPlaying === false && <div style={{ width: '35px', height: '35px' }}>
                  </div>}

                  {isPlaying && <img src={wave} alt="wave" style={{ width: '35px', height: '35px' }} />}
                  <Stack direction="row" spacing={3} className={styles.stk}>

                    <FastRewindIcon onClick={() => { handleFarward() }} />

                    {isPlaying ? <PauseIcon onClick={handlePlayPause} /> : <PlayArrowIcon onClick={handlePlayPause} />}

                    <FastForwardIcon onClick={() => { handlebackward() }} />


                    <div className="stk-dis2">


                      {volumeup}

                    </div>
                  </Stack>

                  <Slider
                    disabled={false}
                    marks={false}
                    max={duration}
                    min={0}
                    step={1}
                    size="small"
                    valueLabelDisplay="on"
                    className="mslider"
                    value={currentTime}
                    onChange={handleSeek}
                  />
                </Stack>

              </div>

              {/* {/ <audio ref={audioRef} src={vijay} /> /} */}
              <Stack direction="row" spacing={3} className="stk-dis">
                {/* {/ <SmsFailedIcon /> /} */}
                {/* {/ <ListIcon /> /} */}

                {volumeup}
              </Stack>
            </div>

          </div>

        </Grid> : <Grid xs={11} sm={11} md={12} lg={11} xl={11} >

          {/* {/ <input type="file" accept="mp3/*" onChange={handleImageUpload} /> /} */}
          <div>
            <div className={styles.musicslider}>
              <Stack direction="row" spacing={3} className={styles.musiccontx2}>
                <div className={styles.musicimg}>
                  <img src={localStorage.getItem("Image") !== undefined ? localStorage.getItem("Image") : bs2} alt="sad" />
                </div>
                <div className={styles.musicconthead}>

                  {localStorage.getItem("songName") !== undefined ? localStorage.getItem("songName") : "Song Name"}
                  <div className={styles.musiccontpara}>
                    {/* {/ The Beach Boys /} */}
                  </div>
                </div>
                <div className={styles.musiccont3}>
                  <img src={mtn7} alt="src" />
                  <MoreHorizIcon onClick={handleClick} />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    className={styles.popover}
                  >
                    <div className={styles.mini_more}>
                      <div className={styles.playpause} onClick={handlePlayPause}>
                        {isPlaying ? "Pause" : "Play"}
                      </div>

                    </div>

                  </Popover>
                  <ZoomOutMapIcon onClick={toggleVisibility} />
                </div>
                <Slider
                  // disabled={false}
                  marks={false}
                  max={duration}
                  min={0}
                  step={1}
                  size="small"
                  valueLabelDisplay="on"
                  className="mslider"
                  value={currentTime}
                  onChange={handleSeek}
                />
              </Stack>

            </div>

          </div>


        </Grid>
        }
      </div>
      <audio ref={audioRef} src={props.audiosource} />
    </Grid>

  )
}

export default NewMusicTrack
