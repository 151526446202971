import React, { useState, useRef } from 'react'
import Axios from '../../Axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import { ToastContainer, toast } from 'react-toastify';
import '../Upload-Music/UploadMusic.css'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import '../Hoisting/Hoisting.css'
import TextField from '@mui/material/TextField';
import '../CreateVirtualMeet/CreateVirtualMeet.css'
import '../Colabration/Colabration.css'
import './PollsSurvey.css'
import Tabs from '@mui/material/Tabs';
import artist1 from '../../img/dster-webp/artist1.webp'
import AddIcon from '@mui/icons-material/Add';
import pollsimg from '../../img/dster-webp/polls&survey.webp'
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import HeaderNew from "../HeaderNew/HeaderNew";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const drawerWidth = 280;


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function PollsSurvey() {
    const theme = useTheme();

    const token = useRef(null)
    const titles = useRef(null)
    const ans1 = useRef(null)
    const ans2 = useRef(null)
    const [date, setDate] = useState("")
    const [date1, setDate1] = useState("")
    const [tokenErr, settokenErr] = useState()
    const [surveyData, setSurveyData] = useState()
    const [titleErr, settitleErr] = useState()
    const [dateErr, setdateErr] = useState()
    const [question, setquestion] = useState(["01"]);
    const [openModel, setOpenModel] = React.useState(false);
    const handleOpen = () => setOpenModel(true);
    const handleClose = () => setOpenModel(false);
    const [quesErr, setquesErr] = useState()
    const [ques1Err, setques1Err] = useState()

    const [ques, setQues] = useState("")

    const [ans1Err, setans1Err] = useState()
    const [ans2Err, setans2Err] = useState()

    const navigate = useNavigate()

    // console.log(validate);

    const inputRef = useRef(null)

    const [islogin, setIslogin] = useState(false)
    const [islogin1, setIslogin1] = useState(false)

    const [message, setMessage] = useState([]);

    const [updated, setUpdated] = useState('');

    const setErrnull = () => {
        setans1Err("")
        setquesErr("")
        settokenErr("")
        setans2Err("")
    }

    const setErr1null = () => {
        settitleErr("")
        setdateErr("")
        setques1Err("")

    }

    const handlenumber = () => {
        const regex = /^[0-9]*$/
        if (!(regex.test(token.current.value))) {
            settokenErr("*Enter Number Only")
        }
        else {
            settokenErr("")
        }

    }

    const createPoll = async () => {
        try {
            console.log(token.current.value)
            if (!token.current.value) {
                settokenErr("*Token is Required")
            }
            else if (date == "") {
                setans2Err("*Poll End Date is Required")
            }

            else if (ques === "") {
                setquesErr("*Question is required")
            }
            else if (!ans1.current.value) {
                setans1Err("*A Answer is Required")
            }

            else if (!ans2.current.value) {
                setans1Err("*B Answer is Required")
            }
            else {
                var Answer = []
                Answer.push(ans1.current.value)
                Answer.push(ans2.current.value)


                var pollData = {
                    end_date: date,
                    answers: Answer,
                    amount: token.current.value,
                    question: ques
                }
                console.log(pollData)
                setIslogin(true)

                await Axios.post("/poll/create_poll", pollData).then((res) => {
                    // console.log(res,"res")
                    if (res?.data?.success) {

                        toast.success(res?.data?.message)
                        setIslogin(false)
                        setQues("")
                        ans1.current.value = ""
                        ans2.current.value = ""


                    }
                    else {
                        toast.error(res?.data?.message)
                        setIslogin(false)
                    }
                }).catch((err) => {

                    console.log(err)

                    setIslogin(false)
                })

            }
        } catch (error) {
            console.log(error)
            setIslogin(false)
        }
    }

    // const addQues = (newValue) => {
    //     setQues(newValue)
    //     console.log(question, "Ques")
    // }

    const createSurvey = async () => {
        try {
            const questions = getValuesArray()
            console.log(questions.length)
            var element = document.getElementById("box");

            if (date1 == "") {
                setdateErr("*Survey End Date is Required")
                element.scrollIntoView({ behavior: "smooth", block: "start" });

            }
            else if (!titles.current.value) {
                settitleErr("*Title is Required")
            }
            else if (questions.length <= 0) {
                setques1Err("*Question is required")
            }

            else {
                var question = []


                var surveyDatas = {
                    end_date: date1,
                    title: titles.current.value,
                    question: questions
                }
                console.log(surveyDatas)
                setSurveyData(surveyDatas)

                handleOpen(true)


            }
        } catch (error) {
            console.log(error)
            //toast.error(error?.response?.data?.message)


        }
    }

    const surveyApprove = async () => {
        setIslogin1(true)
        await Axios.post("/poll/create_survey", surveyData).then((res) => {
            // console.log(res,"res")
            if (res?.data?.success) {

                toast.success(res?.data?.message)
                setIslogin1(false)
                titles.current.value = ""
                handleClose()
                const value = 1
                navigate('/pollshistory', { state: { value } })

            }
            else {
                toast.error(res?.data?.message)
                handleClose()
                setIslogin1(false)
            }
        }).catch((err) => {

            console.log(err)
            toast.error(err?.response?.data?.message)
            handleClose()

            setIslogin1(false)
        })
    }


    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getValueForDiv = (index, value) => {
        var divi = []


        setErr1null()

        const updatedDivs = divs.map((div, i) => {
            if (i === index) {
                return { ...div, value };
            }
            return div;
        });
        setDivs(updatedDivs);
    }

    const getValuesArray = () => {
        var valuesArray = []
        if (divs.length > 0) {
            for (var i = 0; i < divs.length; i++) {
                console.log(divs[i]?.value)
                if (divs[i]?.value !== undefined) {
                    valuesArray.push(divs[i]?.value)

                }
                else {
                    return valuesArray
                }

            }
            return valuesArray
        }
        else {
            return valuesArray
        }
        // divs.map(div => div.value);
        //  return valuesArray // Output the array of values


    }

    const removeDivByIndex = (index) => {
        const updatedDivs = divs.filter((div, i) => {
            return i !== index
        });
        setDivs(updatedDivs);
    }
    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };


    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };



    const [title, setTitle] = useState('polls & survey')

    const [divs, setDivs] = useState([{ id: 1 }]);

    const addDiv = () => {
        const newDiv = { id: divs.length + 1 };
        setDivs([...divs, newDiv]);
    }

    return (

        <div className="Hoisting explore dashboard">
            {/* <SnackBar /> */}
            <Box sx={{ display: 'flex' }} className='display-block'>

                <HeaderNew title={title} />
                <ToastContainer

                    autoClose={3000}
                    position="top-right"

                />
                <Box component="main"
                    className="Hoisting-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    <Grid container spacing={0}>
                        <Container maxWidth="xl">
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="poll-head-tab">

                                    <Box sx={{ width: '100%' }} className='nft-corner-tab'>
                                        <Box sx={{ borderColor: 'divider' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example nft" className="nft-tab-style polls">
                                                <Tab label="Poll" {...a11yProps(0)} />
                                                <Tab label="Survey" {...a11yProps(1)} />
                                            </Tabs>
                                        </Box>
                                    </Box>
                                </div>

                            </Grid>
                        </Container>
                    </Grid>
                    <CustomTabPanel value={value} index={0} >
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, p: 0 }}
                            className="UploadMusic-box3 create-exclusive"
                        >
                            <Container maxWidth="xl" >
                                <Grid container spacing={2} >
                                    <Grid xs={12} sm={12} md={12} lg={7} xl={6} sx={{ p: 1 }}>


                                        <div className="div-dropzone-1mxhs-1">
                                            <header className="frame-set">
                                                <div className="frame-set-child" />
                                                <img className="top-level-frame" alt="" src={pollsimg} />
                                                <div className="frame-with-rectangle">
                                                    <div className="designer-info-frame-parent">
                                                        <img
                                                            className="designer-info-frame"
                                                            loading="eager"
                                                            alt=""
                                                            src={artist1}
                                                        />
                                                        <div className="eatsleepdesignrepeat">
                                                            <div className="kiiara-castillo-parent">
                                                                <div className="kiiara-castillo">Kiiara Castillo</div>
                                                                <div className="eatsleepdesignrepeat1">
                                                                    @eatsleepdesignrepeat
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </header>
                                            <section className="numbers-only-frame">
                                                <div className="frame-with-two-frames">
                                                    <div className="frame-with-mask-group">
                                                        <div className="frame-with-lines">
                                                            <b className="st-prize">Amounts</b>
                                                            <div className="mask-group">
                                                                <div className="background" />

                                                                <div className="dster-frame-and-line">

                                                                    <TextField
                                                                        className="line-end"
                                                                        variant="outlined"
                                                                        inputRef={token}
                                                                        onChange={() => { setErrnull(); handlenumber() }}
                                                                        sx={{
                                                                            "& fieldset": { borderColor: "#23262f" },
                                                                            "& .MuiInputBase-root": {
                                                                                height: "45px",
                                                                                backgroundColor: "#000",
                                                                                borderRadius: "7.455072402954102px",
                                                                                width: '100%'
                                                                            },
                                                                        }}
                                                                    />
                                                                    <div className="incentivize-to-choose" />
                                                                    <b className="line-separator">Dster</b>
                                                                </div>

                                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{tokenErr}</div>


                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="event-date-frame">
                                                        <b className="event-date-and">{`Event Date And Time `}</b>
                                                        <div className="rd-prize">
                                                            <div className="enter-numbers-only">(Enter numbers only)</div>
                                                        </div>

                                                    </div>

                                                    <div className="start-end-time-main">

                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer
                                                                components={[
                                                                    'DateTimePicker',
                                                                    'MobileDateTimePicker',
                                                                    'DesktopDateTimePicker',
                                                                    'StaticDateTimePicker',
                                                                ]}
                                                            >
                                                                <DemoItem label="">
                                                                    <DateTimePicker disablePast={true} onChange={(e) => {
                                                                        setErrnull();

                                                                        var oriDate = `${Number(e?.$M) + 1}/${e?.$D}/${e?.$y} ${e?.$H}:${e?.$m}`
                                                                        setDate(oriDate)
                                                                    }} />
                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>


                                                        <div className="errordiv" style={{ "marginTop": "10px" }}>{ans2Err}</div>

                                                    </div>

                                                </div>
                                            </section>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <div className="div-dropzone-1mxhs-1">
                                            <section className="f-r-a-m-e">
                                                <div className="f-r-a-m-e1">
                                                    <b className="question">Question</b>
                                                    <textarea

                                                        value={ques}
                                                        onChange={(e) => { setQues(e.target.value); setErrnull() }}
                                                        className="t-e-x-t"
                                                        placeholder="Your Question here.... "
                                                        rows={6}
                                                        cols={18}
                                                    />
                                                </div>
                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{quesErr}</div>

                                                <div className="f-r-a-m-e2">
                                                    <b className="answer-type">Answer</b>
                                                    <div className="question-type">
                                                        <TextField id="outlined-basic" placeholder="Answer A" onChange={() => { setErrnull() }} inputRef={ans1} variant="outlined" />
                                                        <TextField id="outlined-basic" placeholder="Answer B" onChange={() => { setErrnull() }} inputRef={ans2} variant="outlined" />
                                                    </div>
                                                    <div className="errordiv" style={{ "marginTop": "10px" }}>{ans1Err}</div>

                                                </div>
                                            </section>
                                            {islogin === false ? <Button
                                                onClick={() => { createPoll() }}
                                                className="button"
                                                disableElevation={true}
                                                variant="contained"
                                                sx={{
                                                    color: "#fff",
                                                    fontSize: "18",
                                                    background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                                    borderRadius: "72.4637680053711px",
                                                    "&:hover": {
                                                        background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                                    },
                                                    marginTop: '240px',
                                                    width: 220,
                                                    height: 37,
                                                }}
                                            >{`Create Poll `}</Button> : <Button

                                                className="button"
                                                disableElevation={true}
                                                variant="contained"
                                                sx={{
                                                    color: "#fff",
                                                    fontSize: "18",
                                                    background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                                    borderRadius: "72.4637680053711px",
                                                    "&:hover": {
                                                        background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                                    },
                                                    marginTop: '240px',
                                                    width: 220,
                                                    height: 37,
                                                }}
                                            >{`Processing ... `}</Button>}
                                        </div>

                                    </Grid>


                                </Grid>
                            </Container>


                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box
                            component="main"
                            sx={{ flexGrow: 1, p: 0 }}
                            className="UploadMusic-box3 create-exclusive" id="box"
                        >
                            <Container maxWidth="xl" >
                                <Grid container spacing={2} >
                                    <Grid xs={12} sm={12} md={12} lg={7} xl={6} sx={{ p: 1 }}>


                                        <div className="div-dropzone-1mxhs-1">
                                            <header className="frame-set">
                                                <div className="frame-set-child" />
                                                <img className="top-level-frame" alt="" src={pollsimg} />
                                                <div className="frame-with-rectangle">
                                                    <div className="designer-info-frame-parent">
                                                        <img
                                                            className="designer-info-frame"
                                                            loading="eager"
                                                            alt=""
                                                            src={artist1}
                                                        />
                                                        <div className="eatsleepdesignrepeat">
                                                            <div className="kiiara-castillo-parent">
                                                                <div className="kiiara-castillo">Kiiara Castillo</div>
                                                                <div className="eatsleepdesignrepeat1">
                                                                    @eatsleepdesignrepeat
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </header>
                                            <section className="numbers-only-frame">
                                                <div className="frame-with-two-frames">
                                                    <div className="event-date-frame">
                                                        <b className="event-date-and">{`Event Date And Time `}</b>
                                                        <div className="rd-prize">
                                                            <div className="enter-numbers-only">(Enter numbers only)</div>
                                                        </div>

                                                    </div>

                                                    <div className="start-end-time-main">

                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer
                                                                components={[
                                                                    'DateTimePicker',
                                                                    'MobileDateTimePicker',
                                                                    'DesktopDateTimePicker',
                                                                    'StaticDateTimePicker',
                                                                ]}
                                                            >
                                                                <DemoItem label="">
                                                                    <DateTimePicker disablePast={true} onChange={(e) => {
                                                                        setErr1null();
                                                                        var oriDate = `${Number(e?.$M) + 1}/${e?.$D}/${e?.$y} ${e?.$H}:${e?.$m}`

                                                                        setDate1(oriDate)
                                                                    }} />
                                                                </DemoItem>
                                                            </DemoContainer>
                                                        </LocalizationProvider>


                                                        <div className="errordiv" style={{ "marginTop": "10px" }}>{dateErr}</div>


                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={6} lg={6} xl={6}  >
                                        <div className="div-dropzone-1mxhs-1 polls">
                                            <section className="f-r-a-m-e">
                                                <div className="f-r-a-m-e1">
                                                    <b className="question">Title</b>
                                                    <TextField
                                                        className="line-end"
                                                        variant="outlined"
                                                        inputRef={titles}
                                                        onChange={() => { setErr1null() }}
                                                        sx={{
                                                            "& fieldset": { borderColor: "#23262f" },
                                                            "& .MuiInputBase-root": {
                                                                height: "45px",
                                                                backgroundColor: "#000",
                                                                borderRadius: "7.455072402954102px",
                                                                width: '100%'
                                                            },
                                                        }}
                                                    />
                                                    <div className="errordiv" style={{ "marginTop": "10px" }}>{titleErr}</div>

                                                    <Button
                                                        className="button addmore"
                                                        disableElevation={true}
                                                        variant="contained"
                                                        sx={{
                                                            color: "#fff",
                                                            fontSize: "18",
                                                            background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                                            borderRadius: "72.4637680053711px",
                                                            "&:hover": {
                                                                background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                                            },

                                                        }}
                                                        onClick={addDiv}
                                                    >Add More<AddIcon /></Button>
                                                </div>
                                                {divs.map((row, index) => {
                                                    return (
                                                        <div key={index} className="f-r-a-m-e1">
                                                            <b className="question">Question</b> <div className="errordiv" style={{ "marginTop": "10px" }}>{ques1Err}</div>
                                                            <textarea
                                                                className="t-e-x-t"
                                                                // onChange={(e) => { addQues(e.target.value) }}
                                                                placeholder="Your Question here.... "
                                                                rows={6}
                                                                cols={18}
                                                                onChange={(e) => getValueForDiv(index, e.target.value)}
                                                                value={row.value || ""}
                                                            />
                                                            <button onClick={() => { removeDivByIndex(index) }}>remove</button>
                                                        </div>
                                                    )
                                                })}

                                            </section>
                                            {islogin1 === false ? <Button
                                                className="button polls"
                                                disableElevation={true}
                                                onClick={() => createSurvey()}
                                                variant="contained"
                                                sx={{
                                                    color: "#fff",
                                                    fontSize: "18",
                                                    background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                                    borderRadius: "72.4637680053711px",
                                                    "&:hover": {
                                                        background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                                    },
                                                    // marginTop: '300px',
                                                    width: 220,
                                                    height: 37,
                                                }}

                                            >{`Create Survey `}</Button> :
                                                <Button
                                                    className="button polls"
                                                    disableElevation={true}

                                                    variant="contained"
                                                    sx={{
                                                        color: "#fff",
                                                        fontSize: "18",
                                                        background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                                        borderRadius: "72.4637680053711px",
                                                        "&:hover": {
                                                            background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                                        },
                                                        // marginTop: '300px',
                                                        width: 220,
                                                        height: 37,
                                                    }}

                                                >{`Processing ... `}</Button>}
                                        </div>

                                    </Grid>

                                </Grid>
                            </Container>

                        </Box>
                    </CustomTabPanel>
                </Box>

            </Box>
            <Modal
                open={openModel}
                onClose={() => { handleClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Survey Detail
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Title : {surveyData?.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        End Date : {surveyData?.end_date}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Question :
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {surveyData?.question?.length > 0 ? <>{surveyData?.question && surveyData?.question?.map((row, index) => {
                            return (
                                <> {index + 1}.{row} <br></br></>
                            )
                        })}</> : <></>}

                    </Typography>
                    {islogin1 === false ? <Button
                        className="button polls"
                        disableElevation={true}
                        onClick={() => surveyApprove()}
                        variant="contained"
                        sx={{
                            color: "#fff",
                            fontSize: "18",
                            background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                            borderRadius: "72.4637680053711px",
                            "&:hover": {
                                background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                            },
                            // marginTop: '300px',
                            width: 220,
                            height: 37,
                        }}

                    >{`Approve Survey `}</Button>
                        : <Button
                            className="button polls"
                            disableElevation={true}
                            variant="contained"
                            sx={{
                                color: "#fff",
                                fontSize: "18",
                                background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                borderRadius: "72.4637680053711px",
                                "&:hover": {
                                    background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                },
                                // marginTop: '300px',
                                width: 220,
                                height: 37,
                            }}
                        >Processing ...</Button>}

                    <Button
                        className="button polls"
                        disableElevation={true}
                        variant="contained"
                        sx={{
                            color: "#fff",
                            fontSize: "18",
                            background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                            borderRadius: "72.4637680053711px",
                            "&:hover": {
                                background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                            },
                            // marginTop: '300px',
                            width: 220,
                            height: 37,
                        }}
                        onClick={() => { handleClose() }}>Close</Button>
                </Box>
            </Modal>
        </div>
    );
}

export default PollsSurvey;