import React from "react";
import Header2 from "../Header/Header2";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Track from "../Music-traxk/Track";
import './UploadMusic.css'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';



const drawerWidth = 280;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


function UploadMusic() {
    const theme = useTheme();
    const [name, setName] = React.useState();

    return (

        <div className="UploadMusic">
            {/* <SnackBar /> */}
            <Box sx={{ display: 'flex' }}>

                <Header2 name={name} setName={setName} />
                <Box component="main"
                    className="UploadMusic-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', marginBottom: '100px' }}>
                    <Grid container spacing={0} className="heading-container">
                        <Container maxWidth="lg">
                            <Grid xs={12} sm={12} md={12} lg={12} xl={8}>
                                <div className="UploadMusic-heading">
                                    UploadMusic
                                </div>
                            </Grid>
                        </Container>
                    </Grid>

                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 3 }}
                        className="UploadMusic-box2"
                    >
                        <Container maxWidth="lg">
                            <Grid container spacing={2} >
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="upload-grid">
                                    <div className="description-UploadMusic">
                                        <Button component="label" variant="contained" className="upload-btn" >
                                            <CloudUploadIcon />
                                            <div>
                                                Drag-and-drop a track here,<span className="go-violet">or browse to upload</span>
                                            </div>
                                            <VisuallyHiddenInput type="file" />
                                        </Button>
                                    </div>


                                </Grid>


                            </Grid>
                        </Container>



                        <div>
                            <Track />
                        </div>
                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default UploadMusic;