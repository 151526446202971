import './Message.css'
import React, { useEffect, useRef, useState } from 'react'
import Axios from "../../Axios";
import StackABI from '../../Web3/stakeAbi.json'
import stakeAddress from '../../Web3/stakeAddress'
import approveABI from '../../Web3/approveAbi.json'
import approveAddress from '../../Web3/approveAddress'
import Web3 from 'web3';
import Box from '@mui/material/Box';
import io from "socket.io-client";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import consts from "../../Constant";
import { ToastContainer, toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import '../Library/Library.css'
import { useNavigate, useParams } from "react-router-dom";
import Companylogo from '../../img/dster-webp/company-logo.webp';
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles';
import { Button, Tooltip } from "@mui/material";
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import HeaderNew from '../HeaderNew/HeaderNew';
import newno from "../../img/dster-webp/newnodata.gif"


const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'var(--clr-bcg)',
    height: '620px',
    // overflow: 'scroll',
    // border: '2px solid #000',
    // boxShadow: 'none',
    p: 4,
    '@media all and (max-width: 1199.98px)': {
        width: '55%'
    },
    '@media all and (max-width: 768.98px)': {
        width: '85%'
    },
    '@media all and (max-width: 575.98px)': {
        width: '95%'
    }
};
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};





const drawerWidth = 280;


function Message() {
    const params = useParams()
    let socket = io.connect(consts.SocketUrl);
    const [messageList, setmessageList] = useState([])
    const [value, setValue] = React.useState(0);
    const [name, setName] = React.useState("0");
    const requestAcc = useRef(true)
    const [metaAccount, setMetaAccount] = useState()
    const [status, setstatus] = React.useState("All")
    const [msg, setMsg] = useState(false)
    const [names, setNames] = useState()
    const [ids, setIds] = useState()
    const [_id, setId] = useState()
    const [rooms, setRooms] = useState([])
    const [roomdetails, setRoomdetails] = useState({})
    const [room, setRoom] = useState()
    const handleMsg = () => { setMsg(true) }
    const [receivers, setReceivers] = useState([])
    const [receiver, setReceiver] = useState()
    const [display, setdisplay] = React.useState(false)
    const tokens = localStorage.getItem("iphephile");
    const [balance, setBalance] = useState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMounted = useRef(false);
    const [search, setSearch] = useState()
    const role = localStorage.getItem("role");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // console.log(obj,'library');



    useEffect(() => {
        console.log("Room", room)

        if (room != undefined) {
            // if (!isMounted.current) {
            socket.emit('join', { room: room })


            socket.on("receive", (data) => {
                console.log("receive room", data)

                setmessageList((list) => [...list, data])
            });
            //     isMounted.current = true;
            // }
            // else {
            //     console.log("Room")
            // }
        }

    }, [room])

    useEffect(() => {

        socket.on("receive", (data) => {
            console.log("receive", data)

            setmessageList((list) => [...list, data])
        });
    }, [])


    useEffect(() => {
        getMyRoom()
    }, [])


    useEffect(() => {
        getMyRoom()
    }, [search])

    useEffect(() => {
        if (requestAcc.current) {

            if (!metaAccount && tokens) {
                // requestAccount()
            }
        }


        requestAcc.current = false


    }, [])

    const onChangeNetwork = async (chainIds, amounts, accountdetail) => {
        try {
            const WEB = new Web3(window.ethereum);
            console.log(accountdetail, "chnges")
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: WEB.utils.toHex(chainIds) }]
            });
            tranferToken(_id, amounts, accountdetail)
            //navigateToBack()

        } catch (err) {
            setName("0")
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                var data = {
                    "name": "BNB Smart Chain Mainnet",
                    "chain": "BSC",
                    "rpc": [
                        "https://bsc-dataseed1.bnbchain.org",
                        "https://bsc-dataseed2.bnbchain.org",
                        "https://bsc-dataseed3.bnbchain.org",
                        "https://bsc-dataseed4.bnbchain.org",
                        "https://bsc-dataseed1.defibit.io",
                        "https://bsc-dataseed2.defibit.io",
                        "https://bsc-dataseed3.defibit.io",
                        "https://bsc-dataseed4.defibit.io",
                        "https://bsc-dataseed1.ninicoin.io",
                        "https://bsc-dataseed2.ninicoin.io",
                        "https://bsc-dataseed3.ninicoin.io",
                        "https://bsc-dataseed4.ninicoin.io",
                        "https://bsc.publicnode.com",
                        "wss://bsc.publicnode.com",
                        "wss://bsc-ws-node.nariox.org"
                    ],
                    "faucets": [],
                    "nativeCurrency": {
                        "name": "BNB Chain Native Token",
                        "symbol": "BNB",
                        "decimals": 18
                    },
                    "infoURL": "https://www.bnbchain.org/en",
                    "shortName": "bnb",
                    "chainId": 56,
                    "networkId": 56,
                    "slip44": 714,
                    "explorers": [
                        {
                            "name": "bscscan",
                            "url": "https://bscscan.com",
                            "standard": "EIP3091"
                        }
                    ]
                }


                // console.log(chainLists1, "chainlist")
                const WEB = new Web3(window.ethereum);
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: data?.chain,
                            chainId: WEB.utils.toHex(chainIds),
                            nativeCurrency: data?.nativeCurrency,
                            rpcUrls: [data?.rpc[0]]
                        }
                    ]
                });
            }
            else if (err.code === 4001) {
                // navigate(0)
                console.log(err)
            }
            else {
                console.log(err)
            }
        }
    }

    const tranferToken = async (datas, amounts, accountdetail) => {
        try {
            if (amounts == undefined) {
                toast.error("amount is required")


            }
            else if (accountdetail == undefined) {
                toast.error("Wallet Address is Unavailable")


            }
            else if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);

                const browserChainId = await web3Instance.eth.getChainId()


                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                if (Number(browserChainId) == consts.chainId) {
                    const contractInstance = new web3Instance.eth.Contract(

                        consts.Abi,
                        consts.Address,

                    );
                    const Transfer = await contractInstance.methods.transfer(
                        accountdetail,
                        web3Instance.utils.toWei(String(amounts), 'ether')

                    ).send({ from: accounts[0] }).then(async (response) => {
                        setName("1")
                        console.log(datas, "datas")
                        const { data } = await Axios.post(`/followers/create_room`, {
                            type: "one",
                            receiver_id: datas,
                            description: "Description"
                        }, {
                            headers: {
                                Authorization: tokens,
                            },
                        })
                        if (data?.success === true) {
                            if (data.message === "Room already Created") {
                                getRoom(data?.result?.room_id)
                                getMyRoom()
                                handleModelClose()
                            }
                            else {
                                getRoom(data?.result?.room_id)
                                handleModelClose()
                                getMyRoom()
                            }

                        }
                        else {
                            toast.error(data?.message)
                        }
                    }).catch((err) => {
                        setName("0")
                        console.log(err)
                        toast("Error while transferring token")
                    })



                    //  return bal
                }
                else {
                    onChangeNetwork(consts.chainId, amounts, accountdetail)
                }


            }
        } catch (err) {
            return false

        }
    };
    const getToken = async () => {
        try {
            if (window.ethereum) {
                const web3Instance = new Web3(window.ethereum);

                await window.ethereum.enable();
                // setWeb3(web3Instance);

                await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                const accounts = await web3Instance.eth.getAccounts();

                const contractInstance = new web3Instance.eth.Contract(
                    StackABI,
                    stakeAddress
                );

                const Token = await contractInstance.methods.token().call()

                const tokenInstance = new web3Instance.eth.Contract(
                    approveABI,
                    approveAddress //token address and abi to check token balance
                );
                const balances = await tokenInstance.methods.balanceOf(accounts[0]).call()
                const bal = await web3Instance.utils.fromWei(String(balances), 'ether')
                setBalance(Number(bal))


            }
        } catch (err) {
            console.log(err)
        }
    };



    const requestAccount = async () => {

        if (window.ethereum) {

            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                setMetaAccount(accounts[0])
                getToken()
                localStorage.setItem('accounts', accounts[0])

            } catch (error) {
                console.log(error, 'Error connecting...');
            }

        } else {
            alert('Please Add Metamask Extension in Your Browser');
        }
    }

    const getMyRoom = async () => {
        try {
            const findData = {
                type: "one",
                name: search
            }

            const datas = await Axios.post("/message/get_room", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        setRooms(res?.data?.result)
                        setIds(res?.data?.user_id)
                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setRooms([])
                        setIds(res?.data?.user_id)

                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }
    const getReceivers = async () => {
        try {

            const findData = { name: receiver }
            const datas = await Axios.post("/message/get_receiver", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        setReceivers(res?.data?.result[0]?.followingDetails)

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setReceivers([])


                    }
                }).catch((err) => {
                    // console.log(err,"err")
                    if (err?.response?.data?.message == "Admin Blocked You") {
                        localStorage.clear();
                        navigate('/');
                        toast.error('Admin Blocked You');
                    }
                })

        } catch (error) {
            // console.log(error,"error");
        }

    }
    const getRoom = async (data) => {
        try {
            var findData = {
                room_id: data,

            }


            const datas = await Axios.post("/message/get_one_room", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        if (res?.data?.result !== "") {
                            setdisplay(true)
                            setRoomdetails(res?.data?.result)
                            setRoom(res?.data?.result?.room_id)
                            getMessage(res?.data?.result?.room_id)

                            socket.emit("join", {
                                room: res?.data?.result?.room_id,
                            })
                            setNames(res?.data?.result?.creator_name)
                            setIds(localStorage.getItem("usrId"))
                        }
                        else {
                            setdisplay(false)
                        }

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setRoomdetails({})


                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const closeRoom = async (data) => {
        try {
            var findData = {
                _id: data,

            }


            const datas = await Axios.post("/message/close_room", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        if (res?.data?.result !== "") {
                            getMyRoom()
                            toast.success(res?.data?.message)
                        }


                    }
                    else {
                        toast.error(res?.data?.message)

                        getMyRoom()
                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }
    useEffect(() => {
        if (!isMounted.current) {
            getReceivers()
            isMounted.current = true;
        }

    }, [receiver])

    const getMessage = async (data) => {
        try {
            var findData = {
                room_id: data,

            }


            const datas = await Axios.post("/message/get_message", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        handleMsg()
                        setmessageList(res?.data?.result)


                        socket.emit("join", {
                            room: data,
                        })

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setmessageList([])


                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const createMessage = async () => {
        console.log("socket")

        try {

            socket.emit("join", {
                room: room,
            })
            const time = `${Date.now()}`
            socket.emit("send", {
                sender_name: names,
                room: room,
                time: time,
                message: updated,
            });

            const { data } = await Axios.post(`/message/create_message`, {

                room: room,
                time: time,
                message: updated,
            }, {
                headers: {

                    Authorization: tokens
                }
            })
            if (data?.success === true) {

                // getOneTicketMesg(room)
                getMessage(room)
                setUpdated('')


            }
            else {
                toast.error(data?.message)
            }
        } catch (error) {
            console.log("🚀 ~ file: Header.js:370 ~ getActivity ~ error:", error)
        }


    }

    // const reset = () => {
    //     setstatus("All")
    //     setName()

    // }
    const amounts = 100
    const createRoom = async (datas, amount, account) => {

        try {

            console.log(typeof (amount), typeof (account))
            const dtokens = await tranferToken(datas, amount, account)
            // if (name === "0") {
            //     toast.error("Error while transferring the token")

            // }
            // else {
            //     console.log(datas, "datas")
            //     const { data } = await Axios.post(`/followers/create_room`, {
            //         type: "one",
            //         receiver_id: datas,
            //         description: "Description"
            //     }, {
            //         headers: {
            //             Authorization: tokens,
            //         },
            //     })
            //     if (data?.success === true) {
            //         if (data.message === "Room already Created") {
            //             getRoom(data?.result?.room_id)
            //             getMyRoom()
            //             handleModelClose()
            //         }
            //         else {
            //             getRoom(data?.result?.room_id)
            //             handleModelClose()
            //             getMyRoom()
            //         }

            //     }
            //     else {
            //         toast.error(data?.message)
            //     }
            // }

        } catch (error) {
            console.log("error:", error)
        }


    }


    const [anchorEl2, setAnchorEl2] = useState(null);

    const handleplaylist = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover' : undefined;



    const handleChange = (event, newValue) => {
        setValue(newValue);
        setName()
    };

    const navigate = useNavigate()
    const theme = useTheme();

    const [message, setMessage] = useState([]);

    const [updated, setUpdated] = useState('');
    const [openModel, setOpenModel] = React.useState(false);
    const handleOpen = () => setOpenModel(true);
    const handleModelClose = () => setOpenModel(false);


    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const handleClickSend = (e) => {

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };

    const handleDeleteTodo = (index) => {
        const newTodos = [...message];
        newTodos.splice(index, 1);
        setMessage(newTodos);
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            createMessage();
        }
    };





    return (
        <div className="message">
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }}>
                <HeaderNew title={"Message"} />


                <Box component="main"
                    className="library-box"
                    sx={{
                        flexGrow: 1, py: 1,

                        '@media (max-width:991.68px)': {
                            width: (`calc(100% - ${drawerWidth}px)`)
                        },

                        marginTop: '55px'

                    }}>
                    <Grid container spacing={0} className="heading-container">
                        <Container maxWidth="xl">

                        </Container>
                    </Grid>
                    <Grid container spacing={0} sx={{ justifyContent: 'center' }}  >

                        {display === true ?
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={0} sx={{ justifyContent: 'center', marginTop: '50px' }} >
                                    <Grid xs={11} sm={11} md={10} lg={10} xl={10}>
                                        <div className="colab-msg-box">
                                            <div className="colab-avatar-cont vr">
                                                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                                    <ArrowBackIosOutlinedIcon onClick={(() => { setdisplay(false) })} sx={{ fill: '#000' }} />
                                                    <Avatar sx={{ width: 35, height: 35 }} alt="Remy Sharp" src={String(roomdetails?.requestor1) === ids ? roomdetails?.receiverDetails?.profilePicture : roomdetails?.requestorDetails?.profilePicture} />
                                                    <div>
                                                        {String(roomdetails?.requestor1) === ids ? roomdetails?.receiverDetails?.name : roomdetails?.requestorDetails?.name}
                                                    </div>


                                                </Stack>
                                            </div>
                                            <div>


                                                <div className="colab-chat-area msg2">

                                                    {messageList.map((todo, ind) => {
                                                        return (
                                                            // className="chat-area-inner-text user"
                                                            <div className={(String(todo?.from?._id) === ids) ? "chat-area-inner-text user" : "chat-area-inner-text vr"} key={ind}>
                                                                <div>
                                                                    {/* <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist3} /> */}
                                                                </div>
                                                                <div className={(String(todo?.from?._id) === ids) ? "collab-send-text-main2 user" : "collab-send-text-main"}>
                                                                    <div className="username">{(String(todo?.from?._id) === ids) ? "You" : <>{todo?.sender_name}</>}</div>
                                                                    <div className="txt1">{todo?.message}</div>

                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                            <div className="colab-text-input">

                                                <div>
                                                    <input type="text" placeholder="Start typing..."
                                                        value={updated}
                                                        onChange={handleChangeSend}
                                                        onKeyDown={handleEnterKeyPress}

                                                    />
                                                </div>
                                                <div className="colab-send">
                                                    <SendIcon onClick={() => { createMessage() }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid> :
                            <Grid xs={11} sm={11} md={10} lg={10} xl={10}>
                                <Grid container spacing={0} sx={{ justifyContent: 'center', marginTop: '50px' }} >



                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} className="heading-container2">


                                            <div className='msglist'>
                                                <div className='msg-list-head'>
                                                    <div className='chats'>
                                                        <ArrowBackIosOutlinedIcon onClick={(() => { navigate(-1) })} sx={{ fill: '#000' }} />
                                                        Chats
                                                    </div>
                                                    <div className='msg-lsit-head-svg'>
                                                        <Tooltip placement='top' title='Create a new conversation'>
                                                            <ModeEditOutlinedIcon onClick={() => { handleOpen() }} />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div className='msg-list-input'>

                                                    <FormControl fullWidth sx={{ m: 0.5 }}>

                                                        <OutlinedInput
                                                            id="outlined-adornment-amount"
                                                            startAdornment={<InputAdornment position="start"><SearchOutlinedIcon /></InputAdornment>}
                                                            label="Amount"
                                                            className='msg-input'
                                                            placeholder='Search Messenger'
                                                            onChange={((e) => { setSearch(e.target.value) })}
                                                            value={search}
                                                        />
                                                    </FormControl>

                                                </div>
                                                {rooms?.length > 0 ? <>

                                                    <div className='msg-list-area'>
                                                        {rooms?.map((row, ind) => {
                                                            return (
                                                                <div key={ind} className='msg-list-chat-main' >
                                                                    <div onClick={() => { getRoom(row?.room_id) }}>
                                                                        <Avatar src={String(row?.requestor1) === ids ? row?.receiverDetails?.profilePicture : row?.requestorDetails?.profilePicture} sx={{ width: 56, height: 56 }} alt="Remy Sharp" />
                                                                    </div>
                                                                    <div className='msg-list-chat-name' onClick={() => { getRoom(row?.room_id) }}>
                                                                        {String(row?.requestor1) === ids ? row?.receiverDetails?.name : row?.requestorDetails?.name}
                                                                        <div className='chat-desc' id='sentence'>
                                                                            {(row?.room_id?.length > 30) ? row?.room_id.substring(0, 28) + '...' : row?.room_id}
                                                                            <span></span>

                                                                        </div>
                                                                    </div>
                                                                    <span></span>{role === "artist" ? <Button className='write-msg-btn' onClick={() => { closeRoom(row?._id) }}>Close Ticket</Button> :
                                                                        <></>}
                                                                </div>
                                                            )
                                                        })}

                                                    </div>

                                                </> : <>
                                                    <div className='start-a-convo'>
                                                        Start a Conversation!
                                                        <div className='connect-with-auids'>
                                                            You have to Tranfer Dster Token to Connect with Artist by starting a private direct message!
                                                        </div>
                                                        <Button className='write-msg-btn' onClick={() => { handleOpen() }}>Create a new conversation</Button>
                                                    </div>
                                                </>}
                                            </div>

                                        </Grid>
                                    </Grid>




                                </Grid>
                            </Grid>
                        }
                    </Grid>

                    <Modal
                        keepMounted
                        open={openModel}
                        onClose={handleModelClose}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <Box sx={style2}>
                            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', color: 'var(--clr-font)' }} >

                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ borderBottom: '1px solid var(--clr-search-border)', display: 'flex' }}>
                                    <Grid xs={1} sm={1} md={1} lg={1} xl={1} >
                                        <div>
                                            <CloseIcon onClick={handleModelClose} />
                                        </div>
                                    </Grid>

                                </Grid>

                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div id="search-wrap2">

                                        <InputBase
                                            id='search-input'
                                            sx={{ ml: 1, flex: 1 }}
                                            value={receiver ? receiver : ''}
                                            onChange={(e) => { setReceiver(e.target.value) }}
                                            placeholder="Search Artist Here"
                                            inputProps={{ 'aria-label': 'search google maps' }}
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" id='search-input'>
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {/* <div className='new-playlist'>
                                
                                <div>
                                    Select Artist
                                </div>
                            </div> */}
                                </Grid>
                                {receivers?.length > 0 ? (<>

                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='grid-height' >
                                        {receivers && receivers.map((row, ind) => {
                                            return (
                                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id} onClick={() => { setId(row._id); createRoom(row?._id, row?.amount, row?.account) }}>
                                                    {/* onClick={() => { handleAdd(row?._id) }} */}
                                                    <div className='new-playlist created'>
                                                        <div className='d-flex'>
                                                            {row?.profilePicture ? <img src={row?.profilePicture} alt='bg2' /> : <img src={Companylogo} alt='bg2' />}

                                                        </div>
                                                        <div>
                                                            {row?.name ? row?.name : "Wallet User"}{row?.amount}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </>) : (<>
                                    <div className='newno'>
                                        <img src={newno} />
                                    </div>

                                </>)}



                            </Grid>

                        </Box>
                    </Modal>


                </Box>

            </Box>
        </div >
    );
}

export default Message;