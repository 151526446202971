import React, { useEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import DownloadIcon from '@mui/icons-material/Download';
import '../Feed/Feed.css'
import '../TrackPage/TrackPage.css'
import '../ArtistPage/ArtistPage.css'
import Axios from "../../Axios";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Album from '../../img/dster-webp/music-logo.webp';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Companylogo from '../../img/dster-webp/company-logo.webp';
import RepeatIcon from '@mui/icons-material/Repeat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Album1 from '../../img/dster-webp/backstage-fanzone.webp';
import Album2 from '../../img/dster-webp/bs4.webp';
import Album3 from '../../img/dster-webp/music-logo6.webp';
import Album4 from '../../img/dster-webp/music-logo7.webp';
import Album5 from '../../img/dster-webp/music-logo8.webp';
import minilogo from '../../img/dster-webp/company-logo.webp';
import m1 from '../../img/dster-webp/m1.webp';
import m2 from '../../img/dster-webp/m2.webp';
import m3 from '../../img/dster-webp/m3.webp';
import Track from "../Music-traxk/Track";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import { useLocation } from 'react-router-dom';
import { Button, Divider } from '@mui/material';
import exp from '../../img/dster-webp/explore.webp'
import SendTrack from '../ShareLink/SendTrack';
import Avatar from '@mui/material/Avatar';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import PaidIcon from '@mui/icons-material/Paid';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SettingsIcon from '@mui/icons-material/Settings';
import ava1 from "../../img/dster-webp/ava1.webp";
import Switch from '@mui/material/Switch';
import AvatarGroup from '@mui/material/AvatarGroup';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Groups2Icon from '@mui/icons-material/Groups2';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { FaTwitter } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaTiktok } from "react-icons/fa6";
import { FaLink } from "react-icons/fa";
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Header from '../Header/Header';
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Profile from './Profile';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    overflow: 'scroll',
    pt: 2,
    px: 4,
    pb: 3,
};


const drawerWidth = 280;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'var(--clr-bcg)',
    height: '620px',
    // overflow: 'scroll',
    // border: '2px solid #000',
    // boxShadow: 'none',
    p: 4,
    '@media all and (max-width: 1199.98px)': {
        width: '55%'
    },
    '@media all and (max-width: 768.98px)': {
        width: '85%'
    },
    '@media all and (max-width: 575.98px)': {
        width: '95%'
    }
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));


const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});

const MyProfile = () => {
    const [update, setUpdate] = React.useState(false);
    const handleUpdateOpen = () => setUpdate(true);
    const handleUpdateClose = () => setUpdate(false);

    const [value, setValue] = React.useState(0);
    const [music, setMusic] = React.useState([]);
    const [usermusic, setUsermusic] = React.useState([]);
    const [track, setTrack] = React.useState([]);
    const [user, setUser] = React.useState({});
    const [name, setName] = React.useState();
    const [openModel, setOpenModel] = React.useState(false);
    const [image, setImage] = useState(null);
    const [isimage, setisImage] = useState(false);
    const handleOpen = () => setOpenModel(true);
    const handleModelClose = () => setOpenModel(false);
    const [openModel1, setOpenModel1] = React.useState(false);
    const handleOpen1 = () => setOpenModel1(true);
    const handleModelClose1 = () => setOpenModel1(false);
    const [openModel2, setOpenModel2] = React.useState(false);
    const handleOpen2 = () => setOpenModel2(true);
    const handleModelClose2 = () => setOpenModel2(false);
    const [playname, setPlayname] = React.useState();
    const [song_id, setsongid] = React.useState()
    const [musics, setMusics] = React.useState([]);
    const [album, setAlbum] = React.useState([]);
    const [playlist, setPlaylist] = React.useState([]);
    const [play, setPlay] = React.useState([]);
    const params = useParams()
    const [rows, setRows] = useState({})
    const [audiosource, setAudiosource] = React.useState();
    const [count, setCount] = React.useState()
    const tokens = localStorage.getItem("iphephile");
    const navigate = useNavigate()
    const [audioDurations, setAudioDurations] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [profileData, setProfileData] = useState({});
    const classes = useStyles()

    const [prf, setPrf] = useState('')

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className='artist-sidebar-list'>

                <div className='tire-main-div'>
                    <div className='art-mini-logo' >
                        <img src={minilogo} alt='logo' />
                    </div>

                    <div class="vl"></div>
                    <div className='tire-level'>
                        TIER 2
                        <div className='tire-medal'>
                            SILVER
                        </div>
                    </div>
                </div>
                <div className='artist-description-cont'>
                    Artist, DJ, WaveLord. Architect of the Meaningwave® Universe
                </div>
                <div className='artist-nft-link'>
                    Nft:
                    <p>https://opensea.io/AkiraTheDon</p>
                </div>
                <div className='artist-sub-div-follow-stack'>
                    <Stack direction="row" spacing={3}>
                        <FaTwitter />
                        <IoLogoInstagram />
                        <FaTiktok />
                        <FaLink />
                    </Stack>
                </div>
                <div className='TIP-audion-btn'>
                    <Button>
                        <div className='art-mini-logo2' >
                            <img src={minilogo} alt='logo' />
                        </div>
                        Tip $AUDIO
                    </Button>
                </div>
                <div className='top-support'>
                    <EmojiEventsIcon /> Top Supporters
                </div>
                <div className='avatar-div-stack'>
                    <AvatarGroup max={5}>
                        <Avatar alt="Remy Sharp" src={Album1} />
                        <Avatar alt="Remy Sharp" src={Album2} />
                        <Avatar alt="Remy Sharp" src={Album3} />
                        <Avatar alt="Remy Sharp" src={Album4} />
                        <Avatar alt="Remy Sharp" src={Album5} />
                        <Avatar alt="Remy Sharp" src={Album4} />
                        <Avatar alt="Remy Sharp" src={Album2} />

                    </AvatarGroup>
                    <div>
                        View More<ArrowRightAltIcon />
                    </div>
                </div>
                <div className='top-support'>
                    <PersonAddAltIcon /> Mutuals
                </div>
                <div className='avatar-div-stack'>
                    <AvatarGroup max={5}>
                        <Avatar alt="Remy Sharp" src={Album1} />
                        <Avatar alt="Remy Sharp" src={Album2} />
                        <Avatar alt="Remy Sharp" src={Album3} />
                        <Avatar alt="Remy Sharp" src={Album4} />
                        <Avatar alt="Remy Sharp" src={Album5} />
                        <Avatar alt="Remy Sharp" src={Album4} />
                        <Avatar alt="Remy Sharp" src={Album2} />

                    </AvatarGroup>
                    <div>
                        View More<ArrowRightAltIcon />
                    </div>
                </div>
                <div className='top-support'>
                    <Groups2Icon /> Related Artists
                </div>
                <div className='avatar-div-stack'>
                    <AvatarGroup max={5}>
                        <Avatar alt="Remy Sharp" src={Album1} />
                        <Avatar alt="Remy Sharp" src={Album2} />
                        <Avatar alt="Remy Sharp" src={Album3} />
                        <Avatar alt="Remy Sharp" src={Album4} />
                        <Avatar alt="Remy Sharp" src={Album5} />
                        <Avatar alt="Remy Sharp" src={Album4} />
                        <Avatar alt="Remy Sharp" src={Album2} />

                    </AvatarGroup>
                    <div>
                        View More<ArrowRightAltIcon />
                    </div>
                </div>
                <div className='top-support'>
                    <Groups2Icon /> Top tags
                </div>
                <div className='top-tags-content'>
                    <div>meaningwave</div>
                    <div>akirathedon</div>
                    <div>navalravikant</div>
                    <div>kingoflofichristmas</div>
                    <div>meaningwave</div>
                </div>


            </List>

        </Box>
    );


    const handleClickEl2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClickEl3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleCloseEl2 = () => {
        setAnchorEl2(null);
    };
    const handleCloseEl3 = () => {
        setAnchorEl3(null);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMore = (event) => {
        setAnchorE2(event?.currentTarget);
        //    console.log(anchorEl2)
    };


    const handleClose2 = () => {
        setAnchorE2(null);
    };

    const location = useLocation()


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const open2 = Boolean(anchorE2);
    const id2 = open2 ? 'simple-popover' : undefined;

    const open3 = Boolean(anchorEl2);
    const id3 = open3 ? 'simple-popover3' : undefined;

    const handleAdd = async (data) => {

        try {

            const create_data = {
                playlistId: data,
                songId: song_id
            }
            console.log(create_data)

            await Axios.post("/users/edit_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getArtist()
                        handleModelClose()
                        console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }


    }
    const isMount = useRef(false)



    const setAudio = (data) => {
        localStorage.setItem("audiosource", data.audio)
        localStorage.setItem("songName", data.song_name)
        localStorage.setItem("current", 0)
        localStorage.setItem("isPause", false)
        setAudiosource(data)

    }

    const createMusic = async (data) => {
        try {

            const create_data = {
                song_id: data
            }
            console.log(create_data)

            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getArtist()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }
    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener('loadedmetadata', () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };


    useEffect(() => {
        if (usermusic.length > 0) {
            usermusic.forEach((row) => {
                getAudioDuration(row?.audio);
            });

        }
    }, [usermusic]);

    useEffect(() => {
        if (music) {

            getAudioDuration(music?.audio);


        }
    }, [music]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // useEffect(() => {
    //     getArtist()


    // }, [])

    const handleClicks = (event) => {
        hiddenFileInput.current.click();
    };

    const hiddenFileInput = useRef(null);
    const handleImageChange = async (event) => {
        const files = event.target.files[0];

        try {
            let formData = await new FormData();
            formData.append("image", files);
            const fileName = files.name
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {
                    // setLogo(data.result)
                    // console.log(data.result,'Total BalanceTotal BalanceTotal Balance');
                    setisImage(true)

                    setImage(data.result);
                    const updateData = {
                        name: profileData.name,
                        // phone: profileData?.result?.phone,
                        coverImage: profileData.coverImage,
                        profilePicture: data.result,
                    };
                    await Axios.post("/updateProfile", updateData, {
                        headers: {
                            Authorization: tokens,
                        },
                    }).then((res) => {
                        // console.log(res,"res")
                        if (res.data.success) {
                            toast.success("Image uploaded Successfully", {
                                duration: 500, // Set the duration for which the toast is visible
                            });
                            getArtist();

                        }
                    });
                }
            } else {
                setImage(null)
                toast.error("Only jpg, jpeg, png and gif files are allowed!");
            }

        } catch (error) {
            setisImage(false)

            console.log(error, "err");
        }
    };


    const getArtist = async () => {
        try {


            const datas = await Axios.post("/followers/get_artist_details", '', {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        console.log(res.data?.result?.UserDetails[0], "res")

                        setUser(res.data?.result?.UserDetails[0])
                        setTrack(res.data?.result?.SongDetails)
                        setAlbum(res.data?.result?.AlbumDetails)
                        setPlaylist(res.data?.result?.PlaylistDetails)
                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setUser({})
                        setTrack([])
                        setAlbum([])
                        setPlaylist([])

                    }
                }).catch((err) => {
                    console.log(err, "err")

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const editSong = async (data) => {
        try {

            const create_data = {
                songId: data.id,
                status: data.status
            }
            console.log(create_data)

            await Axios.post("/users/edit_song_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getArtist()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const editAlbums = async (data) => {
        try {

            const create_data = {
                album_id: data.id,
                status: data.status
            }
            console.log(create_data)

            await Axios.post("/users/edit_album_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        //   toast.success(res?.data?.message)
                        getArtist()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const editPlaylists = async (data) => {
        try {

            const create_data = {
                album_id: data.id,
                status: data.status
            }
            console.log(create_data)

            await Axios.post("/users/edit_playlist_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getArtist()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addLibrary = async (data) => {
        try {

            const create_data = {
                song_id: data
            }
            console.log(create_data)

            await Axios.post("/users/add_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        // toast.success(res?.data?.message)
                        getArtist()
                        console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addFavorite = async (data) => {
        try {

            const create_data = {
                song_id: data
            }
            console.log(create_data)

            await Axios.post("/users/add_to_favourite", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getArtist()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addalbumFavorite = async (data) => {
        try {
            console.log(data)
            const create_data = {
                album_id: data
            }
            console.log(create_data)

            await Axios.post("/users/add_fav_album", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getArtist()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }
    const addplayFavorite = async (data) => {
        try {

            const create_data = {
                album_id: data
            }
            console.log(create_data)

            await Axios.post("/users/add_fav_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getArtist()
                        console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addalbumLibrary = async (data) => {
        try {

            const create_data = {
                album_id: data
            }
            console.log(create_data)

            await Axios.post("/users/add_album_to_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getArtist()
                        console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addplayLibrary = async (data) => {
        try {

            const create_data = {
                play_id: data
            }
            console.log(create_data)

            await Axios.post("/users/add_playlist_to_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res, "res")
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getArtist()
                        console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    useEffect(() => {
        getplayLists()
    }, [playname])
    const getplayLists = async () => {
        try {


            const datas = await Axios.post("/users/get_my_playlist", {
                playname: playname
            }, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res?.data?.result?.musicList,"res")
                    if (res?.data?.success) {
                        // toast.success(res?.data?.message)
                        getArtist()
                        console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addPlay = (data) => {
        // console.log(data,"response")
        // setsongid(data)
        handleOpen()
        handleCloseEl2()
        handleClose()
    }



    return (
        <div className='feed trackPage'>
            <ToastContainer
                position="top-right"
                reverseOrder={true}
            />
            <Box sx={{ display: 'flex' }} className="box-flex">


                <Box component="main"
                    className="feed-bg"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px' }}>
                    <Container maxWidth="lg" id='myprofile-head'>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Item className={classes.containWidth}>
                                <Header name={name} setName={setName} />
                            </Item>
                        </Grid>
                    </Container>


                    <Grid container spacing={0}>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            {prf.coverImage ? (<div className='trackpage-bgimg-div'>
                                <img src={prf.coverImage} alt='bg' />

                            </div>
                            ) : <div className='trackpage-bgimg-div'>
                                <img src={Album1} alt='bg' />

                            </div>
                            }
                        </Grid>
                    </Grid>

                    <Grid container spacing={0} className='trackpage-grid'>
                        <Grid xs={12} sm={12} md={10} lg={11} xl={10}>

                        </Grid>
                    </Grid>
                    <Container maxWidth="md">
                        <Grid container spacing={0} sx={{ marginTop: '-145px' }}>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='artist-page-pannel'>
                                    {prf.profilePicture ? (<div>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={prf.profilePicture}
                                            sx={{ width: 200, height: 200, border: '2px solid #fff' }}
                                        />
                                    </div>
                                    ) : (
                                        <div>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src={Album}
                                                sx={{ width: 200, height: 200, border: '2px solid #fff' }}
                                            />
                                        </div>
                                    )}
                                    <div className='follow-inner2'>
                                        <div className='artist-tag'>
                                            ARTIST
                                        </div>
                                        <div className='artist-name-tag'>
                                            {user?.name}
                                            <div className='artist-user-name'>
                                                @{user?.name}
                                            </div>
                                            <div className='virtualmeet-btn'>
                                                <Button onClick={(() => { navigate('/createvirtualmeet') })}>Virtual Meet +</Button>
                                                <Button onClick={(() => { navigate('/createexclusive') })}>Exclusive Releases +</Button>
                                                <Button>Polls & Surveys +</Button>
                                            </div>

                                        </div>
                                        <div className='artist-follow-main'>
                                            <div className='follow-inner-artist'>
                                                <div className='artist-track'>
                                                    {track?.length}
                                                    <div className='artist-track-inner'>
                                                        TRACKS
                                                    </div>
                                                </div>
                                                <div className='artist-track'>
                                                    {user?.TotalFollowers}
                                                    <div className='artist-track-inner'>
                                                        FOLLOWERS
                                                    </div>
                                                </div>
                                                <div className='artist-track'>
                                                    {user?.TotalFollowings}
                                                    <div className='artist-track-inner'>
                                                        FOLLOWING
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='artist-follow-more-msg-mian'>
                                                <div className="more-svg-artistpage">
                                                    <MoreHorizIcon onClick={handleClickMore} />

                                                    <Popover
                                                        id={id2}
                                                        open={open2}
                                                        anchorEl={anchorE2}
                                                        onClose={handleClose2}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        className='playlist-popover'
                                                    >
                                                        <div className='playlist-more-div' onClick={(() => { navigate('/mymessage') })} >Messages</div>
                                                        <div className='playlist-more-div' ><PaidIcon />Pay & Earn</div>
                                                        <div className='playlist-more-div' ><ShowChartIcon />Artist Dashboard</div>
                                                        <div className='playlist-more-div' ><EmojiEventsIcon />Rewards</div>
                                                        <div className='playlist-more-div' ><SettingsIcon />Settings</div>
                                                    </Popover>
                                                </div>
                                                <div className="more-svg-artistpage">
                                                    <InsertCommentIcon onClick={(() => { navigate(`/mymessage`) })} />
                                                </div>
                                                {/* <div className='artist-follow-btn'>
                                                    {user?.isfollow === false ? <Button onClick={()=>{followUser(params._id)}}><PersonAddAlt1Icon />Follow</Button> : <Button ><PersonAddAlt1Icon />Followed</Button>}
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='artist-pg-tab-main-div'>
                                            <Box sx={{ width: '100%' }}>
                                                <Box >
                                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="library-tab2">
                                                        <Tab label="TRACKS" {...a11yProps(0)} iconPosition="start" icon={<img src={m1} />} />
                                                        <Tab label="ALBUMS" {...a11yProps(1)} iconPosition="start" icon={<img src={m2} />} />
                                                        <Tab label="PLAYLISTS" {...a11yProps(2)} iconPosition="start" icon={<img src={m3} />} />
                                                        <Tab label="REPORTS" {...a11yProps(3)} />
                                                        <Tab label="Details"{...a11yProps(4)} />
                                                    </Tabs>
                                                </Box>

                                            </Box>
                                        </div>

                                    </div>
                                </div>

                            </Grid>
                        </Grid>


                        <Grid container spacing={0} >
                            {['left'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <Button onClick={toggleDrawer(anchor, true)} className='artist-explore-more myprofile'>Exploremore<img src={exp} alt='exp' /></Button>
                                    <Drawer
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                    >
                                        {list(anchor)}
                                    </Drawer>
                                </React.Fragment>
                            ))}

                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomTabPanel value={value} index={0}>
                                    <Grid container spacing={2}>
                                        {track.length > 0 ? <>{track && track.map((row, index) => {
                                            return <>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id}>

                                                    <div className='feed-music-box' >
                                                        <div className='music-detail'>
                                                            <div className='album-img'>
                                                                <img src={row?.image ? row?.image : row?.image} alt={Album} />

                                                            </div>

                                                            <div className='album-detail'>
                                                                <div className='music-time'>
                                                                    <div className='time-music'>
                                                                        <div className='music-name'>

                                                                            {row?.type} {row?.nft_type === "per" ? <><VerifiedIcon className='premium' /></> : <></>}



                                                                            <div onClick={((e) => { navigate(`/trackpage/${row._id}`, { state: { row } }) })} >{row?.song_name}-{row?.ISRC}</div>


                                                                        </div>
                                                                        <div className='link-badge'>
                                                                            <Link to=''>{row?.nft_link}</Link>


                                                                            {/* {audioDurations[row?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }} />
                                                                                : <img src={Mbadge} alt={Mbadge} />} */}



                                                                        </div>
                                                                    </div>
                                                                    <div className='music-timing'>
                                                                        {audioDurations[row?.audio] !== undefined ? <span>{audioDurations[row?.audio]}</span> : <span>-</span>}

                                                                    </div>
                                                                </div>
                                                                <div className='loop-times'>
                                                                    <div className='loop-part'>
                                                                        <div className='company-logo'>


                                                                            {audioDurations[row?.audio] !== undefined && row?.nft_type === "nor" || row?.isMine === true ? <img onClick={() => { createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                : <img src={Companylogo} alt={Companylogo} />}


                                                                        </div>
                                                                        <div className='repost-loop'>

                                                                            <p> <RepeatIcon className='repeat' />{row?.reposts?.length ? row?.reposts?.length : 0} reposts</p>

                                                                        </div>
                                                                        <div className='repost-loop'>
                                                                            <p> <FavoriteIcon className='unfav' />{row?.fav_count ? row?.fav_count : 0} Favorite</p>


                                                                        </div>
                                                                    </div>
                                                                    <div className='music-timing'>
                                                                        <p>{row?.players?.length ? row?.players?.length : 0} Plays</p>

                                                                    </div>
                                                                </div>
                                                                <div className='like-dot'>
                                                                    <div className='share-btn'>
                                                                        {row?.isRepost === true ? <RepeatIcon className='repeat' onClick={() => { editSong({ id: row?._id, status: "repost" }) }} /> : <RepeatIcon onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />}




                                                                        {row?.userFav === true ? <FavoriteIcon className="unfav" onClick={() => { addFavorite(row?._id) }} /> : <FavoriteIcon onClick={() => { addFavorite(row?._id) }} />}



                                                                        <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`} />

                                                                        {row?.nftclaim !== 0 && row?.userFound === false && row?.isMine === false ? <DownloadIcon onClick={() => { addLibrary(row?._id) }} /> : <></>}






                                                                    </div>
                                                                    <div className='music-timing'>
                                                                        {row?.isMine === true ? <Switch {...label} checked={row?.opentoeveryone} onClick={() => { editSong({ id: row?._id, status: "open" }) }} /> : <></>}
                                                                        <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClick(e); setsongid(row?._id); setRows(row) }} />

                                                                        <Popover
                                                                            id={id}
                                                                            open={open}
                                                                            anchorEl={anchorEl}
                                                                            onClose={handleClose}
                                                                            anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'left',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'right',
                                                                            }}
                                                                            className='playlist-popover'
                                                                        >
                                                                            <div className='playlist-more-div' onClick={() => addPlay(row._id)}>Add to Playlists</div>
                                                                            <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`, { state: { rows } }) })}>Visit Track Page</div>
                                                                            <div className='playlist-more-div' >Visit Artist Page</div>
                                                                        </Popover>
                                                                    </div>
                                                                </div>


                                                            </div>

                                                        </div>

                                                    </div>



                                                </Grid>
                                            </>
                                        })}</> : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='feed-music-box'>
                                                <div className='music-detail'>


                                                    <div className='album-detail'>

                                                        <div className='no-music'>
                                                            <div className='time-music'>
                                                                <div className='music-name'>
                                                                    <h4>No Data Found</h4>
                                                                </div>

                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>}
                                    </Grid>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1} className="cust-pannel-artistpage">
                                    {album.length > 0 ? <>{album && album.map((row, index) => {
                                        return <>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id}>

                                                <div className='feed-music-box' >
                                                    <div className='music-detail'>
                                                        <div className='album-img'>
                                                            <img src={row?.image ? row?.image : ava1} alt={Album} />

                                                        </div>

                                                        <div className='album-detail'>
                                                            <div className='music-time'>
                                                                <div className='time-music'>
                                                                    <div className='music-name' style={{ "textAlign": "left" }}>


                                                                        <div onClick={((e) => { navigate(`/showplaylist/${row._id}`, { state: { row } }) })} >{row?.playlist}</div>


                                                                    </div>
                                                                    <div className='link-badge'>
                                                                        Created By <Link to=''>{row?.createUser?.name}</Link>


                                                                        {/* {audioDurations[row?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }} />
                                                                            : <img src={Mbadge} alt={Mbadge} />} */}



                                                                    </div>
                                                                </div>
                                                                <div className='music-timing'>
                                                                    {/* {audioDurations[row?.audio] !== undefined ? <span>{audioDurations[row?.audio]}</span> : <span>-</span>} */}

                                                                </div>
                                                            </div>
                                                            <div className='loop-times'>
                                                                <div className='loop-part'>
                                                                    <div className='company-logo'>


                                                                        {audioDurations[row?.audio] !== undefined && row?.nft_type === "nor" || row?.isMine === true ? <img onClick={() => { createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                            : <img src={Companylogo} alt={Companylogo} />}


                                                                    </div>
                                                                    <div className='repost-loop'>

                                                                        <p> <RepeatIcon className='repeat' />{row?.reposts?.length ? row?.reposts?.length : 0} reposts</p>

                                                                    </div>
                                                                    <div className='repost-loop'>
                                                                        <p> <FavoriteIcon className='unfav' />{row?.fav_count ? row?.fav_count : 0} Favorite</p>


                                                                    </div>
                                                                </div>
                                                                <div className='music-timing'>
                                                                    <p>{row?.players?.length ? row?.players?.length : 0} Plays</p>

                                                                </div>
                                                            </div>
                                                            <div className='like-dot'>
                                                                <div className='share-btn'>
                                                                    {row?.isRepost === true ? <RepeatIcon className='repeat' onClick={() => { editAlbums({ id: row?._id, status: "repost" }) }} /> : <RepeatIcon onClick={() => { editAlbums({ id: row?._id, status: "repost" }) }} />}




                                                                    {row?.userFav === true ? <FavoriteIcon className="unfav" onClick={() => { addalbumFavorite(row?._id) }} /> : <FavoriteIcon onClick={() => { addalbumFavorite(row?._id) }} />}



                                                                    {/* <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`} /> */}

                                                                    {row?.nftclaim !== 0 && row?.userFound === false && row?.isMine === false ? <DownloadIcon onClick={() => { addalbumLibrary(row?._id) }} /> : <></>}






                                                                </div>
                                                                <div className='music-timing'>
                                                                    {/* {row?.isMine === true ? <Switch {...label} checked={row?.opentoeveryone} onClick={() => { editSong({ id: row?._id, status: "open" }) }} /> : <></>} */}
                                                                    <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClick(e); setsongid(row?._id); setRows(row) }} />

                                                                    <Popover
                                                                        id={id}
                                                                        open={open}
                                                                        anchorEl={anchorEl}
                                                                        onClose={handleClose}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        className='playlist-popover'
                                                                    >

                                                                        <div className='playlist-more-div' onClick={((e) => { navigate(`/albumpage/${rows._id}`, { state: { rows } }) })}>Visit Album Page</div>
                                                                        <div className='playlist-more-div' >Visit Artist Page</div>
                                                                    </Popover>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>

                                                </div>



                                            </Grid>
                                        </>
                                    })}</> : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='feed-music-box'>
                                            <div className='music-detail'>


                                                <div className='album-detail'>

                                                    <div className='no-music'>
                                                        <div className='time-music'>
                                                            <div className='music-name'>
                                                                <h4>No Data Found</h4>
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </Grid>}
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={2} className="cust-pannel-artistpage">
                                    {playlist.length > 0 ? <>{playlist && playlist.map((row, index) => {
                                        return <>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id}>

                                                <div className='feed-music-box' >
                                                    <div className='music-detail'>
                                                        <div className='album-img'>
                                                            <img src={row?.image ? row?.image : ava1} alt={Album} />

                                                        </div>

                                                        <div className='album-detail'>
                                                            <div className='music-time'>
                                                                <div className='time-music'>
                                                                    <div className='music-name' style={{ "textAlign": "left" }}>


                                                                        <div onClick={((e) => { navigate(`/showplaylist/${row._id}`, { state: { row } }) })} >{row?.playlist}</div>


                                                                    </div>
                                                                    <div className='link-badge'>
                                                                        Created By <Link to=''>{row?.createUser?.name}</Link>


                                                                        {/* {audioDurations[row?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }} />
                                                                            : <img src={Mbadge} alt={Mbadge} />} */}


                                                                    </div>
                                                                </div>
                                                                <div className='music-timing'>
                                                                    {/* {audioDurations[row?.audio] !== undefined ? <span>{audioDurations[row?.audio]}</span> : <span>-</span>} */}

                                                                </div>
                                                            </div>
                                                            <div className='loop-times'>
                                                                <div className='loop-part'>
                                                                    <div className='company-logo'>


                                                                        {audioDurations[row?.audio] !== undefined && row?.nft_type === "nor" || row?.isMine === true ? <img onClick={() => { createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                            : <img src={Companylogo} alt={Companylogo} />}


                                                                    </div>
                                                                    <div className='repost-loop'>

                                                                        <p> <RepeatIcon className='repeat' />{row?.reposts?.length ? row?.reposts?.length : 0} reposts</p>

                                                                    </div>
                                                                    <div className='repost-loop'>
                                                                        <p> <FavoriteIcon className='unfav' />{row?.fav_count ? row?.fav_count : 0} Favorite</p>


                                                                    </div>
                                                                </div>
                                                                <div className='music-timing'>
                                                                    <p>{row?.players?.length ? row?.players?.length : 0} Plays</p>

                                                                </div>
                                                            </div>
                                                            <div className='like-dot'>
                                                                <div className='share-btn'>
                                                                    {row?.isRepost === true ? <RepeatIcon className='repeat' onClick={() => { editPlaylists({ id: row?._id, status: "repost" }) }} /> : <RepeatIcon onClick={() => { editPlaylists({ id: row?._id, status: "repost" }) }} />}




                                                                    {row?.userFav === true ? <FavoriteIcon className="unfav" onClick={() => { addplayFavorite(row?._id) }} /> : <FavoriteIcon onClick={() => { addplayFavorite(row?._id) }} />}



                                                                    {/* <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`} /> */}

                                                                    {row?.nftclaim !== 0 && row?.userFound === false && row?.isMine === false ? <DownloadIcon onClick={() => { addplayLibrary(row?._id) }} /> : <></>}






                                                                </div>
                                                                <div className='music-timing'>
                                                                    {row?.isMine === true ? <Switch {...label} checked={row?.opentoeveryone} onClick={() => { editSong({ id: row?._id, status: "open" }) }} /> : <></>}
                                                                    <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClick(e); setsongid(row?._id); setRows(row) }} />

                                                                    <Popover
                                                                        id={id}
                                                                        open={open}
                                                                        anchorEl={anchorEl}
                                                                        onClose={handleClose}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        className='playlist-popover'
                                                                    >

                                                                        <div className='playlist-more-div' onClick={((e) => { navigate(`/showplaylist/${rows._id}`, { state: { rows } }) })}>Visit Playlist Page</div>
                                                                        <div className='playlist-more-div' >Visit Artist Page</div>
                                                                    </Popover>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>

                                                </div>



                                            </Grid>
                                        </>
                                    })}</> : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='feed-music-box'>
                                            <div className='music-detail'>


                                                <div className='album-detail'>

                                                    <div className='no-music'>
                                                        <div className='time-music'>
                                                            <div className='music-name'>
                                                                <h4>No Data Found</h4>
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </Grid>}
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={3} className="cust-pannel-artistpage">
                                    Bafu hasn’t created any albums yet...
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={4} className="cust-pannel-artistpage">
                                    <Profile prf={prf} setPrf={setPrf} />
                                </CustomTabPanel>


                            </Grid>
                        </Grid>
                        <div>

                            {audiosource ? <Track audiosource={audiosource} /> : <></>}

                        </div>
                        <Modal
                            keepMounted
                            open={openModel}
                            onClose={handleModelClose}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                        >
                            <Box sx={style2}>
                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', color: 'var(--clr-font)' }} >

                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ borderBottom: '1px solid var(--clr-search-border)', display: 'flex' }}>
                                        <Grid xs={1} sm={1} md={1} lg={1} xl={1} >
                                            <div>
                                                <CloseIcon onClick={handleModelClose} />
                                            </div>
                                        </Grid>
                                        <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                                            <div className='edit-playlist-title'>
                                                {/* <QueueMusicIcon /> */}
                                                Add to Playlist
                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div id="search-wrap2">

                                            <InputBase
                                                id='search-input'
                                                sx={{ ml: 1, flex: 1 }}
                                                value={playname ? playname : ''}
                                                onChange={(e) => setPlayname(e.target.value)}
                                                placeholder="Search your music"
                                                inputProps={{ 'aria-label': 'search google maps' }}
                                            />
                                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" id='search-input'>
                                                <SearchIcon />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='new-playlist' onClick={((e) => { navigate('/createplaylist', { state: { song_id } }) })}>
                                            <AddCircleIcon />
                                            <div>
                                                New Playlist
                                            </div>
                                        </div>
                                    </Grid>
                                    {play.length > 0 ? (<>

                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='grid-height' >
                                            {play && play.map((row, ind) => {
                                                return (
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id} onClick={() => { handleAdd(row?._id) }}>
                                                        <div className='new-playlist created'>
                                                            <div className='d-flex'>
                                                                {row?.image ? <img src={row?.image} alt='bg2' /> : <img src={Companylogo} alt='bg2' />}
                                                                {/* <img src={row?.image} alt='img' /> */}
                                                            </div>
                                                            <div>
                                                                {row?.playlist}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </>) : (<>
                                        <div >
                                            No Data Found
                                        </div>

                                    </>)}



                                </Grid>

                            </Box>
                        </Modal>




                    </Container>
                </Box>
            </Box>
        </div >
    )
}

export default MyProfile
